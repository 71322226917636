import { Identifier } from 'react-admin';
import { Socket as SocketIO } from 'socket.io-client';

import {
  CancelTypingEventDTO,
  Discussion,
  IsTypingEventDTO,
} from './discussion';
import { DiscussionEvent } from './discussionEvent';
import { Handover, HandoverRevive } from './handover';
import { MedicalFollowUp } from './medicalFollowUp';
import { Subject } from './subject';

export enum SocketEvents {
  NEW_DISCUSSION_EVENT = 'NEW_DISCUSSION_EVENT',
  UPDATE_DISCUSSION_EVENT = 'UPDATE_DISCUSSION_EVENT',
  NEW_HANDOVER = 'NEW_HANDOVER',
  UPDATE_SUBJECT = 'UPDATE_SUBJECT',
  CLOSED_SUBJECT = 'CLOSED_SUBJECT',
  UPDATE_HANDOVER = 'UPDATE_HANDOVER',
  NEW_MEDICAL_FOLLOW_UP = 'NEW_MEDICAL_FOLLOW_UP',
  UPDATE_MEDICAL_FOLLOW_UP = 'UPDATE_MEDICAL_FOLLOW_UP',
  DELETED_MEDICAL_FOLLOW_UP = 'DELETED_MEDICAL_FOLLOW_UP',
  USER_IS_TYPING = 'USER_IS_TYPING',
  USER_CANCELED_TYPING = 'USER_CANCELED_TYPING',
  ROOM_CONNECTED_USERS_CHANGED = 'ROOM_CONNECTED_USERS_CHANGED',
  SOCKET_ENTERED_ROOM = 'SOCKET_ENTERED_ROOM',
  SOCKET_LEFT_ROOM = 'SOCKET_LEFT_ROOM',
  ACTIVE_SESSIONS_LIST_CHANGED = 'ACTIVE_SESSIONS_LIST_CHANGED',
  SHOW_ACTIVITY_POPUP = 'SHOW_ACTIVITY_POPUP',
  USER_SENT_SOCKET_TOKEN = 'USER_SENT_SOCKET_TOKEN',
  NEW_HANDOVER_REVIVE = 'NEW_HANDOVER_REVIVE',
  UPDATE_HANDOVER_REVIVE = 'UPDATE_HANDOVER_REVIVE',
  CLOSE_HANDOVER_REVIVE = 'CLOSE_HANDOVER_REVIVE',
  DELETE_HANDOVER_REVIVE = 'DELETE_HANDOVER_REVIVE',
  SUBJECT_ATTRIBUTION_ADDED = 'SUBJECT_ATTRIBUTION_ADDED',
  SUBJECT_ATTRIBUTION_REMOVED = 'SUBJECT_ATTRIBUTION_REMOVED',
  SUBJECT_ADDED_TO_WAITING_LIST = 'SUBJECT_ADDED_TO_WAITING_LIST',
  SUBJECT_REMOVED_FROM_WAITING_LIST = 'SUBJECT_REMOVED_FROM_WAITING_LIST',
}

/**
 * Those types are mapped identically in src/gateways/constant.ts
 * If you update one here it should be made accordingly in the backend file
 */
export type EventHandlerTypes = {
  [SocketEvents.NEW_DISCUSSION_EVENT]: (arg: {
    discussion: Discussion;
    event: DiscussionEvent;
  }) => void;
  [SocketEvents.UPDATE_DISCUSSION_EVENT]: (arg: {
    event: DiscussionEvent;
    discussionId: Identifier;
  }) => void;
  [SocketEvents.NEW_HANDOVER]: (arg: {
    handover: Handover;
    subjectId: Identifier;
  }) => void;
  [SocketEvents.UPDATE_HANDOVER]: (arg: Handover) => void;
  [SocketEvents.CLOSED_SUBJECT]: (arg: Discussion) => void;
  [SocketEvents.UPDATE_SUBJECT]: (arg: {
    subject: Subject;
    discussion: Discussion;
  }) => void;
  [SocketEvents.NEW_MEDICAL_FOLLOW_UP]: (arg: MedicalFollowUp) => void;
  [SocketEvents.UPDATE_MEDICAL_FOLLOW_UP]: (arg: MedicalFollowUp) => void;
  [SocketEvents.DELETED_MEDICAL_FOLLOW_UP]: (arg: MedicalFollowUp) => void;
  [SocketEvents.USER_IS_TYPING]: (argo0: IsTypingEventDTO) => void;
  [SocketEvents.USER_CANCELED_TYPING]: (argo0: CancelTypingEventDTO) => void;
  [SocketEvents.ROOM_CONNECTED_USERS_CHANGED]: (argo0: {
    room: string;
    users: string[];
  }) => void;
  [SocketEvents.ACTIVE_SESSIONS_LIST_CHANGED]: (argo0: {
    users: number[];
  }) => void;
  [SocketEvents.SHOW_ACTIVITY_POPUP]: () => void;
  [SocketEvents.NEW_HANDOVER_REVIVE]: (arg: { revive: HandoverRevive }) => void;
  [SocketEvents.UPDATE_HANDOVER_REVIVE]: (arg: {
    revive: HandoverRevive;
  }) => void;
  [SocketEvents.CLOSE_HANDOVER_REVIVE]: (arg: {
    revive: HandoverRevive;
  }) => void;
  [SocketEvents.DELETE_HANDOVER_REVIVE]: (arg: {
    reviveId: HandoverRevive['id'];
    discussionId: Identifier;
  }) => void;
  [SocketEvents.SUBJECT_ATTRIBUTION_ADDED]: (arg: {
    subjectIds: number[];
    staffUserIds: number[];
  }) => void;
  [SocketEvents.SUBJECT_ATTRIBUTION_REMOVED]: (arg: {
    subjectIds: number[];
    staffUserIds: number[];
  }) => void;
  [SocketEvents.SUBJECT_ADDED_TO_WAITING_LIST]: (arg: {
    subjectId: number;
  }) => void;
  [SocketEvents.SUBJECT_REMOVED_FROM_WAITING_LIST]: (arg: {
    subjectId: number;
  }) => void;
};

type EventEmitTypes = {
  [SocketEvents.USER_SENT_SOCKET_TOKEN]: (argo0: { token: string }) => void;
  [SocketEvents.SOCKET_ENTERED_ROOM]: (argo0: { room: string }) => void;
  [SocketEvents.SOCKET_LEFT_ROOM]: (argo0: { room: string }) => void;
  [SocketEvents.USER_IS_TYPING]: (argo0: IsTypingEventDTO) => void;
  [SocketEvents.USER_CANCELED_TYPING]: (argo0: CancelTypingEventDTO) => void;
};

export type SocketHandlerGenerator<T extends Array<any>> = Partial<{
  [Event in keyof EventHandlerTypes]: (...args: T) => EventHandlerTypes[Event];
}>;

export class RoomContentEmitter {
  private roomUsers: string[] = [];
  private listeners: Function[];

  constructor() {
    this.listeners = [];
  }

  public addListener = (callback: (data: any) => void) => {
    this.listeners = this.listeners || [];
    this.listeners.push(callback);
    callback(this.roomUsers);
    return () => {
      this.listeners = this.listeners.filter(
        (listener) => listener !== callback,
      );
      return this.listeners.length;
    };
  };

  public emit = (users: string[]) => {
    this.roomUsers = users;
    this.listeners.forEach((listener) => listener(users));
  };

  public getRoomUsers = () => this.roomUsers;
}

export type Socket = SocketIO<EventHandlerTypes, EventEmitTypes> & {
  onRoomUsersChanged: (
    room: string,
    callback: (users: string[]) => void,
  ) => () => void;
  roomContentEmitters: Record<string, RoomContentEmitter>;
  getUsersInRoom: (room: string) => string[];
};
