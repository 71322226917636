import {
  BooleanField,
  Datagrid,
  EditButton,
  SearchInput,
  TextField,
} from 'react-admin';

import { List } from '../../components/generic/List';

const PlanFilters = [<SearchInput source="q" alwaysOn variant="standard" />];

export const PlanList = (props) => (
  <List
    {...props}
    exporter={false}
    sx={{ padding: '1rem', overflow: 'auto' }}
    title="Plans"
    filters={PlanFilters}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="Nom" />
      <TextField source="description" label="Description" />
      <BooleanField source="active" label="Active" />
      <EditButton />
    </Datagrid>
  </List>
);
