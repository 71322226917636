import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { ErrorBoundary } from 'react-error-boundary';
import { Document, Page, pdfjs } from 'react-pdf';

import { DialogTitle } from '@components/generic/Dialog';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

import { usePlanningSummary } from './hooks';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const InvoiceViewer = ({ invoiceUrl }: { invoiceUrl: string }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const translate = useTranslate();

  const onDocumentLoadSuccess = ({
    numPages: _numPages,
  }: {
    numPages: number;
  }): void => {
    setNumPages(_numPages);
  };

  const next = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previous = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 10rem)',
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          alignSelf: 'center',
        }}
      >
        <ErrorBoundary
          fallback={<div>{translate('common.errorBoundary')}</div>}
        >
          <Document
            file={invoiceUrl}
            options={{ withCredentials: true }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </ErrorBoundary>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          Page {pageNumber} / {numPages}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={previous}
            startIcon={<ArrowBackIos />}
            variant="text"
            disabled={!numPages || pageNumber <= 1}
          >
            {translate('common.previous')}
          </Button>
          <Button
            onClick={next}
            endIcon={<ArrowForwardIos />}
            variant="text"
            disabled={!numPages || pageNumber >= numPages}
          >
            {translate('common.next')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const InvoiceDialog = ({
  open,
  onClose,
  invoiceUrl,
  withTotal = false,
  planningId,
  staffUserId,
}: {
  open: boolean;
  onClose: () => void;
  invoiceUrl: string;
  withTotal?: boolean;
  planningId?: string;
  staffUserId?: number;
}) => {
  const translate = useTranslate();
  const { summary } = usePlanningSummary(planningId, staffUserId, {
    enabled: Boolean(open && withTotal && planningId && staffUserId),
  });

  if (!invoiceUrl) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle onClose={onClose}>
        {translate('invoice.viewerTitle')}
      </DialogTitle>
      <DialogContent>
        {withTotal && (
          <Typography variant="h6" gutterBottom>
            {translate('invoice.total', {
              total: summary?.invoiceData.total,
            })}
          </Typography>
        )}
        <InvoiceViewer invoiceUrl={invoiceUrl} />
      </DialogContent>
    </Dialog>
  );
};
