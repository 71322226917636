import LockIcon from '@mui/icons-material/Lock';
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { COLORS } from '../../themes';

const Root = styled('div')({
  justifyContent: 'center',
  display: 'flex',
});

const StyledAvatar = styled(Avatar)({
  margin: '1em',
  backgroundColor: COLORS.GREEN['500'],
});

const LockAvatar = () => (
  <Root>
    <StyledAvatar>
      <LockIcon />
    </StyledAvatar>
  </Root>
);

export default LockAvatar;
