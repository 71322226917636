import get from 'lodash/get';
import { LabeledProps } from 'ra-ui-materialui';
import { RecordContextProvider } from 'react-admin';
import { TextField, Labeled, sanitizeInputRestProps } from 'react-admin';

import { Typography } from '@mui/material';

interface ShyTextFieldProps extends Omit<LabeledProps, 'children'> {
  inline?: boolean;
  oneline?: boolean;
}

type ObjectWithKey<T extends string> = Record<string, any> & {
  [key in T]?: any;
};
interface ShyTextProps<T extends string> extends ShyTextFieldProps {
  source: T;
  record: ObjectWithKey<T>;
}

export function ShyText<T extends string>({
  source,
  inline = false,
  oneline = false,
  label = '',
  component,
  record,
  ...inputProps
}: ShyTextProps<T>) {
  const props = sanitizeInputRestProps(inputProps);
  const value = get(record, source);
  if (!value) {
    return null;
  }

  return oneline ? (
    <Typography
      fontSize={'0.75em'}
      variant="caption"
      component="p"
      color="primary"
    >
      {`${label} `}
      <Typography
        fontSize={'0.875rem'}
        variant="caption"
        component="span"
        sx={{
          whiteSpace: 'pre-wrap',
          display: 'block',
          lineHeight: '1.3125rem',
        }}
      >
        {value}
      </Typography>
    </Typography>
  ) : inline ? (
    <div>
      <Typography variant="caption" component="span" color="primary">
        {label}
      </Typography>
      <RecordContextProvider value={record}>
        <TextField source={source} component={component} {...props} />
      </RecordContextProvider>
    </div>
  ) : (
    <RecordContextProvider value={record}>
      <Labeled source={source} label={label}>
        <TextField
          source={source}
          component={component}
          {...props}
          style={{ padding: 0, ...props.style }}
        />
      </Labeled>
    </RecordContextProvider>
  );
}
