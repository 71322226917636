import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import { JobTitle } from '@boTypes/user';
import { consultationStatusStylesMapping } from '@components/fields/StatusChipField';
import { Loader } from '@components/Loader';
import { Phone, Videocam } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';

import { useConsultations } from './hooks';
import { useSelector } from '../../store';
import { dayjsTz, getTimeTo } from '../../utils/date';

export const ConsultationsCard = () => {
  const translate = useTranslate();
  const job = useSelector((state) => state.user.jobTitle);
  const { consultations, isLoading } = useConsultations();

  if (job !== JobTitle.NURSERY_NURSE) {
    return null;
  }
  return (
    <Card elevation={2} sx={{ height: '100%' }}>
      <CardContent sx={{ height: 'calc(100% - 3rem)' }}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold', marginBottom: '1rem' }}
        >
          {translate('consultations.toComeTitle')}
        </Typography>
        {isLoading ? (
          <Loader />
        ) : consultations.length > 0 ? (
          consultations.map((consultation) => (
            <Link
              key={consultation.id}
              to={`/consultations/${consultation.id}`}
              style={{
                textDecoration: 'none',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '0.25rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Chip
                    label={consultation.status}
                    sx={{
                      ...consultationStatusStylesMapping[consultation.status],
                      marginRight: '0.5rem',
                      minWidth: '5rem',
                    }}
                    size="small"
                  />
                  <Typography
                    color="gray"
                    sx={{ marginRight: '0.5rem', minWidth: '5.5rem' }}
                  >
                    {dayjsTz(consultation.start).format('DD/MM HH:mm')}
                  </Typography>
                  {consultation.phoneOnly ? (
                    <Phone
                      sx={{
                        marginRight: '0.5rem',
                        color: 'gray',
                        fontSize: '1rem',
                      }}
                    />
                  ) : (
                    <Videocam
                      sx={{
                        marginRight: '0.5rem',
                        color: 'gray',
                        fontSize: '1rem',
                      }}
                    />
                  )}
                  <Typography>
                    {translate('common.child')}
                    {' : '}
                    {consultation.child.firstName}
                  </Typography>
                </Box>
                <Typography
                  color="gray"
                  sx={{ alignSelf: 'center' }}
                  variant="caption"
                >
                  {translate('common.in')} {getTimeTo(consultation.start)}
                </Typography>
              </Box>
            </Link>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            {translate('consultations.empty')}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="text"
          color="primary"
          component={Link}
          to="/consultations"
        >
          {translate('consultations.goToConsultations')}
        </Button>
      </CardActions>
    </Card>
  );
};
