import sortBy from 'lodash/sortBy';
import { useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import ReactFlow, {
  Background,
  Controls,
  Edge,
  Instance,
  Node,
  NodeAddChange,
  NodeChange,
  NodeDimensionChange,
  NodePositionChange,
  NodeRemoveChange,
  Panel,
  addEdge,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  useReactFlow,
} from 'reactflow';
import './Flow.css';

import 'reactflow/dist/style.css';
import { Button } from '@components/generic/Button';
import Dagre from '@dagrejs/dagre';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress } from '@mui/material';
import { Form, Question, StepLink, Template } from '@teammay/form-core';

import { FormSettingsModal } from './FormSettingsForm';
import { FillerNode } from './nodes/FillerNode';
import { HideConditionNode } from './nodes/HideConditionNode';
import { QuestionNode } from './nodes/QuestionNode';
import { ScoringRuleNode } from './nodes/ScoringRuleNode';
import { StepNode } from './nodes/StepNode';
import { TemplateNode } from './nodes/TemplateNode';
import {
  OnChangeFormArgs,
  OnChangeFormFunction,
  QuestionNodeType,
  ScoringRuleNodeType,
  StepNodeType,
  TemplateNodeType,
} from '../types';
import {
  apiToNodes,
  fillerNode,
  hideConditionEdge,
  hideConditionToNode,
  positionNodesInStep,
  positionSteps,
  questionToNode,
  questionToScoringRuleNode,
  scoringRuleEdge,
  stepToNode,
  stepsEdge,
  templateToNode,
} from '../utils/apiToNodes';

const getLayoutedElements = (nodes, edges, options) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: options.direction });

  nodes.forEach((node) => g.setNode(node.id, node));
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const { x, y } = g.node(node.id);
      return {
        ...node,
        position: { x: x - node.width / 2, y: y - node.height / 2 },
      };
    }),
    edges,
  };
};

const nodeTypes = {
  question: QuestionNode,
  step: StepNode,
  template: TemplateNode,
  hideCondition: HideConditionNode,
  filler: FillerNode,
  scoringRule: ScoringRuleNode,
};

const getLayout = (nodes: Node[], edges: Edge[]) => {
  let layoutNodes = nodes.filter((node) => node.type === 'step');
  const layoutEdges = edges.filter(
    (edge) =>
      layoutNodes.find((node) => node.id === edge.source) &&
      layoutNodes.find((node) => node.id === edge.target),
  );
  layoutNodes = sortBy(layoutNodes, (node) => node.data.link?.order ?? 0);
  const { nodes: layoutedStepNodes } = getLayoutedElements(
    layoutNodes,
    layoutEdges,
    {
      direction: 'TB',
    },
  );

  const otherNodes = nodes.filter(
    (node) => node.type !== 'link' && node.type !== 'step',
  );
  const otherEdges = edges.filter(
    (edge) =>
      !layoutNodes.find((node) => node.id === edge.source) ||
      !layoutNodes.find((node) => node.id === edge.target),
  );

  return {
    nodes: [...layoutedStepNodes, ...otherNodes],
    edges: [...layoutEdges, ...otherEdges],
  };
};

const updateQuestionAndTemplate = ({
  addedNode,
  deletedNode,
  getNode,
  getNodes,
  onNodesChange,
  onChangeProp,
}: {
  addedNode?: Node;
  deletedNode?: Node;
  getNode: Instance.GetNode<any>;
  getNodes: Instance.GetNodes<any>;
  onNodesChange: (changes: NodeChange[]) => void;
  onChangeProp: OnChangeFormFunction;
}) => {
  const impactedNode = addedNode ?? deletedNode;
  const otherNodes = getNodes()
    .filter(
      (node) =>
        node.parentId === impactedNode.parentId &&
        node.id !== impactedNode.id &&
        node.type !== 'hideCondition' &&
        node.type !== 'scoringRule',
    )
    .sort((a, b) => a.position.y - b.position.y);

  const stepNode = getNode(impactedNode.parentId);
  const positionedNode = [...otherNodes, addedNode].filter(Boolean);
  positionNodesInStep(positionedNode, stepNode);

  // update order of questions
  positionedNode.filter(Boolean).forEach((node, index) => {
    if (!node) {
      return;
    }
    const content =
      node.type === 'question' ? node.data.question : node.data.template;
    if (content.order !== index + 1) {
      if (node.type === 'question') {
        onChangeProp({
          updatedQuestion: {
            ...content,
            order: index + 1,
          } as Question,
        });
      } else {
        onChangeProp({
          updatedTemplate: {
            ...content,
            order: index + 1,
          } as Template,
        });
      }
    }
  });

  const addDeleteChange = addedNode
    ? ({ type: 'add', item: addedNode } as NodeAddChange)
    : ({ type: 'remove', id: deletedNode?.id } as NodeRemoveChange);

  const deletedHideCondition = deletedNode
    ? getNode(`${deletedNode.id}-hide`)
    : null;
  const hideConditionChanges: NodeRemoveChange[] = deletedHideCondition
    ? [{ type: 'remove', id: deletedHideCondition.id }]
    : [];

  const stepDimensionChanges: NodeDimensionChange = {
    id: stepNode.id,
    type: 'dimensions',
    dimensions: { height: stepNode.height, width: stepNode.width },
    updateStyle: true,
    resizing: true,
  };
  onNodesChange(
    [addDeleteChange, stepDimensionChanges].concat(hideConditionChanges),
  );
};

export const Flow = ({
  form,
  questions,
  templates,
  onChange: onChangeProp,
  onSelectionChange,
  onSave,
  isLoading,
}: {
  form: Form;
  questions: Question[];
  templates: Template[];
  onChange: OnChangeFormFunction;
  onSelectionChange: (type: 'question' | 'template', id: string) => void;
  onSave: (arg: {
    form: Form;
    questions: Question[];
    templates: Template[];
  }) => void;
  isLoading: boolean;
}) => {
  const { fitView, getNode, getNodes, getEdges } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const translate = useTranslate();
  const [editingForm, setEditingForm] = useState(false);

  useOnSelectionChange({
    onChange: ({ nodes: selected }) => {
      const selectedNode = selected.find(
        (node) => node.type === 'question' || node.type === 'template',
      );
      if (!selectedNode) {
        return;
      }
      onSelectionChange(
        selectedNode.type as 'question' | 'template',
        selectedNode.data.question?.id ?? selectedNode.data.template?.id,
      );
    },
  });

  const onChange = useCallback(
    (args: OnChangeFormArgs) => {
      onChangeProp(args);
      if (args.addHideCondition) {
        setNodes((prev) => {
          const matchingNode = prev.find(
            (n) =>
              (n.type === 'question' &&
                args.addHideCondition.questionId &&
                n.data?.question?.id === args.addHideCondition.questionId) ||
              (n.type === 'template' &&
                args.addHideCondition.templateId &&
                n.data?.template?.id === args.addHideCondition.templateId),
          );
          if (
            !matchingNode ||
            (matchingNode.type !== 'question' &&
              matchingNode.type !== 'template')
          ) {
            return prev;
          }
          const hideConditionNode = hideConditionToNode(
            args.addHideCondition.condition,
            matchingNode as QuestionNodeType | TemplateNodeType,
            onChange,
          );
          setEdges((prevEdges) =>
            addEdge(
              hideConditionEdge(matchingNode, hideConditionNode),
              prevEdges,
            ),
          );
          return [...prev, hideConditionNode];
        });
      }
      if (args.addQuestion || args.addTemplate) {
        const addedNode = args.addQuestion
          ? questionToNode(args.addQuestion, onChange, {
              id: args.addQuestion.stepId,
            })
          : templateToNode(args.addTemplate, onChange, {
              id: args.addTemplate.stepId,
            });

        updateQuestionAndTemplate({
          addedNode,
          getNode,
          getNodes,
          onNodesChange,
          onChangeProp,
        });
      }
      if (args.deletedQuestion || args.deletedTemplates) {
        const deletedNode = getNodes().find(
          (node) =>
            (args.deletedQuestion &&
              node.data?.question?.id === args.deletedQuestion) ||
            (args.deletedTemplates &&
              node.data?.template?.id === args.deletedTemplates),
        );
        if (!deletedNode) {
          return;
        }
        updateQuestionAndTemplate({
          deletedNode,
          getNode,
          getNodes,
          onNodesChange,
          onChangeProp,
        });
        setEdges((prevEdges) =>
          prevEdges.filter((e) => e.source !== deletedNode.id),
        );
      }
      if (args.addScore) {
        const { questionId } = args.addScore;
        const existingNode = getNodes().find(
          (node) =>
            node.type === 'scoringRule' &&
            node.data?.question?.id === questionId,
        ) as ScoringRuleNodeType;
        if (existingNode) {
          return;
        }
        const questionNode = getNodes().find(
          (node) => node.data?.question?.id === questionId,
        ) as QuestionNodeType;
        if (!questionNode) {
          return;
        }
        const scoringRuleNode = questionToScoringRuleNode(
          questionNode.data.question,
          questionNode,
          onChange,
        );
        onNodesChange([{ type: 'add', item: scoringRuleNode }]);
        const edge = scoringRuleEdge(questionNode, scoringRuleNode);
        onEdgesChange([{ type: 'add', item: edge }]);
      }

      if (args.addStep) {
        const { to, link } = args.addStep;
        const toNode = stepToNode(to, onChange, link);
        const newEdge = stepsEdge(link.stepId, link.nextStepId);
        onNodesChange([{ type: 'add', item: toNode }]);
        onEdgesChange([{ type: 'add', item: newEdge }]);
      }

      if (args.deleteStep) {
        const stepNode = getNodes().find(
          (node) =>
            node.type === 'step' && node.data.step.id === args.deleteStep,
        );
        const stepEdges = getEdges().filter(
          (e) => e.target === `step-${args.deleteStep}`,
        );
        const innerStepNodes = getNodes().filter(
          (node) => node.parentId === stepNode.id,
        );
        const innerStepEdges = getEdges().filter((edge) =>
          innerStepNodes.find((node) => node.id === edge.source),
        );
        onNodesChange([
          { type: 'remove', id: stepNode.id },
          ...innerStepNodes.map(
            (node) => ({ type: 'remove', id: node.id }) as const,
          ),
        ]);
        onEdgesChange(
          stepEdges
            .map((edge) => ({ type: 'remove', id: edge.id }) as const)
            .concat(
              innerStepEdges.map(
                (edge) => ({ type: 'remove', id: edge.id }) as const,
              ),
            ),
        );
      }

      setTimeout(() => {
        window.requestAnimationFrame(() => {
          const layout = getLayout(getNodes(), getEdges());
          setNodes(layout.nodes);
          setEdges(layout.edges);
          window.requestAnimationFrame(() => {
            fitView();
          });
        });
      }, 10);
    },
    [
      getEdges,
      getNode,
      getNodes,
      onChangeProp,
      onEdgesChange,
      onNodesChange,
      setEdges,
      setNodes,
      fitView,
    ],
  );

  useEffect(() => {
    const {
      stepsNodes,
      innerNodes,
      hideConditions,
      hideEdges,
      conditionEdges,
      scoringRuleNodes,
      scoringRuleEdges,
    } = apiToNodes(form, questions, templates, onChange);

    const layout = getLayout(
      [...stepsNodes, ...innerNodes, ...hideConditions, ...scoringRuleNodes],
      [...hideEdges, ...conditionEdges, ...scoringRuleEdges],
    );
    setNodes(layout.nodes);
    setEdges(layout.edges);

    window.requestAnimationFrame(() => {
      fitView();
    });
    // Only make the effect run once on mount. User is free to change layout after that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitView, setNodes, setEdges]);

  const handleChanges = useCallback(
    (changes: NodeChange[]) => {
      const movingChange = changes.find(
        (change) =>
          change.type === 'position' &&
          (change.id.startsWith('question') ||
            change.id.startsWith('template')) &&
          !change.id.includes('-hide'),
      ) as NodePositionChange | undefined;

      const movingStepChange = changes.find(
        (change) =>
          change.type === 'position' &&
          (change.id.startsWith('step') ||
            change.id.startsWith('condition-link')),
      ) as NodePositionChange | undefined;

      if (movingChange) {
        const movingNode = getNode(movingChange.id);

        if (!movingChange.dragging) {
          const fillerNodes = getNodes().filter(
            (node) => node.type === 'filler',
          );

          const removeChanges: NodeRemoveChange[] = fillerNodes.map((node) => ({
            type: 'remove',
            id: node.id,
          }));
          const innerNodes = getNodes().filter(
            (node) =>
              node.parentId === movingNode.parentId &&
              node.type !== 'hideCondition' &&
              node.type !== 'scoringRule' &&
              node.type !== 'filler',
          ) as (QuestionNodeType | TemplateNodeType)[];
          const stepNode = getNode(movingNode.parentId);

          const layoutedInnerNodes = sortBy(innerNodes, 'position.y');
          positionNodesInStep(layoutedInnerNodes, stepNode);

          const nodeChanges: NodePositionChange[] = layoutedInnerNodes.map(
            (node) => ({
              type: 'position',
              id: node.id,
              position: node.position,
              positionAbsolute: node.positionAbsolute,
            }),
          );
          layoutedInnerNodes.forEach((node) => {
            const hideCondition = getNode(`${node.id}-hide`);
            if (hideCondition) {
              const hideConditionPosition = {
                x: node.position.x + 210,
                y: node.position.y,
              };
              const hideConditionChange: NodePositionChange = {
                type: 'position',
                id: hideCondition.id,
                position: hideConditionPosition,
                positionAbsolute: hideConditionPosition,
              };
              nodeChanges.push(hideConditionChange);
            }
            const scoreNode = getNode(`${node.id}-score`);
            if (scoreNode) {
              const scoreNodePosition = {
                x: node.position.x - 40,
                y: node.position.y,
              };
              const scoreNodeChange: NodePositionChange = {
                type: 'position',
                id: scoreNode.id,
                position: scoreNodePosition,
                positionAbsolute: scoreNodePosition,
              };
              nodeChanges.push(scoreNodeChange);
            }
          });

          layoutedInnerNodes.forEach((node, index) => {
            const content =
              node.type === 'question'
                ? node.data.question
                : node.data.template;
            if (content.order !== index + 1) {
              if (node.type === 'question') {
                onChangeProp({
                  updatedQuestion: {
                    ...content,
                    order: index + 1,
                  } as Question,
                });
              } else {
                onChangeProp({
                  updatedTemplate: {
                    ...content,
                    order: index + 1,
                  } as Template,
                });
              }
            }
          });

          onNodesChange([...removeChanges, ...nodeChanges, ...changes]);
        } else {
          const innerNodes = getNodes().filter(
            (node) =>
              node.parentId === movingNode.parentId &&
              node.id !== movingNode.id &&
              node.type !== 'hideCondition' &&
              node.type !== 'scoringRule',
          );
          const stepNode = getNode(movingNode.parentId);

          const addChanges = [];
          let filler = innerNodes.find((node) => node.type === 'filler');

          if (!filler) {
            filler = fillerNode(movingNode);
            const addChange: NodeAddChange = { type: 'add', item: filler };
            changes.push(addChange);
            addChanges.push(filler);
          }

          filler.position = movingChange.position;

          const layoutedInnerNodes = sortBy(innerNodes, 'position.y');
          positionNodesInStep(layoutedInnerNodes, stepNode);

          const nodeChanges: NodePositionChange[] = layoutedInnerNodes.map(
            (node) => ({
              type: 'position',
              id: node.id,
              position: node.position,
              positionAbsolute: node.positionAbsolute,
            }),
          );

          onNodesChange([...addChanges, ...nodeChanges, ...changes]);
        }
        return;
      }

      if (movingStepChange) {
        if (!movingStepChange.dragging) {
          const fillerNodes = getNodes().filter(
            (node) => node.type === 'filler',
          );

          const removeChanges: NodeRemoveChange[] = fillerNodes.map((node) => ({
            type: 'remove',
            id: node.id,
          }));

          const finalPosition = fillerNodes[0].position;

          const parentEdge = getEdges().find(
            (edge) => edge.target === movingStepChange.id,
          );
          const otherStepIds = getEdges()
            .filter(
              (e) => e.source === parentEdge.source && e.id !== parentEdge.id,
            )
            .map((e) => e.target);
          const stepNodes = [
            getNode(movingStepChange.id),
            ...getNodes().filter((node) => otherStepIds.includes(node.id)),
          ];
          stepNodes[0].position = finalPosition;

          const orderedNodes = sortBy(
            stepNodes,
            'position.x',
          ) as StepNodeType[];

          orderedNodes.forEach((node, index) => {
            const link = node.data.link;
            if (link && link.order !== index + 1) {
              link.order = index + 1;
              onChangeProp({ updatedLink: link as StepLink });
            }
          });

          onNodesChange([
            ...removeChanges,
            {
              type: 'position',
              id: movingStepChange.id,
              position: finalPosition,
              positionAbsolute: finalPosition,
            },
          ]);
        } else {
          const movingNode = getNode(movingStepChange.id);
          const addChanges = [];
          let filler = getNodes().find((node) => node.type === 'filler');

          if (!filler) {
            filler = fillerNode(movingNode);
            const addChange: NodeAddChange = { type: 'add', item: filler };
            changes.push(addChange);
            addChanges.push(filler);
          }

          const fillerPosition = filler.position;
          filler.position = movingStepChange.position;

          const parentEdge = getEdges().find(
            (edge) => edge.target === movingNode.id,
          );
          const otherIds = getEdges()
            .filter(
              (e) => e?.source === parentEdge.source && e.id !== parentEdge.id,
            )
            .map((e) => e.target);

          const otherNodes = getNodes().filter((node) =>
            otherIds.includes(node.id),
          );

          const layoutedNodes = sortBy([...otherNodes, filler], 'position.x');
          filler.position = fillerPosition;
          positionSteps(layoutedNodes);

          const nodeChanges: NodePositionChange[] = layoutedNodes.map(
            (node) => ({
              type: 'position',
              id: node.id,
              position: node.position,
              positionAbsolute: node.positionAbsolute,
            }),
          );

          onNodesChange([...addChanges, ...nodeChanges, ...changes]);
        }
      }
      onNodesChange(
        changes
          .filter((change) => change.type !== 'remove') // in case
          .filter(
            (change) =>
              !(change.type === 'position' && change.id.startsWith('question')),
          ),
      );
    },
    [getEdges, getNode, getNodes, onChangeProp, onNodesChange],
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={handleChanges}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      fitView
    >
      <Panel position="top-right">
        <FormSettingsModal
          form={editingForm ? form : null}
          onClose={() => {
            setEditingForm(false);
          }}
        />
        <Box p={1} sx={{ display: 'flex', gap: 1 }}>
          <Button
            onClick={() => {
              setEditingForm(true);
            }}
            variant="contained"
            startIcon={<EditIcon />}
          >
            {translate('common.edit')}
          </Button>
          <Button
            onClick={() => {
              onSave({ form, questions, templates });
              return false;
            }}
            variant="contained"
            startIcon={
              isLoading ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <SaveIcon />
              )
            }
            disabled={isLoading}
          >
            {translate('generic.save')}
          </Button>
        </Box>
      </Panel>
      <Background />
      <Controls />
    </ReactFlow>
  );
};
