import { useRecordContext } from 'react-admin';

import { Typography } from '@mui/material';

import { Consultation } from '../../types';

interface ConsultationMediaProps {
  record?: Consultation;
  label?: string;
}

export const ConsultationMedia = ({}: ConsultationMediaProps) => {
  const record = useRecordContext<Consultation>();
  return (
    <Typography component="span">{record.phoneOnly ? '📞' : '🎥'}</Typography>
  );
};
