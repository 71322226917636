import * as React from 'react';

import useScrollTrigger from '@mui/material/useScrollTrigger';

export const ElevationScroll = (props: { children: JSX.Element }) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 1,
  });
};
