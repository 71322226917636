const MidwifeChat = () => (
  <svg
    version="1.1"
    id="Calque_2_00000047769414341750208800000017619389941956215692_"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 360 360"
    width={24}
    height={24}
  >
    <g>
      <circle fill="currentColor" cx="171.4" cy="90.9" r="16.4" />
      <path
        fill="currentColor"
        d="M207.2,148.6c0,0-4.9-6.6-11-8.4c0,0,0.4-22.4-23.9-24.8c0,0-25.3-1.8-25.3,22.9s0.1,59.2,0.1,59.2h16.1
		l0.1,41.4h24.9v-41.4h24.5v-26.9C212.8,170.7,214.1,157.4,207.2,148.6L207.2,148.6z"
      />
    </g>
    <path
      fill="currentColor"
      d="M282.8,11.4H78.3c-35.8,0-64.9,29.1-64.9,64.9v158.6c0,35.8,29.1,64.9,64.9,64.9h36.1v39.8
	c0,3.9,2.1,7.5,5.6,9.4c1.6,0.9,3.4,1.4,5.2,1.4c2,0,4-0.6,5.7-1.6l77.9-48.7c0.1-0.1,0.3-0.2,0.4-0.3h73.5
	c35.8,0,64.9-29.1,64.9-64.9V76.2C347.6,40.5,318.5,11.4,282.8,11.4z M197.4,281.8L136,320.2V289c0-5.9-4.8-10.8-10.8-10.8H78.3
	c-23.9,0-43.3-19.4-43.3-43.3V76.2c0-23.9,19.4-43.3,43.3-43.3h204.4c23.9,0,43.3,19.4,43.3,43.3v158.6c0,23.9-19.4,43.3-43.3,43.3
	h-76.9C202.5,278.3,197.4,281.8,197.4,281.8L197.4,281.8z"
    />
  </svg>
);

export default MidwifeChat;
