import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { EntitySlotType } from '@boTypes/slot';
import { StaffUser } from '@boTypes/staffUser';
import { DateTimeInput } from '@components/forms/dateInput';
import { StaffSelectInput } from '@components/forms/staffSelectInput';
import { SwitchInput } from '@components/forms/switchInput';
import { Box, Button, FormControl, Paper } from '@mui/material';

import { COLORS } from '../../themes';
import { dayjsTz } from '../../utils/date';
import { Drawer } from '../generic/Drawer';

const styles = {
  paper: {
    padding: '1rem',
  },
};

interface SlotFormState {
  start: string;
  end: string;
  id?: number;
  isTriage?: boolean;
  onCall?: boolean;
  onCallActivated?: boolean;
  staffUserId?: number;
}

export const CalendarEventForm = ({
  onClose,
  record,
  callback,
  removeRecord,
  staffUsers,
  isLoadingStaffUsers,
  tz,
  isAdmin = false,
}: {
  onClose: () => void;
  record: Record<string, any>;
  callback: (slot: SlotFormState) => Promise<void>;
  removeRecord?: (slot: SlotFormState) => Promise<void>;
  staffUsers?: StaffUser[];
  isLoadingStaffUsers?: boolean;
  tz: string;
  isAdmin?: boolean;
}) => {
  const translate = useTranslate();

  const extraSlotData = useMemo(
    () =>
      record?.entityType === EntitySlotType.slot
        ? {
            isTriage: record.isTriage,
            onCall: record.onCall,
            onCallActivated: record.onCallActivated,
            staffUserId: record.staffUserId,
          }
        : {},
    [record],
  );

  const { control, handleSubmit, formState, setValue, watch, reset } =
    useForm<SlotFormState>({
      defaultValues: {
        start: dayjsTz(record?.start, tz).format('YYYY-MM-DD HH:mm:ss'),
        end: dayjsTz(record?.end, tz).format('YYYY-MM-DD HH:mm:ss'),
        id: record?.id,
        staffUserId: record?.staffUserId,
        ...extraSlotData,
      },
    });

  const onCallValue = watch('onCall');

  useEffect(() => {
    // No activation possible on a non onCall slot
    if ('onCall' in extraSlotData && !onCallValue) {
      setValue('onCallActivated', false);
    }
  }, [onCallValue, setValue, extraSlotData]);

  const onSubmit = handleSubmit(async (values: SlotFormState) => {
    // Ensure we're sending timezone aware dates
    values.start = dayjs(values.start).tz(tz, true).toISOString();
    values.end = dayjs(values.end).tz(tz, true).toISOString();
    await callback(values);
    reset();
    onClose();
  });

  useEffect(() => {
    if (!record) {
      return;
    }
    setValue('start', dayjsTz(record?.start, tz).format('YYYY-MM-DDTHH:mm:ss'));
    setValue('end', dayjsTz(record?.end, tz).format('YYYY-MM-DDTHH:mm:ss'));
    if (record.id) {
      setValue('id', record.id);
    }
    'isTriage' in extraSlotData && setValue('isTriage', record.isTriage);
    'onCall' in extraSlotData && setValue('onCall', record.onCall);
    'onCallActivated' in extraSlotData &&
      setValue('onCallActivated', record.onCallActivated);
    'staffUserId' in extraSlotData &&
      setValue('staffUserId', record.staffUserId);
  }, [record, setValue, tz, extraSlotData]);

  const { isSubmitting } = formState;
  return (
    <>
      <FormControl fullWidth>
        <DateTimeInput
          sx={{ p: 1 }}
          control={control}
          name="start"
          label={translate('planning.event.start')}
        />
        <DateTimeInput
          sx={{ p: 1 }}
          control={control}
          name="end"
          label={translate('planning.event.end')}
        />
        {((isAdmin && !record?.entityType) ||
          record?.entityType === EntitySlotType.slot) && (
          <>
            <SwitchInput
              sx={{ p: 1 }}
              control={control}
              name="isTriage"
              label={translate('planning.event.isTriage')}
            />
            <SwitchInput
              sx={{ p: 1 }}
              control={control}
              name="onCall"
              label={translate('planning.event.onCall')}
            />
            <SwitchInput
              sx={{ p: 1 }}
              control={control}
              name="onCallActivated"
              disabled={!onCallValue}
              label={translate('planning.event.onCallActivated')}
            />
            <StaffSelectInput
              label={translate('common.pro')}
              staffUsers={staffUsers}
              isLoadingStaff={isLoadingStaffUsers}
              withColor={true}
              control={control}
              name="staffUserId"
              variant="standard"
              fullWidth={true}
            />
          </>
        )}
      </FormControl>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <Button onClick={onClose}>{translate('common.cancel')}</Button>
        <Button
          onClick={() => onSubmit()}
          variant="contained"
          disabled={isSubmitting}
        >
          {translate('common.save')}
        </Button>
      </Box>
      <Box
        sx={{
          marginTop: '2rem',
          paddingTop: '1rem',
          borderTop: `1px solid ${COLORS.GREY_LAYOUT}`,
        }}
      >
        {record?.id && (
          <Button
            // @ts-ignore
            onClick={() => removeRecord(record)}
            variant="outlined"
            color="error"
          >
            {translate('planning.event.delete')}
          </Button>
        )}
      </Box>
    </>
  );
};

export const CalendarDrawer = ({
  open,
  onClose,
  record,
  callback,
  removeRecord,
  staffUsers,
  isLoadingStaffUsers,
  tz,
  isAdmin = false,
}: {
  open: boolean;
  onClose: () => void;
  record: Record<string, any>;
  callback: (slot: SlotFormState) => Promise<void>;
  removeRecord?: (slot: SlotFormState) => Promise<void>;
  staffUsers?: StaffUser[];
  isLoadingStaffUsers?: boolean;
  tz: string;
  isAdmin?: boolean;
}) => {
  const translate = useTranslate();

  return (
    <Drawer
      transitionDuration={10}
      anchor={'left'}
      open={open}
      onClose={onClose}
      SlideProps={{ unmountOnExit: true }}
      title={
        record?.id
          ? translate('planning.event.edit')
          : translate('planning.event.create')
      }
    >
      <Paper elevation={0} style={styles.paper}>
        <CalendarEventForm
          onClose={onClose}
          record={record}
          callback={callback}
          removeRecord={removeRecord}
          staffUsers={staffUsers}
          isLoadingStaffUsers={isLoadingStaffUsers}
          tz={tz}
          isAdmin={isAdmin}
        />
      </Paper>
    </Drawer>
  );
};
