import { useTranslate } from 'react-admin';
import { Controller, Path } from 'react-hook-form';

import { Button } from '@components/generic/Button';
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

import { ExposedControllerProps } from './type';
import { errorMessage } from './utils';

type OptionInputProps<
  TFieldValues extends Record<string, any> & {
    [k: string]: { key: string; value: string }[];
  },
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> & {
  label: string;
  onBlur?: () => void;
  fullWidth?: boolean;
};

export function OptionInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  fullWidth,
  ...other
}: OptionInputProps<TFieldValues, TName>) {
  const translate = useTranslate();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState, formState }) => {
        const hideLabel = typeof label === 'string' && !label;
        return (
          <FormControl fullWidth={fullWidth}>
            {!hideLabel && <Typography>{label ?? name}</Typography>}
            {field.value?.map(({ key, value }, index) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'stretch',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <Typography>{`${index + 1}: `}</Typography>
                <TextField
                  sx={{ flex: 1 }}
                  error={Boolean(fieldState.error)}
                  disabled={formState.isSubmitting}
                  helperText={
                    !key ? (
                      <Typography color="error" fontSize={'0.75rem'}>
                        {translate('field.optionInput.requiredKey')}
                      </Typography>
                    ) : null
                  }
                  {...field}
                  value={key ?? ''}
                  onBlur={other.onBlur}
                  onChange={(e) => {
                    field.value[index].key = e.target.value;
                    field.onChange([...field.value]);
                  }}
                  label={translate('field.optionInput.key')}
                  variant="standard"
                />
                <TextField
                  sx={{ flex: 1 }}
                  error={Boolean(fieldState.error)}
                  disabled={formState.isSubmitting}
                  helperText={
                    !key ? (
                      <Typography color="error" fontSize={'0.75rem'}>
                        {translate('field.optionInput.requiredValue')}
                      </Typography>
                    ) : null
                  }
                  {...field}
                  value={value ?? ''}
                  onBlur={other.onBlur}
                  onChange={(e) => {
                    field.value[index].value = e.target.value;
                    field.onChange([...field.value]);
                  }}
                  label={translate('field.optionInput.value')}
                  variant="standard"
                />
              </Box>
            ))}
            <Button
              onClick={() =>
                field.onChange([...field.value, { key: '', value: '' }])
              }
            >
              {translate('field.optionInput.add')}
            </Button>
            <FormHelperText>
              {errorMessage(fieldState.error, translate)}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
