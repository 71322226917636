import { useTranslate } from 'react-admin';

import { FamilyPopulated } from '@boTypes/family';
import { CircularProgress, Tooltip, Typography } from '@mui/material';

export const PatientName = ({
  patient,
  family,
}: {
  patient?: { firstName: string };
  family?: FamilyPopulated;
}) => {
  const translate = useTranslate();
  const vipString = family?.vip ? '⭐ ' : '';

  if (!patient && !family) {
    return (
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
        <CircularProgress size={'0.875rem'} />
      </Typography>
    );
  }

  if (!patient) {
    return (
      <Typography
        color={'error'}
        sx={{ fontSize: '0.875rem', fontWeight: 500 }}
      >
        {translate('common.unknown')}
      </Typography>
    );
  }
  return (
    <Tooltip title={family?.vip ? translate('discussion.vipTooltip') : ''}>
      <Typography
        sx={{ fontSize: '0.875rem', fontWeight: 500 }}
      >{`${vipString}${patient.firstName}`}</Typography>
    </Tooltip>
  );
};
