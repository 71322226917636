import { Edit } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { PlanForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const PlanEdit = () => {
  const location = useLocation() as RedirectLocation;
  return (
    <Edit
      resource="plans"
      redirect={location?.state?.redirect || 'list'}
      sx={{ padding: '1rem' }}
    >
      <PlanForm />
    </Edit>
  );
};
