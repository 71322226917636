import uniq from 'lodash/uniq';

import {
  Condition,
  Operator,
  Question,
  QuestionType,
  ScoreMode,
  Template,
} from '@teammay/form-core';

export const isRadioQuestionValid = (
  question: Partial<Question>,
): (keyof Question)[] => {
  if (question.type !== QuestionType.RADIO) {
    return [];
  }

  if (!question.options || question.options.length === 0) {
    return ['options'];
  }

  return [];
};

export const isQuestionValid = (
  question: Partial<Question>,
): (keyof Question)[] => {
  const errors: (keyof Question)[] = [];
  if (!question.title) {
    errors.push('title');
  }
  if (!question.description) {
    errors.push('description');
  }
  if (!question.type) {
    errors.push('type');
  }

  const radioQuestionErrors = isRadioQuestionValid(question);

  return errors.concat(radioQuestionErrors);
};

const isImageTemplateValid = (
  template: Partial<Template>,
): (keyof Template)[] => {
  if (!template.image) {
    return ['image'];
  }
  return [];
};

export const isTemplateValid = (
  template: Partial<Template>,
): (keyof Template)[] => {
  const errors: (keyof Template)[] = [];
  if (!template.title) {
    errors.push('title');
  }
  if (!template.description) {
    errors.push('description');
  }

  const imageTemplateErrors = isImageTemplateValid(template);

  return errors.concat(imageTemplateErrors);
};

export const isHideConditionValid = (
  condition: Partial<Condition>,
  questions: Partial<Question>[],
): string[] => {
  const errors: string[] = [];
  const questionRules = condition.questionRules || [];
  const questionsAndExpected = questionRules.flatMap((rule) => {
    const question = questions.find((q) => q.id === rule.questionId);
    return {
      question,
      expected: rule.expectedValue,
      operator: rule.operator,
      questionId: rule.questionId,
    };
  });

  if (questionsAndExpected.some(({ question }) => !question)) {
    errors.push(`unknown question`);
  }

  if (
    questionsAndExpected.some(
      ({ question, expected, operator }) =>
        question.type === QuestionType.RADIO &&
        operator === Operator.EQUAL &&
        !question.options.find((o) => o.key === expected),
    )
  ) {
    errors.push(`missing or impossible expected value`);
  }

  return errors;
};

export const isScoringRulesValid = (question: Partial<Question>): string[] => {
  const errors: string[] = [];

  question.scoringRules?.forEach((rule) => {
    if (!rule.expectedValue) {
      errors.push(`missing expected value`);
    }
    if (!rule.scoringValue) {
      errors.push(`missing scoring value`);
    }
    if (
      rule.mode === ScoreMode.NUMBER &&
      (!rule.scoringValue || Number.isNaN(Number(rule.scoringValue)))
    ) {
      errors.push(`Invalid number scoring value`);
    }
  });

  return uniq(errors);
};
