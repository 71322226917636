import 'dayjs/locale/fr';
import {
  useRef,
  useCallback,
  ComponentProps,
  useEffect,
  useState,
} from 'react';
import { Identifier, useNotify } from 'react-admin';
import { FlatList, View } from 'react-native';

import { AppUser } from '@boTypes/appUser';
import {
  ChatDiscussionEvent,
  DiscussionEvent,
  DiscussionEventType,
} from '@boTypes/discussionEvent';
import {
  UnAttributedChatInput,
  UnCategorizedChatInput,
} from '@components/discussions/chat/AlternateChatInput';
import { useDeleteMessage, useMessageQuery } from '@hooks/discussionEvents';
import {
  useSubjectAttributions,
  useWaitingAttributions,
} from '@hooks/subjectAttribution';
import { useAttributionUI } from '@hooks/useAttributionUI';

import audioFile from '../../components/discussions/chat/assets/sounds/notification.mp3';
import { useSelector } from '../../store';
import { Discussion, Subject } from '../../types';
import { Socket } from '../../types/socket';
import { ChatInput } from '../discussions/chat/chatInput';
import { ContextMenu, MenuOptions } from '../discussions/chat/contextMenu';
import { ContextMenuType, MessageList } from '../discussions/chat/messageList';
import { ScrollToBottom } from '../discussions/chat/scrollToBottom';

const audio = new Audio(audioFile);

export const MayContextMenu = ({
  discussionId,
  readonly,
  ...props
}: {
  discussionId: Identifier;
  readonly: boolean;
} & ComponentProps<ContextMenuType>) => {
  const notify = useNotify();
  const { mutate: deleteMessage } = useDeleteMessage(discussionId);
  const handleClick = useCallback(
    (message: ChatDiscussionEvent, option: MenuOptions) => {
      switch (option) {
        case MenuOptions.COPY:
          navigator.clipboard.writeText(message.content);
          break;
        case MenuOptions.DELETE:
          deleteMessage(message.id, {
            onError: (error) => {
              notify(`Erreur : ${error.message}`, { type: 'error' });
            },
          });
          break;
        default:
          break;
      }
    },
    [deleteMessage, notify],
  );
  return (
    <ContextMenu readonly={readonly} {...props} onItemPressed={handleClick} />
  );
};

const Input = ({
  discussion,
  flatListRef,
  lastAppUserName,
}: {
  discussion: Discussion & {
    addDiscussionEvent: (arg: { event: DiscussionEvent }) => void;
    updateSubject: (arg0: { subject: Subject }) => void;
  };
  flatListRef: React.RefObject<FlatList>;
  lastAppUserName: string;
}) => {
  const attributionUI = useAttributionUI();
  const { data: waitingSubject } = useWaitingAttributions();
  const { data: attributions } = useSubjectAttributions();
  const staffUserId = useSelector((state) => state.user.userId);
  const staffIdForSubject =
    attributionUI && !discussion.lastSubject?.empty // if last subject is empty, we are in a revive case
      ? attributions?.staffUserFromSubjectId?.[discussion.lastSubjectId]
      : staffUserId; // inhibit log of attribution taken in the send button

  if (discussion.lastSubject && !discussion.lastSubject.empty) {
    if (!discussion.lastSubject?.categoryId) {
      return (
        <UnCategorizedChatInput
          subject={discussion.lastSubject}
          onCategoryChanged={(subject) => discussion.updateSubject({ subject })}
        />
      );
    }
    if (attributionUI && waitingSubject?.includes(discussion.lastSubjectId)) {
      return <UnAttributedChatInput subject={discussion.lastSubject} />;
    }
  }

  // display chat input if last subject is empty => revive case
  // display chat input if category and staff attribution is set
  return (
    <ChatInput
      discussion={discussion}
      lastAppUserName={lastAppUserName}
      onSend={discussion.addDiscussionEvent}
      staffIdForSubject={staffIdForSubject}
      flatListRef={flatListRef}
    />
  );
};

export const DiscussionEditChat = ({
  discussion,
  socket,
}: {
  discussion: Discussion & {
    addDiscussionEvent: (arg: { event: DiscussionEvent }) => void;
    updateSubject: (arg0: { subject: Subject }) => void;
  };
  socket?: Socket;
}) => {
  const ref = useRef<FlatList>(null);
  const {
    messages,
    fetchNextPage: getOlderMessages,
    hasNextPage: hasOlderMessages,
    isLoading,
    isFetchingNextPage: loadingPrevious,
  } = useMessageQuery(discussion, socket);

  const lastMessage = useRef<ChatDiscussionEvent | null>(null);
  const [lastAppUserName, setLastAppUserName] = useState<string>('');

  useEffect(() => {
    if (messages?.length) {
      const lastAppUserEmail = messages.find((msg) => !msg.isMay)?.authorEmail;
      setLastAppUserName(
        (discussion.appUsers as AppUser[])?.find(
          (au: AppUser) => au?.email === lastAppUserEmail,
        )?.firstName ?? '',
      );
      if (lastMessage.current) {
        if (messages[0].id === lastMessage.current.id) {
          return;
        }
        if (
          !messages[0].isCurrentUser &&
          messages[0].type !== DiscussionEventType.SYSTEM &&
          messages[0].type !== DiscussionEventType.TYPING &&
          !messages[0].isMay
        ) {
          lastMessage.current = messages[0];
          audio.play().catch(() => {});
        }
      } else {
        lastMessage.current = messages[0];
      }
    }
  }, [messages, discussion?.appUsers]);

  const ChatContextMenu = useCallback(
    (props: ComponentProps<ContextMenuType>) => {
      return (
        <MayContextMenu
          readonly={false}
          {...props}
          discussionId={discussion?.id}
        />
      );
    },
    [discussion?.id],
  );

  return (
    <View style={{ flex: 1 }}>
      <MessageList
        discussionId={discussion.id}
        messages={messages}
        isLoading={isLoading}
        isLoadingMore={loadingPrevious}
        hasMore={hasOlderMessages}
        onEndReached={getOlderMessages}
        scrollToBottomOffset={20}
        ScrollToBottom={ScrollToBottom}
        ContextMenu={ChatContextMenu}
        ref={ref}
      />
      <Input
        discussion={discussion}
        lastAppUserName={lastAppUserName}
        flatListRef={ref}
      />
    </View>
  );
};
