import { Dayjs } from 'dayjs';

import { Planning, PlanningJob } from './planning';

export enum EntitySlotType {
  slot = 'slot',
  additionalHour = 'additionalHour',
}

export interface SlotCreate {
  start: Date;
  end: Date;
  onCall?: boolean;
  isPriority?: boolean;
  isTriage?: boolean;
  entityType?: EntitySlotType;
}

export interface Slot extends Omit<SlotCreate, 'entityType'> {
  id: number;
  start: Date;
  planningId: string;
  planning?: Planning;
  job?: PlanningJob;
}

export interface SlotsCreate {
  slots: SlotCreate[];
}

export interface DayjsSlotCreate {
  start: Dayjs;
  end: Dayjs;
  onCall: boolean;
  isPriority: boolean;
  isTriage: boolean;
  entityType: EntitySlotType;
}

export interface DayOfSlots {
  isTemplate: boolean;
  day?: Dayjs;
  slots: Partial<DayjsSlotCreate>[];
}

export enum SlotEvaluationFeedback {
  BORING = 'BORING', // -> Un peu trop calme 😴
  CALM = 'CALM', // -> Plutôt calme 😌
  PERFECT = 'PERFECT', // -> Juste parfait 👌
  INTENSE = 'INTENSE', // -> Intense mais ça restait gérable 💪
  TOO_INTENSE = 'TOO_INTENSE', // -> Trop intense donc inconfortable 🥵
}
