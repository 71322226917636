import { ReferenceField, DateField, useTranslate } from 'react-admin';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AvatarAndTextField, ShyText } from '../../components/fields';
import { COLORS } from '../../themes';
import { Handover } from '../../types';

const Section = styled('section')(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${COLORS.GREY_LAYOUT}`,
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const Header = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}));

const HeaderTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    marginRight: theme.spacing(0.5),
  },
}));

export const HandoverItem = ({ handover }: { handover: Handover }) => {
  const translate = useTranslate();
  return (
    <Section>
      <Header>
        <HeaderTitle>
          <ReferenceField
            source="userId"
            record={handover}
            reference="users"
            link={false}
          >
            <AvatarAndTextField />
          </ReferenceField>
          <span>{'-'}</span>
          <DateField
            record={handover}
            source="createdAt"
            locales="fr-FR"
            showTime
            style={{ lineHeight: '1.5rem' }}
            sx={(theme) => ({ fontSize: theme.spacing(1.5) })}
          />
        </HeaderTitle>
      </Header>
      <main>
        <ShyText
          record={handover}
          inline
          source="problem"
          label="Problème identifié : "
        />
        <ShyText
          component="pre"
          style={{ whiteSpace: 'pre-wrap' }}
          record={handover}
          inline
          source="observations"
          label="Observations faites : "
        />
        <ShyText
          component="pre"
          style={{ whiteSpace: 'pre-wrap' }}
          record={handover}
          inline
          source="advices"
          label="Conseils/Actions proposés : "
        />
        {handover.reviveAt && (
          <div>
            <Typography variant="body2" component="span" color="primary">
              Date relance souhaitée :{' '}
            </Typography>
            <DateField record={handover} source="reviveAt" locales="fr-FR" />
          </div>
        )}
        <ShyText
          component="pre"
          style={{ whiteSpace: 'pre-wrap' }}
          inline
          record={handover}
          source="plannedText"
          label={translate('revives.plannedText') + ' : '}
        />
      </main>
    </Section>
  );
};
