import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';

import { useQuery } from '@hooks/queryWrappers';

export const isKeyValid = (
  key: string,
): { valid: boolean; shouldRefetch: boolean } => {
  if (!key) {
    return { valid: false, shouldRefetch: true };
  }

  // read key
  const payload = jwtDecode<{ exp?: number }>(key);
  const validityTolerance = 2;
  const refreshTolerance = 60; // 1 minute
  const now = Date.now() / 1000; // timestamp in second
  return {
    valid: payload.exp - validityTolerance > now,
    shouldRefetch: payload.exp - refreshTolerance < now, // < as shouldRefetch is the opposite logic of valid
  };
};

export const useSearchKey = () => {
  const { data: searchKey, refetch } = useQuery<{ token: string }>(
    'createSearchKey',
    {
      url: `/api/search/token`,
      method: 'GET',
    },
    {
      staleTime: Infinity,
    },
  );
  const { valid, shouldRefetch } = isKeyValid(searchKey?.token);
  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch, refetch]);

  return valid ? searchKey?.token : undefined;
};
