import { uniq } from 'lodash';
import { useMemo } from 'react';

import { Discussion } from '@boTypes/discussion';
import { FamilyPopulated } from '@boTypes/family';

import { useGetVaryingMany } from './useGetVaryingMany';

export const useChildren = (childIds: number[]) => {
  const { data: children } = useGetVaryingMany('children', childIds);
  return children ?? [];
};

export const useAppUsers = (appUserIds: number[]) => {
  const { data: appUsers } = useGetVaryingMany('patients', appUserIds);
  return appUsers ?? [];
};

export const useDiscussionFamilies = (discussion?: Discussion[]) => {
  const childIds =
    discussion
      ?.map((d) => d.kidId)
      .filter(Boolean)
      .map(Number) ?? [];
  const appUserIds = uniq(
    discussion
      ?.map((d) =>
        typeof d.appUser?.id !== 'undefined'
          ? d.appUser?.id
          : d.appUser || d.appUserId,
      )
      .filter(Boolean)
      .map(Number) ?? [],
  );

  const children = useChildren(childIds);
  const appUsers = useAppUsers(appUserIds);
  const familyIds = useMemo<string[]>(
    () =>
      children
        .map((child) => child?.familyId)
        .concat(appUsers.map((appUser) => appUser?.family?.id))
        .filter(Boolean),
    [children, appUsers],
  );

  const { data, ...rest } = useGetVaryingMany('family', familyIds, {
    enabled: !!familyIds.length,
  });

  const childIdToFamily = useMemo(() => {
    const idToFamily =
      data?.filter(Boolean).reduce(
        (acc, family) => {
          if (family.children) {
            family.children.forEach((child) => {
              acc[child.id] = family;
            });
          }
          return acc;
        },
        {} as Record<number, FamilyPopulated>,
      ) ?? {};

    // add children as deactivated if they are not in the family
    children.forEach((child) => {
      if (child && !idToFamily[child.id]) {
        const childFamily = data?.find(
          (family) => family?.id === child.familyId,
        );
        if (childFamily) {
          idToFamily[child.id] = {
            ...childFamily,
            children: [
              ...childFamily.children,
              {
                ...child,
                firstName: `${child.firstName} (désactivé)`,
              },
            ],
          };
        }
      }
    });

    return idToFamily;
  }, [children, data]);

  const appUserIdToFamily = useMemo(() => {
    const idToFamily =
      data?.filter(Boolean).reduce(
        (acc, family) => {
          if (family.appUsers) {
            family.appUsers.forEach((appUser) => {
              acc[appUser.id] = family;
            });
          }
          return acc;
        },
        {} as Record<number, FamilyPopulated>,
      ) ?? {};

    appUsers.forEach((appUser) => {
      if (appUser && !idToFamily[appUser.id]) {
        const appUserFamily = data?.find(
          (family) => family?.id === appUser.family.id,
        );
        if (appUserFamily) {
          idToFamily[appUser.id] = {
            ...appUserFamily,
            appUsers: [
              ...appUserFamily.appUsers,
              {
                ...appUser,
                firstName: `${appUser.firstName} (désactivé)`,
                pregnancies: [],
              },
            ],
          };
        }
      }
    });

    return idToFamily;
  }, [appUsers, data]);

  const matchedFamily = useMemo<(FamilyPopulated | undefined)[]>(
    () =>
      discussion?.map<FamilyPopulated | undefined>((d) =>
        d.kidId
          ? childIdToFamily[d.kidId]
          : appUserIdToFamily[
              d.appUser?.id || Number(d.appUser) || d.appUserId
            ],
      ) ?? [],
    [appUserIdToFamily, childIdToFamily, discussion],
  );

  return {
    data: matchedFamily,
    ...rest,
  };
};
