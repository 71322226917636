import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { Title } from '@components/title';
import { useMutation } from '@hooks/queryWrappers';
import { MenuItem } from '@mui/material';
import { Box } from '@mui/system';

import { ConfirmDialog } from '../../components/ConfirmDialog/ConfirmDialog';
import { DateInput } from '../../components/forms/dateInput';
import { SelectInput } from '../../components/forms/selectInput';
import { TextInput } from '../../components/forms/textInput';
import { Button } from '../../components/generic/Button';
import {
  jobToString,
  Planning,
  PlanningJob,
  PlanningUpdate,
} from '../../types/planning';

export const PlanningForm = ({
  onClose,
  planning,
}: {
  onClose: () => void;
  planning?: Planning;
}) => {
  const translate = useTranslate();
  const { handleSubmit, control, formState } = useForm<PlanningUpdate>({
    defaultValues: {
      job: planning?.job ?? PlanningJob.NURSE,
      title: planning?.title ?? '',
      begin: planning?.begin ? new Date(planning.begin) : new Date(),
      end: planning?.end ? new Date(planning.end) : new Date(),
      deadlineAvailabilities: planning?.deadlineAvailabilities
        ? new Date(planning.deadlineAvailabilities)
        : new Date(),
    },
  });
  const { isDirty, isSubmitting } = formState;
  const notify = useNotify();
  const { mutateAsync } = useMutation<Planning, AxiosError, PlanningUpdate>(
    ['key'],
    (values) => ({
      url: planning ? `/api/planning/${planning.id}` : '/api/planning',
      method: planning ? 'put' : 'post',
      data: values,
    }),
  );

  const onSubmit = handleSubmit(async (values: PlanningUpdate) => {
    try {
      await mutateAsync(values);
      notify(planning ? 'Planning modifié' : 'Planning créé');
      onClose();
    } catch {
      notify('Erreur lors de la soumission du planning', { type: 'error' });
    }
  });

  const [open, setOpen] = useState(false);
  const protectedOnClose = () => {
    if (isDirty) {
      setOpen(true);
      return;
    }
    onClose();
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <ConfirmDialog
        title="Confirmer la fermeture"
        text="Vos modifications ne seront pas enregistrées, voulez vous continuer ?"
        onClick={onClose}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Title
        title={
          planning ? translate('planning.edit') : translate('planning.create')
        }
        onClose={protectedOnClose}
      />
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            flex: 1,
          }}
        >
          <TextInput
            name="title"
            label="Titre"
            control={control}
            required
            fullWidth
          />
          <DateInput
            variant="standard"
            control={control}
            name="begin"
            label="Début"
          />
          <DateInput
            variant="standard"
            control={control}
            name="end"
            label="Fin"
          />
          <DateInput
            variant="standard"
            control={control}
            name="deadlineAvailabilities"
            label="Deadline remplissage des disponibilités"
          />
          <SelectInput
            variant="standard"
            control={control}
            name="job"
            label="Job"
          >
            {Object.values(PlanningJob).map((s, k) => (
              <MenuItem value={s} key={k}>
                {jobToString[s]}
              </MenuItem>
            ))}
          </SelectInput>
          <Button
            sx={{ flex: 1, margin: '1rem 0' }}
            variant="contained"
            type="submit"
            color="primary"
            loading={isSubmitting}
            value={planning ? 'Modifier' : 'Créer'}
            fullWidth
          />
        </Box>
      </form>
    </Box>
  );
};
