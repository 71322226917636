import { DiscussionEventType } from '@boTypes/discussionEvent';
import { ContentSuggestion } from '@components/discussions/chat/contentSuggestion/ContentSuggestion';
import { useSuggestions } from '@components/discussions/chat/contentSuggestion/hooks';
import { Paper } from '@mui/material';

export const ContentSuggestionPaper = ({
  subjectId,
  discussionId,
  onContentSend,
  onCopyContent,
  onClose,
}: {
  subjectId?: number;
  discussionId?: number;
  onContentSend: (input: {
    type: DiscussionEventType;
    content: string;
  }) => void;
  onCopyContent: (text: string) => void;
  onClose: () => void;
}) => {
  const {
    isLoading,
    guides,
    macro,
    posts,
    masterclasses,
    handleContentSend,
    handleCopyContent,
    setOpenSimilarQuestionDialog,
    openSimilarQuestionDialog,
  } = useSuggestions({
    subjectId,
    open: true,
    discussionId,
    onContentSend,
    onCopyContent,
    onClose,
  });

  return (
    <Paper
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          minWidth: 400,
          maxWidth: 500,
        },
      }}
      elevation={0}
    >
      <ContentSuggestion
        isLoading={isLoading}
        guides={guides}
        posts={posts}
        masterclasses={masterclasses}
        macro={macro}
        handleContentSend={handleContentSend}
        handleCopyContent={handleCopyContent}
        subjectId={subjectId}
        withinLibrary={true}
        onClose={onClose}
        setSimilarQuestionDialog={setOpenSimilarQuestionDialog}
        openSimilarQuestionDialog={openSimilarQuestionDialog}
      />
    </Paper>
  );
};
