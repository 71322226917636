import { createSlice } from '@reduxjs/toolkit';

import { LibraryContext } from '../common/context';

const libraryFiltersSlice = createSlice({
  name: 'libraryFilters',
  initialState: null as null | LibraryContext,
  reducers: {
    setPregnancy(state) {
      return state === LibraryContext.PREGNANCY
        ? null
        : LibraryContext.PREGNANCY;
    },
    setChildren(state) {
      return state === LibraryContext.CHILDREN ? null : LibraryContext.CHILDREN;
    },
  },
});

export const { setPregnancy, setChildren } = libraryFiltersSlice.actions;

export default libraryFiltersSlice.reducer;
