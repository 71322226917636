import { useEffect } from 'react';
import { useLogout } from 'react-admin';

import { Loader } from '../components/Loader';

export const Logout = () => {
  const logout = useLogout();

  useEffect(() => {
    logout(undefined, '/login');
  }, [logout]);
  return <Loader />;
};
