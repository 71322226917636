import dayjs from 'dayjs';
import { remove as removeDiacritics } from 'diacritics';
import { useMemo } from 'react';
import { useTranslate } from 'react-admin';

import { Planning } from '@boTypes/planning';
import { StaffUser } from '@boTypes/staffUser';
import { Download, EuroSymbol } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import { formatTZ } from '@utils/date';

import { s3URL } from '../../common/url';
import {
  useGetInvoicesSepa,
  useValidateInvoices,
} from '../../entities/plannings/hooks/invoices';

export const robustFileNames = (fileName: string): string => {
  return encodeURIComponent(
    removeDiacritics(fileName.replace(/[\s'"]+/g, '_')),
  );
};

const onDownload = ({
  files,
  fileNames,
}: {
  files: string[];
  fileNames: string[];
}) => {
  const link = document.createElement('a');
  link.href = `${s3URL}/zip?${files
    .map((file) => `files[]=${file}`)
    .join('&')}&${fileNames
    .map((fileName) => `fileNames[]=${fileName}`)
    .join('&')}`;
  link.download = `invoices-${dayjs().format('YYYY-MM-DD')}.zip`;
  link.click();
};

const GridToolbar = () => {
  const ctx = useGridApiContext();
  const translate = useTranslate();

  const { mutate: getSepa } = useGetInvoicesSepa();
  const { mutate: validateInvoices } = useValidateInvoices();

  const selectedRows = ctx.current.getSelectedRows();
  const count = selectedRows.size;
  // row id is staffUserId
  const invoices = useMemo(() => {
    return Array.from(selectedRows.values()).reduce<{
      files: string[];
      fileNames: string[];
    }>(
      (acc, current: any) => {
        const row = current as {
          invoiceURL: string;
          staffUser: StaffUser;
          planning: Planning;
        };
        acc.files.push(row.invoiceURL);
        acc.fileNames.push(
          [
            formatTZ(row.planning.begin, 'YYYY_MM_DD'),
            row.planning.title,
            row.staffUser.firstName,
            row.staffUser.lastName,
          ]
            .map(robustFileNames)
            .join('-') + '.pdf',
        );
        return acc;
      },
      { files: [], fileNames: [] },
    );
  }, [selectedRows]);

  const invoiceIds = Array.from(selectedRows.values()).map(({ id }) => id);

  return (
    <>
      <Button
        disabled={count === 0}
        size="small"
        onClick={() => onDownload(invoices)}
        startIcon={<Download />}
        color="success"
        variant="outlined"
      >
        {translate('invoice.adminList.downloadButton', { smart_count: count })}
      </Button>
      <Button
        disabled={count === 0}
        size="small"
        onClick={() => {
          validateInvoices({ invoiceIds });
          getSepa({ invoiceIds });
        }}
        startIcon={<EuroSymbol />}
        color="success"
        variant="outlined"
      >
        {translate('invoice.adminList.validateAndSepa')}
      </Button>
    </>
  );
};

export const CustomToolbar = () => (
  <GridToolbarContainer sx={{ mb: 1 }}>
    <GridToolbar />
  </GridToolbarContainer>
);
