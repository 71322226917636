export const LiviIcon = ({ color = '#002845' }: { color?: string }) => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 2059 900">
      <g transform="translate(250 50)">
        <path
          fill={color}
          d="M510,0c-64,0-108,43-108,104s44,103,108,103s108-42,108-103S574,0,510,0z"
        />
        <rect x="414" y="259" fill={color} width="192" height="540" />
        <path
          fill={color}
          d="M192,525V59H0v471c0,195,76,278,255,278c31,0,59-3,89-9V616c-18,7-37,11-59,11C192,627,192,556,192,525z"
        />
        <polygon
          fill={color}
          points="982.5,608.8 862,259 656,259 868,799 917,799 1048,799 1093,799 1305,259 1103,259 	"
        />
        <rect x="1355" y="259" fill={color} width="192" height="540" />
        <path
          fill={color}
          d="M1451,207c64,0,108-42,108-103S1515,0,1451,0s-108,43-108,104S1387,207,1451,207z"
        />
      </g>
    </svg>
  );
};
