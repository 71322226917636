import { AxiosRequestConfig } from 'axios';

import { AgeLevel, Categories } from '@boTypes/cms';
import Folder from '@boTypes/folder';
import ItemSuggestion from '@boTypes/ItemSuggestion';
import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import { useCMSQuery, useInfiniteSearchQuery } from '@hooks/queryWrappers';
import { getSmallestImageUrlAvailableFor } from '@utils/cmsFiles';

import { DailyTip, Guide, Macro, Masterclass, Post, WeeklyTip } from '../types';

const getSearchRequest = ({
  search,
  category,
  ageLevel,
  url,
  pregnancy = null,
  key,
  signal,
  offset = 0,
}: {
  search?: string;
  url: string;
  category?: number;
  ageLevel?: number;
  pregnancy?: boolean | null;
  key?: string;
  signal?: AbortSignal;
  offset?: number;
}): AxiosRequestConfig => {
  const payload = {} as {
    q: string;
    filter?: string;
    limit?: number;
    offset?: number;
  };
  if (search) {
    payload.q = search;
  }
  const filtering = [];
  if (category) {
    filtering.push(`categoryIds = '${category}'`);
  }
  if (ageLevel) {
    filtering.push(`ageLevelIds = '${ageLevel}'`);
  }
  if (pregnancy !== null) {
    if (pregnancy) {
      filtering.push(`isPregnancy =  ${pregnancy}`);
    } else {
      filtering.push(`isChild = ${!pregnancy}`);
    }
  }
  if (filtering.length) {
    payload.filter = filtering.join(' AND ');
  }

  payload.limit = 40;
  payload.offset = offset * payload.limit;

  return {
    url,
    data: payload,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${key}`,
    },
    signal,
  };
};

const getListCMSRequest = ({ url }: { url: string }): AxiosRequestConfig => {
  return {
    url,
    method: 'GET',
  };
};

const getDetailCMSRequest = ({
  lookup,
  url,
}: {
  lookup: string | number;
  url: string;
}): AxiosRequestConfig => {
  return {
    url: url + '/' + lookup,
  };
};

const fetchCategoriesList = ({ pregnancy }) =>
  getListCMSRequest({
    url: `/pro/categories${
      pregnancy !== undefined
        ? '?filters[pregnancy]=' + (pregnancy ? 'true' : 'false')
        : ''
    }`,
  });

const useContentList = <
  T extends
    | Post
    | Guide
    | Masterclass
    | DailyTip
    | WeeklyTip
    | Macro
    | Folder
    | PregnancyQuestion
    | ItemSuggestion,
>(
  kind: T extends Guide
    ? 'guides'
    : T extends Masterclass
      ? 'masterclasses'
      : T extends Post
        ? 'posts'
        : T extends DailyTip
          ? 'daily-tips'
          : T extends Macro
            ? 'macros'
            : T extends Folder
              ? 'programs'
              : T extends PregnancyQuestion
                ? 'pregnancy-questions'
                : T extends ItemSuggestion
                  ? 'item-suggestions'
                  : T extends WeeklyTip
                    ? 'weekly-tips'
                    : never,
  search: string,
  isTypeSelected: boolean,
  ageLevel?: number,
  category?: number,
  pregnancy?: boolean,
  key?: string,
  excludeFromSearchMode?: boolean,
) => {
  const { data, ...rest } = useInfiniteSearchQuery<T[]>(
    [kind, 'list-search', { search, ageLevel, category, pregnancy, key }],
    ({ signal, pageParam = 0 }) => {
      return getSearchRequest({
        url: `/indexes/${kind}/search`,
        search,
        ageLevel,
        category,
        pregnancy,
        key,
        signal,
        offset: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = lastPage?.length;
        // 40 is the limit we set on meilisearch max hits per page
        if (nextPage === 0 || nextPage < 40) {
          return undefined;
        }
        return pages.length;
      },
      keepPreviousData: true,
      cacheTime: 10 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled:
        (Boolean(key) &&
          Boolean(
            !excludeFromSearchMode && (search || ageLevel || category),
          )) ||
        isTypeSelected,
    },
  );

  return { data: data?.pages?.flatMap((page) => page) ?? [], ...rest };
};

export const usePostsList = (
  search: string,
  isTypeSelected: boolean,
  ageLevel?: number,
  category?: number,
  pregnancy?: boolean,
  key?: string,
) =>
  useContentList<Post>(
    'posts',
    search,
    isTypeSelected,
    ageLevel,
    category,
    pregnancy,
    key,
  );

export const usePostItem = (lookup: string) => {
  return useCMSQuery<Post>(['post-item', lookup], () =>
    getDetailCMSRequest({ lookup, url: '/pro/posts' }),
  );
};

export const useGuidesList = (
  search: string,
  isTypeSelected: boolean,
  ageLevel?: number,
  category?: number,
  pregnancy?: boolean,
  key?: string,
) =>
  useContentList<Guide>(
    'guides',
    search,
    isTypeSelected,
    ageLevel,
    category,
    pregnancy,
    key,
  );

export const useGuideItem = (lookup: string) => {
  return useCMSQuery<Guide>(['guide-item', lookup], () =>
    getDetailCMSRequest({ lookup, url: '/pro/guides' }),
  );
};

export const useMasterclassesList = (
  search: string,
  isTypeSelected: boolean,
  ageLevel?: number,
  category?: number,
  pregnancy?: boolean,
  key?: string,
) =>
  useContentList<Masterclass>(
    'masterclasses',
    search,
    isTypeSelected,
    ageLevel,
    category,
    pregnancy,
    key,
  );

export const useMasterclassItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<Masterclass>(
    ['masterclass-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/masterclasses' }),
    {
      select: (res) => {
        res.trackTitles = res.tracks.map((track) => track.title);
        return res;
      },
    },
  );
  return { data, isLoading };
};

export const useDailyTipsList = (
  search: string,
  isTypeSelected: boolean,
  pregnancy?: boolean,
  key?: string,
) =>
  useContentList<DailyTip>(
    'daily-tips',
    search,
    isTypeSelected,
    undefined,
    undefined,
    pregnancy,
    key,
  );

export const useWeeklyTipsList = (
  search: string,
  isTypeSelected: boolean,
  key?: string,
) =>
  useContentList<WeeklyTip>(
    'weekly-tips',
    search,
    isTypeSelected,
    undefined,
    undefined,
    undefined,
    key,
  );

export const usePregnancyQuestionsList = (
  search: string,
  isTypeSelected: boolean,
  key?: string,
) =>
  useContentList<PregnancyQuestion>(
    'pregnancy-questions',
    search,
    isTypeSelected,
    undefined,
    undefined,
    undefined,
    key,
    true,
  );

export const useItemSuggestionsList = (
  search: string,
  isTypeSelected: boolean,
  key?: string,
) =>
  useContentList<ItemSuggestion>(
    'item-suggestions',
    search,
    isTypeSelected,
    undefined,
    undefined,
    undefined,
    key,
    true,
  );

export const useMacrosList = (
  search: string,
  isTypeSelected: boolean,
  ageLevel?: number,
  category?: number,
  pregnancy?: boolean,
  key?: string,
) => {
  const { data, ...rest } = useContentList<Macro>(
    'macros',
    search,
    isTypeSelected,
    ageLevel,
    category,
    pregnancy,
    key,
  );

  const _data = data.map((macro) => {
    const { suggestionsContents, suggestionsIds, suggestionsTitles } = macro;
    if (!macro.suggestions?.length && suggestionsContents?.length) {
      macro.suggestions = suggestionsContents.map(
        (content: string, index: number) => ({
          content,
          title: suggestionsTitles[index],
          id: suggestionsIds[index],
        }),
      );
    }
    return macro;
  });
  return { data: _data, ...rest };
};

export const useFoldersList = (
  search: string,
  isTypeSelected: boolean,

  ageLevel?: number,
  category?: number,
  key?: string,
) =>
  useContentList<Folder>(
    'programs',
    search,
    isTypeSelected,
    ageLevel,
    category,
    null,
    key,
  );

export const useFolderItem = (lookup: string) => {
  return useCMSQuery<Folder>(
    ['folder-item', lookup],
    () => getDetailCMSRequest({ lookup, url: '/pro/programs' }),
    {
      select: ({ program_steps, ...rest }) => {
        rest.programSteps = program_steps.map((el) => {
          el.content = el.content.map((_el) => {
            if (_el.__component === 'step.article-wrapper') {
              _el.post.coverThumbnailUrl = getSmallestImageUrlAvailableFor(
                _el.post.cover,
              );
            }
            return _el;
          });
          return el;
        });

        return rest;
      },
    },
  );
};

export const useDailyTipItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<DailyTip>(
    ['daily-tip-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/daily-tips' }),
    { staleTime: 5 * 60 * 1000 },
  );
  return { data, isLoading };
};

export const useWeeklyTipItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<WeeklyTip>(
    ['weekly-tip-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/weekly-tips' }),
    { staleTime: 5 * 60 * 1000 },
  );
  return { data, isLoading };
};

export const useAgeLevelsList = () =>
  useCMSQuery<AgeLevel[]>(
    'age-levels',
    getListCMSRequest({ url: '/pro/age-levels?pregnancy=false' }),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

export const useCategoriesList = (pregnancy: boolean) =>
  useCMSQuery<Categories[]>(
    ['categories', pregnancy],
    () => fetchCategoriesList({ pregnancy }),
    {
      staleTime: 5 * 60 * 1000,
    },
  );
