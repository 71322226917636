import {
  ChatDiscussionEvent,
  DiscussionEventType,
} from '@boTypes/discussionEvent';
import { Menu, MenuItem, MenuProps } from '@mui/material';

export enum MenuOptions {
  COPY = 'COPY',
  DELETE = 'DELETE',
}

const OPTIONS_TO_TEXT: Record<MenuOptions, string> = {
  [MenuOptions.COPY]: 'Copier',
  [MenuOptions.DELETE]: 'Supprimer',
};

const optionsGenerator = (
  message: ChatDiscussionEvent,
  readonly = false,
): MenuOptions[] => {
  const options: MenuOptions[] = [];
  if (message.type !== DiscussionEventType.PARTNER) {
    options.push(MenuOptions.COPY);
  }
  if (
    (message.isCurrentUser ||
      message.type === DiscussionEventType.IMAGE ||
      message.type === DiscussionEventType.VIDEO) &&
    !readonly
  ) {
    options.push(MenuOptions.DELETE);
  }
  return options;
};

export const ContextMenu = ({
  message,
  readonly = false,
  onItemPressed,
  ...menuProps
}: {
  message: ChatDiscussionEvent;
  readonly?: boolean;
  onItemPressed: (item: ChatDiscussionEvent, option: MenuOptions) => void;
  onClose: () => void;
} & Omit<MenuProps, 'children' | 'onClose'>) => {
  const options = optionsGenerator(message, readonly);

  return (
    <Menu {...menuProps}>
      {options.map((option) => (
        <MenuItem
          key={option}
          onClick={() => {
            onItemPressed(message, option);
            menuProps.onClose?.();
          }}
        >
          {OPTIONS_TO_TEXT[option]}
        </MenuItem>
      ))}
    </Menu>
  );
};
