import debounce from 'lodash/debounce';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Title } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  ATTRIBUTION_OPTIONS_ENUM,
  CLASSICAL_OPTIONS_ENUM,
  Filters,
} from '@boTypes/subject';
import { isDoctorJob } from '@boTypes/user';
import { DiscussionMainFilters } from '@components/discussions/quickFilters';
import { useChatFormList } from '@hooks/form-builder';
import { useMyCurrentSlot } from '@hooks/plannings';
import { useSubjectAttributions } from '@hooks/subjectAttribution';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

// @ts-ignore
import emptyDiscussion from '../assets/empty_discussion.webp';
import { DiscussionContext } from '../common';
import {
  attributionFilters,
  countDiscussionType,
  discussionFilters,
  useUsersInJob,
} from '../components/discussions/hooks';
import { SearchAndAttributionFilters } from '../components/discussions/searchAndAttributionFilters';
import { DiscussionDetail } from '../components/subjects/discussionDetail';
import {
  ActiveSubjectsContext,
  SubjectFilters,
} from '../components/subjects/subjectFilters';
import { SubjectList } from '../components/subjects/subjectList';
import { DiscussionDetailProvider } from '../hooks/discussion';
import {
  useActiveTab,
  useSubjectFilter,
  useSubjects,
  useSubjectsQuery,
} from '../hooks/subjects';
import { useAttributionFilterState } from '../hooks/useAttributionFilterState';
import { useDiscussionContext } from '../hooks/useDiscussionContext';
import { useQuickFilterState } from '../hooks/useQuickFilterState';
import { useUsersInRoom } from '../hooks/useUsersInRoom';
import { useSelector } from '../store';
import { forceAttributionFilter } from '../store/attributionFilters';
import { forceQuickFilter } from '../store/discussionFilters';
import { COLORS } from '../themes';
import {
  AttributionFilter,
  Discussion,
  DiscussionQuickFilter,
} from '../types/discussion';
import { useGateway } from '../utils/gateway';

export const ALL_PRO_ROOM = 'pros';

const defaultCount = {
  doctorInterventionCount: 0,
  nurseInterventionCount: 0,
  priorityCount: 0,
  uncategorizedCount: 0,
  zenCount: 0,
  mineZenCount: 0,
};

export const CountDiscussionContext = createContext(defaultCount);

export const SubjectScreen = () => {
  const { discussionId } = useParams();
  const socket = useGateway();
  const activeFilter = useSubjectFilter();
  const discussionContext = useDiscussionContext();
  const subjectsData = useSubjects();
  const userId = useSelector((state) => state.user.userId);
  const jobTitle = useSelector((state) => state.user.jobTitle);
  const isDoctor = isDoctorJob(jobTitle);
  const connectedUsers = useUsersInRoom(socket, ALL_PRO_ROOM);

  const connectedInJobIds = useUsersInJob(discussionContext, connectedUsers);
  const inJobIds = useUsersInJob(
    discussionContext,
    subjectsData.subjects
      .filter((d) => d.lastMaySender)
      .map((d) => String(d.lastMaySender))
      .concat(connectedUsers),
  );

  const dispatch = useDispatch();
  const setQuickFilter = useCallback(
    (filter: DiscussionQuickFilter) =>
      dispatch(forceQuickFilter({ filter, filterType: discussionContext })),
    [dispatch, discussionContext],
  );
  const setAttribFilter = useCallback(
    (filter: AttributionFilter) =>
      dispatch(
        forceAttributionFilter({ filter, filterType: discussionContext }),
      ),
    [dispatch, discussionContext],
  );
  const [quickFilter, onFilterClick] = useQuickFilterState(discussionContext);
  const [attributionFilter, onAttribFilterClick] =
    useAttributionFilterState(discussionContext);
  const bigScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, _setSearchText] = useState<string>('');
  const [debouncedSearchText, _setDebouncedSearchText] = useState<string>('');

  const setDebouncedSearchText = useMemo(
    () => debounce((value: string) => _setDebouncedSearchText(value), 500),
    [_setDebouncedSearchText],
  );

  const setSearchText = useCallback(
    (text: string) => {
      _setSearchText(text);
      setDebouncedSearchText(text);
    },
    [setDebouncedSearchText],
  );

  useEffect(() => {
    const filterString = searchParams.get('filter');
    if (!filterString) {
      const filter = { status: 'Active' };
      searchParams.set('filter', JSON.stringify(filter));
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const tab = useActiveTab();
  useEffect(() => {
    switch (tab as ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM) {
      case ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS:
        setAttribFilter(AttributionFilter.MINE);
        setQuickFilter(DiscussionQuickFilter.ZEN);
        break;
      case ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED:
        setAttribFilter(AttributionFilter.NONE);
        setQuickFilter(DiscussionQuickFilter.UNCATEGORIZED_AND_ZEN);
        break;
      case ATTRIBUTION_OPTIONS_ENUM.REVIVE:
        setAttribFilter(AttributionFilter.NONE);
        setQuickFilter(DiscussionQuickFilter.NONE);
        break;
      case ATTRIBUTION_OPTIONS_ENUM.WITH_DOCTOR_INTERVENTION:
        setAttribFilter(AttributionFilter.NONE);
        setQuickFilter(DiscussionQuickFilter.NONE);
        break;
      case ATTRIBUTION_OPTIONS_ENUM.CLOSED:
        setAttribFilter(AttributionFilter.NONE);
        setQuickFilter(DiscussionQuickFilter.NONE);
        break;
      case CLASSICAL_OPTIONS_ENUM.ACTIVE:
      case CLASSICAL_OPTIONS_ENUM.INACTIVE:
      case CLASSICAL_OPTIONS_ENUM.REVIVE:
      default:
        break;
    }
  }, [setAttribFilter, setQuickFilter, tab]);

  const { data } = useSubjectAttributions();

  const applyAttributionFilter = useCallback(
    (_discussions: Discussion[], _attributionFilter: AttributionFilter) => {
      if (_attributionFilter) {
        if (_attributionFilter === AttributionFilter.ATTRIBUTED) {
          return _discussions.filter(
            attributionFilters[attributionFilter](
              Number(userId),
              activeFilter,
              isDoctor,
            ),
          );
        } else if (attributionFilter === AttributionFilter.MINE) {
          return _discussions.filter(
            attributionFilters[attributionFilter](
              Number(userId),
              data?.staffUserFromSubjectId ?? {},
            ),
          );
        } else if (attributionFilter === AttributionFilter.UNATTRIBUTED) {
          return _discussions.filter(
            attributionFilters[attributionFilter](
              connectedInJobIds,
              Number(userId),
              activeFilter,
            ),
          );
        }
      }
      return _discussions;
    },
    [
      activeFilter,
      attributionFilter,
      connectedInJobIds,
      data?.staffUserFromSubjectId,
      isDoctor,
      userId,
    ],
  );

  const displayedObjects = useMemo(() => {
    if (!subjectsData.subjects || subjectsData.loading) {
      return [];
    }
    if (!quickFilter || !discussionFilters[quickFilter]) {
      const temp = subjectsData.subjects ?? [];
      return applyAttributionFilter(temp, attributionFilter);
    }
    const tempResult = subjectsData.subjects.filter((d) =>
      discussionFilters[quickFilter](d, inJobIds, discussionContext),
    );
    return applyAttributionFilter(tempResult, attributionFilter);
  }, [
    subjectsData.subjects,
    subjectsData.loading,
    quickFilter,
    applyAttributionFilter,
    attributionFilter,
    inJobIds,
    discussionContext,
  ]);

  const currentSlot = useMyCurrentSlot();
  const activeTab = useActiveTab();

  const uncategorizedObjects = useMemo(() => {
    if (
      activeTab !== ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS ||
      !currentSlot?.isTriage ||
      !subjectsData.allSubjects ||
      subjectsData.loading
    ) {
      return [];
    }
    return subjectsData.allSubjects.filter((d) =>
      discussionFilters[DiscussionQuickFilter.UNCATEGORIZED](
        d,
        inJobIds,
        discussionContext,
      ),
    );
  }, [
    activeTab,
    currentSlot?.isTriage,
    subjectsData.allSubjects,
    subjectsData.loading,
    inJobIds,
    discussionContext,
  ]);

  // Count total with diff filters
  const activeList = useSubjectsQuery(
    socket,
    discussionContext,
    Filters.ACTIVE,
  );

  const {
    doctorInterventionCount,
    nurseInterventionCount,
    priorityCount,
    uncategorizedCount,
    zenCount,
    mineZenCount,
  } = countDiscussionType({
    discussions: subjectsData.subjects,
    activeList: activeList.data || [],
    inJobIds,
    context: discussionContext,
    attribFilterValue: attributionFilter,
    userId,
    staffUserFromSubjectId: data?.staffUserFromSubjectId,
  });

  const countContext = useMemo(
    () => ({
      doctorInterventionCount,
      nurseInterventionCount,
      priorityCount,
      uncategorizedCount,
      zenCount,
      mineZenCount,
    }),
    [
      doctorInterventionCount,
      nurseInterventionCount,
      priorityCount,
      uncategorizedCount,
      zenCount,
      mineZenCount,
    ],
  );

  useChatFormList({ enabled: true });

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Title
        title={
          discussionContext === DiscussionContext.MIDWIFE
            ? 'Chat SF'
            : 'Chat IPDE'
        }
      />
      {!socket && (
        <Box
          sx={{
            backgroundColor: COLORS.SAND,
            height: '50px',
            width: '100%',
            border: '1px solid ' + COLORS.ORANGE,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body1" color="error">
            {
              'Erreur de connexion au serveur, rechargez la page si ce message ne disparait pas'
            }
          </Typography>
          <IconButton onClick={() => location.reload()}>
            <ReplayIcon />
          </IconButton>
        </Box>
      )}
      <Grid
        container
        sx={{
          height: '100%',
        }}
      >
        <CountDiscussionContext.Provider value={countContext}>
          {Boolean(bigScreen || !discussionId) && (
            <Grid
              item
              sx={(theme) => ({
                flexDirection: 'column',
                height: '100%',
                flex: 2,
                borderRight: `1px solid ${COLORS.GREY_LAYOUT}`,
                alignItems: 'stretch',
                display: 'flex',
                width: 'calc(100vw - 50px)',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '100vw',
                },
              })}
              xs={12}
              md={3}
            >
              <Box
                sx={{
                  borderBottom: `1px solid ${COLORS.GREY_LAYOUT}`,
                }}
              >
                <ActiveSubjectsContext.Provider value={activeList.data || []}>
                  <SubjectFilters />
                </ActiveSubjectsContext.Provider>
              </Box>
              <Box
                sx={{
                  borderBottom: `1px solid ${COLORS.GREY_LAYOUT}`,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <DiscussionMainFilters
                  discussions={subjectsData.subjects}
                  allDiscussions={subjectsData.allSubjects}
                  filterValue={quickFilter}
                  onFilterClick={onFilterClick}
                  attribFilterValue={attributionFilter}
                  onAttribFilterClick={onAttribFilterClick}
                />
              </Box>
              <SearchAndAttributionFilters
                text={searchText}
                onChangeText={setSearchText}
                discussions={subjectsData.subjects}
                filterValue={attributionFilter}
                onFilterClick={onAttribFilterClick}
              />
              <Box sx={{ height: 'calc(100% - 122px)' }}>
                <SubjectList
                  loading={subjectsData.loading}
                  subjects={displayedObjects}
                  uncategorizedSubjects={uncategorizedObjects}
                  searchText={searchText ? debouncedSearchText : undefined}
                />
              </Box>
            </Grid>
          )}
          <Grid
            item
            sx={(theme) => ({
              flexDirection: 'column',
              height: '100%',
              flex: 8,
              [theme.breakpoints.down('md')]: {
                display: discussionId ? 'flex' : 'none',
              },
              [theme.breakpoints.up('md')]: {
                display: 'flex',
              },
            })}
            xs={12}
            md={9}
          >
            {discussionId ? (
              <DiscussionDetailProvider
                discussionId={discussionId}
                socket={socket}
              >
                {/* use key to ensure local states of discussion detail are reset when discussion changes */}
                <DiscussionDetail key={discussionId} />
              </DiscussionDetailProvider>
            ) : (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={emptyDiscussion}
                  alt="empty selection"
                  style={{ maxHeight: '50%', marginTop: '-6rem' }}
                />
                <Typography
                  sx={{ padding: 1 }}
                >{`Sélectionnez une question dans la liste de gauche`}</Typography>
              </Box>
            )}
          </Grid>
        </CountDiscussionContext.Provider>
      </Grid>
    </Box>
  );
};
