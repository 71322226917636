import { useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import { CameraRoll } from '@mui/icons-material';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { useSelector } from '../../store';
import { COLORS } from '../../themes';

export const ScenarioCard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);

  if (!roles.includes(Roles.ADMIN)) {
    return null;
  }

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="subtitle1" color="primary" sx={{ paddingBottom: 1 }}>
        {translate('admin.others.title')}
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton disableGutters component="a" href="/#/scenarios">
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <CameraRoll fontSize="small" sx={{ color: COLORS.GREY_TEXT }} />
            </ListItemIcon>
            <ListItemText primary={translate('admin.others.scenarios')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
};
