import { useContext, useMemo } from 'react';

import { useAttributionUI } from '@hooks/useAttributionUI';
import { useDiscussionContext } from '@hooks/useDiscussionContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';

import { AttributionUIDiscussionMainFilters } from './attributionUIQuickFilters';
import { ColoredBadge } from './coloredBadge';
import { getLongestWaitingTime } from './hooks';
import { StyledIconButton } from './styledIconButton';
import { DiscussionContext } from '../../common';
import { CountDiscussionContext } from '../../pages/subjects';
import { COLORS } from '../../themes';
import { Discussion } from '../../types';
import {
  AttributionFilter,
  DiscussionQuickFilter,
} from '../../types/discussion';
import { conditionalColor } from '../../utils/conditionalColor';

const DoctorBadge = ColoredBadge(COLORS.BLUE_DARK['300']);
const NurseBadge = ColoredBadge(COLORS.BLUE['300']);
const ZenBadge = ColoredBadge(COLORS.GREEN['400']);
const PriorityBadge = ColoredBadge(COLORS.PINK['500']);
const UncategorizedBadge = ColoredBadge(COLORS.ORANGE);

const FilterWrapperBox = styled(Box)({
  height: '40px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
});

const OldUIDiscussionMainFilters = ({
  discussions = [],
  onFilterClick,
  filterValue,
}: {
  discussions: Discussion[];
  onFilterClick: (filter: DiscussionQuickFilter) => void;
  filterValue: DiscussionQuickFilter;
}) => {
  const discussionContext = useDiscussionContext();

  const {
    doctorInterventionCount,
    nurseInterventionCount,
    priorityCount,
    uncategorizedCount,
    zenCount,
  } = useContext(CountDiscussionContext);
  const { longestWaitingTime, longestWaitingTimeAgo } =
    getLongestWaitingTime(discussions);

  const color = useMemo(() => {
    if (longestWaitingTime > 60) {
      return COLORS.PINK['500'];
    } else if (longestWaitingTime > 30) {
      return COLORS.ORANGE;
    } else {
      return COLORS.GREY_TEXT_LIGHT;
    }
  }, [longestWaitingTime]);

  return (
    <FilterWrapperBox>
      <StyledIconButton
        onClick={() =>
          onFilterClick(
            discussionContext === DiscussionContext.MIDWIFE
              ? DiscussionQuickFilter.NURSE
              : DiscussionQuickFilter.MIDWIFE,
          )
        }
      >
        <NurseBadge badgeContent={nurseInterventionCount}>
          {discussionContext === DiscussionContext.MIDWIFE ? (
            <ChildCareIcon
              sx={{
                color: conditionalColor(
                  filterValue === DiscussionQuickFilter.NURSE,
                ),
              }}
            />
          ) : (
            <PregnantWomanIcon
              sx={{
                color: conditionalColor(
                  filterValue === DiscussionQuickFilter.MIDWIFE,
                ),
              }}
            />
          )}
        </NurseBadge>
      </StyledIconButton>
      <Tooltip title="Questions en cours">
        <StyledIconButton
          onClick={() => onFilterClick(DiscussionQuickFilter.ZEN)}
        >
          <ZenBadge badgeContent={zenCount}>
            <SelfImprovementIcon
              sx={{
                color: conditionalColor(
                  filterValue === DiscussionQuickFilter.ZEN,
                ),
              }}
            />
          </ZenBadge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Questions avec avis médical">
        <StyledIconButton
          onClick={() => onFilterClick(DiscussionQuickFilter.DOCTOR)}
        >
          <DoctorBadge badgeContent={doctorInterventionCount}>
            <LocalHospitalIcon
              sx={{
                color: conditionalColor(
                  filterValue === DiscussionQuickFilter.DOCTOR,
                ),
              }}
            />
          </DoctorBadge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Questions prioritaires">
        <StyledIconButton
          onClick={() => onFilterClick(DiscussionQuickFilter.PRIORITY)}
        >
          <PriorityBadge badgeContent={priorityCount}>
            <WarningIcon
              sx={{
                color: conditionalColor(
                  filterValue === DiscussionQuickFilter.PRIORITY,
                ),
              }}
            />
          </PriorityBadge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Questions sans catégorie">
        <StyledIconButton
          onClick={() => onFilterClick(DiscussionQuickFilter.UNCATEGORIZED)}
        >
          <UncategorizedBadge badgeContent={uncategorizedCount}>
            <LabelOffIcon
              sx={{
                color: conditionalColor(
                  filterValue === DiscussionQuickFilter.UNCATEGORIZED,
                ),
              }}
            />
          </UncategorizedBadge>
        </StyledIconButton>
      </Tooltip>
      {Boolean(longestWaitingTime) && (
        <>
          <AccessTimeIcon
            sx={{
              color,
              marginLeft: '0.25rem',
              marginRight: '0.125rem',
              fontSize: '1rem',
            }}
          />
          <Typography variant="body2" sx={{ color, fontSize: '0.625rem' }}>
            {longestWaitingTimeAgo}
          </Typography>
        </>
      )}
    </FilterWrapperBox>
  );
};

export const DiscussionMainFilters = ({
  discussions = [],
  allDiscussions = [],
  onFilterClick,
  filterValue,
  onAttribFilterClick,
  attribFilterValue,
}: {
  discussions: Discussion[];
  allDiscussions: Discussion[];
  onFilterClick: (filter: DiscussionQuickFilter) => void;
  filterValue: DiscussionQuickFilter;
  onAttribFilterClick: (filter: AttributionFilter) => void;
  attribFilterValue: AttributionFilter;
}) => {
  const attributionUI = useAttributionUI();
  return attributionUI ? (
    <AttributionUIDiscussionMainFilters
      discussions={discussions}
      allDiscussions={allDiscussions}
      onFilterClick={onFilterClick}
      filterValue={filterValue}
      onAttribFilterClick={onAttribFilterClick}
      attribFilterValue={attribFilterValue}
    />
  ) : (
    <OldUIDiscussionMainFilters
      discussions={discussions}
      onFilterClick={onFilterClick}
      filterValue={filterValue}
    />
  );
};
