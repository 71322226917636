import { SubjectScreen } from './subjects';
import { DiscussionContext } from '../common/context';
import { SubjectProvider } from '../hooks/subjects';
import { DiscussionContextProvider } from '../hooks/useDiscussionContext';
import { useGateway } from '../utils/gateway';

export const MidwifeSubjects = () => {
  const socket = useGateway();

  return (
    <DiscussionContextProvider discussionContext={DiscussionContext.MIDWIFE}>
      <SubjectProvider
        socket={socket}
        discussionContext={DiscussionContext.MIDWIFE}
      >
        <SubjectScreen />
      </SubjectProvider>
    </DiscussionContextProvider>
  );
};
