import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ChatDiscussionEvent } from '@boTypes/discussionEvent';
import { styled } from '@mui/material/styles';

const Video = styled('video')({
  maxWidth: '100%',
  width: '100%',
  height: 200,
  borderTopLeftRadius: '0.5rem',
  borderTopRightRadius: '0.5rem',
});

export const VideoMessage = React.memo(
  ({ message }: { message: ChatDiscussionEvent }) => {
    return (
      <View style={[styles.container]}>
        <Video controls>
          <source src={message.content} />
          <source src={message.content} type="video/mp4" />
          Your browser does not support this video format.
        </Video>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 200,
  },
});
