import { useFormContext } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { Template } from '@teammay/form-core';

export type ImageTemplate = Omit<Template, 'id' | 'createdAt' | 'updatedAt'>;

export const ImageTemplateEdit = ({ onBlur }: { onBlur: () => void }) => {
  const { control } = useFormContext<ImageTemplate>();
  return (
    <>
      <TextInput control={control} name="title" label="Title" onBlur={onBlur} />
      <TextInput
        control={control}
        name="description"
        label="Description"
        onBlur={onBlur}
      />
      <TextInput control={control} name="image" label="Image" onBlur={onBlur} />
    </>
  );
};
