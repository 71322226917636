import {
  ActivityIndicator,
  GestureResponderEvent,
  PressableProps,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import { PressableNative } from './PressableNative';
import { MobileColors as Colors } from '../../../../../themes';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 40,
    height: 48,
    borderRadius: 24,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  disabled: { opacity: 0.5 },
  hidden: { opacity: 0 },

  loader: StyleSheet.absoluteFillObject,

  inner: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  reverse: { flexDirection: 'row-reverse' },
  label: {
    fontFamily: 'Poppins-Semibold',
    fontSize: 16,
    textAlign: 'center',
  },
});

export interface ButtonUIProps
  extends Omit<PressableProps, 'style' | 'onPress'> {
  style?: StyleProp<ViewStyle>;
  onPress: (event: GestureResponderEvent) => void;
  label?: string | undefined;
  iconStrokeWidth?: number;
  textColor?: string;
  isLoading: boolean;
  inverted?: boolean;
}
function ButtonUI({
  style,
  onPress,
  label,
  textColor = Colors.duck800,
  iconStrokeWidth,
  disabled,
  isLoading,
  inverted,
  children,
  ...pressableProps
}: ButtonUIProps) {
  return (
    <PressableNative
      style={[styles.container, disabled && styles.disabled, style]}
      onPress={onPress}
      disabled={disabled || isLoading}
      {...pressableProps}
    >
      {isLoading && (
        <ActivityIndicator
          style={styles.loader}
          color={textColor}
          size="large"
          testID="activityIndicator"
        />
      )}
      <View
        style={[
          styles.inner,
          isLoading && styles.hidden,
          inverted && styles.reverse,
        ]}
      >
        {label && (
          <Text style={[styles.label, { color: textColor }]}>{label}</Text>
        )}
        {children && typeof children !== 'function' && (
          <Text style={[styles.label, { color: textColor }]}>{children}</Text>
        )}
      </View>
    </PressableNative>
  );
}

export default ButtonUI;
