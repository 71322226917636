import { Send } from '@mui/icons-material';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  padding: 0,
  minWidth: theme.spacing(5),
  minHeight: theme.spacing(5),
  borderRadius: '50%',
}));

export const SendIcon = (props) => {
  return (
    <StyledButton
      variant="contained"
      color="primary"
      aria-label="send"
      size="medium"
      {...props}
    >
      <Send />
    </StyledButton>
  );
};
