export enum OMSDataTypes {
  BOY_WEIGHT = 'BOY_WEIGHT',
  GIRL_WEIGHT = 'GIRL_WEIGHT',
  BOY_HEIGHT = 'BOY_HEIGHT',
  GIRL_HEIGHT = 'GIRL_HEIGHT',
}

export enum OMSCurve {
  SD3neg = 'SD3neg',
  SD2neg = 'SD2neg',
  SD1neg = 'SD1neg',
  SD0 = 'SD0',
  SD1 = 'SD1',
  SD2 = 'SD2',
  SD3 = 'SD3',
}

export const orderedOMSCurve = [
  OMSCurve.SD3neg,
  OMSCurve.SD2neg,
  OMSCurve.SD1neg,
  OMSCurve.SD0,
  OMSCurve.SD1,
  OMSCurve.SD2,
  OMSCurve.SD3,
];
