import { memoize } from 'lodash';
import { useNotify } from 'react-admin';

import {
  UseQueryOptions,
  useQuery,
  useMutation,
  useQueryClient,
} from '@hooks/queryWrappers';
import { Form, Question, Template } from '@teammay/form-core';

export type UnpopulatedForm = Omit<Form, 'firstStep' | 'steps'>;
export interface FormDetailRawResponse {
  form: Form;
  questions: (Omit<Question, 'options'> & { options: string })[];
  templates: Template[];
  availableDataSlugs: string[];
}
export interface FormDetailResponse {
  form: Form;
  questions: Question[];
  templates: Template[];
  availableDataSlugs: string[];
}

const select = memoize(
  ({
    form,
    templates,
    questions,
    availableDataSlugs,
  }: FormDetailRawResponse) => {
    return {
      form,
      templates,
      questions: questions.map((question) => ({
        ...question,
        options: question.options ? JSON.parse(question.options) : [],
      })),
      availableDataSlugs,
    };
  },
  ({ form, templates, questions }) =>
    JSON.stringify({ form, templates, questions }), // prevent automatic refetching to destroy our local state
);

export const useFormDetail = (
  id: Form['id'],
  options: UseQueryOptions<FormDetailRawResponse, any, FormDetailResponse> = {},
) => {
  return useQuery<FormDetailRawResponse, any, FormDetailResponse>(
    ['form', id],
    {
      method: 'GET',
      url: `/api/forms/${id}`,
    },
    {
      enabled: !!id,
      select,
      ...options,
    },
  );
};

export const useFormList = () => {
  return useQuery<UnpopulatedForm[]>('forms', {
    method: 'GET',
    url: '/api/forms',
  });
};

export const useChatFormList = (
  { enabled = false } = {} as { enabled?: boolean },
) => {
  return useQuery<UnpopulatedForm[]>(
    ['forms', { sentInChat: true, status: 'published' }],
    {
      method: 'GET',
      url: '/api/forms',
      params: {
        sentInChat: true,
        status: 'published',
      },
    },
    { enabled },
  );
};

export type FormSettingsUpdate = Pick<
  Form,
  | 'id'
  | 'slug'
  | 'title'
  | 'status'
  | 'description'
  | 'illustration'
  | 'version'
  | 'sentInChat'
  | 'questionCount'
>;

export const useFormUpdate = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation<UnpopulatedForm, unknown, FormSettingsUpdate>(
    ['updateForm'],
    (data) => ({
      method: 'PUT',
      url: `/api/forms/${data.id}`,
      data: data,
    }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<UnpopulatedForm[]>('forms', (oldData) => {
          if (!oldData) {
            return [];
          }
          return oldData.map((form) =>
            form.id === data.id ? { ...form, ...data } : form,
          );
        });
        queryClient.setQueryData<FormDetailResponse>(
          ['form', data.id],
          (formDetail) => {
            if (!formDetail) {
              return;
            }
            return { ...formDetail, form: { ...formDetail.form, ...data } };
          },
        );
        notify('forms.edit.saved');
      },
      onError: () => {
        notify('forms.errors.generic_error');
      },
    },
  );
};

export const useFormDetailUpdate = () => {
  const notify = useNotify();
  return useMutation<
    { success: true },
    unknown,
    Omit<FormDetailResponse, 'availableDataSlugs'>
  >(
    ['updateForm'],
    (data) => {
      const sentQuestions = data.questions.map((question) => {
        return {
          ...question,
          formId: data.form.id,
          options: question.options
            ? JSON.stringify(question.options)
            : undefined,
        };
      });
      return {
        method: 'PUT',
        url: `/api/forms/detail`,
        data: {
          form: data.form,
          questions: sentQuestions,
          templates: data.templates,
        },
      };
    },
    {
      onSuccess: () => {
        notify('forms.edit.saved');
      },
      onError: () => {
        notify('forms.errors.generic_error');
      },
    },
  );
};
