import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';

import { Planning } from '@boTypes/planning';
import { StaffUser } from '@boTypes/staffUser';
import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { CloudUpload } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material';

import { usePlanningSummary } from './hooks';
import { useCreateInvoice, useInvoiceUpload } from './invoice.form.hook';
import { useSelector } from '../../store';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PDFUpload = ({
  planningId,
  setValue,
  setError,
  clearErrors,
}: {
  planningId: Planning['id'];
  setValue: UseFormSetValue<InvoiceFormSetValue>;
  setError: UseFormSetError<InvoiceFormSetValue>;
  clearErrors: UseFormClearErrors<InvoiceFormSetValue>;
}) => {
  const translate = useTranslate();
  const staffUserId = useSelector((state) => state.user?.userId);
  const { checkFileAndUpload, showAmountError, showAmountSuccess, fileName } =
    useInvoiceUpload();
  const { summary } = usePlanningSummary(planningId, staffUserId, {
    enabled: true,
  });
  if (!summary) {
    return (
      <Typography color="error">
        {translate('invoice.dialog.errorRetrievingSummary')}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.5rem',
        rowGap: '0.5rem',
      }}
    >
      {showAmountError && (
        <Alert severity="error">
          <AlertTitle>
            {translate('invoice.dialog.amountErrorTitle')}
          </AlertTitle>
          {translate('invoice.dialog.amountError', {
            amount: summary.invoiceData.total,
          })}
        </Alert>
      )}
      {showAmountSuccess && (
        <Alert severity="success">
          <AlertTitle>
            {translate('invoice.dialog.amountSuccessTitle')}
          </AlertTitle>
          {translate('invoice.dialog.amountSuccess', {
            amount: summary.invoiceData.total,
          })}
        </Alert>
      )}
      <Button
        component="label"
        variant="outlined"
        color="primary"
        startIcon={<CloudUpload />}
      >
        {translate('invoice.dialog.upload')}
        <VisuallyHiddenInput
          type="file"
          accept="application/pdf"
          onChange={(ev) =>
            checkFileAndUpload(
              ev,
              summary.invoiceData.total,
              setValue,
              setError,
              clearErrors,
            )
          }
        />
      </Button>
      {fileName && <Typography variant="caption">{fileName}</Typography>}
    </Box>
  );
};

export type InvoiceFormSetValue = {
  staffUserId: StaffUser['id'];
  comments: string;
  planningId: Planning['id'];
  invoiceURL: string;
  amountValidated: boolean;
};

export const InvoiceUploadDialog = ({
  open,
  onClose,
  planningId,
}: {
  open: boolean;
  onClose: () => void;
  planningId: Planning['id'];
}) => {
  const translate = useTranslate();
  const staffUserId = useSelector((state) => state.user?.userId);
  const [creatingInvoice, setCreatingInvoice] = useState(false);
  const { createInvoice } = useCreateInvoice();
  const { control, reset, setValue, getValues, setError, clearErrors } =
    useForm<InvoiceFormSetValue>({
      defaultValues: {
        staffUserId,
        planningId,
        comments: '',
        invoiceURL: '',
        amountValidated: false,
      },
    });
  const notify = useNotify();

  const onValidate = async () => {
    // get values from form
    setCreatingInvoice(true);
    try {
      const values = getValues();
      await createInvoice(values, { onSuccess: () => reset() });
      notify('invoice.dialog.success', { type: 'success' });
    } catch {
      notify('invoice.dialog.error', {
        type: 'error',
      });
    } finally {
      setCreatingInvoice(false);
      reset();
      onClose();
    }
  };

  const [amountValidated, invoiceURL, comments] = useWatch({
    control,
    name: ['amountValidated', 'invoiceURL', 'comments'],
  });

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('invoice.dialog.title')}
      </DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          <AlertTitle>{translate('invoice.dialog.instructions')}</AlertTitle>
          <List dense>
            <ListItem>{translate('invoice.dialog.instructions1')}</ListItem>
            <ListItem>{translate('invoice.dialog.instructions2')}</ListItem>
            <ListItem>{translate('invoice.dialog.instructions3')}</ListItem>
          </List>
        </Alert>
        <PDFUpload
          planningId={planningId}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
        />
        <TextInput
          control={control}
          name="comments"
          label={translate('invoice.comments')}
          required={!amountValidated}
          multiline
          fullWidth
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <Button
          variant="contained"
          onClick={onValidate}
          disabled={
            creatingInvoice || (!amountValidated && !comments) || !invoiceURL
          }
        >
          {translate('invoice.upload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
