import { StyleSheet } from 'react-native';

import ButtonUI, { ButtonUIProps } from './ButtonUI';
import { MobileColors as Colors } from '../../../../../themes';

const styles = StyleSheet.create({
  primary: { backgroundColor: Colors.duck800 },
  secondary: { borderWidth: 2, borderColor: Colors.duck800 },
  yellow: { backgroundColor: Colors.yellow500 },
  white: { backgroundColor: Colors.white100 },
});

type ButtonProps = Omit<ButtonUIProps, 'textColor' | 'android_ripple'>;

const ANDROID_RIPPLE_PRIMARY = {
  color: `${Colors.black500}${Colors.transparent30}`,
  foreground: true,
};
export function PrimaryButton({ style, ...props }: ButtonProps) {
  return (
    <ButtonUI
      style={[styles.primary, style]}
      textColor={Colors.white100}
      android_ripple={ANDROID_RIPPLE_PRIMARY}
      {...props}
    />
  );
}

const ANDROID_RIPPLE_SECONDARY = {
  color: `${Colors.black500}${Colors.transparent15}`,
  foreground: true,
};
export function SecondaryButton({ style, ...props }: ButtonProps) {
  return (
    <ButtonUI
      style={[styles.secondary, style]}
      textColor={Colors.duck800}
      android_ripple={ANDROID_RIPPLE_SECONDARY}
      {...props}
    />
  );
}

const ANDROID_RIPPLE_BORDERLESS = {
  color: `${Colors.black500}${Colors.transparent15}`,
  foreground: true,
};
export function BorderlessButton(props: Omit<ButtonUIProps, 'android_ripple'>) {
  return <ButtonUI android_ripple={ANDROID_RIPPLE_BORDERLESS} {...props} />;
}

const ANDROID_RIPPLE_YELLOW = {
  color: `${Colors.black500}${Colors.transparent15}`,
  foreground: true,
};
export function YellowButton({ style, ...props }: ButtonProps) {
  return (
    <ButtonUI
      style={[styles.yellow, style]}
      textColor={Colors.white100}
      android_ripple={ANDROID_RIPPLE_YELLOW}
      {...props}
    />
  );
}

const ANDROID_RIPPLE_WHITE = {
  color: `${Colors.black500}${Colors.transparent15}`,
  foreground: true,
};
export function WhiteButton({ style, ...props }: ButtonProps) {
  return (
    <ButtonUI
      style={[styles.white, style]}
      textColor={Colors.duck800}
      android_ripple={ANDROID_RIPPLE_WHITE}
      {...props}
    />
  );
}
