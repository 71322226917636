import { useState } from 'react';
import { Handle, NodeProps, Position, useNodes, useStore } from 'reactflow';

import { QuestionMark } from '@mui/icons-material';
import {
  BooleanOperator,
  Condition,
  Question,
  Template,
} from '@teammay/form-core';

import { COLORS } from '../../../../themes';
import { QuestionNodeType } from '../../types';
import { ConditionEdit, ConditionForm } from '../conditions/ConditionEdit';
import { EditWrapper } from '../EditWrapper';

export function HideHandle<
  Content extends Partial<Question> | Partial<Template>,
>({
  id,
  onClick,
}: {
  id: NodeProps['id'];
  type: Content extends Partial<Question> ? 'question' : 'template';
  content: Content;
  onClick: (condition: Condition) => void;
}) {
  const hasHideCondition = useStore((store) =>
    store.edges.find((e) => e.sourceHandle === 'hide' && e.source === id),
  );

  const [condition, setCondition] = useState<ConditionForm | null>(null);
  const questions = (
    useNodes().filter(
      (node) => node.type === 'question' && node.id !== id,
    ) as QuestionNodeType[]
  ).map((node) => node.data.question as Question);

  if (!questions.length) {
    return null;
  }

  return (
    <>
      {hasHideCondition ? (
        <Handle
          type="source"
          position={Position.Right}
          id="hide"
          className={'hideHandleConnected'}
          isConnectable={false}
        />
      ) : (
        <Handle
          type="source"
          position={Position.Right}
          id="hide"
          className={'hideHandleFree'}
          onClick={() =>
            setCondition({
              id: self.crypto.randomUUID(),
              booleanOperator: BooleanOperator.AND,
              questionRules: [],
              dataRules: [],
              children: [],
            })
          }
        >
          <QuestionMark
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              width: '1rem',
              height: '1rem',
              border: `1px solid ${COLORS.GREY_LAYOUT}`,
            }}
          />
        </Handle>
      )}
      <EditWrapper
        content={condition as ConditionForm}
        onSave={(content) => {
          onClick({ id: condition.id, ...content });
          setCondition(null);
        }}
        open={!!condition}
        onCancel={() => setCondition(null)}
        FormComponent={ConditionEdit}
        defaultValues={{ booleanOperator: BooleanOperator.AND }}
      />
    </>
  );
}
