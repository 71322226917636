export const Arrow = ({ color = '#FFF' }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6889 19.7071C16.2741 20.0976 15.6015 20.0976 15.1867 19.7071L7 12L15.1867 4.29289C15.6015 3.90237 16.2741 3.90237 16.6889 4.29289C17.1037 4.68342 17.1037 5.31658 16.6889 5.70711L10.0044 12L16.6889 18.2929C17.1037 18.6834 17.1037 19.3166 16.6889 19.7071Z"
      fill={color}
    />
  </svg>
);
