import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import { Form } from '@teammay/form-core';

import { PressableNative } from './mobile-components/buttons/PressableNative';

export const CARD_MAX_WIDTH = 220;
export const FORM_CARD_HEIGHT = 150;
const styles = StyleSheet.create({
  container: {
    minHeight: FORM_CARD_HEIGHT,
    width: CARD_MAX_WIDTH,
  },
  image: {
    width: '100%',
    height: (FORM_CARD_HEIGHT * 3) / 4,
  },
  titleContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 20,
    width: '100%',
    textAlign: 'center',
  },
  statusChip: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'white',
    borderRadius: 16,
    zIndex: 1,
    padding: 8,
    minWidth: 56,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export const FormCard = ({
  title,
  illustration,
  status,
  style,
  onPress,
}: Pick<Form, 'title' | 'illustration' | 'status'> & {
  onPress?: () => void;
  style: StyleProp<ViewStyle>;
}) => {
  return (
    <PressableNative
      style={[styles.container, style]}
      onPress={onPress}
      disabled={!onPress}
    >
      <Image
        style={styles.image}
        source={{ uri: illustration ?? 'https://via.placeholder.com/150' }}
      />
      {status !== 'published' && (
        <View style={styles.statusChip}>
          <Text>{status}</Text>
        </View>
      )}
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
      </View>
    </PressableNative>
  );
};
