import React, { useEffect, useState, useCallback } from 'react';
import { ListProps, useDataProvider, useTranslate } from 'react-admin';

import { CircularProgress, Typography } from '@mui/material';

import { HandoverItem } from './item';
import { getListDefaultOptions } from '../../provider/dataProvider';

interface Props extends Omit<ListProps, 'children'> {
  subjectId?: number;
  children?: React.ReactElement;
}

export const HandoverList = (props: Props) => {
  const { subjectId } = props;
  const [loading, setLoading] = useState(true);
  const [handovers, setHandovers] = useState([]);
  const [shouldReloadHandovers, setShouldReloadHandovers] = useState(true);
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  const getHandovers = useCallback(async () => {
    if (!subjectId || !shouldReloadHandovers) {
      return;
    }
    const response = await dataProvider.getList('handovers', {
      ...getListDefaultOptions,
      filter: { subjectId },
    });
    setLoading(false);
    if (!response) {
      return;
    }
    setHandovers(response.data.filter((h) => !!h.active));
    setShouldReloadHandovers(false);
  }, [subjectId, dataProvider, shouldReloadHandovers]);

  useEffect(() => {
    getHandovers();
  }, [getHandovers]);

  useEffect(() => {
    setShouldReloadHandovers(true);
  }, [subjectId]);

  return (
    <div style={{ margin: '1rem' }}>
      <div style={{ marginTop: '0.5rem' }}>
        {loading ? (
          <CircularProgress size="1rem" />
        ) : handovers.length ? (
          handovers.map((handover, ndx) => (
            <HandoverItem key={ndx} handover={handover} />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            {translate('handover.noData')}
          </Typography>
        )}
      </div>
    </div>
  );
};
