import { Category } from './category';
import { Discussion } from './discussion';
import { DiscussionEvent } from './discussionEvent';
import { AppUser, FamilyPopulated } from './family';
import { Handover } from './handover';

export enum Filters {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REVIVE = 'Relance',
}

export enum ATTRIBUTION_OPTIONS_ENUM {
  NOT_ATTRIBUTED = 'subject.notAttributed',
  MY_QUESTIONS = 'subject.myQuestions',
  REVIVE = 'subject.revive',
  WITH_DOCTOR_INTERVENTION = 'subject.with.doctor.intervention',
  CLOSED = 'subject.closed',
}

// todo : remove this when automatic attribution rules
export enum CLASSICAL_OPTIONS_ENUM {
  ACTIVE = 'subject.actives',
  INACTIVE = 'subject.inactives',
  REVIVE = 'subject.revives',
}

export interface Subject {
  id: number;
  withDoctorIntervention: boolean;
  withNurseIntervention: boolean;
  isPriority: boolean;
  start: Date;
  firstMessageAt: Date;
  end: Date;
  discussionId: number;
  discussion?: Discussion;
  category?: Category;
  handovers?: Handover[];
  empty: boolean;
  mayAnswered: boolean;
  revive: boolean;
}

export interface MinimalSubject {
  id: number;
  withDoctorIntervention: boolean;
  withNurseIntervention: boolean;
  isPriority: boolean;
  firstMessageAt: Date;
  start: Date;
  end: Date;
  categoryId?: number;
  category?: Category;
  empty: boolean;
  handovers?: Handover[];
  mayAnswered: boolean;
}

export interface SubjectExplorer extends Subject {
  appUsers: AppUser[];
  lastEvent: DiscussionEvent;
  lastMayEvent?: DiscussionEvent;
}

export interface SubjectExplorerPopulated {
  subject: SubjectExplorer;
  family: FamilyPopulated;
}

export class SubjectSetCategoryDTO {
  category?: Category;
}

export class SetDoctorInterventionInput {
  cancel?: boolean;
  midwife?: boolean;
}

export class SetNurseInterventionInput {
  cancel?: boolean;
}
