import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

import { AvatarList } from './AvatarList';
import { useActiveSessions } from '../hooks/useActiveSessions';
import { useUsersInRoom } from '../hooks/useUsersInRoom';
import { ALL_PRO_ROOM } from '../pages/subjects';
import { jobTitleSort } from '../types/user';
import { useGateway } from '../utils/gateway';

export const ConnectedUsers = ({
  room = ALL_PRO_ROOM,
  size = 'default',
}: {
  room?: string;
  size?: 'default' | 'dense' | 'medium';
}) => {
  const socket = useGateway();
  const users = useUsersInRoom(socket, room);
  const { data: active } = useActiveSessions(socket);
  const { data: usersAvatar, isLoading: isUsersLoading } = useGetVaryingMany(
    'users',
    users,
    {
      select: (allUsers) =>
        allUsers.sort((u1, u2) => {
          const u1Active = active?.includes(u1.id);
          const u2Active = active?.includes(u2.id);
          if (u1Active && !u2Active) {
            return -1;
          }
          if (!u1Active && u2Active) {
            return 1;
          }
          return jobTitleSort(u1.jobTitle, u2.jobTitle);
        }),
    },
  );

  return isUsersLoading ? (
    <CircularProgress />
  ) : (
    <Box>
      <AvatarList list={usersAvatar} size={size} active={active} />
    </Box>
  );
};
