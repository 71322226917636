import { DateInput, SimpleForm, BooleanInput, required } from 'react-admin';

import { FormProps } from '../../types';

export const PregnanciesForm = (props: FormProps) => (
  <SimpleForm {...props}>
    <DateInput
      label="Date de terme"
      source="expectedEnd"
      validate={required()}
      variant="standard"
    />
    <BooleanInput source="active" label="Actif" variant="standard" />
    <BooleanInput source="multiple" label="Multiple" variant="standard" />
    <BooleanInput source="interrupted" label="Interrompue" variant="standard" />
    <BooleanInput source="born" label="Né·e" variant="standard" />
  </SimpleForm>
);
