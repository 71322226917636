import { AppUser } from '@boTypes/appUser';
import { Form, FormAnswers } from '@teammay/form-core';

import { useQuery } from './queryWrappers';

export const useFormAnswers = (params: { appUserId: AppUser['id'] }) => {
  return useQuery<(FormAnswers & { form: Form })[]>(
    ['formAnswers', params.appUserId],
    {
      method: 'GET',
      url: `/api/forms/scores`,
      params,
    },
    {
      enabled: !!params.appUserId,
    },
  );
};
