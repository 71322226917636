import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { Controller } from 'react-hook-form';

import { Category } from '@boTypes/category';
import { StaffUser } from '@boTypes/staffUser';
import {
  User,
  midwifeDiscussionJobs,
  nurseDiscussionJobs,
} from '@boTypes/user';
import { Search, Settings } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { meFirstSort } from '@utils/staffUser';

import { useSelector } from '../../store';
import { COLORS } from '../../themes';

const useCategories = (midwife: boolean) => {
  const { data: categories } = useGetList<Category>(
    'categories',
    {
      pagination: { page: 1, perPage: 1000 },
      filter: { pregnancy: midwife },
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );
  return categories ?? [];
};

export const useStaffUsersSorted = (
  filter: (u: User) => boolean = () => true,
) => {
  const { data: allStaffUsers, isLoading } = useGetList<User>('users', {
    pagination: { page: 1, perPage: 1000 },
  });
  const userId = useSelector((state) => state.user.userId);
  return useMemo(
    () => ({
      data: allStaffUsers?.filter(filter).sort(meFirstSort(Number(userId))) ?? [
        { id: Number(userId), firstName: 'Moi', lastName: '' } as User,
      ],
      isLoading,
    }),
    [allStaffUsers, filter, isLoading, userId],
  );
};

export const SubjectExplorerSearchFilters = ({
  register,
  control,
  midwife,
}: {
  register: any;
  control: any;
  midwife: boolean;
}) => {
  const translate = useTranslate();
  const [height, setHeight] = useState(0);
  const filterPro = useCallback(
    (u: User) =>
      midwife
        ? midwifeDiscussionJobs.includes(u.jobTitle)
        : nurseDiscussionJobs.includes(u.jobTitle),
    [midwife],
  );

  const { data: proUsers } = useStaffUsersSorted(filterPro);
  const categories = useCategories(midwife);

  return (
    <Box sx={{ px: '0.5rem', borderBottom: `1px solid ${COLORS.GREY_LAYOUT}` }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel
          control={<Switch name="midwife" {...register('midwife')} />}
          label="SF"
        />
        <IconButton onClick={() => setHeight((h) => (h === 0 ? 203 : 0))}>
          <Settings />
        </IconButton>
      </Box>
      <Box sx={{ height, overflow: 'hidden', paddingX: '0.125rem' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            name="start"
            render={({ field }) => (
              <DatePicker
                onChange={(date: string | Dayjs) =>
                  dayjs(date).isValid()
                    ? field.onChange(dayjs(date).format('YYYY-MM-DD'))
                    : undefined
                }
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    placeholder: translate('subjectExplorer.start'),
                    variant: 'standard',
                  },
                }}
                sx={{ minWidth: '100%' }}
              />
            )}
          />
          <Controller
            control={control}
            name="end"
            render={({ field }) => (
              <DatePicker
                onChange={(date: string | Dayjs) =>
                  dayjs(date).isValid()
                    ? field.onChange(dayjs(date).format('YYYY-MM-DD'))
                    : undefined
                }
                format="DD/MM/YYYY"
                sx={{ minWidth: '100%' }}
                slotProps={{
                  textField: {
                    placeholder: translate('subjectExplorer.end'),
                    variant: 'standard',
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>

        <Controller
          control={control}
          name="handoverUserId"
          render={({ field: { onChange } }) => (
            <Autocomplete
              onChange={(_, data: StaffUser[]) =>
                onChange(data?.map((d) => d.id))
              }
              multiple
              options={proUsers}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              getOptionKey={(option) => option.id}
              size="small"
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  placeholder={translate('subjectExplorer.pro')}
                  fullWidth
                  sx={{ minWidth: '100%' }}
                />
              )}
            />
          )}
        />
        <Controller
          control={control}
          name="categoryId"
          render={({ field: { onChange } }) => (
            <Autocomplete
              onChange={(_, data: Category[]) =>
                onChange(data?.map((d) => d.id))
              }
              multiple
              options={categories}
              getOptionLabel={(option) => option.name || '---'}
              getOptionKey={(option) => option.id}
              size="small"
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  placeholder={translate('subjectExplorer.category')}
                  fullWidth
                  sx={{ minWidth: '100%' }}
                />
              )}
            />
          )}
        />

        <TextField
          variant="standard"
          placeholder={translate('subjectExplorer.discussion')}
          fullWidth
          sx={{ minWidth: '100%' }}
          type="number"
          {...register('discussionId')}
        />
      </Box>
      <Controller
        control={control}
        name="keywords"
        render={({ field: { onChange } }) => (
          <Autocomplete
            multiple
            freeSolo
            onChange={(_, data: string[]) => onChange(data)}
            options={[]}
            defaultValue={[]}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                autoFocus
                InputProps={{
                  startAdornment: <Search />,
                }}
                variant="standard"
                {...params}
                placeholder={translate('subjectExplorer.keywords')}
                fullWidth
                sx={{ minWidth: '100%' }}
              />
            )}
          />
        )}
      />
    </Box>
  );
};
