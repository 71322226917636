import { AxiosError, AxiosResponse } from 'axios';
import { produce } from 'immer';
import differenceBy from 'lodash/differenceBy';
import { useCallback, useMemo } from 'react';
import { Identifier, useGetOne, useNotify } from 'react-admin';
import { InfiniteData, QueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { Discussion } from '@boTypes/discussion';
import {
  ChatDiscussionEvent,
  DiscussionEvent,
  DiscussionEventType,
} from '@boTypes/discussionEvent';
import { FamilyActiveMembers } from '@boTypes/family';
import { HandoverRevive } from '@boTypes/handover';
import { Socket, SocketEvents, SocketHandlerGenerator } from '@boTypes/socket';
import { MinimalSubject } from '@boTypes/subject';
import { useTypingEvent } from '@components/discussions/chat/typingIndicator';

import { useCloseHandoverRevive } from './handover';
import { useInfiniteQuery, useQueryClient, useMutation } from './queryWrappers';
import { useGetVaryingMany } from './useGetVaryingMany';
import { useSocketHandlers } from './useSocketHandlers';
import { logPostedAndClosedRevive } from '../analytics/events';
import { SystemLikeTypes } from '../components/discussions/chat/constants';
import { useSelector } from '../store';
import { setTargetEventId } from '../store/discussion';
import { formatTZ, isSameDay } from '../utils/date';

const pageLength = 20;

const addMessage = produce(
  (
    previousData: InfiniteData<AxiosResponse<DiscussionEvent[]>>,
    message: DiscussionEvent,
  ) => {
    if (!previousData?.pages?.length) {
      return previousData;
    }
    if (!previousData.pages[0]?.data.length) {
      return previousData;
    }

    // messages are only inserted in first page
    const date = new Date(message.createdAt);
    if (
      date.getTime() >=
        new Date(previousData.pages[0].data[0].createdAt).getTime() &&
      previousData.pages[0].data.every((m) => m.id !== message.id)
    ) {
      previousData.pages[0].data.unshift(message);
    }
  },
);

// TODO test it
const insertMessages = produce(
  (
    previousData: InfiniteData<AxiosResponse<DiscussionEvent[]>>,
    messages: DiscussionEvent[],
  ) => {
    if (!previousData?.pages?.length) {
      return previousData;
    }
    messages.forEach((message) => {
      const date = new Date(message.createdAt);
      let pageIndex = previousData.pages.findIndex((page) => {
        const lastMessage = page.data[page.data.length - 1];
        return date.getTime() >= new Date(lastMessage.createdAt).getTime();
      });
      if (pageIndex === -1) {
        pageIndex = previousData.pages.length - 1;
        previousData.pages[pageIndex].data.push(message);
      } else {
        const inPageIndex = previousData.pages[pageIndex].data.findIndex(
          (m) => date.getTime() > new Date(m.createdAt).getTime(),
        );
        if (inPageIndex === -1) {
          previousData.pages[pageIndex].data.push(message);
        } else {
          previousData.pages[pageIndex].data.splice(inPageIndex, 0, message);
        }
      }
    });
  },
);

const updateMessage = produce(
  (
    previousData: InfiniteData<AxiosResponse<DiscussionEvent[]>>,
    message: DiscussionEvent,
  ) => {
    if (!previousData?.pages?.length) {
      return previousData;
    }

    for (const page of previousData.pages) {
      const messageIndex = page.data.findIndex((m) => message.id === m.id);
      if (messageIndex !== -1) {
        page.data[messageIndex] = message;
        break;
      }
    }
  },
);

const socketMessageHandlerGenerator: SocketHandlerGenerator<[QueryClient]> = {
  [SocketEvents.NEW_DISCUSSION_EVENT]:
    (queryClient: QueryClient) =>
    ({
      discussion,
      event,
    }: {
      discussion: Discussion;
      event: DiscussionEvent;
    }) => {
      queryClient.setQueriesData<
        InfiniteData<AxiosResponse<DiscussionEvent[]>>
      >(['messages', discussion.id], (previousMessages) =>
        addMessage(previousMessages, event),
      );
    },
  [SocketEvents.UPDATE_DISCUSSION_EVENT]:
    (queryClient: QueryClient) =>
    ({
      event,
      discussionId: eventDiscussion,
    }: {
      event: DiscussionEvent;
      discussionId: Identifier;
    }) => {
      queryClient.setQueriesData<
        InfiniteData<AxiosResponse<DiscussionEvent[]>>
      >(['messages', eventDiscussion], (previousMessages) =>
        updateMessage(previousMessages, event),
      );
    },
};

export const useSocketDiscussionEventHandlers = (socket: Socket) => {
  const queryClient = useQueryClient();
  useSocketHandlers(socket, socketMessageHandlerGenerator, queryClient);
};

const enrichAndTransformInfiniteQuery = (
  { pages }: InfiniteData<DiscussionEvent[]>,
  activeUser: string,
) => {
  const messages = pages.flat();
  return enrichAndTransformMessageList(messages, activeUser);
};

export const enrichAndTransformMessageList = (
  messages: DiscussionEvent[],
  activeUser: string,
): ChatDiscussionEvent[] => {
  const result: ChatDiscussionEvent[] = [];

  // to understand this loop you have to remember that the messages are in reverse order
  // therefore when we change author, the current message is the last of the author's messages
  let previousMessage: ChatDiscussionEvent = {
    ...messages[0],
    isFirst: false,
    isLast: true,
    isCurrentUser: messages[0].authorEmail === activeUser,
    isRead: Boolean(messages[0].seenBy?.length),
  };
  for (const message of messages) {
    const authorChanged = message.authorEmail !== previousMessage.authorEmail;

    // first create the message
    const currentMessage: ChatDiscussionEvent = {
      ...message,
      isFirst: SystemLikeTypes.includes(message.type),
      isLast: SystemLikeTypes.includes(message.type) || authorChanged,
      isCurrentUser: message.authorEmail === activeUser,
      isRead: Boolean(message.seenBy?.length),
    };

    // if author changed than previous message is the first of the message group
    previousMessage.isFirst = authorChanged || previousMessage.isFirst;

    // add date separator if needed
    if (
      // date transition
      (!isSameDay(message.createdAt, previousMessage.createdAt) &&
        previousMessage.type !== DiscussionEventType.SYSTEM) ||
      // add a date under subject separators
      (message.type === DiscussionEventType.SYSTEM &&
        message.content === 'END_SUBJECT' &&
        previousMessage.type !== DiscussionEventType.SYSTEM)
    ) {
      // use previous message date : remember messages are in reverse order
      const date = formatTZ(previousMessage.createdAt, 'dddd D MMM YY');
      result.push({
        ...message,
        content: date,
        type: DiscussionEventType.DATE_SEPARATOR,
        isFirst: true,
        isLast: true,
        isRead: Boolean(message.seenBy?.length),
        authorEmail: '',
        isCurrentUser: false,
        id: date + message.id,
      });
      currentMessage.isFirst = true;
    }

    result.push(currentMessage);
    previousMessage = currentMessage;
  }
  if (result[0]) {
    result[0].isLast = true;
  }
  return result;
};

export const useTargetSubject = (discussionId?: Discussion['id']) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data } = useInfiniteQuery<DiscussionEvent[]>(
    ['messages', discussionId],
    () => ({}),
    {
      enabled: false,
    },
  );

  const lastEventId = useMemo(() => {
    if (!data?.pages?.length) {
      return;
    }
    const lastPage = data.pages[data.pages.length - 1]?.data ?? [];

    if (!lastPage.length) {
      return;
    }
    return lastPage[lastPage.length - 1].id;
  }, [data]);

  const { mutateAsync: fetchSubject } = useMutation<
    DiscussionEvent[],
    AxiosError,
    MinimalSubject
  >(['fetchSubject', lastEventId], (subject) => ({
    method: 'get',
    url: `/api/discussion_events/${discussionId}`,
    params: {
      end: subject.firstMessageAt,
      lastEventId,
    },
  }));

  return useCallback(
    async (subject: MinimalSubject) => {
      // If revives points to a subject that has no messages, we don't want to highlight anything
      if (subject.firstMessageAt === null) {
        setTargetEventId({ targetEventId: null, highlightedEventIds: [] });
        return;
      }
      const subjectId = subject.id;
      const hasSubject = data?.pages
        .flatMap(({ data: d }) => d)
        .reverse()
        .filter(
          (m) =>
            m.type !== DiscussionEventType.SYSTEM && m.subjectId === subjectId,
        );
      if (hasSubject?.length) {
        dispatch(
          setTargetEventId({
            targetEventId: hasSubject[0].id,
            highlightedEventIds: hasSubject.map((m) => m.id),
          }),
        );
      } else {
        try {
          const subjectMessages = await fetchSubject(subject);
          if (subjectMessages.length) {
            queryClient.setQueryData<
              InfiniteData<AxiosResponse<DiscussionEvent[]>>
            >(['messages', discussionId], (previousData) => {
              if (!previousData?.pages?.length) {
                return previousData;
              }
              const allData = previousData.pages.flatMap((p) => p.data);
              const toAddEvents = differenceBy(subjectMessages, allData, 'id');
              if (!toAddEvents.length) {
                return previousData;
              }
              return insertMessages(previousData, toAddEvents);
            });

            const targetMessages = subjectMessages
              .reverse()
              .filter((s) => s.type !== DiscussionEventType.SYSTEM);
            dispatch(
              setTargetEventId({
                targetEventId: targetMessages[0].id,
                highlightedEventIds: targetMessages
                  .filter((s) => s.subjectId === subjectId)
                  .map((m) => m.id),
              }),
            );
          }
        } catch (error) {}
      }
    },
    [data?.pages, discussionId, dispatch, fetchSubject, queryClient],
  );
};

export const useMessageQuery = (discussion?: Discussion, socket?: Socket) => {
  const discussionId = discussion?.id;
  const email = useSelector((state) => state.user.email);
  const { data, ...rest } = useInfiniteQuery<
    DiscussionEvent[],
    any,
    DiscussionEvent[]
  >(
    ['messages', discussionId],
    ({ pageParam }: { pageParam?: { lastEventId: string } }) => {
      return {
        method: 'get',
        url: `/api/discussion_events/${discussionId}`,
        params: {
          lastEventId: pageParam?.lastEventId,
          limit: pageLength,
        },
      };
    },
    {
      enabled: !!discussionId,
      select: ({ pageParams, pages }) => ({
        pageParams,
        pages: pages.map(({ data: page }) => page ?? []),
      }),
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: socket ? undefined : 2000,
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.length >= pageLength
          ? { lastEventId: lastPage.data[lastPage.data.length - 1].id }
          : undefined;
      },
    },
  );

  const familyId =
    discussion?.appUsers?.length &&
    discussion.appUsers[0] &&
    typeof discussion.appUsers[0] === 'object'
      ? discussion.appUsers[0].familyId
      : discussion?.appUser && typeof discussion.appUser === 'object'
        ? discussion.appUser?.familyId
        : undefined;

  const { data: familyContent } = useGetOne<FamilyActiveMembers>(
    'family',
    {
      id: familyId,
    },
    { enabled: !!familyId },
  );

  const [isTypingList, isPatientTyping] = useTypingEvent(discussionId);
  const { data: users, isLoading: isUsersLoading } = useGetVaryingMany(
    'users',
    isTypingList,
  );

  const messages = useMemo<ChatDiscussionEvent[]>(() => {
    const result = data?.pages?.length
      ? enrichAndTransformInfiniteQuery(data, email)
      : [];

    if (isPatientTyping) {
      const familyMember = familyContent?.appUsers?.find(
        (u) => u.id === isPatientTyping,
      );
      result.unshift({
        id: 'typing-patient',
        content: '',
        type: DiscussionEventType.TYPING,
        authorEmail: familyMember?.email ?? 'patient@typing.fr',
        authorName: familyMember?.firstName ?? 'Le·a patient·e',
        isCurrentUser: false,
        isLast: true,
        isFirst: true,
        isRead: true,
        isMay: false,
        createdAt: new Date(),
      });
    }
    if (isTypingList.length) {
      if (isUsersLoading) {
        result.unshift({
          id: 'typing-professional',
          content: '',
          type: DiscussionEventType.TYPING,
          authorEmail: 'pro@typing.fr',
          authorName: `${isTypingList.length} collègues`,
          isCurrentUser: false,
          isLast: true,
          isFirst: true,
          isRead: true,
          isMay: true,
          createdAt: new Date(),
        });
      } else {
        users.forEach((u) => {
          // Not really clear why but "u" can somehow be undefined
          u &&
            result.unshift({
              id: 'typing-professional',
              content: '',
              type: DiscussionEventType.TYPING,
              authorEmail: u.email,
              authorName: `${u.firstName} ${u.lastName}`,
              authorAvatar: u.avatar,
              isCurrentUser: email === u.email,
              isLast: true,
              isFirst: true,
              isRead: true,
              isMay: true,
              createdAt: new Date(),
            });
        });
      }
    }
    return result;
  }, [
    data,
    email,
    isPatientTyping,
    isTypingList.length,
    isUsersLoading,
    users,
    familyContent?.appUsers,
  ]);

  return { ...rest, messages };
};

interface CreateDiscussionEvent {
  discussionId: number | string;
  content: string;
  type: DiscussionEventType;
  macroId?: number;
  macroSuggestionId?: number;
}
export const usePostMessage = () => {
  const queryClient = useQueryClient();
  return useMutation<DiscussionEvent, any, CreateDiscussionEvent>(
    ['postMessage'],
    (data) => ({
      method: 'post',
      url: `/api/discussion_events`,
      data,
    }),
    {
      onSuccess: (data, { discussionId }) => {
        queryClient.setQueriesData<
          InfiniteData<AxiosResponse<DiscussionEvent[]>>
        >(['messages', discussionId], (previousMessages) =>
          addMessage(previousMessages, data),
        );
      },
    },
  );
};

export const usePostAndCloseRevive = () => {
  const { mutateAsync: post } = usePostMessage();
  const { mutateAsync: closeRevive } = useCloseHandoverRevive();
  const notify = useNotify();
  return useCallback(
    async (createDTO: CreateDiscussionEvent, revive?: HandoverRevive) => {
      try {
        const discussionEvent = await post(createDTO);
        if (revive) {
          try {
            await closeRevive(revive.id);
            logPostedAndClosedRevive(revive.id);
          } catch (error) {
            notify('Erreur lors de la cloture de la relance', {
              type: 'error',
            });
          }
        }
        return discussionEvent;
      } catch (e) {
        notify("Erreur lors de l'envoi du message", { type: 'error' });
      }
    },
    [closeRevive, post, notify],
  );
};

export const useDeleteMessage = (discussionId: Identifier) => {
  const queryClient = useQueryClient();
  return useMutation<DiscussionEvent, AxiosError, Identifier>(
    ['deleteDiscussionEvent'],
    (id) => ({
      method: 'delete',
      url: `/api/discussion_events/${id}`,
    }),
    {
      onSuccess(data) {
        queryClient.setQueriesData<
          InfiniteData<AxiosResponse<DiscussionEvent[]>>
        >(['messages', discussionId], (previousMessages) =>
          updateMessage(previousMessages, data),
        );
      },
    },
  );
};
