import maxBy from 'lodash/maxBy';
import { useMemo } from 'react';
import { Identifier } from 'react-admin';
import 'dayjs/locale/fr';

import { Filters } from '@boTypes/subject';
import { JobTitle } from '@boTypes/user';
import { useSubjectFilter } from '@hooks/subjects';
import { useDiscussionContext } from '@hooks/useDiscussionContext';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';

import { DiscussionContext } from '../../common';
import { useSelector } from '../../store';
import { Discussion } from '../../types';
import {
  AttributionFilter,
  DiscussionQuickFilter,
} from '../../types/discussion';
import { getMinuteDiff, getTimeAgo } from '../../utils/date';

const crossJobDiscussionFilters = (
  discussion: Discussion,
  inJobIds: string[],
  discussionContext: DiscussionContext,
) => {
  if (discussionContext === DiscussionContext.NURSE) {
    return (
      discussion.lastSubject.withNurseIntervention &&
      inJobIds &&
      discussion.lastMaySender &&
      !inJobIds.includes(String(discussion.lastMaySender))
    );
  }

  return (
    !discussion.lastSubject.withNurseIntervention &&
    inJobIds &&
    discussion.lastMaySender &&
    !inJobIds.includes(String(discussion.lastMaySender))
  );
};

const ongoingDiscussion = (
  discussion: Discussion,
  inJobIds: string[],
  discussionContext: DiscussionContext,
) => {
  return (
    !discussion.lastSubject?.empty &&
    (!discussion?.lastEvent?.isMay ||
      crossJobDiscussionFilters(discussion, inJobIds, discussionContext))
  );
};

export const discussionFilters: Record<
  DiscussionQuickFilter,
  (v: Discussion, inJobIds: string[], context: DiscussionContext) => boolean
> = {
  [DiscussionQuickFilter.NONE]: () => true,
  [DiscussionQuickFilter.NEW]: (v: Discussion) =>
    !v.lastSubject?.empty && !v.lastSubject?.mayAnswered,
  [DiscussionQuickFilter.ONGOING]: (v: Discussion) =>
    !v.lastSubject?.empty && v.lastSubject.mayAnswered && !v?.lastEvent.isMay,
  [DiscussionQuickFilter.DOCTOR]: (v: Discussion) =>
    v.lastSubject?.withDoctorIntervention,
  [DiscussionQuickFilter.NURSE]: (v: Discussion) =>
    v.lastSubject?.withNurseIntervention,
  [DiscussionQuickFilter.MIDWIFE]: (v: Discussion) =>
    v.lastSubject?.withNurseIntervention,
  [DiscussionQuickFilter.ZEN]: ongoingDiscussion,
  [DiscussionQuickFilter.PRIORITY]: (
    v: Discussion,
    inJobIds: string[],
    context: DiscussionContext,
  ) => ongoingDiscussion(v, inJobIds, context) && v.lastSubject?.isPriority,
  [DiscussionQuickFilter.UNCATEGORIZED]: (
    v: Discussion,
    inJobIds: string[],
    context: DiscussionContext,
  ) => ongoingDiscussion(v, inJobIds, context) && !v.lastSubject?.categoryId,
  [DiscussionQuickFilter.UNCATEGORIZED_AND_ZEN]: (
    v: Discussion,
    inJobIds: string[],
    context: DiscussionContext,
  ) => ongoingDiscussion(v, inJobIds, context) || !v.lastSubject?.categoryId,
};

export const attributionFilters = {
  [AttributionFilter.MINE]:
    (userId: number, staffUserFromSubjectId: Record<number, number>) =>
    (v: Pick<Discussion, 'lastSubjectId'>) =>
      userId === staffUserFromSubjectId[v.lastSubjectId],
  [AttributionFilter.ATTRIBUTED]:
    (userId: number, filter?: Filters, isDoctor: boolean = false) =>
    (v: Discussion) =>
      (filter !== Filters.REVIVE &&
        String(v.lastMaySender) === String(userId)) ||
      (filter === Filters.REVIVE &&
        v.revives?.some(
          (r) => String(r.attributedStaffUserId) === String(userId),
        )) ||
      (isDoctor && v.lastSubject?.withDoctorIntervention),
  [AttributionFilter.UNATTRIBUTED]:
    (
      inJobIds: string[],
      userId: number,
      context: DiscussionContext,
      filter?: Filters,
    ) =>
    (v: Discussion) => {
      return (
        (ongoingDiscussion(v, inJobIds, context) &&
          filter !== Filters.REVIVE &&
          !v.lastSubject?.withDoctorIntervention &&
          (!v.lastMaySender ||
            (!inJobIds.includes(String(v.lastMaySender)) &&
              String(v.lastMaySender) !== String(userId)))) ||
        (filter === Filters.REVIVE &&
          v.revives?.every((r) => {
            const res =
              !r.attributedStaffUserId ||
              (r.attributedStaffUserId !== userId &&
                !inJobIds.includes(String(r.attributedStaffUserId)));
            return res;
          }))
      );
    },
};

export const countDiscussionType = ({
  discussions,
  activeList,
  inJobIds,
  context,
  attribFilterValue,
  staffUserFromSubjectId,
  userId,
}: {
  discussions: Discussion[];
  activeList: Discussion[];
  inJobIds: string[];
  context: DiscussionContext;
} & (
  | {
      attribFilterValue: AttributionFilter;
      staffUserFromSubjectId: Record<number, number>;
      userId: number;
    }
  | {
      attribFilterValue?: never;
      staffUserFromSubjectId?: never;
      userId?: never;
    }
)) => {
  const doctorInterventionCount = discussions.reduce(
    (acc, d) =>
      acc +
      (discussionFilters[DiscussionQuickFilter.DOCTOR](d, inJobIds, context)
        ? 1
        : 0),
    0,
  );
  const nurseInterventionCount = discussions.reduce(
    (acc, d) =>
      acc +
      (discussionFilters[DiscussionQuickFilter.NURSE](d, inJobIds, context)
        ? 1
        : 0),
    0,
  );
  const priorityCount = discussions.reduce(
    (acc, d) =>
      acc +
      (discussionFilters[DiscussionQuickFilter.PRIORITY](d, inJobIds, context)
        ? 1
        : 0),
    0,
  );
  const uncategorizedCount = discussions.reduce(
    (acc, d) =>
      acc +
      (discussionFilters[DiscussionQuickFilter.UNCATEGORIZED](
        d,
        inJobIds,
        context,
      )
        ? 1
        : 0),
    0,
  );
  const zenCount = (
    attribFilterValue === AttributionFilter.MINE && staffUserFromSubjectId
      ? discussions.filter(
          attributionFilters[attribFilterValue](userId, staffUserFromSubjectId),
        )
      : discussions
  ).reduce(
    (acc, d) =>
      acc +
      (discussionFilters[DiscussionQuickFilter.ZEN](d, inJobIds, context)
        ? 1
        : 0),
    0,
  );

  const mineZenCount = (
    staffUserFromSubjectId
      ? activeList.filter(
          attributionFilters[AttributionFilter.MINE](
            userId,
            staffUserFromSubjectId,
          ),
        )
      : activeList
  ).reduce(
    (acc, d) =>
      acc +
      (discussionFilters[DiscussionQuickFilter.ZEN](d, inJobIds, context)
        ? 1
        : 0),
    0,
  );

  return {
    doctorInterventionCount,
    nurseInterventionCount,
    priorityCount,
    uncategorizedCount,
    zenCount,
    mineZenCount,
  };
};

export const useUsersInJob = (
  discussionContext: DiscussionContext,
  connectedUsersIds: string[],
) => {
  const userId = useSelector((state) => state.user.userId);
  const users = useGetVaryingMany(
    'users',
    // complicated because on logout this may be called once with null userId
    userId ? [String(userId)].concat(connectedUsersIds) : connectedUsersIds,
  );

  return useMemo(() => {
    const allUsers = users.data;
    if (!allUsers) {
      return [];
    }
    let acceptedJobs: JobTitle[] = [];

    const notAttributingJob =
      discussionContext === DiscussionContext.NURSE
        ? JobTitle.MIDWIFE
        : JobTitle.NURSERY_NURSE;

    if (discussionContext === DiscussionContext.NURSE) {
      acceptedJobs = Object.values(JobTitle).filter(
        (j) => j !== notAttributingJob,
      );
    } else {
      acceptedJobs = Object.values(JobTitle).filter(
        (j) => j !== notAttributingJob,
      );
    }
    return allUsers
      .filter((u) => u && acceptedJobs?.includes(u.jobTitle))
      .map((u) => String(u.id));
  }, [discussionContext, users.data]);
};

export const useCountAttributions = (
  data: Discussion[],
  userId: Identifier,
  connectedUsersIds: string[],
) => {
  const filter = useSubjectFilter();
  const discussionContext = useDiscussionContext();
  const connectedInJobIds = useUsersInJob(discussionContext, connectedUsersIds);
  return useMemo(() => {
    const isUnattributed = attributionFilters[AttributionFilter.UNATTRIBUTED](
      connectedInJobIds,
      Number(userId),
      discussionContext,
      filter,
    );
    const unattributedCount = data.reduce(
      (nonAttrib, d) => nonAttrib + (isUnattributed(d) ? 1 : 0),
      0,
    );

    return { unattributedCount };
  }, [data, userId, filter, connectedInJobIds, discussionContext]);
};

export const getLongestWaitingTime = (data: Discussion[]) => {
  const waitingTimes = data
    .filter(
      (v) =>
        !v.lastSubject?.empty &&
        (!v.lastSubject?.mayAnswered ||
          (v.lastSubject.mayAnswered && !v?.lastEvent.isMay)) &&
        !v.lastSubject.withDoctorIntervention,
    )
    .map((d) => {
      return {
        ago: getTimeAgo(d.lastEvent?.firstOfUser),
        value: getMinuteDiff(d.lastEvent?.firstOfUser),
      };
    });

  const oldestMessage = maxBy(waitingTimes, 'value');
  return {
    longestWaitingTime: oldestMessage?.value || 0,
    longestWaitingTimeAgo: oldestMessage?.ago || '',
  };
};
