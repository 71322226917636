import { useState } from 'react';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { Guide } from '@boTypes/guide';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import { GuideCarousel } from '@components/Guide/GuideItem';
import { MacrosItemDialog } from '@components/Macro/MacroItem';
import { MasterclassItemDialog } from '@components/Masterclass/MasterclassItem';
import { PostItemDialog } from '@components/Post/PostItem';
import { Send } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { getSmallestImageUrlAvailableFor } from '@utils/cmsFiles';

import { logContentSuggestionView } from '../../../../analytics/events';
import { COLORS } from '../../../../themes';

export enum SuggestionContentType {
  GUIDES = 'guides',
  POSTS = 'posts',
  MACRO = 'macro',
  MASTERCLASSES = 'masterclasses',
}

const mappingToDiscussionEventType = {
  [SuggestionContentType.GUIDES]: DiscussionEventType.GUIDE,
  [SuggestionContentType.POSTS]: DiscussionEventType.POST,
  [SuggestionContentType.MASTERCLASSES]: DiscussionEventType.MASTERCLASS,
} as Record<SuggestionContentType, DiscussionEventType>;

export function SuggestionDetail<T extends SuggestionContentType>({
  type,
  open,
  setOpen,
  item,
  onContentCopy,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: T;
  item?: T extends SuggestionContentType.GUIDES
    ? Guide
    : T extends SuggestionContentType.POSTS
      ? Post
      : T extends SuggestionContentType.MASTERCLASSES
        ? Masterclass
        : Macro;
  onContentCopy?: (
    text: string,
    macroId: number,
    macroSuggestionId: number,
  ) => void;
}) {
  if (!open) {
    return null;
  }
  if (type === SuggestionContentType.GUIDES) {
    return (
      <GuideCarousel
        slug={(item as Guide)?.slug}
        open={open}
        setOpen={setOpen}
      />
    );
  } else if (type === SuggestionContentType.POSTS) {
    return <PostItemDialog open={open} setOpen={setOpen} item={item as Post} />;
  } else if (type === SuggestionContentType.MASTERCLASSES) {
    return (
      <MasterclassItemDialog
        item={item as Masterclass}
        opened={open}
        close={() => setOpen(false)}
      />
    );
  } else if (type === SuggestionContentType.MACRO) {
    return (
      <MacrosItemDialog
        open={open}
        close={() => setOpen(false)}
        item={item as Macro}
        onCopy={(text, suggestionId) =>
          onContentCopy(text, item.id, suggestionId)
        }
      />
    );
  }
}

export function SuggestionList<T extends SuggestionContentType>({
  title,
  items,
  type,
  onContentSend,
  onContentCopy,
  subjectId,
}: {
  title: string;
  type: T;
  items?: T extends SuggestionContentType.GUIDES
    ? Guide[]
    : T extends SuggestionContentType.POSTS
      ? Post[]
      : T extends SuggestionContentType.MASTERCLASSES
        ? Masterclass[]
        : Macro[];
  onContentSend?: (input: {
    type: DiscussionEventType;
    content: string;
    contentId: number;
    contentType: string;
  }) => void;
  onContentCopy?: (
    text: string,
    macroId: number,
    macroSuggestionId: number,
  ) => void;
  subjectId: number;
}) {
  const [open, setOpen] = useState(false);
  const [item, setItem] =
    useState<
      T extends SuggestionContentType.GUIDES
        ? Guide
        : T extends SuggestionContentType.POSTS
          ? Post
          : T extends SuggestionContentType.MASTERCLASSES
            ? Masterclass
            : Macro
    >();
  if (!items.length) {
    return null;
  }

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ mt: 1, mx: 2 }} color="primary">
        {title}
      </Typography>
      <List dense sx={{ mx: 1 }}>
        {items.map((itm) => (
          <ListItem disableGutters disablePadding key={itm.id}>
            <ListItemButton
              color="primary"
              disableGutters
              sx={{
                '&:hover': {
                  borderRadius: 2,
                  backgroundColor: COLORS.GREEN['100'],

                  '.MuiTouchRipple-child': {
                    backgroundColor: COLORS.GREEN['800'],
                  },
                },
                justifyContent: 'flex-start',
                paddingLeft: onContentSend ? '2.5rem' : '',
              }}
              onClick={() => {
                setItem(itm);
                logContentSuggestionView(subjectId, type, itm.id);
                setOpen(true);
              }}
            >
              {onContentSend ? (
                <ListItemSecondaryAction style={{ left: '0', right: 'auto' }}>
                  <IconButton
                    aria-label="send"
                    color="primary"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      onContentSend({
                        type: mappingToDiscussionEventType[type],
                        content: [
                          SuggestionContentType.GUIDES,
                          SuggestionContentType.POSTS,
                        ].includes(type)
                          ? itm.slug
                          : String(itm.id),
                        contentType: type,
                        contentId: itm.id,
                      });
                    }}
                  >
                    <Send />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
              {(itm.cover ||
                itm.coverThumbnailUrl ||
                itm.masterclass_expert?.photo) && (
                <ListItemAvatar sx={{ minWidth: 32, marginLeft: 1 }}>
                  <Avatar
                    src={
                      getSmallestImageUrlAvailableFor(itm.cover) ||
                      itm.cover ||
                      itm.coverThumbnailUrl ||
                      getSmallestImageUrlAvailableFor(
                        itm.masterclass_expert?.photo,
                      )
                    }
                    sx={{
                      width: 32,
                      height: 32,
                      borderColor: COLORS.GREY_TEXT_LIGHT,
                      borderWidth: 1,
                      borderStyle: 'solid',
                    }}
                  />
                </ListItemAvatar>
              )}
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    color:
                      itm?.sent !== undefined && itm?.sent
                        ? COLORS.GREY_TEXT_LIGHT
                        : 'inherit',
                  },
                }}
                sx={{
                  pl: 1,
                }}
                /* @ts-ignore */
                primary={itm?.title || itm?.name}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <SuggestionDetail
        type={type}
        open={open}
        setOpen={setOpen}
        item={item}
        onContentCopy={onContentCopy}
      />
      <Divider />
    </Box>
  );
}
