import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useNavigateWithParams = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  return useCallback(
    (pathname) => navigate({ pathname, search: `?${searchParams.toString()}` }),
    [navigate, searchParams],
  );
};
