const openInTab = (url: string) => {
  window.location.assign(url);
};

export const openNewSecureTab = (url: string) => {
  // fixes security issue, see https://mathiasbynens.github.io/rel-noopener/
  const newWindow = window.open();
  if (!newWindow) {
    return openInTab(url);
  }
  newWindow.opener = null;
  newWindow.location = url;
};
