import { BooleanInput, SimpleForm, TextInput, required } from 'react-admin';

import type { FormProps } from '../../types';

export const CompanyForm = (props: FormProps) => (
  <SimpleForm {...props}>
    <TextInput
      label="Nom"
      source="name"
      validate={required()}
      variant="standard"
    />
    <BooleanInput
      label="Actif"
      source="active"
      defaultValue={true}
      variant="standard"
    />
  </SimpleForm>
);
