import { Paper, Typography } from '@mui/material';

import { EditPasswordForm } from './editPasswordForm';

export const EditPassword = () => {
  return (
    <Paper elevation={0} sx={{ padding: '1rem', minHeight: '100%' }}>
      <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
        Changer mon mot de passe
      </Typography>
      <EditPasswordForm />
    </Paper>
  );
};
