import intersection from 'lodash/intersection';
import { useEffect, useState } from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { views as Views } from 'react-big-calendar/lib/utils/constants';
import { Link } from 'react-router-dom';

import { PlanningJob } from '@boTypes/planning';
import { Roles } from '@boTypes/user';
import Calendar from '@components/Calendar';
import { ConfirmDialog } from '@components/ConfirmDialog/ConfirmDialog';
import { Loader } from '@components/Loader';
import { useAdditionalHoursMutations } from '@hooks/plannings';
import { CalendarMonth } from '@mui/icons-material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { Box, Button, Paper, Typography } from '@mui/material';

import { AdditionalHourDialog } from './components/addAdditionalHourDialog';
import {
  useCalendarDateConfig,
  usePlanningData,
  useSlotActions,
} from './staffUserPlanning.hook';
import { Toolbar, useToolbar } from './staffUserPlanning.toolbar';
import { useSelector } from '../../store';

export const StaffUserPlanning = () => {
  const translate = useTranslate();
  const [addHoursDialogOpened, setAddHoursDialogOpened] = useState(false);
  const closeAddHoursDialog = () => setAddHoursDialogOpened(false);

  const roles = useSelector((state) => state.user.roles);

  const proOrAdmin =
    intersection(roles, [
      Roles.ADMIN,
      Roles.HEALTH_PRO,
      Roles.STAFF_MANAGER,
      Roles.SUPERUSER,
    ]).length > 0;
  const redirect = useRedirect();

  useEffect(() => {
    if (!proOrAdmin) {
      // redirect to availabilities for AVAILABILITIES role (only case left)
      redirect('/planning-availabilities');
    }
  }, [proOrAdmin, redirect]);

  const {
    displayType,
    staffUsers,
    staffUsersRawList,
    isLoadingStaff,
    control,
    selectedUser,
    selectedJobs,
  } = useToolbar();

  // to retrieve data correctly, we need to have view and date range on that level too
  const { view, setView, currentRange, setCurrentRange } =
    useCalendarDateConfig(Views.DAY);

  const {
    isLoading,
    setAdditionalHours,
    agendaData,
    onLeaveAttribution,
    onRequestAttribution,
  } = usePlanningData({
    selectedUserId: selectedUser,
    range: currentRange,
    selectedJobs: Object.values(PlanningJob).filter(
      (_, index) => selectedJobs[index],
    ),
    staffUsers,
    displayType,
  });

  const { deleteAdditionalHour } = useAdditionalHoursMutations();

  const {
    displayActions,
    callbackActions,
    openDeleteModal,
    setOpenDeleteModal,
    selectedSlot,
  } = useSlotActions();

  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        height: '100%',
        padding: '0.5rem 1rem',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: 'bold', marginBottom: 0, marginRight: 2 }}
          >
            {translate('planning.title')}
          </Typography>
          <Toolbar
            staffUsers={staffUsersRawList}
            isLoadingStaff={isLoadingStaff}
            control={control}
            displayType={displayType}
          />
        </Box>
        <AdditionalHourDialog
          open={addHoursDialogOpened}
          onClose={closeAddHoursDialog}
          onCreated={(data) => {
            setAdditionalHours((previousAdditionalHours) => {
              return previousAdditionalHours
                ? [...previousAdditionalHours, data]
                : [data];
            });
            closeAddHoursDialog();
          }}
        />
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItem: 'center' }}
        >
          <Button
            onClick={() => setAddHoursDialogOpened(true)}
            startIcon={<MoreTimeIcon />}
          >
            {translate('additionalHours.add')}
          </Button>
          <Button
            variant="text"
            component={Link}
            to="/planning-availabilities"
            startIcon={<CalendarMonth />}
          >
            {translate('planning.availabilities.title')}
          </Button>
        </Box>
      </Box>
      {isLoading || isLoadingStaff ? (
        <Box
          sx={{
            position: 'absolute',
            width: 'calc(100% - 4rem)',
            height: 'calc(100% - 8rem)',
            zIndex: 3,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <Loader />
        </Box>
      ) : null}
      <ConfirmDialog
        title={translate('common.confirmDeletion')}
        text={translate('additionalHours.deleteConfirmText')}
        onClick={() =>
          selectedSlot &&
          deleteAdditionalHour({
            planningId: selectedSlot!.planningId,
            id: selectedSlot!._id,
          })
        }
        onClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
      />
      <Calendar
        dayLayoutAlgorithm="no-overlap"
        events={agendaData}
        view={view}
        staffUsers={staffUsers}
        onView={setView}
        setCurrentRange={setCurrentRange}
        displayActions={displayActions}
        // @ts-ignore
        drawerCallback={callbackActions}
        selectable={false}
        // extra actions
        displayExtraActions={true}
        callbackExtraActions={{
          // @ts-ignore
          addAttribution: onRequestAttribution,
          // @ts-ignore
          removeAttribution: onLeaveAttribution,
        }}
        participantIds={Object.keys(staffUsers).map(Number)}
      />
    </Paper>
  );
};
