export const domainMessages = {
  fr: {
    auth: {
      forms: {
        errors: {
          generic_error:
            'Une erreur est survenue... Vérifiez les règles de mot de passe',
          invalid_credentials: 'Identifiants invalides',
        },
        success: {
          email_sent: 'Email envoyé',
          password_updated: 'Mot de passe mis à jour',
          password_reset: 'Mot de passe réinitialisé',
        },
      },
      changePassword: 'Changer mon mot de passe',
      trustDevice: 'Faire confiance à cet appareil',
    },
    attributions: {
      choiceTitle: "Mode d'attribution :",
      toMe: "Je m'attribue cette question et réponds",
      automatic: 'Automatique',
      start: 'Commencer ma vacation',
      startNoAttrib: 'Prêter main forte',
    },
    additionalHours: {
      add: 'Heure de renfort',
      comment: 'Commentaire',
      deleteConfirmText:
        'Voulez-vous vraiment supprimer cette heure de renfort ?',
      cancelSelection: 'Annuler la sélection',
      startHour: 'Heure de début',
      duration: 'Durée (min)',
      validated: 'Validée',
      emptySelection: 'Aucune heure de renfort sélectionnée',
      selectedRows:
        '1 heure de renfort sélectionnée |||| %{smart_count} heures de renfort sélectionnées',
      validateHours: '✅ Valider',
      invalidateHours: '❌ Invalider',
      deleteHours: 'Supprimer',
      error:
        'La date de fin doit être après le début, le même jour. La durée entre 15min et 2h.',
    },
    admin: {
      categories: {
        title: 'Catégories',
        questions: 'Catégories de question',
        consultations: 'Catégories de consultation',
      },
      forms: {
        title: 'Formulaires',
        list: 'Liste des formulaires',
        create: 'Créer un formulaire',
      },
      others: {
        title: 'Autres',
        scenarios: 'Scénarios',
      },
      planning: {
        admin: 'Admin',
      },
      pricing: {
        affiliations: 'Code promo affiliations',
        slots: 'Prix des vacations',
        title: 'Pricing et factures',
      },
    },
    affiliations: {
      empty: 'Aucune affiliation',
      title: 'Affiliations',
      create: 'Ajouter une affiliation',
      exportProCommunity: 'Export communauté de pros',
      managePartners: 'Gérer les partenaires',
      managePlans: 'Gérer les offres génériques',
      titleCode: 'Codes promo affiliation',
    },
    appUsers: {
      currentEmail: 'Email actuel',
      deletionConfirmation: 'Êtes vous sûr de vouloir supprimer ce compte ?',
      deletionCompletedAt: 'Suppression effectuée le ',
      deletionDate: 'Suppression planifiée le ',
      discussion: 'Discussions',
      moreInformation: 'Informations détaillées',
      requestDeletion: 'Demander la suppression ',
      title: 'Patient',
      updateMailAlertItem1: 'Le mail est valide',
      updateMailAlertItem2: "Le mail est différent de l'ancien",
      updateMailAlertItem3: "Aucun compte n'existe avec ce nouveau mail",
      updateMailAlertItem4: 'Stripe & Customer.io sont mis à jour',
      updateMailAlertTitle: 'Etapes intégrées',
      updateMailButton: 'Mettre à jour le mail',
      updateMailError: 'Erreur - Email non mis à jour',
      updateMailSuccess: 'Email mis à jour',
      updateMailTitle: 'Mettre à jour le mail utilisateur',
    },
    availabilities: {
      created: 'Créneau créé',
      deleted: 'Créneau supprimé',
      my_consultations: 'Mes consultations',
      other_consultations: 'Autres consultations',
      owner_error:
        'Vous ne pouvez pas modifier/supprimer un créneau qui ne vous appartient pas',
      updated: 'Créneau mis à jour',
    },
    button: {
      add: 'Ajouter',
    },
    category: {
      name: 'Catégorie',
      save: 'Catégorie sauvée',
      useSuggestion: 'Utiliser la suggestion',
      chose: 'Choisir une catégorie :',
      suggested: 'Catégorie suggérée',
    },
    changelog: {
      title: 'Nouveautés',
      empty: 'Pas de news pour le moment :(',
    },
    changes_saved: 'Changements sauvés',
    chat: {
      loadPrevious: 'Charger les messages précédents',
      placeholder: 'Tapez votre message ici...',
    },
    children: {
      empty: 'Aucun enfant',
    },
    common: {
      active: 'Actif',
      add: 'Ajouter',
      admin: 'Admin',
      all: 'Tous',
      already: 'déjà',
      cancel: 'Annuler',
      OK: 'OK',
      charts: 'Courbes',
      child: 'Enfant',
      children: 'Enfants',
      confirm: 'Confirmer',
      confirmClose: 'Confirmer la fermeture',
      confirmCloseText:
        'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
      confirmDeletion: 'Confirmer la suppression',
      confirmSave: 'Confirmer la sauvegarde',
      create: 'Créer',
      createdAt: 'Création',
      dashboard: 'Tableau de bord',
      date: 'Date',
      day: 'Jour',
      delete: 'Supprimer',
      doctorShort: 'Doc',
      edit: 'Editer',
      email: 'email',
      emptyList: 'Aucune donnée disponible',
      error: {
        create: 'Erreur lors de la création',
      },
      errorBoundary:
        'Une erreur est survenue, merci de réessayer ultérieurement',
      feedback: 'Votre retour',
      feedbackHelpText:
        'Votre retour nous aide à améliorer notre service. Quel contenus attendiez vous ?',
      genericError: 'Une erreur est survenue...',
      goToDiscussion: 'Voir la discussion',
      height: 'Taille',
      in: 'dans',
      loadMore: 'Charger plus',
      modifyEntry: 'Modifier ma saisie',
      more: 'Voir plus',
      midwifeShort: 'SF',
      name: 'Nom',
      negativeFeedbackSubmitted: 'Entendu. On fera mieux la prochaine fois !',
      next: 'Suivant',
      noData: 'Aucune donnée',
      nurseShort: 'IPDE',
      partners: 'Partenaires',
      phone: 'Téléphone',
      positiveFeedbackSubmitted: '🤗 Merci pour votre retour !',
      pregnancy: 'Grossesse',
      previous: 'Précédent',
      pro: 'Pro',
      save: 'Enregistrer',
      saveAnyway: 'Sauvegarder quand même',
      search: 'Recherche...',
      seeMore: 'Voir plus...',
      seen: 'Vu',
      selectAll: 'Tout sélectionner',
      status: 'Statut',
      submit: 'Soumettre',
      subscriptionStatus: "Status d'abonnement",
      successfulUpdate: 'Mise à jour réussie',
      today: "Aujourd'hui",
      unknown: 'Inconnu',
      unselectAll: 'Tout déselectionner',
      update: 'Modifier',
      updatedAt: 'Modification',
      validate: 'Valider',
      waitingTime: "Tps d'attente global",
      waitingTimeMine: "Tps d'attente perso",
      weight: 'Poids',
    },
    company: {
      create: 'Créer une entreprise',
    },
    companyPlan: {
      create: 'Ajouter un plan',
    },
    companyPlanCode: {
      create: 'Ajouter un code',
    },
    consultations: {
      attribution: 'Changer de pro',
      attributionWarningTitle: 'Attention',
      attributionWarningText:
        'Avant de changer de professionnel, assurez-vous que toutes les parties prenantes sont au courant, une notification slack sera envoyée.',
      attributionSuccess:
        "Consultation mise à jour : changement de l'attribution",
      availabilities: 'Créneau de disponibilité',
      date: 'Date',
      planning: {
        title: 'Planning des consultations',
      },
      toComeTitle: 'Consultations à venir',
      empty: 'Aucune consultation prévue',
      goToConsultations: 'Accéder aux consultations',
      otherActionsTitle: 'Autres actions',
      report: 'Compte-rendu de CS',
      staffUser: 'Pro',
      title: 'Consultations',
    },
    contentSuggestion: {
      guide: 'Fiches',
      macro: 'Macro',
      masterclass: 'Masterclass',
      openLibrary: 'Consulter la bibliothèque',
      post: 'Article',
      subtitle: 'Contenus suggérés en fonction des messages patient',
      title: 'Suggestions de contenu',
      empty: 'Aucun contenu pertinent trouvé.',
      assessment: 'Évaluer les suggestions',
    },
    discussion: {
      numberCopied: 'Numéro de discussion copié',
      vipTooltip: 'Famille VIP ⭐ à chouchouter sur le BO 😊',
    },
    favorites: {
      add: 'Ajouter un favori',
      addLabel: 'Ajouter une discussion aux favoris',
      addDescription: 'Ajouter une discussion aux favoris avec un commentaire',
      commentLabel: 'Ajouter un commentaire',
      comments: 'Commentaires',
      dashboard: 'Mes discussions en favoris',
      none: "Vous n'avez pas encore de favoris",
      save: 'Enregistrer le favori',
      title: 'Favoris',
    },
    field: {
      image: {
        placeholder: 'Glissez votre fichier ou sélectionnez une image',
      },
      optionInput: {
        title: 'Options',
        add: 'Ajouter une option',
        key: 'Clé',
        value: 'Valeur',
        requiredKey: 'Clé requise',
        requiredValue: 'Valeur requise',
      },
    },
    forms: {
      errors: {
        generic_error: 'Une erreur est survenue...',
        invalid_pattern: 'Format invalide',
        required_field: 'Champ Requis',
        invalid_fields: 'Champs invalides ou manquants: %{invalidFields}',
      },
      conditions: {
        booleanOperator: 'Opérateur logique',
        questionRules: {
          title: 'Règles sur les questions',
          add: 'Ajouter une règle',
          question: 'Question',
          operator: 'Opérateur',
          expectedValue: 'Valeur cible',
        },
        dataRules: {
          title: 'Règles sur les données utilisateurs',
          add: 'Ajouter une donnée',
          dataSlug: 'Donnée',
          expectedValue: 'Valeur cible',
          operator: 'Opérateur',
        },
      },
      questions: {
        title: 'Titre',
        description: 'Description',
        label: 'CTA Label',
        type: 'Type',
        options: 'Options',
      },
      dialogs: {
        delete: {
          title: 'Confirmer la suppression',
          content: 'Voulez vous vraiment supprimer cet élément ?',
        },
      },
      edit: {
        title: 'Formulaire',
        modified:
          'Des modifications sont en cours. Voulez-vous vraiment quitter ?',
        saved: 'Formulaire sauvegardé',
      },
      list: {
        title: 'Formulaires',
        empty: 'Aucun formulaire',
        columns: {
          title: 'Titre',
          slug: 'slug',
          createdAt: 'Création',
          description: 'Description',
        },
      },
      settings: {
        title: 'Paramètres',
        slug: 'Slug',
        titleField: 'Titre',
        description: 'Description',
        illustration: 'Illustration',
        sentInChat: 'Envoyé au sein de la messagerie',
        status: 'Statut',
        questionCount: 'Nombre de questions (0 si pas de progress bar)',
      },
      runner: {
        backClicked: 'Retour',
        submitClicked: 'Valider',
        canceledForm: 'Formulaire annulé',
        completedForm: 'Formulaire complété',
        questionCount: 'Nombre de questions',
      },
      step: {
        createQuestion: 'Ajouter une question',
        createTemplate: 'Ajouter un template',
      },
      scoreMode: {
        NUMBER: 'Nombre',
        TAG: 'Tag',
      },
      scoringRule: {
        add: 'Ajouter une règle de scoring',
        formTitle: 'Règle de scoring pour la question %{question}',
        expectedValue: 'Valeur cible',
        scoringValue: 'Valeur si vrai (%{number_or_string})',
      },
      operator: {
        EQUAL: 'Égal',
        NOT_EQUAL: 'Différent',
      },
      patientFile: {
        empty: " Aucun formulaire n'a été rempli pour le moment",
      },
      send: 'Envoyer un formulaire',
    },
    generic: {
      createSuccess: 'Création réalisée',
      updateSuccess: 'Modification réalisée',
      notSaved:
        'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
      save: 'Enregistrer',
      reset: 'Annuler',
    },
    handover: {
      activeRevives: 'relances actives',
      add: 'Ajouter une transmission',
      create: 'Créer une transmission',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      createWithRevive: 'Créer avec relance',
      deleteConfirmContent:
        'Voulez-vous vraiment supprimer cette transmission ?',
      deleteConfirmTitle: 'Supprimer cette transmission ?',
      edit: 'Editer une transmission',
      editTooltip: 'Modifier la transmission',
      noData: 'Aucune transmission disponible',
      title: 'Transmissions',
      titleForOne: 'Transmission',
      preventEarlyLeave:
        "Attention, votre transmission/relance n'est pas sauvegardée",
      noneAvailable: 'Pas de transmission disponible !',
      observations: 'Obs :',
      advice: 'Conseils :',
    },
    history: {
      title: 'Transmissions',
      revives:
        '%{smart_count} relance active |||| %{smart_count} relances actives',
    },
    invoice: {
      admin: {
        title: 'Factures - Admin',
        summary: 'Résumé',
        list: 'Liste',
      },
      adminList: {
        amountValidated: 'Montant validé',
        amountValidatedShort: '€ ?',
        comments: '💬',
        createdAt: 'Ajout',
        downloadButton:
          'Télécharger une facture |||| Télécharger les %{smart_count} factures',
        downloadError: 'Erreur lors du téléchargement',
        downloadSuccess: 'Téléchargement réussi',
        filters: 'Filtres',
        invoice: 'Facture',
        job: {
          doctor: 'Médecin',
          midwife: 'SF',
          nurse: 'IPDE',
          title: 'Métier',
        },
        notValidated: 'Non validé',
        planning: 'Planning',
        resetFilters: 'Réinitialiser',
        staffUser: 'Pro',
        validated: 'Validé',
        validateAndSepa: 'Valider + SEPA',
        validatedShort: '✔️',
      },
      comments: 'Commentaires',
      deleteShort: 'Suppr.',
      dialog: {
        amountErrorTitle: 'Erreur de montant',
        amountSuccessTitle: 'Montant détecté dans la facture',
        amountError:
          "Nous n'avons pas détecté de montant correspondant à votre bilan (%{amount} €) dans votre facture. Corrigez-la si besoin ou laissez un commentaire ci-dessous pour justifier l'écart.",
        amountSuccess:
          'Nous avons bien détecté le montant correspondant à votre bilan (%{amount} €) dans votre facture.',
        comments: 'Commentaires',
        commentsHelpTextWhenError:
          "Commentaire obligatoire pour justifier l'écart",
        error: 'Erreur lors de création de la facture',
        success: 'Facture ajoutée avec succès',
        errorRetrievingSummary: 'Erreur lors de la récupération du bilan',
        title: 'Ajouter une facture',
        upload: 'Ajouter une facture',
        instructions: 'Consignes',
        instructions1: 'Votre document doit être au format PDF',
        instructions2:
          'La date qui y figure doit correspondre au mois de la prestation',
        instructions3:
          'Le total doit correspondre au montant dans le bilan (sauf erreur de notre part : faites-le nous savoir)',
      },
      empty: 'Aucune facture pour le moment.',
      noActivity: 'Aucune activité sur ce planning',
      pendingInvoice: 'En attente de validation',
      see: 'Voir',
      summary: 'Bilan',
      title: 'Factures',
      total: 'Total calculé: %{total} €',
      viewerTitle: 'Facture',
      upload: 'Ajouter une facture',
      validatedInvoice: 'Facture validée',
    },
    latestContent: {
      title: 'Derniers contenus',
      subtitle: 'Sortis il y a moins de 7 jours',
      empty: "Aucun contenu n'a été créé dans les 7 derniers jours. 😴",
      goToLibrary: 'Consulter la bibliothèque',
      loadError: 'Impossible de charger les derniers contenus',
    },
    followUp: {
      suppressTitle: 'Supprimer un suivi',
      suppressText: 'Êtes vous sur de vouloir supprimer ce suivi',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      create: 'Suivi',
      createTitle: 'Ajouter un suivi',
      edit: 'Modifier un suivi',
      title: 'Suivi',
      empty: " Aucun suivi médical n'a été enregistré pour le moment",
    },
    patientFile: {
      title: 'Dossier patient',
    },
    library: {
      contentType: 'Type de contenu',
      dailyTips: 'Daily tips',
      empty: 'Aucun résultat',
      guide: {
        title: 'Fiche',
        carousel: 'Détail des fiches',
      },
      explorer: 'Tous contenus',
      folders: 'Dossiers',
      guides: 'Fiches',
      itemSuggestions: "Puériculture : Guide d'achats",
      loading: 'Chargement...',
      macros: 'Protocoles',
      masterclasses: 'Masterclasses',
      mode: 'Mode',
      post: 'Article',
      posts: 'Articles',
      pregnancyQuestions: 'Grossesse: Est-ce que je peux...',
      results: 'Résultat de la recherche',
      search: 'Contenus à envoyer',
      searchField: 'Recherche',
      shareContent: 'Partager du contenu',
      steps: 'Étapes',
      summary: 'Résumé',
      title: 'Bibliothèque',
      tracks: 'Episodes',
      types: 'Types de contenus',
      weeklyTips: 'Weekly tips',
      form: 'Formulaires',
    },
    macro: {
      protocol: 'CAT',
      behavior: 'Conduite à tenir',
      noProtocol: "Aucune CAT n'a été défini pour le moment",
    },
    medicalFollowUp: {
      add: 'Ajouter un suivi',
      deleteConfirmTitle: 'Supprimer cette observation ?',
      deleteConfirmContent:
        'Voulez-vous vraiment supprimer cette observation ?',
      fields: {
        content: 'Observations',
      },
      noData: 'Aucune observation disponible',
      preventEarlyLeave: "Attention, votre suivi n'est pas sauvegardé",
      title: 'Suivi global',
    },
    partner: {
      livi: {
        title: 'Téléconsultez en ligne avec un médecin',
        description: '7j/7 de 7h à minuit',
      },
    },
    planning: {
      bias: 'Biais planning',
      priorityBias: 'Biais pour créneaux prioritaires',
      event: {
        additionalHour: 'Heure de renfort',
        attribution: 'Attribution',
        cancelAttribution: 'Libérer le créneau',
        cancelAttributionConfirmText:
          'Êtes-vous sûr·e de vouloir libérer ce créneau ?',
        create: 'Ajouter un créneau',
        created: 'Créneau créé',
        delete: 'Supprimer ce créneau',
        deleteError: 'Erreur lors de la suppression du créneau',
        deleted: 'Créneau supprimé',
        edit: 'Modifier un créneau',
        end: 'Fin',
        error: 'Erreur lors de la création/modification du créneau',
        getAttribution: "S'attribuer le créneau",
        getAttributionConfirmText:
          'Êtes-vous sûr·e de vouloir vous attribuer ce créneau ?',
        isTriage: 'IAO',
        noOtherAvailable: 'Aucune disponibilité',
        onCall: 'Astreinte',
        onCallActivated: 'Astreinte activée',
        otherAvailable:
          '%{smart_count} disponibilité |||| %{smart_count} disponibilités',
        type: 'Type de créneau',
        slot: 'Créneau',
        start: 'Début',
        updated: 'Créneau mis à jour',
      },
      displayedUsers: 'Utilisateurs affichés',
      displaySelector: {
        displayUnattributed: 'Afficher les créneaux non attribués',
      },
      preferences: {
        unsaved:
          'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
        confirmClose: 'Confirmer la fermeture',
        confirmCloseText:
          'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
        title: 'Préférences',
        maximumPerPlanning: 'Maximum de vacations sur tout le planning',
        maximumSlotsOnWeekends:
          'Maximum de WE travaillés dans le mois (samedi ou dimanche)',
        maximumPerWeek: 'Maximum de vacations par semaine (hors WE)',
        maximumNightsPerWeek:
          'Maximum de vacations le soir par semaine (hors WE)',
        minimumSlotsWanted: 'Minimum de vacations souhaitées au total',
        minimumSlotsWantedError: 'Le minimum doit être inférieur au maximum !',
        workWholeWeekend:
          'Acceptez-vous de travailler deux jours différents dans le même weekend ?',
        save: 'Mettre à jour mes préférences',
        setFirst: "Saisir les préférences d'abord",
        reminderTitle: 'Rappel',
        reminderText:
          "Mieux vaut remplir tes préférences d'abord pour bénéficier de la sauvegarde automatique",
      },
      fields: {
        title: 'Planning',
        dates: 'Dates',
        totalInvoices: 'Factures',
        totalValidated: 'Validés',
        totalInvalidAmount: 'Erreurs',
        totalExpectedInvoices: 'Pros',
      },
      notYetOpen:
        '🤷 Les plannings ne sont pas encore ouverts pour la saisie des disponibilités futures.',
      mine: 'Mes plannings',
      edit: 'Modifier le planning',
      create: 'Créer un nouveau planning',
      availabilities: {
        title: 'Disponibilités',
        cantUpdate: 'Vous ne pouvez modifier les disponibilités de ce planning',
        explanationTitle:
          'Quelques rappels pour améliorer le remplissage du planning :',
        explanationItem1:
          'Donne des disponibilités sur un maximum de JOURS possibles dans le mois',
        explanationItem2:
          'Donne des disponibilités sur un maximum de CRENEAUX possibles dans une journée',
        explanationItem3:
          'Remplis tes préférences de manière à venir "encadrer" ce que l\'algo t\'attribue parmi toutes ces disponibilités',
        explanationItem4:
          'Coche ✅ si tu es disponible sur le créneau, sinon ne coche rien. Si tu as coché ✅ par erreur, tu peux recliquer dessus pour que la case redevienne vide (= pas dispo)',
        notSaved:
          'Vos modifications ne sont pas sauvegardées. Quitter quand même?',
      },
      title: 'Planning',
      topToolbar: {
        byJob: 'Par métier',
        byUser: 'Par personne',
      },
      goToPlanning: 'Voir le planning',
      evaluation: {
        title: 'Retour sur ta vacation',
        feedback: "Comment s'est passée ta vacation ?",
        summary:
          'Pendant cette vacation, tu as participé à %{participatedSubjects} questions et en a clôturé %{closedSubjects}.\n\nMerci 👍 !',
        summaryNoCounts: 'Merci pour ton retour !',
        error: 'Une erreur est survenue tu peux réessayer ?',
        BORING: 'Un peu trop calme 😴',
        CALM: 'Plutôt calme 😌',
        PERFECT: 'Juste parfait 👌',
        INTENSE: 'Intense mais ça restait gérable 💪',
        TOO_INTENSE: 'Trop intense donc inconfortable 🥵',
      },
    },
    pregnancies: {
      empty: 'Aucune grossesse en cours',
    },
    profile: {
      title: 'Mon profil',
      update: 'Mettre à jour mes informations',
      changePassword: 'Changer mon mot de passe',
    },
    revives: {
      attributed: 'Attribuée',
      attributedStaffUserId: 'Attribuée à',
      by: 'par : ',
      close: 'Fermer la relance',
      closeQuestion: 'Fermer la relance ?',
      createOne: 'Créer une relance',
      edit: 'Modifier la relance',
      errorCreate: 'Erreur lors de la création de la relance',
      loading: 'Chargement...',
      noRevives: 'Aucune relance',
      nonAttributed: 'Non attribuée',
      pendingRevivesBeforeSlotCancel:
        '1 relance était attribuée sur ce créneau. Elle a été désattribuée. |||| %{smart_count} relances étaient attribuées sur ce créneau. Elles ont été désattribuées.',
      performAt: 'Relancer le : ',
      performedAt: 'Relance faite le : ',
      plannedText: 'Texte de la relance',
      reviveAt: 'Date souhaitée de relance',
      revives: '1 relance |||| %{smart_count} relances',
      sendRevive: 'Envoyer la relance ?',
      sendText: 'Envoyer le texte de la relance',
      slot: 'Créneau',
      successCreate: 'Relance créée avec succès',
      title: 'Relance',
      unattributed: 'non attribuée |||| non attribuées',
    },
    roles: {
      cannotCreateAdminRoleDueToPermission:
        "Vous n'avez pas les droits suffisants pour créer un rôle administrateur",
      cannotCreateRoleWithSameRole: 'Cet utilisateur possède déjà ce rôle',
      created: 'Rôle créé',
      deleted: 'Rôle supprimé',
      role: 'Rôle',
    },
    similarQuestion: {
      button: 'Afficher des questions similaires',
      noMatch: 'Aucune question similaire trouvée.',
      noMatchNB:
        'ℹ️ Pour les relances, aucune question similaire ne remonte. Ainsi que toutes les questions où les pros ont initié une conversation',
      selectOne: 'Sélectionnez une question dans la liste de gauche',
      title: 'Questions similaires',
    },
    slots: {
      toComeTitle: 'Créneaux à venir',
      toComeSubtitle: 'Seuls 10 résultats sont affichés au maximum',
      empty: 'Aucun créneau prévu pour le moment.',
    },
    staffUser: {
      avatar: 'Avatar',
      bic: 'BIC',
      comfortArea: "A l'aise avec",
      commercialName: 'Nom commercial (facture)',
      consultationPrice: 'Tarif de la consultation',
      email: 'Email',
      expertiseArea: "Domaine d'expertise",
      firstName: 'Prénom',
      iban: 'IBAN',
      lastName: 'Nom',
      password: 'Mot de passe',
      phone: 'Téléphone',
      phoneWithFormat: 'Téléphone (format +336...)',
      timezone: 'Fuseau horaire',
    },
    subject: {
      actives: 'En cours',
      inactives: 'Terminés',
      medicalIntervention: 'Avis médicaux',
      myQuestions: 'Mes questions',
      myQuestionsTooltip: 'Nombre de questions attribuées : perso (total)',
      notAttributed: 'Non attribuées',
      notAttributedTooltip: 'Nombre de questions non attribuées',
      revives: 'Relances',
      uncategorized: 'Non catégorisées',
      zenTooltip: 'Questions en cours\n(attendant une réponse de notre part)',
      midwifeQuestionsTooltip: "Questions SF en attente d'une réponse IPDE",
    },
    subjectExplorer: {
      category: 'Catégorie',
      discussion: '# de discussion',
      end: 'Fin',
      goToDiscussion: 'Aller sur la discussion',
      keywords: 'Mots clés',
      noMessage: 'Aucun message...',
      noSubjectWithFilters: 'Aucune question ne correspond à ce(s) filtre(s)',
      pro: 'Pro',
      selectOneSubject: 'Sélectionnez une question dans la liste de gauche',
      start: 'Début',
    },
    subjectImpact: {
      description: "Mesure de l'impact de la discussion sur le patient",
      title: 'Intention du patient',
    },
    subscription: {
      activateBonus: 'Créer abonnement bonus',
      deactivateBonus: 'Désactiver le compte bonus',
      refresh: 'Rafraîchir le status',
      empty: 'Aucun abonnement pour le moment',
    },
    timezoneSelector: {
      title: 'Fuseau horaire',
      label: 'Fuseau horaire',
    },
    title: {
      moreInfo: "Plus d'infos",
      help: 'Aide',
      calendars: 'Calendriers',
      patient: 'Parents',
      admin: "Interface d'administration",
      dashboard: 'Tableau de bord',
    },
    validation: {
      integer: 'Un chiffre entier est attendu',
    },
  },
};
