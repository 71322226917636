import { createContext, useContext, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useSearchParams } from 'react-router-dom';

import { Discussion } from '@boTypes/discussion';
import {
  ATTRIBUTION_OPTIONS_ENUM,
  CLASSICAL_OPTIONS_ENUM,
  Filters,
} from '@boTypes/subject';
import { useNextHandoverReviveDate } from '@hooks/handover';
import { useSubjectAttributions } from '@hooks/subjectAttribution';
import { useAttributionUI } from '@hooks/useAttributionUI';
import { LabelOff } from '@mui/icons-material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { Box, TabProps, Tooltip, Typography } from '@mui/material';

import {
  getAllUnattributedCount,
  getAttributedCount,
  getUnattributedCount,
} from './utils';
import { logSubjectTabSelected } from '../../analytics/events';
import { i18nProvider } from '../../App';
import { DiscussionContext } from '../../common';
import { useActiveTab } from '../../hooks/subjects';
import { useDiscussionContext } from '../../hooks/useDiscussionContext';
import { useSelector } from '../../store';
import { COLORS } from '../../themes';
import { TabSelector } from '../Tabs';
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabProps = (
  shouldRevive: boolean,
): Record<ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM, TabProps> => ({
  [ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED]: {
    ...a11yProps(0),
    sx: { flex: 5 },
  },
  [ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS]: { ...a11yProps(1), sx: { flex: 5 } },
  [ATTRIBUTION_OPTIONS_ENUM.REVIVE]: {
    ...a11yProps(2),
    icon: (
      <Tooltip title={i18nProvider.translate('subject.revives')}>
        <AccessAlarmIcon />
      </Tooltip>
    ),
    sx: { color: shouldRevive ? COLORS.PINK['500'] : COLORS.GREY_TEXT_LIGHT },
  },
  [ATTRIBUTION_OPTIONS_ENUM.WITH_DOCTOR_INTERVENTION]: {
    ...a11yProps(3),
    icon: (
      <Tooltip title={i18nProvider.translate('subject.medicalIntervention')}>
        <LocalHospitalIcon />
      </Tooltip>
    ),
    sx: { color: COLORS.GREY_TEXT_LIGHT },
  },
  [ATTRIBUTION_OPTIONS_ENUM.CLOSED]: {
    ...a11yProps(4),
    icon: (
      <Tooltip title={i18nProvider.translate('subject.inactives')}>
        <FolderCopyIcon />
      </Tooltip>
    ),
    sx: { color: COLORS.GREY_TEXT_LIGHT },
  },

  // todo : remove this when automatic attribution rules
  [CLASSICAL_OPTIONS_ENUM.ACTIVE]: a11yProps(0),
  [CLASSICAL_OPTIONS_ENUM.INACTIVE]: a11yProps(1),
  [CLASSICAL_OPTIONS_ENUM.REVIVE]: {
    ...a11yProps(2),
    sx: { color: shouldRevive && COLORS.PINK['500'] },
  },
});

const filtersValues: Record<
  ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM,
  string
> = {
  [ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED]: JSON.stringify({
    status: Filters.ACTIVE,
  }),
  [ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS]: JSON.stringify({
    status: Filters.ACTIVE,
  }),
  [ATTRIBUTION_OPTIONS_ENUM.REVIVE]: JSON.stringify({ status: Filters.REVIVE }),
  [ATTRIBUTION_OPTIONS_ENUM.WITH_DOCTOR_INTERVENTION]: JSON.stringify({
    status: Filters.ACTIVE,
  }),
  [ATTRIBUTION_OPTIONS_ENUM.CLOSED]: JSON.stringify({
    status: Filters.INACTIVE,
  }),

  // todo : remove this when automatic attribution rules
  [CLASSICAL_OPTIONS_ENUM.ACTIVE]: JSON.stringify({ status: Filters.ACTIVE }),
  [CLASSICAL_OPTIONS_ENUM.INACTIVE]: JSON.stringify({
    status: Filters.INACTIVE,
  }),
  [CLASSICAL_OPTIONS_ENUM.REVIVE]: JSON.stringify({ status: Filters.REVIVE }),
};

// todo : remove this when automatic attribution rules
const classicalOptions = {
  [CLASSICAL_OPTIONS_ENUM.ACTIVE]: String(CLASSICAL_OPTIONS_ENUM.ACTIVE),
  [CLASSICAL_OPTIONS_ENUM.INACTIVE]: String(CLASSICAL_OPTIONS_ENUM.INACTIVE),
  [CLASSICAL_OPTIONS_ENUM.REVIVE]: String(CLASSICAL_OPTIONS_ENUM.REVIVE),
};

export const ActiveSubjectsContext = createContext([] as Discussion[]);

const labelWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

const labelStyles = (selected: boolean) => ({
  fontWeight: selected ? 600 : 400,
  fontSize: '0.75rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
});

const subLabelStyles = (selected: boolean) => ({
  fontWeight: selected ? 600 : 400,
  fontSize: '0.875rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
});

const MyQuestions = ({ selected }: { selected: boolean }) => {
  const activeList = useContext(ActiveSubjectsContext);
  const translate = useTranslate();
  const userId = useSelector((state) => state.user.userId);
  const { data } = useSubjectAttributions();

  const [questionCount, unattributedCount] = useMemo(
    () => [
      getAttributedCount(activeList, data?.staffUserFromSubjectId, userId),
      getAllUnattributedCount(activeList, data?.staffUserFromSubjectId),
    ],
    [activeList, data?.staffUserFromSubjectId, userId],
  );
  return (
    <Box component="span" sx={labelWrapperStyles}>
      <Typography
        component="span"
        sx={labelStyles(selected)}
        color={selected ? 'primary' : 'inherit'}
      >
        {translate(ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS)}
      </Typography>
      <Tooltip title={translate('subject.myQuestionsTooltip')}>
        <Typography
          component="span"
          sx={subLabelStyles(false)}
          color={selected ? 'primary' : 'inherit'}
        >
          {questionCount} ({activeList.length - unattributedCount})
        </Typography>
      </Tooltip>
    </Box>
  );
};

const Unattributed = ({ selected }: { selected: boolean }) => {
  const activeList = useContext(ActiveSubjectsContext);
  const translate = useTranslate();
  const { data } = useSubjectAttributions();
  const [unattributedCount, uncategorizedCount] = useMemo(
    () => getUnattributedCount(activeList, data?.staffUserFromSubjectId),
    [activeList, data?.staffUserFromSubjectId],
  );
  return (
    <Box component="span" sx={labelWrapperStyles}>
      <Typography
        sx={labelStyles(selected)}
        color={selected ? 'primary' : 'inherit'}
      >
        {translate(ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED)}
      </Typography>
      <Tooltip title={translate('subject.notAttributedTooltip')}>
        <Typography
          component="span"
          sx={subLabelStyles(true)}
          color={selected ? 'primary' : 'inherit'}
        >
          {unattributedCount}
          {Boolean(uncategorizedCount) && (
            <>
              {' - '}
              <LabelOff sx={{ fontSize: '0.75rem' }} />
              {uncategorizedCount}
            </>
          )}
        </Typography>
      </Tooltip>
    </Box>
  );
};

const attributionOptions = {
  [ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS]: MyQuestions,
  [ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED]: Unattributed,
  [ATTRIBUTION_OPTIONS_ENUM.REVIVE]: '',
  [ATTRIBUTION_OPTIONS_ENUM.WITH_DOCTOR_INTERVENTION]: '',
  [ATTRIBUTION_OPTIONS_ENUM.CLOSED]: '',
};

export const SubjectFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = useActiveTab();
  const attributionUI = useAttributionUI();

  const handleStatusChange = (
    _,
    newValue: ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM,
  ) => {
    searchParams.set('filter', filtersValues[newValue]);
    searchParams.set('tab', newValue);
    setSearchParams(searchParams);
    logSubjectTabSelected(newValue);
  };
  const discussionContext = useDiscussionContext();
  const { data } = useNextHandoverReviveDate(
    discussionContext === DiscussionContext.MIDWIFE ? 'midwife' : 'nurse',
  );

  const shouldRevive = data?.reviveAt
    ? new Date(data.reviveAt).getTime() < Date.now() + 15 * 60 * 1000
    : false;
  return attributionUI ? (
    <TabSelector<ATTRIBUTION_OPTIONS_ENUM>
      aria-label="subject type filter"
      value={activeTab as ATTRIBUTION_OPTIONS_ENUM}
      options={attributionOptions}
      onChange={handleStatusChange}
      tabProps={tabProps(shouldRevive)}
    />
  ) : (
    <TabSelector<CLASSICAL_OPTIONS_ENUM>
      aria-label="subject type filter"
      value={activeTab as CLASSICAL_OPTIONS_ENUM}
      options={classicalOptions}
      onChange={handleStatusChange}
      tabProps={tabProps(shouldRevive)}
    />
  );
};
