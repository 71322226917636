import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  useInfiniteQuery as useInfiniteQueryRaw,
  useQuery as useQueryRaw,
  useMutation as useMutationRaw,
  useQueryClient as useQueryClientRaw,
  QueryKey as QueryKeyRaw,
  MutationKey,
  QueryFunctionContext,
  UseQueryOptions,
} from 'react-query';
import { Updater } from 'react-query/types/core/utils';

import {
  apiRequest,
  cmsRequest,
  fullApiRequest,
  s3Request,
  searchRequest,
} from '../utils/httpClients';

export type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
} from 'react-query';

export { QueryClient } from 'react-query';

type AxiosRequestClient<TData> = (arg: AxiosRequestConfig) => Promise<TData>;

export type QueryKey = QueryKeyRaw;

type RequestGenerator<
  TQueryKey extends QueryKey,
  RequestClient extends AxiosRequestClient<any>,
> =
  | ((context: QueryFunctionContext<TQueryKey>) => Parameters<RequestClient>[0])
  | Parameters<RequestClient>[0];

type MutationRequestGenerator<
  RequestClient extends AxiosRequestClient<any>,
  TVariables = any,
> =
  | ((arg: TVariables) => Parameters<RequestClient>[0])
  | Parameters<RequestClient>[0];

export const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  key: MutationKey,
  func: MutationRequestGenerator<typeof apiRequest<TData>, TVariables>,
  options?: Parameters<
    typeof useMutationRaw<TData, TError, TVariables, TContext>
  >[2],
): ReturnType<typeof useMutationRaw<TData, TError, TVariables, TContext>> => {
  return useMutationRaw(
    key,
    (data: TVariables) =>
      apiRequest(typeof func === 'function' ? func(data) : { data, ...func }),
    options,
  );
};

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  func: RequestGenerator<
    TQueryKey,
    (arg: AxiosRequestConfig) => Promise<TQueryFnData>
  >,
  options?: Parameters<
    typeof useQueryRaw<TQueryFnData, TError, TData, TQueryKey>
  >[2],
  requestClient: (
    arg: AxiosRequestConfig,
  ) => Promise<TQueryFnData> = apiRequest,
): ReturnType<typeof useQueryRaw<TQueryFnData, TError, TData, TQueryKey>> => {
  return useQueryRaw(
    key,
    (context) =>
      requestClient(typeof func === 'function' ? func(context) : func),
    options,
  );
};

export const useCMSQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  func: Parameters<typeof useQuery>[1],
  options?: Parameters<
    typeof useQueryRaw<TQueryFnData, TError, TData, TQueryKey>
  >[2],
) =>
  useQuery<TQueryFnData, TError, TData, TQueryKey>(
    key,
    func,
    options,
    cmsRequest,
  );

export const useCMSMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  key: MutationKey,
  func: MutationRequestGenerator<typeof cmsRequest<TData>, TVariables>,
  options?: Parameters<
    typeof useMutationRaw<TData, TError, TVariables, TContext>
  >[2],
): ReturnType<typeof useMutationRaw<TData, TError, TVariables, TContext>> => {
  return useMutationRaw(
    key,
    (arg: TVariables) =>
      cmsRequest(typeof func === 'function' ? func(arg) : func),
    options,
  );
};

export const useQueryClient = useQueryClientRaw;

export const useQueryWithUpdater = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn:
    | ((
        context: QueryFunctionContext<TQueryKey>,
      ) => Parameters<typeof apiRequest<TData>>[0])
    | Parameters<typeof apiRequest<TData>>[0],
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  const queryClient = useQueryClient();
  const setCache = (data: Updater<TQueryFnData | undefined, TQueryFnData>) =>
    queryClient.setQueryData<TQueryFnData>(queryKey, data);

  const queryRes = useQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    options,
  );
  return { ...queryRes, setCache };
};

export const useInfiniteSearchQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  func: RequestGenerator<
    TQueryKey,
    (arg: AxiosRequestConfig) => Promise<TQueryFnData>
  >,
  options?: Parameters<
    typeof useInfiniteQueryRaw<TQueryFnData, TError, TData, TQueryKey>
  >[2],
): ReturnType<
  typeof useInfiniteQueryRaw<TQueryFnData, TError, TData, TQueryKey>
> => {
  return useInfiniteQueryRaw<TQueryFnData, TError, TData, TQueryKey>(
    key,
    ({ signal, ...context }) =>
      searchRequest({
        ...(typeof func === 'function' ? func(context) : func),
        signal,
      }),
    options,
  );
};

export const useInfiniteQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = AxiosResponse<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  func: RequestGenerator<TQueryKey, typeof fullApiRequest>,
  options?: Parameters<
    typeof useInfiniteQueryRaw<
      AxiosResponse<TQueryFnData>,
      TError,
      TData,
      TQueryKey
    >
  >[2],
): ReturnType<
  typeof useInfiniteQueryRaw<
    AxiosResponse<TQueryFnData>,
    TError,
    TData,
    TQueryKey
  >
> => {
  return useInfiniteQueryRaw<
    AxiosResponse<TQueryFnData>,
    TError,
    TData,
    TQueryKey
  >(
    key,
    (context) =>
      fullApiRequest<TQueryFnData>(
        typeof func === 'function' ? func(context) : func,
      ),
    options,
  );
};

export const useS3Mutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  key: MutationKey,
  func: MutationRequestGenerator<typeof s3Request<TData>, TVariables>,
  options?: Parameters<
    typeof useMutationRaw<TData, TError, TVariables, TContext>
  >[2],
): ReturnType<typeof useMutationRaw<TData, TError, TVariables, TContext>> => {
  return useMutationRaw(
    key,
    (data: TVariables) =>
      s3Request(typeof func === 'function' ? func(data) : { data, ...func }),
    options,
  );
};
