import { ComponentProps, ComponentType, useCallback, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { FixedSizeList as List } from 'react-window';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { Box } from '@mui/material';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    maxWidth: '100%',
  },
});

export const FullWidthList = (props: ComponentProps<typeof List>) => {
  const [width, setWidth] = useState(300);

  return (
    <View
      style={styles.wrapper}
      onLayout={({ nativeEvent }) => {
        const { layout } = nativeEvent;
        if (layout.width !== width) {
          setWidth(layout.width);
        }
      }}
    >
      <List
        className={'scrollbar'}
        {...props}
        width={width}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ ...props.style, maxWidth: '100%', overflow: 'overlay' }}
      />
    </View>
  );
};

export const useWrappedItemWithSend = (
  Component: ComponentType<{
    item: any;
    onSend?: (
      { type, content }: { type: DiscussionEventType; content: string },
      contentType: string,
      contentId: number,
    ) => void;
  }>,
  list: any[],
  onSend?: (
    { type, content }: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
  ) => void,
) => {
  return useCallback(
    ({ index, style }: { index: number; style: any }) => {
      return (
        <Box style={style} key={list[index]?.id || index}>
          <Component item={list[index]} onSend={onSend} />
        </Box>
      );
    },
    [Component, list, onSend],
  );
};

export const useWrappedItemWithCopy = (
  Component: ComponentType<{
    item: any;
    onCopy?: (val: string, macroId: number, suggestionId: number) => void;
  }>,
  list: any[],
  onCopy?: (arg: string, macroId: number, suggestionId: number) => void,
) => {
  return useCallback(
    ({ index, style }: { index: number; style: any }) => {
      return (
        <Box style={style} key={list[index]?.id || index}>
          <Component item={list[index]} onCopy={onCopy} />
        </Box>
      );
    },
    [Component, list, onCopy],
  );
};
