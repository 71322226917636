import React, { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
  CardActions,
  Dialog,
  DialogContent,
  Typography,
  styled,
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { logContentView } from '../../analytics/events';
import { Post } from '../../types';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 208;
const IMAGE_HEIGHT = 80;
const TITLE_LINE_NB = 2;
const TITLE_HEIGHT = TITLE_LINE_NB * 24;
const TITLE_PADDING = 2 * 8;
export const HEIGHT = IMAGE_HEIGHT + TITLE_HEIGHT + TITLE_PADDING;

const StyledImage = styled('img')({
  marginLeft: '-24px',
  marginRight: '-24px',
  width: 'calc(100% + 48px)',
  height: '250px',
  objectFit: 'cover',
});

export const PostItemDialog = ({
  item,
  open,
  setOpen,
}: {
  item: Post;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const close = useCallback(() => setOpen(false), [setOpen]);
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth="lg"
    >
      <DialogTitle onClose={close}>{item.title}</DialogTitle>
      <DialogContent>
        <StyledImage
          src={item?.cover?.url ?? item?.coverThumbnailUrl}
          alt={item.title}
        />
        {item?.authorNames?.length > 0 && (
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            {item?.authorNames?.join(', ')}
          </Typography>
        )}
        <ReactMarkdown>{item.content}</ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
};

export const PostItem = ({
  item: post,
  onSend,
}: {
  item: Post;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
}) => {
  const { cover, title } = post;
  const { formats, url } = cover || { url: post?.coverThumbnailUrl };
  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => {
    logContentView('post', post.id);
    setOpen(true);
  }, [setOpen, post.id]);
  const postInfo = {
    type: DiscussionEventType.POST,
    content: post.slug,
  };

  return (
    <CMSCard width={WIDTH} height={HEIGHT}>
      {open ? (
        <PostItemDialog open={open} setOpen={setOpen} item={post} />
      ) : null}
      <CMSCardActionArea
        height={HEIGHT}
        onClick={onClick}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        {post.sent && <CMSCardSentOverlay />}
        <CardMedia
          component="img"
          alt={title}
          height={String(IMAGE_HEIGHT)}
          image={formats?.thumbnail?.path ?? url}
          title={title}
        />
        <CardContent sx={{ height: TITLE_HEIGHT + TITLE_PADDING, padding: 1 }}>
          <CMSCardTitle
            variant="subtitle2"
            component="h2"
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
            clamp={TITLE_LINE_NB}
          >
            {title}
          </CMSCardTitle>
        </CardContent>
        {post.sent && (
          <DownloadDoneIcon
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              bottom: theme.spacing(0),
            })}
          />
        )}
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0 }}>
          <SendIcon
            onClick={() => onSend(postInfo, DiscussionEventType.POST, post.id)}
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};
