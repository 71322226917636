import { useAttributionUI } from '@hooks/useAttributionUI';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase } from '@mui/material';

import { AttributionFilters } from './AttributionFilters';
import { COLORS } from '../../themes';
import { Discussion } from '../../types';
import { AttributionFilter } from '../../types/discussion';

export const SearchAndAttributionFilters = ({
  text,
  onChangeText,
  discussions,
  filterValue,
  onFilterClick,
}: {
  text: string;
  onChangeText: (value: string) => void;
  discussions: Discussion[];
  onFilterClick: (filter: AttributionFilter) => void;
  filterValue: AttributionFilter;
}) => {
  const attributionUI = useAttributionUI();
  return (
    <Box
      sx={{
        m: 0,
        paddingLeft: 1,
        paddingRight: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `1px solid ${COLORS.GREY_LAYOUT}`,
      }}
    >
      {text ? (
        <IconButton onClick={() => onChangeText('')} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      ) : (
        <SearchIcon sx={{ color: COLORS.GREY_TEXT }} />
      )}
      <InputBase
        autoFocus
        value={text}
        sx={{ marginTop: 0, flex: 1, marginLeft: 1 }}
        onChange={(e) => onChangeText(e.target.value)}
      />
      {!attributionUI && (
        <AttributionFilters
          discussions={discussions}
          filterValue={filterValue}
          onFilterClick={onFilterClick}
        />
      )}
    </Box>
  );
};
