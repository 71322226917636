import { List as RaList, ListProps } from 'react-admin';

export const List = ({ children, ...props }: ListProps) => (
  <RaList
    exporter={false}
    perPage={25}
    sx={{ marginLeft: 1, marginRight: 1 }}
    {...props}
  >
    {children}
  </RaList>
);
