const emptyFollowUpList = ({ width }) => (
  <svg
    id="Calque_3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360 360"
    width={width}
  >
    <g>
      <path
        fill="currentColor"
        d="M161.24,93.55H69.55c-5.8,0-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5h91.69c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5Z"
      />
      <path
        fill="currentColor"
        d="M161.24,265.14H69.55c-5.8,0-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5h91.69c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5Z"
      />
      <path
        fill="currentColor"
        d="M217.54,150.86H69.55c-5.8,0-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5H217.54c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5Z"
      />
      <path
        fill="currentColor"
        d="M217.54,208.17H69.55c-5.8,0-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5H217.54c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5Z"
      />
      <path
        fill="currentColor"
        d="M257.71,204.31c5.8,0,10.5-4.7,10.5-10.5V91.25c0-3.19-1.45-6.22-3.95-8.21L199.95,31.74c-1.86-1.48-4.17-2.29-6.55-2.29H52.05c-16.79,0-30.45,13.66-30.45,30.45v249.04c0,16.79,13.66,30.46,30.45,30.46H237.76c16.79,0,30.45-13.66,30.45-30.46v-47.46c0-5.8-4.7-10.5-10.5-10.5s-10.5,4.7-10.5,10.5v47.46c0,5.21-4.24,9.46-9.45,9.46H52.05c-5.21,0-9.45-4.24-9.45-9.46V59.91c0-5.21,4.24-9.45,9.45-9.45H183.4v41.16c0,2.66,1.06,5.21,2.95,7.09,1.87,1.86,4.41,2.91,7.05,2.91h.06l53.76-.3v92.5c0,5.8,4.7,10.5,10.5,10.5ZM203.4,61.36l25.14,20.06-25.14,.14v-20.2Z"
      />
    </g>
    <path
      fill="currentColor"
      d="M332.81,135.02c-12.77-10.37-31.6-8.42-41.93,4.3l-81.44,98.79c-.79,.95-1.39,2.05-1.78,3.22l-15.45,46.68c-1.25,3.79-.13,7.97,2.85,10.62,1.87,1.66,4.24,2.52,6.64,2.52,1.44,0,2.89-.31,4.24-.94l46.25-21.67c1.34-.63,2.53-1.55,3.47-2.69l81.49-98.85c10.37-12.77,8.42-31.6-4.36-41.98Zm-95.33,100.51l51.63-62.62,15.22,12.48-52.06,63.15-14.79-13.01Zm-12.12,15.97l13,11.43-19.86,9.3,6.86-20.73Zm96.33-87.18l-4.64,5.63-15.22-12.48,4.53-5.5c3.42-4.21,9.63-4.86,13.84-1.44,4.21,3.42,4.86,9.63,1.48,13.79Z"
    />
  </svg>
);

export default emptyFollowUpList;
