import {
  DateInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { FormProps } from '../../types';
import { sexChoices } from '../common';

export const ChildrenForm = (props: FormProps) => (
  <SimpleForm {...props}>
    <TextInput
      label="Prénom"
      source="firstName"
      validate={required()}
      variant="standard"
    />
    <TextInput label="Nom" source="lastName" variant="standard" />
    <SelectInput
      label="Sexe"
      source="sex"
      choices={sexChoices}
      validate={required()}
      variant="standard"
    />
    <DateInput
      label="Date de naissance"
      source="birthDay"
      validate={required()}
      variant="standard"
    />
  </SimpleForm>
);
