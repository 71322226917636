import {
  BooleanInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';

import type { FormProps } from '../../types';

export const AffiliationsForm = (props: FormProps) => (
  <SimpleForm {...props}>
    <TextInput
      multiline
      label="Description"
      source="description"
      validate={required()}
      variant="standard"
    />
    <BooleanInput source="active" defaultValue={true} variant="standard" />
    <ReferenceInput
      label="Company Plan"
      source="companyPlan.id"
      reference="company-plans"
    >
      <SelectInput
        label="Company Plan"
        optionText="alias"
        variant="standard"
        validate={required()}
      />
    </ReferenceInput>
  </SimpleForm>
);
