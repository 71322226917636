import { DateLocalizer } from 'react-big-calendar';

export const formats = {
  timeGutterFormat: (
    date: Date,
    culture: 'string',
    localizerFormat: DateLocalizer,
  ) => localizerFormat.format(date, 'H:mm', culture),
  dayFormat: (date: Date, culture: string, localizerFormat: DateLocalizer) =>
    localizerFormat.format(date, 'ddd D', culture),
  dateFormat: (date: Date, culture: string, localizerFormat: DateLocalizer) =>
    localizerFormat.format(date, 'D', culture),
  dayHeaderFormat: (
    date: Date,
    culture: string,
    localizerFormat: DateLocalizer,
  ) => localizerFormat.format(date, 'dddd D MMMM', culture),
  eventTimeRangeEndFormat: (
    date: { start: Date; end: Date },
    culture: string,
    localizerFormat: DateLocalizer,
  ) => `<< ${localizerFormat.format(date.end, 'H:mm', culture)}`,
  eventTimeRangeStartFormat: (
    date: { start: Date; end: Date },
    culture: string,
    localizerFormat: DateLocalizer,
  ) => `${localizerFormat.format(date.start, 'H:mm', culture)} >>`,
  eventTimeRangeFormat: (
    range: { start: Date; end: Date },
    culture: string,
    localizerFormat: DateLocalizer,
  ) =>
    `${localizerFormat.format(
      range.start,
      'H:mm',
      culture,
    )} - ${localizerFormat.format(range.end, 'H:mm', culture)}`,
};
