import { useMemo } from 'react';

import { useSelector } from '../../../store';
import { PlanningAttribution } from '../../../types/planning';
import { Slot } from '../../../types/slot';
import { titleToPlanningJob } from '../../../types/user';

export const useUnattSlots = (
  slots: Slot[],
  attributedSlotsById: Record<string, boolean>,
) => {
  const userJob = useSelector((state) =>
    state.user?.jobTitle ? titleToPlanningJob[state.user?.jobTitle] : null,
  );

  return useMemo<Slot[]>(() => {
    return (
      slots?.filter(
        (slot) =>
          !attributedSlotsById?.[slot.id] &&
          (userJob === 'all' ||
            !slot.planning ||
            slot.planning?.job === userJob),
      ) ?? []
    );
  }, [attributedSlotsById, slots, userJob]);
};

export const useUnattributedSlots = (
  slots: Slot[],
  attributions?: PlanningAttribution[],
) => {
  const attributedSlotsById = useMemo(() => {
    return (
      attributions?.reduce(
        (acc, { slotId }) => ({ ...acc, [slotId]: true }),
        {} as Record<string, boolean>,
      ) ?? {}
    );
  }, [attributions]);

  return useUnattSlots(slots, attributedSlotsById);
};
