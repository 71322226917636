import { Box } from '@mui/material';

import { PricingProCustomList } from '../entities/pricingProCustom/list';
import { PricingProGenericList } from '../entities/pricingProGeneric/list';

export const PricingProList = () => {
  return (
    <Box>
      <PricingProGenericList />
      <PricingProCustomList />
    </Box>
  );
};
