import { useState } from 'react';

import { ChildCare, PregnantWoman } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { CommonProps } from '@mui/material/OverridableComponent';

import { jobColors } from './healthProColors';
import { LinearProgress } from './Timeline';
import { ConfirmDialog } from '../../../components/ConfirmDialog/ConfirmDialog';
import { DialogButton } from '../../../components/DialogButton/DialogButton';
import { StethoIcon } from '../../../components/icons/stethoscope';
import {
  useDeletePlanning,
  useUpdatePlanningStatus,
} from '../../../hooks/plannings';
import { useNavigateWithParams } from '../../../hooks/useNavigateWithParams';
import { COLORS } from '../../../themes';
import { Planning, PlanningJob, PlanningStatus } from '../../../types/planning';
import { getReadableTimeRange } from '../../../utils/date';

export const CARD_MAX_WIDTH = 450;

const PlanningIcon = ({
  job,
  style,
}: {
  job: PlanningJob;
  style?: CommonProps['style'];
}) => {
  switch (job) {
    case PlanningJob.NURSE:
      return <ChildCare style={style} />;
    case PlanningJob.MIDWIFE:
      return <PregnantWoman style={style} />;
    case PlanningJob.DOCTOR:
      return <StethoIcon style={style} />;
    default:
      return null;
  }
};

export const PlanningCard = ({
  planning,
  onEdit,
}: {
  planning: Planning;
  onEdit: () => void;
}) => {
  const navigate = useNavigateWithParams();
  const { mutate: setStatus } = useUpdatePlanningStatus(planning);
  const { mutate: deletePlanning } = useDeletePlanning();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  return (
    <Card
      sx={{
        p: 2,
        m: 1,
        flex: 1,
        maxWidth: CARD_MAX_WIDTH,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 1.5,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <PlanningIcon
            job={planning.job}
            style={{ color: jobColors[planning.job], marginRight: 4 }}
          />
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {planning.title}
          </Typography>
          {planning.runningSolver && (
            <CircularProgress size={16} sx={{ marginLeft: 1 }} />
          )}
        </Box>
        <Typography
          variant="h6"
          sx={{
            color: COLORS.GREY_TEXT,
            fontWeight: 200,
            fontSize: '0.825rem',
          }}
        >
          {getReadableTimeRange(planning.begin, planning.end)}
          <IconButton
            sx={{ mx: 0, px: 0 }}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                onEdit();
                setAnchorEl(null);
              }}
            >
              <EditIcon sx={{ color: COLORS.GREY_TEXT, marginRight: 1 }} />
              <Typography variant="h6">Editer</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setDeleteDialogOpen(true);
              }}
            >
              <DeleteIcon sx={{ color: COLORS.GREY_TEXT, marginRight: 1 }} />
              <Typography variant="h6">Supprimer</Typography>
            </MenuItem>
          </Menu>

          <ConfirmDialog
            title={'Suppression'}
            text={'Supprimer ce planning ?'}
            cancelText={'annuler'}
            confirmText={'Supprimer'}
            onClose={() => setDeleteDialogOpen(false)}
            onClick={() => {
              deletePlanning(String(planning.id));
            }}
            open={deleteDialogOpen}
          />
        </Typography>
      </Box>
      <LinearProgress
        list={Object.values(PlanningStatus)}
        active={planning.status}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {(planning.status === PlanningStatus.SLOTS_CREATED ||
          planning.status === PlanningStatus.HEALTH_PRO_ASSIGNED) && (
          <DialogButton
            text={"Permettre aux professionnels de s'inscrire ?"}
            onClick={() =>
              setStatus({ status: PlanningStatus.ADMIN_SLOTS_ASSIGNED })
            }
            disabled={planning.runningSolver}
            startIcon={<EventAvailableIcon />}
            variant="outlined"
            size="small"
          >
            Ouvrir la saisie pro
          </DialogButton>
        )}
        {planning.status === PlanningStatus.ADMIN_SLOTS_ASSIGNED && (
          <DialogButton
            text={'Finaliser les inscriptions ?'}
            onClick={() =>
              setStatus({ status: PlanningStatus.HEALTH_PRO_ASSIGNED })
            }
            disabled={planning.runningSolver}
            startIcon={<EventBusyIcon />}
            color="error"
            variant="outlined"
            size="small"
          >
            Clôturer la saisie
          </DialogButton>
        )}
        {(planning.status === PlanningStatus.SLOTS_CREATED ||
          planning.status === PlanningStatus.DRAFT) && (
          <Button
            onClick={() => {
              navigate(`/planning/slots/${planning.id}`);
            }}
            disabled={planning.runningSolver}
            startIcon={<EditIcon />}
            color="primary"
            variant="outlined"
            size="small"
          >
            Modifier les créneaux
          </Button>
        )}
        {(planning.status === PlanningStatus.ADMIN_SLOTS_ASSIGNED ||
          planning.status === PlanningStatus.CALCULATION_PERFORMED ||
          planning.status === PlanningStatus.DONE) && (
          <Button
            onClick={() => {
              navigate(`/planning/agenda/${planning.id}`);
            }}
            disabled={planning.runningSolver}
            startIcon={<DateRangeIcon />}
            color="primary"
            variant="outlined"
            size="small"
          >
            Voir le planning
          </Button>
        )}
        {planning.status === PlanningStatus.CALCULATION_PERFORMED && (
          <Button
            onClick={() => {
              setStatus({ status: PlanningStatus.DONE });
            }}
            disabled={planning.runningSolver}
            startIcon={<CheckIcon />}
            color="success"
            variant="outlined"
            size="small"
          >
            Valider le planning
          </Button>
        )}
        {planning.status === PlanningStatus.DONE && (
          <Button
            onClick={() => {
              navigate(`/planning/${planning.id}/invoices`);
            }}
            disabled={planning.runningSolver}
            startIcon={<EuroSymbolIcon />}
            color="primary"
            variant="outlined"
            size="small"
          >
            Récap/Factures
          </Button>
        )}
      </Box>
    </Card>
  );
};
