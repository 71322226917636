import { useTranslate } from 'react-admin';
import { Control, Path } from 'react-hook-form';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { MenuItem } from '@mui/material';
import { Question, QuestionType } from '@teammay/form-core';

const implementedQuestionTypes = [
  QuestionType.RADIO,
  QuestionType.TEXT,
  QuestionType.SELECT,
];
export type CommonQuestion = Pick<Question, 'title' | 'description' | 'type'>;

export function CommonQuestionEdit<T extends CommonQuestion>({
  control,
  onBlur,
}: {
  control: Control<T, any>;
  onBlur: () => void;
}) {
  const t = useTranslate();
  return (
    <>
      <TextInput
        control={control}
        name={'title' as Path<T>}
        label={t('forms.questions.title')}
        onBlur={onBlur}
      />
      <TextInput
        control={control}
        name={'description' as Path<T>}
        label={t('forms.questions.description')}
        onBlur={onBlur}
      />
      <SelectInput
        variant="outlined"
        control={control}
        name={'type' as Path<T>}
        rules={{ required: true }}
      >
        {implementedQuestionTypes.map((qType) => (
          <MenuItem key={qType} value={qType}>
            {qType}
          </MenuItem>
        ))}
      </SelectInput>
    </>
  );
}
