import { Identifier } from 'react-admin';

import { fullApiRequest } from '../utils/httpClients';

interface Query {
  sort?: string;
  range?: string;
  filter?: string;
}

export const getListDefaultOptions = {
  pagination: { page: null, perPage: null },
  filter: {},
  sort: { field: null, order: null },
};

export const dataProvider = {
  getList: async (
    resource: string,
    {
      pagination = {},
      sort = {},
      filter,
    }: {
      pagination?: { page?: number | null; perPage?: number | null };
      sort?: { field?: string | null; order?: string | null };
      filter?: any;
    },
  ) => {
    const query: Query = {};
    let options = {};

    const { page, perPage } = pagination;
    if (page && perPage) {
      const rangeStart = (page - 1) * perPage;
      const rangeEnd = page * perPage - 1;
      query.range = JSON.stringify([rangeStart, rangeEnd]);
      options = {
        // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
        headers: new Headers({
          Range: `${resource}=${rangeStart}-${rangeEnd}`,
        }),
      };
    }

    const { field, order } = sort;
    if (field && order) {
      query.sort = JSON.stringify([field, order]);
    }

    if (filter) {
      query.filter =
        typeof filter === 'string' ? filter : JSON.stringify(filter);
    }
    const url = `/api/${resource}`;

    return fullApiRequest({ url, ...options, params: query }).then(
      ({ headers, data, status }) => {
        if (!headers['content-range'] && page && perPage) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?',
          );
        }
        if (status < 200 || status >= 300) {
          return Promise.reject(
            new Error(`Unable to fetch list - status: ${status}`),
          );
        }

        const contentRange = headers['content-range'];
        return {
          data,
          total:
            (page && perPage) || contentRange
              ? parseInt(headers['content-range'].split('/').pop(), 10)
              : (Array.isArray(data) && data.length) || 0,
        };
      },
    );
  },

  getOne: async (resource: string, params: { id: Identifier }) =>
    fullApiRequest({ url: `/api/${resource}/${params.id}`, method: 'GET' }),

  getMany: (resource: string, params: { ids: Identifier[] }) => {
    const url = `/api/${resource}`;
    return fullApiRequest({
      url,
      params: {
        filter: JSON.stringify({ id: params.ids }),
      },
    });
  },

  getManyReference: async (
    resource: string,
    params?: {
      pagination?: { page: number; perPage: number };
      sort?: { field: string; order: 'ASC' | 'DESC' };
      filter?: any;
      target?: string;
      id?: Identifier;
    },
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `/api/${resource}`;

    return fullApiRequest({
      url,
      headers: {
        Range: `${resource}=${rangeStart}-${rangeEnd}`,
      },
      params: query,
    }).then(({ headers, data }) => {
      if (!('content-range' in headers)) {
        throw new Error(
          `The content-range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare content-range in the Access-Control-Expose-Headers header?`,
        );
      }
      return {
        data,
        total: parseInt(headers['content-range'].split('/').pop(), 10),
      };
    });
  },

  update: async (resource: string, params: { id: Identifier; data: any }) =>
    fullApiRequest({
      url: `/api/${resource}/${params.id}`,
      method: 'PUT',
      data: params.data,
    }),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: async (
    resource: string,
    params: { ids?: Identifier[]; data: any },
  ) =>
    Promise.all(
      params.ids.map((id) =>
        fullApiRequest({
          url: `/api/${resource}/${id}`,
          method: 'PUT',
          data: params.data,
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ data }) => data.id) })),

  create: async (resource: string, params: { data: any }) =>
    fullApiRequest({
      url: `/api/${resource}`,
      method: 'POST',
      data: params.data,
    }),

  delete: (resource: string, params: { id: Identifier }) =>
    fullApiRequest({
      url: `/api/${resource}/${params.id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'text/plain',
      },
    }),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource: string, params: { ids: Identifier[] }) =>
    Promise.all(
      params.ids.map((id) =>
        fullApiRequest({
          url: `/api/${resource}/${id}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'text/plain',
          },
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ data }) => data.id),
    })),
};
