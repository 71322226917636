import { AxiosError } from 'axios';
import { SetStateAction, useCallback, useRef, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';

import { useMutation } from '@hooks/queryWrappers';
import EditIcon from '@mui/icons-material/Edit';
import PushPinIcon from '@mui/icons-material/PushPin';
import {
  CircularProgress,
  IconButton,
  List,
  Paper,
  Slide,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { FollowUpForm } from './followUpForm';
import { StaffUserSignature } from './staffUserSignature';
import EmptyFollowUpList from '../../assets/empty_follow_up';
import { useDiscussionDetail } from '../../hooks/discussion';
import { useFollowUp } from '../../hooks/followUp';
import { COLORS } from '../../themes';
import { MedicalFollowUp } from '../../types';

const border = `1px solid ${COLORS.GREY_LAYOUT}`;

const EmptyList = () => {
  const translate = useTranslate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: COLORS.GREY_LAYOUT,
      }}
    >
      <EmptyFollowUpList width="120px" />
      <Typography
        sx={{
          textAlign: 'center',
          color: COLORS.GREY_TEXT_LIGHT,
          fontSize: '0.875rem',
          maxWidth: '40%',
        }}
      >
        {translate('followUp.empty')}
      </Typography>
    </Box>
  );
};

const FollowUpCard = ({
  followUp,
  onClickEdit,
  onClickPin,
}: {
  followUp: MedicalFollowUp;
  onClickEdit: (arg0: MedicalFollowUp) => void;
  onClickPin: (arg0: MedicalFollowUp) => void;
}) => {
  return (
    <Box
      sx={{
        padding: '0.25rem 0.5rem 0 0.5rem',
        marginBottom: '0.25rem',
        border,
        borderRadius: 2,
        flex: 1,
      }}
    >
      {Boolean(followUp.content) && (
        <Typography
          variant="caption"
          color={followUp.pinned ? 'error' : 'default'}
          sx={{
            display: 'block',
            whiteSpace: 'pre-wrap',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          }}
        >
          {followUp.content}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Box sx={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
          <IconButton size="small" onClick={() => onClickPin(followUp)}>
            <PushPinIcon
              sx={{ fontSize: 16 }}
              color={followUp.pinned ? 'primary' : 'secondary'}
            />
          </IconButton>
          <IconButton size="small" onClick={() => onClickEdit(followUp)}>
            <EditIcon sx={{ fontSize: 16 }} color="primary" />
          </IconButton>
        </Box>
        <StaffUserSignature
          staffUser={followUp.staffUser}
          createdAt={followUp.createdAt}
        />
      </Box>
    </Box>
  );
};

export const FollowupList = ({
  create,
  setCreate,
}: {
  create: boolean;
  setCreate: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const discussion = useDiscussionDetail();
  const { data, isLoading } = useFollowUp(discussion);
  const [editedFollowUp, setEditedFollowUp] = useState<MedicalFollowUp | null>(
    null,
  );
  const contentRef = useRef<HTMLUListElement>(null);

  const onClose = useCallback(() => {
    setEditedFollowUp(null);
    setCreate(false);
  }, []);

  const familyId =
    discussion?.appUsers?.[0] && typeof discussion.appUsers?.[0] === 'object'
      ? discussion.appUsers[0].familyId
      : discussion?.appUser && typeof discussion.appUser === 'object'
        ? discussion.appUser.familyId
        : undefined;

  const notify = useNotify();

  const { mutate: pin } = useMutation<
    MedicalFollowUp,
    AxiosError,
    MedicalFollowUp
  >(
    ['pinned'],
    (fu: MedicalFollowUp) => ({
      method: 'put',
      url: `/api/medical-follow-ups/pinned/${fu.id}`,
      data: { pinned: !fu.pinned },
    }),
    {
      onError(error) {
        notify(`Erreur : ${error.message}`, { type: 'error' });
      },
    },
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <List
          ref={contentRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          }}
          className="scrollbar"
        >
          {data?.map((followUp) => (
            <FollowUpCard
              key={followUp.id}
              followUp={followUp}
              onClickEdit={setEditedFollowUp}
              onClickPin={pin}
            />
          ))}
          {(data?.length ?? 0) === 0 && <EmptyList />}
        </List>
      )}
      <Slide
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        direction="left"
        in={Boolean(editedFollowUp || create)}
        mountOnEnter
        unmountOnExit
        container={contentRef.current}
      >
        <Paper sx={{ height: '100%', width: '100%' }} elevation={4}>
          {create ? (
            <FollowUpForm familyId={familyId} onClose={onClose} />
          ) : (
            <FollowUpForm followUp={editedFollowUp} onClose={onClose} />
          )}
        </Paper>
      </Slide>
    </>
  );
};
