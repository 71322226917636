import { useGetOne } from 'react-admin';

import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

import { COLORS } from '../../themes';
import { FamilyActiveMembers } from '../../types/family';
import { FamilyAvatars } from '../family/familyList';

const border = `1px solid ${COLORS.GREY_LAYOUT}`;
export const FamilyHeader = ({
  familyId,
  callback,
  excludeIds = { appUsers: [], children: [] },
}: {
  familyId: string;
  callback?: () => void;
  excludeIds?: { appUsers: number[]; children: number[] };
}) => {
  const { data: _familyContent } = useGetOne<FamilyActiveMembers>(
    'family',
    {
      id: familyId,
    },
    { enabled: !!familyId },
  );
  const familyContent = _familyContent && {
    id: _familyContent.id,
    vip: _familyContent.vip,
    children: _familyContent.children.filter(
      (c) => !excludeIds.children.includes(c.id),
    ),
    appUsers: _familyContent.appUsers.filter(
      (a) => !excludeIds.appUsers.includes(a.id),
    ),
  };
  return (
    <Box
      sx={{
        borderBottom: border,
        display: 'flex',
        width: '100%',
        overflow: 'auto',
        height: '48px',
        alignItems: 'center',
      }}
      className="scrollbar"
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          alignItems: 'center',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        }}
      >
        <FamilyRestroomIcon
          fontSize="small"
          sx={{
            color: COLORS.GREY_TEXT,
            selfAlign: 'center',
            marginRight: '0.25rem',
          }}
        />
        {familyContent ? (
          <FamilyAvatars
            family={familyContent}
            size={'medium'}
            callback={callback}
          />
        ) : (
          <CircularProgress size={25} thickness={2} />
        )}
      </Box>
    </Box>
  );
};
