import boysHeight from './boysHeight.json';
import boysWeight from './boysWeight.json';
import girlsHeight from './girlsHeight.json';
import girlsWeight from './girlsWeight.json';
import { OMSCurve, OMSDataTypes } from '../types';

const curves = {
  [OMSDataTypes.BOY_HEIGHT]: boysHeight,
  [OMSDataTypes.BOY_WEIGHT]: boysWeight,
  [OMSDataTypes.GIRL_HEIGHT]: girlsHeight,
  [OMSDataTypes.GIRL_WEIGHT]: girlsWeight,
} as Record<OMSDataTypes, Record<OMSCurve, number[]>>;

export default curves;
