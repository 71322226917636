import { FC, useCallback, useState } from 'react';
import { AppBar, AppBarProps, TitlePortal, useRedirect } from 'react-admin';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

import { ElevationScroll } from './elevationOnScroll';
import { SessionButton } from './SessionButton';
import { MyUserMenu } from './UserMenu';
import { ConnectedUsers } from '../components/ConnectedUsers';
import { useChangelogs } from '../pages/common/changelog.hook';
import { useSelector } from '../store';
import { Roles } from '../types/user';

const MobileHide = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
const Logo = styled('img')(({ theme }) => ({
  width: theme.spacing(6),
  marginRight: theme.spacing(2),
}));
const appBarStyles = {
  backdropFilter: 'blur(10px)',
  backgroundColor: '#fff9',
  '& .MuiToolbar-root': {
    minHeight: '48px',
    height: '48px',
  },
  height: '48px',
  minHeight: '48px',
  '& .RaAppBar-toolbar': {
    padding: '0 6px',
  },
};

const MyAppBar: FC<AppBarProps> = (props) => {
  const isHealthPro = useSelector((state) =>
    state.user.roles?.includes(Roles.HEALTH_PRO),
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onNotificationOpen = useCallback((evt) => {
    setAnchorEl(evt.currentTarget);
  }, []);
  const redirect = useRedirect();

  const redirectToNotification = useCallback(
    (_: unknown, changelogId: number) => {
      setAnchorEl(null);
      redirect(`/changelogs/${changelogId}`);
    },
    [redirect],
  );

  const { changelogs } = useChangelogs();

  return (
    <AppBar
      {...props}
      sx={appBarStyles}
      userMenu={<MyUserMenu />}
      container={ElevationScroll}
    >
      <Logo src="/may_logo_green.svg" alt="May logo" />
      <MobileHide>
        <ConnectedUsers size="medium" />
      </MobileHide>
      <Box
        component={'span'}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MobileHide>
          <TitlePortal />
        </MobileHide>
      </Box>
      {isHealthPro && <SessionButton />}
      <IconButton
        size="large"
        aria-label={`Afficher ${
          changelogs?.length && changelogs?.length > 1
            ? `les ${changelogs?.length} notifications`
            : 'la notification'
        }`}
        color="inherit"
        onClick={onNotificationOpen}
        aria-controls="menu-notifications"
        aria-haspopup="true"
      >
        <Badge
          badgeContent={changelogs?.filter(({ read }) => !read)?.length}
          color="error"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {changelogs?.length ? (
        <Menu
          id="menu-notifications"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {changelogs.map((changelog) => (
            <MenuItem
              key={changelog.id}
              onClick={(evt) => redirectToNotification(evt, changelog.id)}
              sx={{ fontWeight: changelog.read ? 'normal' : 'bold' }}
            >
              {changelog.title}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </AppBar>
  );
};
export default MyAppBar;
