import { Create } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { CompanyPlanCodeForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const CompanyPlanCodeCreate = () => {
  const location = useLocation() as RedirectLocation;

  return (
    <Create
      resource="company-plan-codes"
      title="Ajouter une code"
      redirect={location?.state?.redirect || 'list'}
      sx={{ padding: '1rem' }}
    >
      <CompanyPlanCodeForm />
    </Create>
  );
};
