import React from 'react';

import { Videocam } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';

import { Tokbox } from './tokbox';
import { ConsultationWithRelationIds } from '../../types';

interface Props {
  startVisio: () => void;
  record: ConsultationWithRelationIds;
  openVisio: boolean;
  setOpenVisio: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TokboxHandler = ({
  startVisio,
  record,
  openVisio,
  setOpenVisio,
}: Props) =>
  record.phoneOnly ? (
    <Alert severity="warning" sx={{ marginTop: '1rem' }}>
      <AlertTitle>Attention</AlertTitle>
      <Typography>
        Pensez bien à masquer votre numéro en le préfixant avec "#31#" sur votre
        portable.
      </Typography>
    </Alert>
  ) : (
    <Box sx={{ marginTop: '1rem' }}>
      <Button
        startIcon={<Videocam />}
        variant="contained"
        onClick={startVisio}
        color="primary"
      >
        Démarrer la visio
      </Button>
      {openVisio && (
        <Tokbox
          record={record}
          openVisio={openVisio}
          setOpenVisio={setOpenVisio}
        />
      )}
    </Box>
  );
