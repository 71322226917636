import { AppUser as AppUserWithMail } from './appUser';
import type { DiscussionEventType } from './discussionEvent';
import { AppUser } from './family';
import { HandoverRevive, Handover } from './handover';

export enum DiscussionQuickFilter {
  NEW = 'new',
  NURSE = 'nurse',
  MIDWIFE = 'midwife',
  ONGOING = 'ongoing',
  DOCTOR = 'doctor',
  ZEN = 'zen',
  NONE = '',
  PRIORITY = 'priority',
  UNCATEGORIZED = 'uncategorized',
  UNCATEGORIZED_AND_ZEN = 'uncategorized_and_zen',
}

export enum AttributionFilter {
  MINE = 'mine',
  ATTRIBUTED = 'attributed',
  UNATTRIBUTED = 'unattributed',
  NONE = '',
}

interface MinimumChild {
  firstName: string;
}

export interface MinimalDiscussionEvent {
  id: number | string;
  active?: boolean;
  type: DiscussionEventType;
  content: string;
  isMay: boolean;
  createdAt: Date | string;
  firstOfUser?: Date | string;
}

export interface MinimalSubject {
  id: number;
  withDoctorIntervention: boolean;
  withNurseIntervention: boolean;
  isPriority: boolean;
  firstMessageAt: Date;
  start: Date;
  end: Date;
  categoryId?: number;
  empty: boolean;
  handovers?: Handover[];
  mayAnswered: boolean;
  suggestedCategoryId?: number;
}

interface _Discussion {
  id: number;
  lastSubjectId: number;
  lastSubject?: MinimalSubject;
  lastEvent?: MinimalDiscussionEvent;
  lastMaySender?: number;
  createdAt?: string;
  kidId?: number | null;
  appUser?: AppUser;
  appUserId?: number;
  child?: MinimumChild;
  revives?: HandoverRevive[];
}
export interface Discussion extends _Discussion {
  appUsers?: number[] | AppUserWithMail[];
}

export interface DiscussionWithAppUsers extends _Discussion {
  appUsers: AppUser[];
}

export interface IsTypingEventDTO {
  room: string;
  userId: string;
  isPatient?: boolean;
}
export interface CancelTypingEventDTO {
  room: string;
  userId: string;
  isPatient?: boolean;
}
