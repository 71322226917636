import { Subject } from './subject';

export enum SubjectIntent {
  EMERGENCIES = 'emergencies',
  CLASSIC = 'classic',
  MAY = 'may',
  NA = 'na',
}

export interface SubjectImpact {
  id: string;
  initialIntent: SubjectIntent;
  finalIntent: SubjectIntent;
  subjectId: Subject['id'];
}

export const subjectImpactChoices = [
  { id: SubjectIntent.EMERGENCIES, name: 'Urgences' },
  { id: SubjectIntent.MAY, name: 'Discussion May' },
  {
    id: SubjectIntent.CLASSIC,
    name: 'Médecine de ville / Parcours de soin classique',
  },
  { id: SubjectIntent.NA, name: 'Je ne sais pas' },
];
