import { forwardRef } from 'react';

import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material';

export const StyledIconButton = styled(
  forwardRef<any, IconButtonProps>(
    ({ children, ...props }: IconButtonProps, ref) => (
      <IconButton size="small" {...props} ref={ref}>
        {children}
      </IconButton>
    ),
  ),
)(() => ({
  '&:focus::after': {
    backgroundColor: 'transparent',
  },
  '&:hover::after': {
    backgroundColor: 'transparent',
  },
}));
