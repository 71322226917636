import ReactMarkdown from 'react-markdown';

import ItemSuggestion from '@boTypes/ItemSuggestion';
import { Dialog, DialogContent } from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const ItemSuggestionDialog = ({
  item,
  open,
  close,
}: {
  item: ItemSuggestion;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.name}
    aria-describedby={item.name}
    maxWidth="md"
  >
    <DialogTitle onClose={close}>{item.name}</DialogTitle>
    <DialogContent>
      <ReactMarkdown>{item.intro}</ReactMarkdown>
    </DialogContent>
  </Dialog>
);
