import { useFormContext } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { FormStep } from '@teammay/form-core';

export type FormStepForm = Omit<FormStep, 'id' | 'links'>;

export const StepEdit = ({ onBlur }: { onBlur: () => void }) => {
  const { control } = useFormContext<FormStepForm>();
  return (
    <>
      <TextInput
        control={control}
        name={'title'}
        label="Title"
        onBlur={onBlur}
      />
    </>
  );
};
