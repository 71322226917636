import { MouseEvent, useMemo, useState } from 'react';
import { useGetList } from 'react-admin';

import { Category, Subject } from '@boTypes';
import { MinimalSubject } from '@boTypes/discussion';
import { useUpdateSubjectCategory } from '@hooks/category';
import {
  Box,
  Chip,
  ChipProps,
  CircularProgress,
  Menu,
  MenuItem,
} from '@mui/material';

import { DiscussionContext } from '../../common';
import { useDiscussionContext } from '../../hooks/useDiscussionContext';
import {
  CategoryChip,
  categoryColors,
  getCategoryColor,
} from '../fields/CategoriesChipField';

export const CategoryChipSelect = ({
  subject,
  onCategoryChanged,
  ...otherProps
}: {
  subject: MinimalSubject;
  onCategoryChanged?: (subject: Subject) => void;
} & Pick<ChipProps, 'sx'>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { mutate: changeCategory } = useUpdateSubjectCategory(
    subject,
    handleClose,
  );

  const discussionContext = useDiscussionContext();
  const { data: categoryList } = useGetList<Category>(
    'categories',
    {
      pagination: { page: 1, perPage: 1000 },
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );
  const categories = categoryList?.filter(
    (category) =>
      category.pregnancy === (discussionContext === DiscussionContext.MIDWIFE),
  );

  const currentCategory = useMemo(() => {
    if (!categories) {
      return null;
    }

    let _category = categories?.find(
      (category) => category.id === subject?.categoryId,
    );
    if (!_category && subject?.suggestedCategoryId) {
      _category = categories?.find(
        (category) => category.id === subject?.suggestedCategoryId,
      );
    }
    return _category;
  }, [categories, subject?.categoryId, subject?.suggestedCategoryId]);

  return (
    <Box>
      {currentCategory ? (
        <CategoryChip
          category={currentCategory}
          size="small"
          isValidated={!!subject.categoryId}
          onClick={handleClick}
          {...otherProps}
        />
      ) : (
        <Chip
          label={'N/A'}
          onClick={handleClick}
          color="primary"
          size="small"
          variant="outlined"
          sx={{ marginLeft: '2px', fontSize: '0.75rem' }}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        sx={{ [`& ul`]: { paddingTop: 0, paddingBottom: 0 } }}
      >
        {categories
          ?.filter(Boolean)
          ?.filter(({ active }) => active)
          ?.sort(
            (a, b) =>
              (a.pregnancy
                ? // Midwife - sort by metaCategory, thanks category should be last
                  a.slug === 'thanksmidwife'
                  ? 1
                  : b.slug === 'thanksmidwife'
                    ? -1
                    : (categoryColors[a.metaCategory] ?? 'zzz').localeCompare(
                        categoryColors[b.metaCategory],
                      )
                : // Nurse - thanks category should be last
                  a.slug === 'thanksnurse'
                  ? 1
                  : b.slug === 'thanksnurse'
                    ? -1
                    : 0) ||
              // When same metaCategory, sort by name
              a.name.localeCompare(b.name) ||
              0,
          )
          .map((category) => (
            <MenuItem
              key={category.id}
              onClick={() =>
                changeCategory({ category }, { onSuccess: onCategoryChanged })
              }
              sx={{
                borderLeft: `6px solid ${
                  getCategoryColor(category) ?? 'transparent'
                }`,
              }}
            >
              {category.name}
            </MenuItem>
          ))}
        {!categories?.length && (
          <MenuItem>
            <CircularProgress />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
