import {
  BooleanInput,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { FormProps } from '../../types';

export const CompanyPlanForm = (props: FormProps) => (
  <SimpleForm {...props}>
    <TextInput
      label="Alias"
      source="alias"
      validate={required()}
      variant="standard"
    />
    <TextInput label="Description" source="description" variant="standard" />
    <BooleanInput
      label="Renouvelable"
      source="renewable"
      defaultValue={true}
      variant="standard"
    />
    <BooleanInput
      label="Actif"
      source="active"
      defaultValue={true}
      variant="standard"
    />
    <NumberInput
      label="Nombre max d'usage (-1 pour infini)"
      source="maxUsage"
      defaultValue={-1}
      min={-1}
      step={1}
      variant="standard"
    />
    <ReferenceInput label="Plan" source="plan.id" reference="plans">
      <SelectInput
        label="Plan"
        optionText="name"
        variant="standard"
        validate={required()}
      />
    </ReferenceInput>
  </SimpleForm>
);
