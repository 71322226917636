import { Edit, usePermissions } from 'react-admin';
import { useParams } from 'react-router';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { PatientsForm } from './form';
import { InlineAffiliationsList } from './inlines/affiliations';
import { InlineChildrenList } from './inlines/children';
import { EmailUpdateForm } from './inlines/mailUpdate';
import { PhoneForm } from './inlines/phoneForm';
import { InlinePregnanciesList } from './inlines/pregnancies';
import { InlineSubscriptionsList } from './inlines/subscriptions';
import { Roles } from '../../types/user';

export const PatientsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN);
  if (!isAdmin) {
    return null;
  }

  return (
    <Box
      sx={{
        paddingLeft: '1rem',
        paddingTop: 0,
        paddingRight: '1rem',
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Edit resource="patients" mutationMode="pessimistic">
            <PatientsForm />
          </Edit>
        </Grid>
        <Grid item xs={6}>
          <InlineChildrenList />
          <InlinePregnanciesList />
        </Grid>
        <Grid item xs={6}>
          <PhoneForm id={id} />
        </Grid>
        <Grid item xs={6}>
          <EmailUpdateForm id={id} />
        </Grid>
        <Grid item xs={12}>
          <InlineSubscriptionsList />
          <InlineAffiliationsList />
        </Grid>
      </Grid>
    </Box>
  );
};
