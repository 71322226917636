import { useState } from 'react';
import { Handle, NodeProps, Position, useStore } from 'reactflow';

import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Operator, Question, ScoreMode, ScoringRule } from '@teammay/form-core';

import { COLORS } from '../../../../themes';
import { EditWrapper } from '../EditWrapper';
import { ScoringRulesEdit } from '../scoringRules/ScoringRulesEdit';

export function ScoreHandle({
  id,
  question,
  onClick,
}: {
  id: NodeProps['id'];
  question: Question;
  onClick: (question: Question) => void;
}) {
  const hasScoringRule = useStore((store) =>
    store.edges.find((e) => e.sourceHandle === 'score' && e.source === id),
  );

  const [scoringRule, setRule] = useState<ScoringRule | null>(null);

  return (
    <>
      {hasScoringRule ? (
        <Handle
          type="source"
          position={Position.Left}
          id="score"
          className={'scoreHandleConnected'}
          isConnectable={false}
        />
      ) : (
        <Handle
          type="source"
          position={Position.Left}
          id="score"
          className={'scoreHandleFree'}
          onClick={() => {
            const newRule: ScoringRule = {
              id: self.crypto.randomUUID(),
              questionId: question.id,
              expectedValue: '',
              scoringValue: '',
              operator: Operator.EQUAL,
              mode: ScoreMode.TAG,
            };
            setRule(newRule);
          }}
        >
          <Box>
            <Add
              sx={{
                backgroundColor: 'white',
                borderRadius: '50%',
                width: '1rem',
                height: '1rem',
                border: `1px solid ${COLORS.GREY_LAYOUT}`,
              }}
            />
          </Box>
        </Handle>
      )}
      {scoringRule && (
        <EditWrapper<Question>
          content={{ ...question, scoringRules: [scoringRule] }}
          onSave={(content) => {
            onClick(content);
            setRule(null);
          }}
          open={!!scoringRule}
          onCancel={() => setRule(null)}
          defaultValues={{
            ...question,
            scoringRules: [scoringRule],
          }}
          FormComponent={ScoringRulesEdit}
        />
      )}
    </>
  );
}
