import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import Folder from '@boTypes/folder';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
  Avatar,
  Box,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';

import { COLORS } from '../../themes';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
const IMAGE_HEIGHT = 96;
const TITLE_LINE_NB = 3;
const TITLE_HEIGHT = TITLE_LINE_NB * 24;
const TITLE_PADDING = 2 * 8;
export const HEIGHT = IMAGE_HEIGHT + TITLE_HEIGHT + TITLE_PADDING;

export const FolderItemDialog = ({
  item,
  open,
  close,
}: {
  item: Folder;
  open: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth="md"
    >
      <DialogTitle onClose={close}>{item.title}</DialogTitle>
      <DialogContent>
        <Typography
          variant="subtitle2"
          component="h2"
          color="primary"
          sx={{ py: '1rem' }}
        >
          {translate('library.summary')}
        </Typography>
        <ReactMarkdown>{item.summary}</ReactMarkdown>
        <Divider />
        <Typography
          variant="subtitle2"
          component="h2"
          color="primary"
          sx={{ py: '1rem' }}
        >
          {translate('library.steps')}
        </Typography>
        {item.programSteps?.map((step, index) => (
          <Box sx={{ paddingTop: '0.5rem' }} key={step.id}>
            <Typography variant="subtitle2" component="h3" color="primary">
              {index + 1} - {step.title}
            </Typography>
            <List dense disablePadding>
              {step.content.map((el, ndx) => (
                <ListItem disableGutters key={ndx}>
                  <ListItemAvatar sx={{ minWidth: 32, marginLeft: 1 }}>
                    <Avatar
                      // @ts-ignore
                      src={
                        el.__component === 'step.guide-wrapper'
                          ? el.guides.cover
                          : el.post.coverThumbnailUrl || el.post.cover
                      }
                      sx={{
                        width: 28,
                        height: 28,
                        borderColor: COLORS.GREY_TEXT_LIGHT,
                        borderWidth: 1,
                        borderStyle: 'solid',
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ pl: 1 }}
                    /* @ts-ignore */
                    primary={
                      el.__component === 'step.guide-wrapper'
                        ? `${translate('library.guide.title')} - ${
                            el.guides.title
                          }`
                        : `${translate('library.post')} - ${el.post.title}`
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export const FolderItem = ({
  item,
  onSend,
}: {
  item: Folder;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
}) => {
  const { title, illustration } = item;
  const { formats, url } =
    illustration ||
    ({ url: item?.cover, formats: undefined } as { url: string; formats: any });

  const [open, setOpen] = React.useState(false);
  const onClick = useCallback(() => setOpen(true), [setOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const folderInfo = {
    type: DiscussionEventType.FOLDER,
    content: String(item.entryId || item.id),
  };

  return (
    <CMSCard width={WIDTH} height={HEIGHT}>
      {open ? <FolderItemDialog item={item} open={open} close={close} /> : null}
      <CMSCardActionArea
        height={HEIGHT}
        onClick={onClick}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        {item.sent && <CMSCardSentOverlay />}
        <CardMedia
          component="img"
          alt={title}
          height={String(IMAGE_HEIGHT)}
          image={formats?.thumbnail?.path ?? url}
          title={title}
        />
        <CardContent sx={{ height: TITLE_HEIGHT + TITLE_PADDING, padding: 1 }}>
          <CMSCardTitle
            variant="subtitle2"
            component="h2"
            sx={(theme) => ({
              color: theme.palette.text.primary,
            })}
            clamp={TITLE_LINE_NB}
          >
            {title}
          </CMSCardTitle>
        </CardContent>
        {item.sent && (
          <DownloadDoneIcon
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              bottom: theme.spacing(0),
            })}
          />
        )}
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0 }}>
          <SendIcon
            onClick={() =>
              onSend(folderInfo, DiscussionEventType.FOLDER, item.id)
            }
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};
