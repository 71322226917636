import { useLogout } from 'ra-core';
import React, {
  useCallback,
  useState,
  FunctionComponent,
  ReactElement,
} from 'react';

import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { ListItemIcon, MenuItem } from '@mui/material';
import { MenuItemProps } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

import { ConfirmationDialog } from './components/ConfirmationDialog';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  minWidth: theme.spacing(5),
}));

interface Props {
  className?: string;
  redirectTo?: string;
  icon?: ReactElement;
}

/**
 * Logout button component, to be passed to the Admin component
 *
 * Used for the Logout Menu item in the sidebar
 */
// @ts-ignore
export const LogoutButton: FunctionComponent<
  Props & MenuItemProps<'li', { button?: true }> // HACK: https://github.com/mui-org/material-ui/issues/16245
> = React.forwardRef((props: Props, ref) => {
  const { redirectTo, icon, ...rest } = props;

  const logout = useLogout();
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    logout(undefined, redirectTo ?? '/login');
  }, [logout, redirectTo]);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <StyledMenuItem onClick={toggle} ref={ref} {...rest}>
      <ConfirmationDialog open={open} onClose={handleClick} />
      <ListItemIcon sx={(theme) => ({ minWidth: theme.spacing(5) })}>
        {icon || <ExitIcon />}
      </ListItemIcon>
      <div>Déconnexion</div>
    </StyledMenuItem>
  );
});
