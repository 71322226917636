import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import { Identifier } from 'react-admin';

import { QueryClient, useQuery, useQueryClient } from '@hooks/queryWrappers';

import { useSocketHandlers } from './useSocketHandlers';
import { Discussion, MedicalFollowUp } from '../types';
import { SocketEvents, SocketHandlerGenerator } from '../types/socket';
import { useGateway } from '../utils/gateway';

const queryKey = (appUserId: Identifier) => ['medicalFollowUp', appUserId];
const sortFollowUp = (a: MedicalFollowUp, b: MedicalFollowUp) => {
  if (a.pinned === b.pinned) {
    return b.createdAt.localeCompare(a.createdAt);
  }
  if (a.pinned) {
    return -1;
  }
  return 1;
};

const updateFollowUps = (
  medicalFollowUp: MedicalFollowUp,
  familyId: string,
  previousValues = [] as MedicalFollowUp[],
) => {
  if (
    medicalFollowUp.familyId &&
    `${familyId}` !== `${medicalFollowUp.familyId}`
  ) {
    return previousValues;
  }
  const existing = previousValues.find(
    (m) => `${m.id}` === `${medicalFollowUp.id}`,
  );

  const merged = _merge(existing || {}, medicalFollowUp);

  // create a new reference
  const res = existing
    ? previousValues.concat([])
    : previousValues.concat([merged]);
  res.sort(sortFollowUp);
  return res;
};

const socketMessageHandlerGenerator: SocketHandlerGenerator<
  [QueryClient, string]
> = {
  [SocketEvents.NEW_MEDICAL_FOLLOW_UP]:
    (queryClient: QueryClient, familyId: string) =>
    (medicalFollowUp: MedicalFollowUp) => {
      queryClient.setQueryData<MedicalFollowUp[]>(
        queryKey(familyId),
        (previousValues) =>
          updateFollowUps(medicalFollowUp, familyId, previousValues),
      );
    },
  [SocketEvents.UPDATE_MEDICAL_FOLLOW_UP]:
    (queryClient: QueryClient, familyId: string) =>
    (medicalFollowUp: MedicalFollowUp) => {
      queryClient.setQueryData<MedicalFollowUp[]>(
        queryKey(familyId),
        (previousValues) =>
          updateFollowUps(medicalFollowUp, familyId, previousValues),
      );
    },
  [SocketEvents.DELETED_MEDICAL_FOLLOW_UP]:
    (queryClient: QueryClient, familyId: string) =>
    (medicalFollowUp: MedicalFollowUp) => {
      queryClient.setQueryData<MedicalFollowUp[]>(
        queryKey(familyId),
        (previousValues) =>
          previousValues?.filter((m) => `${medicalFollowUp.id}` !== `${m.id}`),
      );
    },
};

export const useFollowUp = (discussion?: Discussion) => {
  const familyId =
    discussion?.appUsers?.[0] && typeof discussion?.appUsers?.[0] === 'object'
      ? discussion.appUsers[0].familyId
      : discussion?.appUser && typeof discussion.appUser === 'object'
        ? discussion.appUser.familyId
        : undefined;

  const queryClient = useQueryClient();
  const socket = useGateway();

  useSocketHandlers(
    socket,
    socketMessageHandlerGenerator,
    queryClient,
    familyId,
  );
  return useQuery<MedicalFollowUp[]>(
    queryKey(familyId),
    () => ({
      method: 'get',
      url: `/api/medical-follow-ups/all/${familyId}`,
    }),
    {
      enabled: Boolean(familyId),
    },
  );
};
