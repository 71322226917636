import { useState } from 'react';
import {
  SaveButton,
  useRedirect,
  useRefresh,
  useRecordContext,
  RaRecord,
  Identifier,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { MayToolbar } from '@components/mayToolbar';
import { useMutation } from '@hooks/queryWrappers';
import { Box, Button, CircularProgress } from '@mui/material';

import { Consultation } from '../../../types';

export const CustomPostSaveButtons = ({
  record,
  status,
}: {
  record: RaRecord;
  status: string;
}) => {
  const refresh = useRefresh();

  const [isLoading, setLoading] = useState(false);

  const { getValues } = useFormContext<Consultation>();
  const redirect = useRedirect();

  const { mutate: open } = useMutation<any, any, { id: Identifier }>(
    ['consultationOpen'],
    (input) => ({
      method: 'post',
      url: `/api/consultations/open`,
      data: input,
    }),
  );
  const { mutate: cancelMutation } = useMutation<any, any, { id: Identifier }>(
    ['consultationOpen'],
    (input) => ({
      method: 'put',
      url: `/api/consultations/cancel`,
      data: input,
    }),
  );

  const { mutate: closeMutation } = useMutation<
    any,
    any,
    {
      id: Identifier;
      report: string;
      patientEmailContent: string;
    }
  >(['consultationOpen'], (input) => ({
    method: 'put',
    url: `/api/consultations/close`,
    data: input,
  }));

  const reopen = () => {
    setLoading(true);
    open(
      { id: record.id },
      {
        onSuccess: refresh,
        onSettled: () => setLoading(false),
      },
    );
  };

  const cancel = () => {
    setLoading(true);
    cancelMutation(
      { id: record.id },
      {
        onSuccess: refresh,
        onSettled: () => setLoading(false),
      },
    );
  };

  const close = () => {
    setLoading(true);
    const values = getValues();
    const { id, report, patientEmailContent } = values;
    closeMutation(
      { id, report, patientEmailContent },
      {
        onSuccess: () => {
          redirect('/consultations');
          refresh();
        },
        onSettled: () => setLoading(false),
      },
    );
  };

  if (status === 'A traiter') {
    return (
      <Box>
        <Button
          color="error"
          onClick={cancel}
          variant="text"
          disabled={isLoading}
          sx={{ mr: 1 }}
        >
          {isLoading ? <CircularProgress /> : 'Annuler consultation'}
        </Button>
        <Button
          color="primary"
          onClick={close}
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress /> : 'Clôturer'}
        </Button>
      </Box>
    );
  } else {
    return (
      <Button
        color="primary"
        onClick={reopen}
        variant="contained"
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress /> : 'Réouvrir'}
      </Button>
    );
  }
};

export const ConsultationToolbar = () => {
  const record = useRecordContext<Consultation>();

  return (
    <MayToolbar>
      <SaveButton />
      <CustomPostSaveButtons record={record} status={record.status} />
    </MayToolbar>
  );
};
