import { useMemo } from 'react';
import { Title, useTranslate } from 'react-admin';

import { WeeklyTipList } from '@components/WeeklyTip/WeeklyTipList';
import { Box } from '@mui/material';

import { ContentSuggestionPaper } from './contentSuggestionPaper';
import { ContentList, ContentType } from './contentTextList';
import { useContentType, useLibraryList, useLibraryMode } from './hook';
import {
  ContentFilters,
  ContentTypeSelector,
  Header,
  Main,
  ModeSelector,
  SearchField,
  libraryMode,
} from './layout';
import { DailyTipList } from '../../components/DailyTip/DailyTipList';
import { FolderList } from '../../components/Folder/FolderList';
import { GuidesList } from '../../components/Guide/GuidesList';
import { MacroList } from '../../components/Macro/MacroList';
import { MasterclassList } from '../../components/Masterclass/MasterclassList';
import { PostsList } from '../../components/Post/PostsList';
import { LatestContentCard } from '../../pages/dashboard/latestContentCard';
import { DiscussionEventType } from '../../types/discussionEvent';

export const LibraryList = ({
  onSend,
  onCopy,
  onSuggestionSend,
  onSuggestionCopy,
  discussionId,
  subjectId,
  onClose,
}: {
  onSend?: (
    { type, content }: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
  ) => void;
  onCopy?: (val: string, macroId: number, suggestionId: number) => void;
  onSuggestionSend?: (input: {
    type: DiscussionEventType;
    content: string;
  }) => void;
  onSuggestionCopy?: (text: string) => void;
  discussionId?: number;
  subjectId?: number;
  onClose?: () => void;
}) => {
  const translate = useTranslate();

  const { selectedContentType, onTypeChange, setContentType } =
    useContentType();
  const { mode, onModeChange } = useLibraryMode(setContentType);

  const {
    ageLevelsList,
    categoriesList,
    dailyTipsList,
    weeklyTipsList,
    foldersList,
    guidesList,
    isLoading,
    isLoadingFilters,
    itemSuggestionsList,
    macrosList,
    masterclassesList,
    onSearchChange,
    postsList,
    pregnancy,
    pregnancyQuestionsList,
    resetSearch,
    search,
    selectedAgeLevel,
    selectedCategory,
    setAgeLevel,
    setCategory,
    loadNextPage,
    hasNextPage,
    isNextPageLoading,
  } = useLibraryList(discussionId, selectedContentType);

  const hasSearch = search.length > 0 || selectedAgeLevel || selectedCategory;

  const { items } = useMemo(() => {
    const mapping = {
      [ContentType.MACRO]: macrosList,
      [ContentType.GUIDES]: guidesList,
      [ContentType.POSTS]: postsList,
      [ContentType.MASTERCLASSES]: masterclassesList,
      [ContentType.FOLDER]: foldersList,
      [ContentType.DAILY_TIPS]: dailyTipsList,
      [ContentType.PREGNANCY_QUESTIONS]: pregnancyQuestionsList,
      [ContentType.ITEM_SUGGESTIONS]: itemSuggestionsList,
      [ContentType.WEEKLY_TIPS]: weeklyTipsList,
    };
    return {
      items: mapping[selectedContentType],
    };
  }, [
    selectedContentType,
    macrosList,
    guidesList,
    postsList,
    masterclassesList,
    foldersList,
    dailyTipsList,
    pregnancyQuestionsList,
    itemSuggestionsList,
    weeklyTipsList,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      <Title title={translate('library.title')} />
      <Main>
        <Header>
          <SearchField
            search={search}
            onSearchChange={onSearchChange}
            resetSearch={resetSearch}
            isLoading={isLoading}
          />
          <ModeSelector mode={mode} onModeChange={onModeChange} />
        </Header>
        <ContentFilters
          isLoading={isLoadingFilters}
          pregnancy={pregnancy}
          setAgeLevel={setAgeLevel}
          ageLevelsList={ageLevelsList}
          selectedAgeLevel={selectedAgeLevel}
          categoriesList={categoriesList}
          selectedCategory={selectedCategory}
          setCategory={setCategory}
        />

        {mode === libraryMode.SEARCH ? (
          hasSearch ? (
            <Box
              sx={{
                paddingLeft: '1rem',
                height: 'calc(100% - 82px)',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <MacroList list={macrosList} onCopy={onCopy} />
              <GuidesList list={guidesList} onSend={onSend} />
              <PostsList list={postsList} onSend={onSend} />
              <MasterclassList list={masterclassesList} onSend={onSend} />
              <FolderList list={foldersList} />
              <DailyTipList list={dailyTipsList} onSend={onSend} />
              <WeeklyTipList list={weeklyTipsList} onSend={onSend} />
            </Box>
          ) : subjectId && discussionId ? (
            <ContentSuggestionPaper
              subjectId={subjectId}
              discussionId={discussionId}
              onContentSend={onSuggestionSend}
              onCopyContent={onSuggestionCopy}
              onClose={onClose}
            />
          ) : (
            <LatestContentCard withinLibrary />
          )
        ) : (
          <Box
            sx={{
              height: 'calc(100% - 82px)',
            }}
          >
            <ContentTypeSelector
              selectedContentType={selectedContentType}
              onTypeChange={onTypeChange}
            />
            <ContentList
              items={items}
              type={selectedContentType}
              loadNextPage={loadNextPage}
              hasNextPage={hasNextPage}
              isNextPageLoading={isNextPageLoading}
              onSend={
                [
                  ContentType.DAILY_TIPS,
                  ContentType.GUIDES,
                  ContentType.POSTS,
                  ContentType.MASTERCLASSES,
                  ContentType.FOLDER,
                  ContentType.WEEKLY_TIPS,
                ].includes(selectedContentType)
                  ? onSend
                  : undefined
              }
              onCopy={
                selectedContentType === ContentType.MACRO ? onCopy : undefined
              }
            />
          </Box>
        )}
      </Main>
    </Box>
  );
};
