import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native';

import {
  ChatDiscussionEvent,
  DiscussionEventType,
} from '@boTypes/discussionEvent';
import { Box } from '@mui/material';

import { COLORS } from '../../../../themes';
import { Avatar } from '../../../fields/AvatarField';

export type RowProps = {
  children: React.ReactNode;
  message: ChatDiscussionEvent;
} & Omit<ViewProps, 'children' | 'style'>;

const styles = StyleSheet.create({
  userRow: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  systemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  introRow: {
    marginBottom: 8,
  },
  otherRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  emptyView: {
    width: 10,
  },
});

const SystemMessagesTypes = [
  DiscussionEventType.SYSTEM,
  DiscussionEventType.INTRO,
  DiscussionEventType.DATE_SEPARATOR,
];

export const Row = ({ children, message, ...other }: RowProps) => {
  let style: ViewStyle = styles.otherRow;
  if (SystemMessagesTypes.includes(message.type)) {
    if (message.type === DiscussionEventType.SYSTEM) {
      style = {};
    } else if (message.type === DiscussionEventType.INTRO) {
      style = { ...styles.systemRow, ...styles.introRow };
    } else {
      style = styles.systemRow;
    }
  } else if (message.isCurrentUser) {
    style = styles.userRow;
  }

  const isSystem = SystemMessagesTypes.includes(message.type);
  let avatar = <Box sx={{ width: '2.25rem', marginLeft: 1 }} />;
  if (message.isLast && !SystemMessagesTypes.includes(message.type)) {
    const nameSplits = message.authorName.split(' ');
    const user = {
      id: message.authorEmail,
      avatar: message.authorAvatar,
      firstName: nameSplits.shift(),
      lastName: nameSplits.join(' '),
    };
    avatar = (
      <Avatar
        user={user}
        size="custom"
        sx={{
          width: '2.25rem',
          height: '2.25rem',
          fontSize: '1rem',
          alignSelf: 'flex-end',
          marginLeft: 1,
          border: `1px solid ${COLORS.GREY_LAYOUT}`,
          backgroundColor: COLORS.WHITE,
        }}
      />
    );
  }

  return (
    <View
      style={[
        style,
        // eslint-disable-next-line react-native/no-inline-styles
        !isSystem && {
          marginTop: message.isFirst ? 4 : 1,
          marginBottom: message.isLast ? 4 : 1,
        },
      ]}
      {...other}
    >
      {!message.isCurrentUser && !isSystem && avatar}
      {!isSystem && <View style={styles.emptyView} />}
      {children}
    </View>
  );
};
