import { ActivityIndicator, StyleSheet, View } from 'react-native';

import {
  useDailyTipItem,
  useFolderItem,
  useGuideItem,
  useMasterclassItem,
  usePostItem,
  useWeeklyTipItem,
} from '@hooks/cms';
import { useChatFormList } from '@hooks/form-builder';

import {
  FORM_CARD_HEIGHT,
  FormCard,
} from '../../../../entities/form/components/FormCard';
import { COLORS } from '../../../../themes';
import {
  HEIGHT as DT_HEIGHT,
  DailyTipItem,
} from '../../../DailyTip/DailyTipItem';
import {
  HEIGHT as FOLDER_HEIGHT,
  FolderItem,
} from '../../../Folder/FolderItem';
import { HEIGHT as GUIDE_HEIGHT, GuideItem } from '../../../Guide/GuideItem';
import {
  HEIGHT as MC_HEIGHT,
  MasterclassItem,
} from '../../../Masterclass/MasterclassItem';
import { HEIGHT as POST_HEIGHT, PostItem } from '../../../Post/PostItem';
import {
  HEIGHT as WT_HEIGHT,
  WeeklyTipItem,
} from '../../../WeeklyTip/WeeklyTipItem';
const containerStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: COLORS.GREEN['300'],
    height: 96,
    width: 50,
    justifyContent: 'center',
  },
});

const Loader = () => (
  <View style={containerStyles.container}>
    <ActivityIndicator color={COLORS.GREEN['800']} size="large" />
  </View>
);

export const DailyTipMessage = ({ lookup }: { lookup: number | string }) => {
  const { data, isLoading } = useDailyTipItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: DT_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <DailyTipItem item={data} />;
};

export const WeeklyTipMessage = ({ lookup }: { lookup: number | string }) => {
  const { data, isLoading } = useWeeklyTipItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: WT_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <WeeklyTipItem item={data} />;
};

export const PostMessage = ({ lookup }: { lookup: string }) => {
  const { data, isLoading } = usePostItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: POST_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <PostItem item={data} />;
};

export const MasterclassMessage = ({ lookup }: { lookup: number | string }) => {
  const { data, isLoading } = useMasterclassItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: MC_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <MasterclassItem item={data} />;
};

export const GuideMessage = ({ lookup }: { lookup: string }) => {
  const { data, isLoading } = useGuideItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: GUIDE_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <GuideItem item={data} />;
};

export const FolderMessage = ({ lookup }: { lookup: string }) => {
  const { data, isLoading } = useFolderItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: FOLDER_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <FolderItem item={data} />;
};

export const FormMessage = ({ formId }: { formId: string }) => {
  const { data } = useChatFormList({ enabled: false });
  const form = data?.find((f) => f.id === formId);
  return form ? (
    <FormCard {...form} style={{ height: FORM_CARD_HEIGHT }} />
  ) : (
    <View style={{ height: FORM_CARD_HEIGHT }}>
      <Loader />
    </View>
  );
};
