import React, {
  ForwardedRef,
  MutableRefObject,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Identifier, useTranslate } from 'react-admin';
import {
  ActivityIndicator,
  Animated,
  FlatList,
  LayoutChangeEvent,
  ListRenderItem,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
  ViewToken,
} from 'react-native';
import { useDispatch } from 'react-redux';

import {
  ChatDiscussionEvent,
  DiscussionEvent,
  DiscussionEventType,
} from '@boTypes/discussionEvent';

import { SystemLikeTypes } from './constants';
import { Bubble } from './messages/bubble';
import {
  DailyTipMessage,
  FolderMessage,
  FormMessage,
  GuideMessage,
  MasterclassMessage,
  PostMessage,
  WeeklyTipMessage,
} from './messages/cmsMessage';
import { DateMessage } from './messages/dateMessage';
import { ImageMessage } from './messages/imageMessage';
import { IntroMessage } from './messages/introMessage';
import { PartnerMessage } from './messages/partnerMessage';
import { Row, RowProps } from './messages/row';
import { SystemMessage } from './messages/systemMessage';
import { TextMessage } from './messages/textMessage';
import { TypingMessage } from './messages/typingMessage';
import { VideoMessage } from './messages/videoMessage';
import { useSelector } from '../../../store';
import { setTargetEventId } from '../../../store/discussion';
import { COLORS } from '../../../themes';

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  loadMoreContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreText: {
    borderRadius: 8,
    backgroundColor: COLORS.GREY_LAYOUT,
    padding: 8,
  },
  containerView: {
    flex: 1,
    position: 'relative',
  },
});

export type ContextMenuType = React.ComponentType<{
  anchorEl: Element;
  open: boolean;
  onClose: () => void;
  message: ChatDiscussionEvent;
}>;

interface MessageListProps {
  messages: ChatDiscussionEvent[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  hasMore?: boolean;
  onEndReached?: () => void;
  scrollToBottomOffset?: number;
  ScrollToBottom?: React.ComponentType<{
    unseenCount: number;
    flatListRef: RefObject<FlatList>;
  }>;
  ContextMenu?: ContextMenuType;
  discussionId?: Identifier;
  ref: ForwardedRef<FlatList>;
}

const noColorTypes = [
  DiscussionEventType.SYSTEM,
  DiscussionEventType.DATE_SEPARATOR,
];

const messageToColor = (message: ChatDiscussionEvent) => {
  if (noColorTypes.includes(message.type)) {
    return;
  }

  if (message.type === DiscussionEventType.PARTNER) {
    return COLORS.GREEN['500'];
  }

  if (message.type === DiscussionEventType.INTRO) {
    return COLORS.GREEN['300'];
  }

  if (message.isCurrentUser) {
    return COLORS.GREEN['500'];
  }

  if (message.isMay) {
    return COLORS.STAFF_BUBBLE_BACKGROUP;
  } else {
    return COLORS.APP_USER_BUBBLE_BACKGROUP;
  }
};

const Message = ({ message }: { message: ChatDiscussionEvent }) => {
  switch (message.type) {
    case DiscussionEventType.SYSTEM:
      return <SystemMessage message={message} />;
    case DiscussionEventType.DATE_SEPARATOR:
      return <DateMessage message={message} />;
    case DiscussionEventType.INTRO:
      return <IntroMessage message={message} />;
    case DiscussionEventType.TEXT:
      return <TextMessage message={message} />;
    case DiscussionEventType.IMAGE:
      return <ImageMessage message={message} />;
    case DiscussionEventType.VIDEO:
      return <VideoMessage message={message} />;
    case DiscussionEventType.GUIDE:
      return <GuideMessage lookup={message.content} />;
    case DiscussionEventType.POST:
      return <PostMessage lookup={message.content} />;
    case DiscussionEventType.MASTERCLASS:
      return <MasterclassMessage lookup={message.content} />;
    case DiscussionEventType.DAILY_TIP:
      return <DailyTipMessage lookup={message.content} />;
    case DiscussionEventType.WEEKLY_TIP:
      return <WeeklyTipMessage lookup={message.content} />;
    case DiscussionEventType.PARTNER:
      return <PartnerMessage partner={message.content} />;
    case DiscussionEventType.TYPING:
      return <TypingMessage message={message} />;
    case DiscussionEventType.FOLDER:
      return <FolderMessage lookup={message.content} />;
    case DiscussionEventType.FORM:
      return <FormMessage formId={message.formId ?? message.content} />;
    default:
      return <Text>{message.content}</Text>;
  }
};

const withFooterMessages = [
  DiscussionEventType.TEXT,
  DiscussionEventType.IMAGE,
  DiscussionEventType.VIDEO,
  DiscussionEventType.GUIDE,
  DiscussionEventType.POST,
  DiscussionEventType.MASTERCLASS,
  DiscussionEventType.DAILY_TIP,
  DiscussionEventType.PARTNER,
];

interface ChatOptions {
  onLayout?: (event: LayoutChangeEvent, messageId: Identifier) => void;
  setOlderMessageSeen: (timestamp: number) => void;
  ContextMenu?: ContextMenuType;
  targetMessageId?: Identifier;
  highlightedMessageIds?: Identifier[];
}
const MemoMessage = React.memo(
  ({
    item,
    isHighlighted,
    setOlderMessageSeen,
    ContextMenu,
    onLayout,
    ...other
  }: {
    isHighlighted: boolean;
    item: ChatDiscussionEvent;
  } & Omit<ChatOptions, 'targetMessageId' | 'highlightedMessageIds'> &
    Omit<RowProps, 'children' | 'message' | 'onLayout'>) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const colorIndex = useRef(new Animated.Value(0));
    const backgroundColor = colorIndex.current.interpolate({
      inputRange: [0, 1],
      outputRange: ['#FFFFFF', COLORS.GREEN[200]],
    });
    const wasHighlighted = useRef(isHighlighted);

    useEffect(() => {
      if (isHighlighted) {
        wasHighlighted.current = true;
        Animated.spring(colorIndex.current, {
          toValue: 1,
          useNativeDriver: false,
        }).start();
      } else {
        if (!wasHighlighted.current) {
          return;
        }
        wasHighlighted.current = false;
        Animated.sequence([
          Animated.spring(colorIndex.current, {
            toValue: 1,
            useNativeDriver: false,
          }),
          Animated.spring(colorIndex.current, {
            toValue: 0,
            useNativeDriver: false,
          }),
        ]).start();
      }
    }, [isHighlighted]);
    return (
      <Animated.View
        onLayout={(event) => onLayout?.(event, item.id)}
        style={{ backgroundColor }}
      >
        <Row message={item} {...other}>
          <Bubble
            message={item}
            onItemDisplayed={setOlderMessageSeen}
            backgroundColor={messageToColor(item)}
            withFooter={withFooterMessages.includes(item.type)}
          >
            <Pressable
              //@ts-ignore
              // eslint-disable-next-line react-native/no-inline-styles
              style={{ display: 'inherit' }}
              //@ts-ignore
              onLongPress={(event: { currentTarget: Element }) =>
                setAnchorEl(event.currentTarget)
              }
              disabled={SystemLikeTypes.includes(item.type)}
            >
              <Message message={item} />
            </Pressable>
            {Boolean(ContextMenu && anchorEl) && (
              <ContextMenu
                message={item}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              />
            )}
          </Bubble>
        </Row>
      </Animated.View>
    );
  },
);

const renderItem: (arg: ChatOptions) => ListRenderItem<ChatDiscussionEvent> =
  ({ targetMessageId, highlightedMessageIds, ...options }: ChatOptions) =>
  ({ item }) => {
    const isHighlighted = highlightedMessageIds?.includes(item.id);
    return (
      <MemoMessage item={item} {...options} isHighlighted={isHighlighted} />
    );
  };

const FetchMore = ({
  hasMore,
  isLoading,
  onEndReached = () => null,
}: {
  hasMore: boolean;
  isLoading: boolean;
  onEndReached?: () => void;
}) => {
  const translate = useTranslate();
  if (!hasMore) {
    return null;
  }
  if (isLoading) {
    return (
      <View style={styles.loadMoreContainer}>
        <ActivityIndicator />
      </View>
    );
  }
  return (
    <Pressable onPress={onEndReached} style={styles.loadMoreContainer}>
      <Text style={styles.loadMoreText}>{translate('chat.loadPrevious')}</Text>
    </Pressable>
  );
};

const keyExtractor = (item: ChatDiscussionEvent) => {
  return item.id.toString();
};

const viewabilityConfig = {
  viewAreaCoveragePercentThreshold: 95,
};

const handleScrollToTarget = (
  targetMessageId: Identifier,
  messages: DiscussionEvent[],
  messagesHeight: MutableRefObject<Record<Identifier, number>>,
  flatListHeight: number,
  lastScrollOffset: MutableRefObject<number>,
  ref: RefObject<FlatList>,
) => {
  const index = messages.findIndex((message) => message.id === targetMessageId);
  if (index >= 0) {
    const offset = messages
      .slice(0, index)
      .reduce((acc, { id }) => acc + (messagesHeight.current[id] || 0), 0);
    if (offset !== lastScrollOffset.current) {
      lastScrollOffset.current = offset;
      ref.current?.scrollToOffset({
        offset: Math.max(offset - 0.7 * flatListHeight, 0),
        animated: true,
      });
    }
  }
};

const defaultMaxToRenderPerBatch = 10;

const getMessageCountToRender = (
  messages: ChatDiscussionEvent[],
  targetMessageId: ChatDiscussionEvent['id'],
) => {
  if (!targetMessageId || !messages.length) {
    return defaultMaxToRenderPerBatch;
  }
  const index = messages.findIndex((message) => message.id === targetMessageId);
  if (index < 0) {
    return defaultMaxToRenderPerBatch;
  }

  return Math.max(defaultMaxToRenderPerBatch, index + 1);
};

const MessageListInner = (
  {
    messages,
    hasMore = false,
    isLoading = false,
    isLoadingMore = false,
    onEndReached = () => null,
    scrollToBottomOffset = 20,
    ScrollToBottom,
    ContextMenu,
    discussionId,
  }: MessageListProps,
  ref: ForwardedRef<FlatList>,
) => {
  const innerRef = useRef<FlatList>(null);
  useImperativeHandle(ref, () => innerRef.current!, []);
  const earlierMessageSeen = useRef<number>(0);
  const [unseenCount, setUnseenCount] = useState<number>(0);
  const flatListHeight = useRef<number>(0);
  const onLayoutFlatList = useCallback((event: LayoutChangeEvent) => {
    flatListHeight.current = event.nativeEvent.layout.height;
  }, []);

  const targetMessageId = useSelector(
    (state) => state.discussion.targetEventId,
  );
  const highlightedMessageIds = useSelector(
    (state) => state.discussion.highlightedEventIds,
  );
  const targetMessageRef = useRef<Identifier>(targetMessageId);
  targetMessageRef.current = targetMessageId;

  const messagesHeight = useRef<{ [key: string]: number }>({});
  const onLayout = useRef<ChatOptions['onLayout']>((event, id) => {
    messagesHeight.current[id] = event.nativeEvent.layout.height;
  });
  const dispatch = useDispatch();

  const lastViewableItems = useRef<ViewToken[]>([]);

  const onVisibilityChange = useRef(
    ({ viewableItems }: { viewableItems: ViewToken[] }) => {
      lastViewableItems.current = viewableItems;
      if (!targetMessageRef.current) {
        return;
      }
      const viewedItem = viewableItems.find(
        (item) => item.item.id === targetMessageRef.current,
      );
      if (viewedItem?.isViewable) {
        dispatch(
          setTargetEventId({ targetEventId: null, highlightedEventIds: [] }),
        );
      }
    },
  ).current;

  const lastScrollOffset = useRef<number>(0);

  // when targetMessageId is set, we check if it is visible
  useEffect(() => {
    if (targetMessageId) {
      const viewedItem = lastViewableItems.current.find(
        (item) => item.item.id === targetMessageId,
      );
      if (viewedItem?.isViewable) {
        dispatch(
          setTargetEventId({ targetEventId: null, highlightedEventIds: [] }),
        );
      } else {
        handleScrollToTarget(
          targetMessageId,
          messages,
          messagesHeight,
          flatListHeight.current,
          lastScrollOffset,
          innerRef,
        );
      }
    }
  }, [dispatch, messages, scrollToBottomOffset, targetMessageId, innerRef]);

  useEffect(() => {
    if (discussionId) {
      return () => {
        dispatch(
          setTargetEventId({ targetEventId: null, highlightedEventIds: [] }),
        );
      };
    }
  }, [discussionId, dispatch]);

  const setOlderMessageSeen = useCallback(
    (timestamp: number) => {
      earlierMessageSeen.current = Math.max(
        earlierMessageSeen.current,
        timestamp,
      );
      setUnseenCount(
        messages.filter(
          (m) =>
            new Date(m.createdAt).getTime() > earlierMessageSeen.current &&
            m.type !== DiscussionEventType.SYSTEM &&
            m.type !== DiscussionEventType.DATE_SEPARATOR &&
            !m.isCurrentUser,
        ).length,
      );
    },
    [messages],
  );

  const [displayScrollToBottom, setDisplayScrollToBottom] =
    useState<boolean>(false);

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { contentOffset } = event.nativeEvent;
      setDisplayScrollToBottom(contentOffset.y > scrollToBottomOffset);
      handleScrollToTarget(
        targetMessageId,
        messages,
        messagesHeight,
        flatListHeight.current,
        lastScrollOffset,
        innerRef,
      );
    },
    [messages, scrollToBottomOffset, targetMessageId, innerRef],
  );

  const flatListRender = useMemo(
    () =>
      renderItem({
        setOlderMessageSeen,
        ContextMenu,
        onLayout: onLayout.current,
        targetMessageId,
        highlightedMessageIds,
      }),
    [ContextMenu, setOlderMessageSeen, targetMessageId, highlightedMessageIds],
  );

  const handleEndReached = useCallback(() => {
    if (isLoadingMore || !hasMore) {
      return;
    }
    onEndReached();
  }, [isLoadingMore, onEndReached, hasMore]);

  const maxToRenderPerBatch = targetMessageId
    ? getMessageCountToRender(messages, targetMessageId)
    : defaultMaxToRenderPerBatch;

  return (
    <View style={styles.containerView}>
      <FlatList<ChatDiscussionEvent>
        ref={innerRef}
        // @ts-ignore
        dataSet={{ className: 'scrollbar' }}
        keyExtractor={keyExtractor}
        inverted={true}
        data={messages}
        renderItem={flatListRender}
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.3}
        maxToRenderPerBatch={maxToRenderPerBatch}
        contentContainerStyle={styles.contentContainer}
        disableVirtualization={true}
        ListFooterComponent={
          // list is inverted so footer is on top
          <FetchMore
            hasMore={hasMore}
            isLoading={isLoadingMore}
            onEndReached={handleEndReached}
          />
        }
        ListHeaderComponent={
          <FetchMore hasMore={isLoading} isLoading={isLoading} />
        }
        onScroll={onScroll}
        onViewableItemsChanged={onVisibilityChange}
        viewabilityConfig={viewabilityConfig}
        onLayout={onLayoutFlatList}
      />
      {Boolean(displayScrollToBottom && ScrollToBottom) && (
        <ScrollToBottom unseenCount={unseenCount} flatListRef={innerRef} />
      )}
    </View>
  );
};

export const MessageList = forwardRef<FlatList, MessageListProps>(
  MessageListInner,
);
