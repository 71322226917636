import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AttributionFilter } from '../types/discussion';

const attributionFiltersSlice = createSlice({
  name: 'attributionFilters',
  initialState: {
    nurse: AttributionFilter.NONE,
    midwife: AttributionFilter.NONE,
  },
  reducers: {
    setAttributionFilter(
      state,
      action: PayloadAction<{
        filter: AttributionFilter;
        filterType: 'nurse' | 'midwife';
      }>,
    ) {
      if (action.payload.filter === state[action.payload.filterType]) {
        return {
          ...state,
          [action.payload.filterType]: AttributionFilter.NONE,
        };
      }
      return { ...state, [action.payload.filterType]: action.payload.filter };
    },
    forceAttributionFilter(
      state,
      action: PayloadAction<{
        filter: AttributionFilter;
        filterType: 'nurse' | 'midwife';
      }>,
    ) {
      return {
        ...state,
        [action.payload.filterType]: action.payload.filter,
      };
    },
  },
});

export const { setAttributionFilter, forceAttributionFilter } =
  attributionFiltersSlice.actions;

export default attributionFiltersSlice.reducer;
