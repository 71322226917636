import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import { useEffect } from 'react';
import { Admin, Authenticated, CustomRoutes, Resource } from 'react-admin';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import './App.css';

import { useTracking } from './analytics';
import { Login } from './auth';
import { Logout } from './auth/Logout';
import { ResetPassword } from './auth/reset/ResetPassword';
import { FormEdit } from './entities/form/edit';
import { FormList } from './entities/form/list';
import { Library } from './entities/library';
import { PlanningAvailabilitiesEdit } from './entities/planningAvailabilities/edit';
import { PlanningAvailabilitiesList } from './entities/planningAvailabilities/list';
import { Invoices } from './entities/plannings/invoices';
import { PlanningList } from './entities/plannings/list';
import { PlanningResults } from './entities/plannings/results';
import { SlotCreate } from './entities/plannings/slotCreate';
import { StaffUserPlanning } from './entities/plannings/staffUserPlanning';
import { domainMessages } from './i18n';
import MyLayout from './Layout';
import { RedirectAfterLogin, EditProfile, Favorites, Help } from './pages';
import { Admin as AdminArea } from './pages/admin/admin';
import { AdminInvoices } from './pages/adminInvoices';
import { Changelog } from './pages/changelog';
import { CodeAffiliation } from './pages/codeAffiliation';
import { ConsultationPlanning } from './pages/consultationPlanning';
import { Dashboard } from './pages/dashboard';
import { MidwifeSubjects } from './pages/midwifeSubjects';
import { NurseSubjects } from './pages/nurseSubjects';
import { PricingProList } from './pages/pricingProList';
import { EditPassword } from './pages/profile/editPassword';
import { SubjectExplorerScreen } from './pages/subjectExplorer/subjectExplorer';
import { authProvider, dataProvider } from './provider';
import { Resources } from './resources';
import { store, persistor } from './store';
import { mayTheme } from './themes';
import { GatewayProvider } from './utils/gateway';
import { setApiClientLogout } from './utils/httpClients';

const messages = {
  fr: { ...frenchMessages, ...domainMessages.fr },
};
export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  'fr',
);

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
});

const App = () => {
  useTracking();
  useEffect(() => setApiClientLogout(authProvider.logout), []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GatewayProvider>
            <Admin
              disableTelemetry
              theme={mayTheme}
              title="May Pro"
              dataProvider={dataProvider}
              authProvider={authProvider}
              loginPage={Login}
              i18nProvider={i18nProvider}
              layout={MyLayout}
              queryClient={queryClient}
              dashboard={RedirectAfterLogin}
            >
              <CustomRoutes>
                <Route
                  key="midwife-subjects"
                  path="/midwifesubjects"
                  element={
                    <Authenticated requireAuth>
                      <MidwifeSubjects />
                    </Authenticated>
                  }
                >
                  <Route
                    path=":discussionId"
                    element={
                      <Authenticated requireAuth>
                        <MidwifeSubjects />
                      </Authenticated>
                    }
                  />
                </Route>
                <Route
                  key="nurse-subjects"
                  path="/nursesubjects"
                  element={
                    <Authenticated requireAuth>
                      <NurseSubjects />
                    </Authenticated>
                  }
                >
                  <Route
                    path=":discussionId"
                    element={
                      <Authenticated requireAuth>
                        <NurseSubjects />
                      </Authenticated>
                    }
                  />
                </Route>
                <Route
                  key="planning"
                  path="/planning"
                  element={
                    <Authenticated requireAuth>
                      <PlanningList />
                    </Authenticated>
                  }
                />
                <Route
                  key="invoices-admin"
                  path="/admin/invoices"
                  element={
                    <Authenticated requireAuth>
                      <AdminInvoices />
                    </Authenticated>
                  }
                />
                <Route
                  key="staffUserPlanning"
                  path="/staffUserPlanning"
                  element={
                    <Authenticated requireAuth>
                      <StaffUserPlanning />
                    </Authenticated>
                  }
                />
                <Route
                  key="staffUserPlanning"
                  path="/planning/:planningId/invoices"
                  element={
                    <Authenticated requireAuth>
                      <Invoices />
                    </Authenticated>
                  }
                />
                <Route
                  key="planningAvailabilities"
                  path="/planning-availabilities"
                  element={
                    <Authenticated requireAuth>
                      <PlanningAvailabilitiesList />
                    </Authenticated>
                  }
                />
                <Route
                  key="planningAvailabilities"
                  path="/planning-availabilities/:planningId"
                  element={
                    <Authenticated requireAuth>
                      <PlanningAvailabilitiesEdit />
                    </Authenticated>
                  }
                />
                <Route
                  key="planningSlots"
                  path="/planning/slots/:planningId"
                  element={
                    <Authenticated requireAuth>
                      <SlotCreate />
                    </Authenticated>
                  }
                />
                <Route
                  key="planningSlots"
                  path="/planning/agenda/:planningId"
                  element={
                    <Authenticated requireAuth>
                      <PlanningResults />
                    </Authenticated>
                  }
                />
                <Route
                  key="profile"
                  path="/profile"
                  element={
                    <Authenticated requireAuth>
                      <EditProfile />
                    </Authenticated>
                  }
                />
                <Route
                  key="favorites"
                  path="/favorites"
                  element={
                    <Authenticated requireAuth>
                      <Favorites />
                    </Authenticated>
                  }
                />
                <Route
                  key="changelog"
                  path="/changelogs/:id"
                  element={
                    <Authenticated requireAuth>
                      <Changelog />
                    </Authenticated>
                  }
                />
                <Route
                  key="help"
                  path="/help"
                  element={
                    <Authenticated requireAuth>
                      <Help />
                    </Authenticated>
                  }
                />
                <Route
                  key="subjects-explorer"
                  path="/subjects-explorer"
                  element={
                    <Authenticated requireAuth>
                      <SubjectExplorerScreen />
                    </Authenticated>
                  }
                >
                  <Route
                    path=":subjectId/discussion/:discussionId"
                    element={
                      <Authenticated requireAuth>
                        <SubjectExplorerScreen />
                      </Authenticated>
                    }
                  />
                </Route>
                <Route
                  key="dashboard"
                  path="/my-dashboard"
                  element={
                    <Authenticated requireAuth>
                      <Dashboard />
                    </Authenticated>
                  }
                />
                <Route
                  key="edit-password"
                  path="/profile-password"
                  element={
                    <Authenticated requireAuth>
                      <EditPassword />
                    </Authenticated>
                  }
                />
                <Route
                  key="pricing-pro"
                  path="/pricing-pro"
                  element={
                    <Authenticated requireAuth>
                      <PricingProList />
                    </Authenticated>
                  }
                />
                <Route
                  key="code-affiliations"
                  path="/code-affiliations"
                  element={
                    <Authenticated requireAuth>
                      <CodeAffiliation />
                    </Authenticated>
                  }
                />
                <Route
                  key="calendars"
                  path="/calendars"
                  element={
                    <Authenticated requireAuth>
                      <StaffUserPlanning />
                    </Authenticated>
                  }
                />
                <Route
                  path="/discussions"
                  element={
                    <Authenticated requireAuth>
                      <Navigate to="/nursesubjects" />
                    </Authenticated>
                  }
                />
                <Route
                  path="/discussions-midwife"
                  element={
                    <Authenticated requireAuth>
                      <Navigate to="/midwifesubjects" />
                    </Authenticated>
                  }
                />
                <Route
                  path="/library"
                  element={
                    <Authenticated requireAuth>
                      <Library />
                    </Authenticated>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <Authenticated requireAuth>
                      <AdminArea />
                    </Authenticated>
                  }
                />
                <Route
                  path="/consultations-planning"
                  element={
                    <Authenticated requireAuth>
                      <ConsultationPlanning />
                    </Authenticated>
                  }
                />
                <Route
                  key="formList"
                  path="/forms"
                  element={
                    <Authenticated requireAuth>
                      <FormList />
                    </Authenticated>
                  }
                />
                <Route
                  key="formEdition"
                  path="/forms/:slug"
                  element={
                    <Authenticated requireAuth>
                      <FormEdit />
                    </Authenticated>
                  }
                />
              </CustomRoutes>
              <CustomRoutes noLayout>
                <Route
                  key="reset-password"
                  path="/reset-password/:id/:token"
                  element={<ResetPassword />}
                />
                <Route key="logout" path="/logout" element={<Logout />} />
              </CustomRoutes>

              {Resources.map(({ name, icon, crud = {}, options }) => (
                <Resource
                  key={name}
                  name={name}
                  icon={icon}
                  options={options}
                  {...crud}
                />
              ))}
            </Admin>
          </GatewayProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};
export default App;
