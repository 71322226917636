import { LibraryFilterContainer } from '@components/libraryFilterContainer';
import { Chip } from '@mui/material';
import { stringToPastelColor } from '@utils/color';

import { AgeLevel } from '../../types/cms';

export const AgeLevelsList = ({
  list = [],
  selected,
  setSelected,
}: {
  list: AgeLevel[];
  selected: number;
  setSelected: (ageLevel?: number) => void;
}) => {
  return (
    <LibraryFilterContainer>
      {(list || []).map((ageLevel) => (
        <Chip
          key={ageLevel.id}
          variant="outlined"
          size="small"
          label={ageLevel.name}
          color={selected === ageLevel.id ? 'primary' : 'default'}
          sx={{
            backgroundColor: stringToPastelColor(ageLevel.name),
          }}
          onClick={
            selected === ageLevel.id
              ? () => setSelected(null)
              : () => setSelected(ageLevel.id)
          }
        />
      ))}
    </LibraryFilterContainer>
  );
};
