import { useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';

import { useQuery } from './queryWrappers';
import { ConsultationWithRelationIds, Discussion } from '../types';

export const useConsultation = () => {
  const { id } = useParams<'id'>();
  const { data: consultation, isLoading: loadingConsultation } =
    useGetOne<ConsultationWithRelationIds>(
      'consultations',
      {
        id: Number(id),
      },
      { enabled: Boolean(id) },
    );

  const { data: relatedDiscussion, isLoading: loadingDiscussion } =
    useQuery<Discussion>(
      ['childDiscussion', consultation?.childId],
      {
        url: `/api/discussions/fromKid/${consultation?.childId}`,
        method: 'GET',
      },
      { enabled: Boolean(consultation?.childId) },
    );

  return {
    consultation,
    relatedDiscussion,
    loading: loadingConsultation || loadingDiscussion,
  };
};
