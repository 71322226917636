import { MouseEventHandler } from 'react';

import { Close } from '@mui/icons-material';
import { Box, IconButton, SxProps, Typography } from '@mui/material';

export const Title = ({
  title,
  onClose,
  sx,
}: {
  title: string;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        padding: '1rem',
        ...sx,
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: '500', marginRight: '2rem' }}>
        {title}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onClose}
          size="medium"
        >
          <Close />
        </IconButton>
      ) : null}
    </Box>
  );
};
