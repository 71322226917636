import { LibraryFilterContainer } from '@components/libraryFilterContainer';
import { Chip } from '@mui/material';
import { stringToPastelColor } from '@utils/color';

import { Categories } from '../../types/cms';

export const CategoriesList = ({
  list = [],
  selected,
  setSelected,
}: {
  list: Categories[];
  selected: number;
  setSelected: (category?: number) => void;
}) => {
  return (
    <LibraryFilterContainer>
      {(list || []).map((category) => (
        <Chip
          key={category.id}
          variant="outlined"
          size="small"
          label={category.name}
          color={selected === category.id ? 'primary' : 'default'}
          onClick={
            selected === category.id
              ? () => setSelected(null)
              : () => setSelected(category.id)
          }
          sx={{
            backgroundColor: stringToPastelColor(category.name),
          }}
        />
      ))}
    </LibraryFilterContainer>
  );
};
