import {
  BooleanField,
  Datagrid,
  EditButton,
  SearchInput,
  TextField,
} from 'react-admin';

import { CompanyPlanField } from '../../components/fields';
import { List } from '../../components/generic/List';

const CompanyFilters = [<SearchInput source="q" alwaysOn variant="standard" />];

export const CompanyList = (props) => (
  <List
    {...props}
    title="Entreprises"
    sx={{ padding: '1rem', overflow: 'auto' }}
    filters={CompanyFilters}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="Nom" />
      <BooleanField source="active" label="Active" />
      <CompanyPlanField label="Plans actifs" />
      <EditButton />
    </Datagrid>
  </List>
);
