import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

import { Typography } from '@mui/material';

import { User } from '../../types';
import { getAgeComplete } from '../../utils';

interface AgeFieldProps {
  label?: string;
  source?: string;
  locales?: string;
  body?: string;
  variant?: 'body1' | 'body2';
}

interface AgeProps extends AgeFieldProps {
  user: User;
}

export const Age = ({
  user,
  source = 'birthDay',
  locales = 'fr',
  variant = 'body2',
}: AgeProps) => {
  const birthDate = getAgeComplete(get(user, source), locales);

  return (
    <Typography component="span" variant={variant}>
      {birthDate}
    </Typography>
  );
};

export const AgeField = (props: AgeFieldProps) => {
  const record = useRecordContext<User>();
  return <Age user={record} {...props} />;
};
