import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { OptionInput } from '@components/forms/optionInput';
import { Question, QuestionType } from '@teammay/form-core';

export type RadioQuestion = Omit<Question, 'id' | 'createdAt' | 'updatedAt'>;

export const RadioQuestionEdit = ({ onBlur }: { onBlur: () => void }) => {
  const { control } = useFormContext<RadioQuestion>();
  const type = useWatch({ control, name: 'type' });
  const t = useTranslate();
  if (type !== QuestionType.RADIO) {
    return null;
  }
  return (
    <>
      <OptionInput
        control={control}
        name="options"
        label={t('forms.questions.options')}
        onBlur={onBlur}
      />
    </>
  );
};
