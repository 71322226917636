import { useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import { ConfirmDialog } from '@components/ConfirmDialog/ConfirmDialog';
import { ButtonBase, Menu, MenuItem } from '@mui/material';
import { Question, QuestionRule, Template } from '@teammay/form-core';

import { EditWrapper, EditWrapperProps } from './EditWrapper';

export function MenuWrapper<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>({
  children,
  onDelete,
  ...props
}: Omit<EditWrapperProps<Content>, 'open' | 'onSave' | 'onCancel'> & {
  onChange: (content: Content) => void;
  children: React.ReactNode;
  onDelete?: () => void;
}) {
  const translate = useTranslate();
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [menu, setMenu] = useState<{ top: number; left: number } | null>(null);
  return (
    <ButtonBase
      ref={ref}
      onDoubleClick={() => {
        setOpen(true);
        setMenu(null);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        setMenu({ top: e.clientY, left: e.clientX });
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <Menu
        open={!!menu}
        onClose={() => setMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={menu}
      >
        <MenuItem
          onClick={() => {
            setOpen(true);
            setMenu(null);
          }}
        >
          {'Edit'}
        </MenuItem>
        {!!onDelete && (
          <MenuItem
            onClick={() => {
              setOpenDialog(true);
              setMenu(null);
            }}
          >
            {'Delete'}
          </MenuItem>
        )}
      </Menu>

      {children}
      {/* @ts-ignore */}
      <EditWrapper<Content>
        {...props}
        open={open}
        onCancel={(reason) => reason !== 'cancelClicked' && setOpen(false)}
        onSave={(content) => {
          props.onChange(content);
          setOpen(false);
        }}
      />
      <ConfirmDialog
        title={translate('forms.dialogs.delete.title')}
        text={translate('forms.dialogs.delete.content')}
        onClick={() => {
          onDelete();
          setOpenDialog(false);
        }}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
    </ButtonBase>
  );
}
