import classNames from 'classnames';
import addClass from 'dom-helpers/addClass';
import removeClass from 'dom-helpers/removeClass';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import getWidth from 'dom-helpers/width';
import React, { useRef, useEffect, memo } from 'react';
import { Navigate } from 'react-big-calendar';
import { isSelected } from 'react-big-calendar/lib/utils/selection';

// Copy pasted from library code to rewrite the "range" part where we select events.
// Otherwise, we get currentDate - currentDate + length as a range instead of the whole month

const TimeRangeLabel = memo(
  ({ day, event, components, localizer, accessors }: any) => {
    let labelClass = '',
      TimeComponent = components.time,
      label = localizer.messages.allDay;

    let end = accessors.end(event);
    let start = accessors.start(event);

    if (!accessors.allDay(event)) {
      if (localizer.eq(start, end)) {
        label = localizer.format(start, 'agendaTimeFormat');
      } else if (localizer.isSameDate(start, end)) {
        label = localizer.format({ start, end }, 'agendaTimeRangeFormat');
      } else if (localizer.isSameDate(day, start)) {
        label = localizer.format(start, 'agendaTimeFormat');
      } else if (localizer.isSameDate(day, end)) {
        label = localizer.format(end, 'agendaTimeFormat');
      }
    }

    if (localizer.gt(day, start, 'day')) {
      labelClass = 'rbc-continues-prior';
    }
    if (
      localizer.lt(day, end, 'day') &&
      localizer.diff(localizer.endOf(day, 'day'), end, 'minutes')
    ) {
      labelClass += ' rbc-continues-after';
    }

    return (
      <span className={labelClass.trim()}>
        {TimeComponent ? (
          <TimeComponent event={event} day={day} label={label} />
        ) : (
          label
        )}
      </span>
    );
  },
);

const Day = memo(
  ({
    day,
    events,
    dayKey,
    components,
    localizer,
    accessors,
    getters,
    selected,
    onDoubleClickEvent,
    onSelectEvent,
  }: any) => {
    const { event: Event, date: AgendaDate } = components;

    const range = {
      start: localizer.startOf(day, 'day'),
      end: localizer.endOf(day, 'day'),
    };

    events = events.filter((e) =>
      localizer.inEventRange({
        event: {
          start: accessors.start(e),
          end: accessors.end(e),
        },
        range,
      }),
    );

    return events.map((event, idx) => {
      let title = accessors.title(event);
      let end = accessors.end(event);
      let start = accessors.start(event);

      const userProps = getters.eventProp(
        event,
        start,
        end,
        isSelected(event, selected),
      );

      let dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat');
      let first =
        idx === 0 ? (
          <td rowSpan={events.length} className="rbc-agenda-date-cell">
            {AgendaDate ? (
              <AgendaDate day={day} label={dateLabel} />
            ) : (
              dateLabel
            )}
          </td>
        ) : (
          false
        );

      return (
        <tr
          key={dayKey + '_' + idx}
          className={userProps.className}
          style={userProps.style}
        >
          {first}
          <td className="rbc-agenda-time-cell">
            <TimeRangeLabel
              day={day}
              event={event}
              components={components}
              localizer={localizer}
              accessors={accessors}
            />
          </td>
          <td
            className={classNames(
              'rbc-agenda-event-cell',
              isSelected(event, selected) && 'rbc-selected',
            )}
            onClick={(e) => onSelectEvent && onSelectEvent(event, e)}
            onDoubleClick={(e) =>
              onDoubleClickEvent && onDoubleClickEvent(event, e)
            }
          >
            {Event ? <Event event={event} title={title} /> : title}
          </td>
        </tr>
      );
    }, []);
  },
);

function Agenda({
  accessors,
  components,
  date,
  events,
  getters,
  localizer,
  onDoubleClickEvent,
  onSelectEvent,
  selected,
}) {
  const headerRef = useRef(null);
  const dateColRef = useRef(null);
  const timeColRef = useRef(null);
  const contentRef = useRef(null);
  const tbodyRef = useRef(null);

  useEffect(() => {
    _adjustHeader();
  });

  const _adjustHeader = () => {
    if (!tbodyRef.current) {
      return;
    }

    let header = headerRef.current;
    let firstRow = tbodyRef.current.firstChild;

    if (!firstRow) {
      return;
    }

    let isOverflowing =
      contentRef.current.scrollHeight > contentRef.current.clientHeight;

    let _widths = [];
    let widths = _widths;

    _widths = [getWidth(firstRow.children[0]), getWidth(firstRow.children[1])];

    if (widths[0] !== _widths[0] || widths[1] !== _widths[1]) {
      dateColRef.current.style.width = _widths[0] + 'px';
      timeColRef.current.style.width = _widths[1] + 'px';
    }

    if (isOverflowing) {
      addClass(header, 'rbc-header-overflowing');
      header.style.marginRight = scrollbarSize() + 'px';
    } else {
      removeClass(header, 'rbc-header-overflowing');
    }
  };

  let { messages } = localizer;
  const { start, end } = Agenda.range(date, { localizer });

  let range = localizer.range(start, end, 'day');

  events = events.filter((e) =>
    localizer.inEventRange({
      event: {
        start: accessors.start(e),
        end: accessors.end(e),
      },
      range: {
        start,
        end,
      },
    }),
  );

  events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

  return (
    <div className="rbc-agenda-view">
      {events.length !== 0 ? (
        <React.Fragment>
          <table ref={headerRef} className="rbc-agenda-table">
            <thead>
              <tr>
                <th className="rbc-header" ref={dateColRef}>
                  {messages.date}
                </th>
                <th className="rbc-header" ref={timeColRef}>
                  {messages.time}
                </th>
                <th className="rbc-header">{messages.event}</th>
              </tr>
            </thead>
          </table>
          <div className="rbc-agenda-content" ref={contentRef}>
            <table className="rbc-agenda-table">
              <tbody ref={tbodyRef}>
                {range.map((day, idx) => (
                  <Day
                    day={day}
                    accessors={accessors}
                    events={events}
                    dayKey={idx}
                    key={idx}
                    components={components}
                    localizer={localizer}
                    getters={getters}
                    selected={selected}
                    onDoubleClickEvent={onDoubleClickEvent}
                    onSelectEvent={onSelectEvent}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      ) : (
        <span className="rbc-agenda-empty">{messages.noEventsInRange}</span>
      )}
    </div>
  );
}

Agenda.range = (date: Date, { localizer }) => {
  const start = localizer.startOf(date, 'month');
  const end = localizer.endOf(date, 'month');
  return { start, end };
};

Agenda.title = (start: Date, { localizer }) => {
  return localizer.format(start, 'monthHeaderFormat');
};

Agenda.navigate = (date: Date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'month');

    case Navigate.NEXT:
      return localizer.add(date, 1, 'month');

    default:
      return date;
  }
};

export default Agenda;
