import { Fragment } from 'react';
import { Datagrid, List, TextField, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';

import { MayTopToolbar } from '@components/mayTopToolbar';
import { Box, Typography } from '@mui/material';

import { AgeField, FullNameField } from '../../../../components/fields';
import { EditButton } from '../../../../components/generic/EditButton';

const Toolbar = () => (
  <MayTopToolbar>
    <Typography variant="subtitle2">Enfants</Typography>
  </MayTopToolbar>
);

const EmptyList = () => {
  const translate = useTranslate();

  return (
    <Box sx={{ paddingTop: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="body2" sx={{ ml: 0.5 }}>
        {translate('children.empty')}
      </Typography>
    </Box>
  );
};

export const InlineChildrenList = () => {
  const { id } = useParams<'id'>();
  return (
    <List
      resource="children"
      exporter={false}
      pagination={null}
      perPage={50}
      filter={{ appUser: Number(id), active: true }}
      actions={<Toolbar />}
      empty={<EmptyList />}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <FullNameField label="Nom" />
        <AgeField source="birthDay" label="Age" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
