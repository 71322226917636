import { useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';

import { useMutation } from '@hooks/queryWrappers';
import { FormControl, styled } from '@mui/material';

import { TextInput } from '../../components/forms/textInput';
import { Button } from '../../components/generic/Button';

type Password = {
  password?: string;
  passwordConfirmation?: string;
};

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

export const EditPasswordForm = () => {
  const notify = useNotify();

  const { mutateAsync: changePassword } = useMutation<any, any, Password>(
    'change_password',
    (data: Password) => ({
      method: 'PUT',
      url: `/api/auth/change_password`,
      data: {
        password: data.password,
      },
    }),
  );
  const onSubmit = async (data: Password) => {
    try {
      await changePassword(data);
      notify('auth.forms.success.password_updated', { type: 'success' });
    } catch {
      notify('auth.forms.errors.generic_error', { type: 'error' });
    }
  };

  const { handleSubmit, control, watch, formState } = useForm<Password>({
    defaultValues: { password: '', passwordConfirmation: '' },
  });
  const { isSubmitting } = formState;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth>
        <TextInput
          control={control}
          type="password"
          autoFocus
          name="password"
          label="Nouveau mot de passe"
          disabled={isSubmitting}
          rules={{
            required: true,
            minLength: 8,
            pattern: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          }}
          helperText="Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre ou caractère spécial."
          sx={{
            margin: '1rem',
          }}
        />

        <TextInput
          control={control}
          name="passwordConfirmation"
          type="password"
          label="Confirmer le nouveau mot de passe"
          rules={{
            required: true,
            validate: (value) =>
              (value && value === watch('password')) ||
              'Les mots de passe ne sont pas identiques.',
          }}
          disabled={isSubmitting}
          sx={{
            margin: '1rem',
          }}
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          loading={isSubmitting}
          value="Valider"
          style={{ marginTop: '2rem' }}
        />
      </FormControl>
    </StyledForm>
  );
};
