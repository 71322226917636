import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { produce } from 'immer';
import _clone from 'lodash/clone';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import isFinite from 'lodash/isFinite';
import _merge from 'lodash/merge';
import { Identifier } from 'react-admin';

import { HandoverRevive } from '@boTypes/handover';
import { HistoryItem } from '@hooks/history';

import { dayjsTz } from './date';
import { getAgeShort } from './getAge';
import { DiscussionContext } from '../common';
import { Discussion, Handover } from '../types';

const mutateDiscussionRevives = (
  discussion: Discussion,
  revive: HandoverRevive,
) => {
  const reviveIndex =
    discussion.revives?.findIndex((r) => r.id === revive.id) ?? -1;
  if (reviveIndex < 0) {
    if (discussion.revives) {
      discussion.revives.unshift(revive);
    } else {
      discussion.revives = [revive];
    }
  } else {
    discussion.revives[reviveIndex] = _merge(
      discussion.revives[reviveIndex],
      revive,
    );
  }
};

export const removeDiscussionRevives = (
  discussion: Discussion,
  reviveId: HandoverRevive['id'],
) => {
  const handover = discussion.lastSubject?.handovers?.find(
    (h) => String(h.revive?.id) === String(reviveId),
  );
  handover && delete handover.revive;
  const reviveIndex =
    discussion.revives?.findIndex((r) => String(r.id) === String(reviveId)) ??
    -1;
  if (reviveIndex >= 0) {
    discussion.revives.splice(reviveIndex, 1);
  }
};

export const mergeHandoverInDiscussion = produce(
  (discussion: Discussion, handover: Handover) => {
    const previousHandoverIndex =
      discussion?.lastSubject?.handovers?.findIndex(
        (h) => h.id === handover.id,
      ) ?? -1;
    if (previousHandoverIndex < 0) {
      return discussion;
    }

    _merge(discussion.lastSubject.handovers[previousHandoverIndex], handover);
    if (handover.revive) {
      mutateDiscussionRevives(discussion, handover.revive);
    }
  },
);

export const deleteHandoverInDiscussion = produce(
  (discussion: Discussion, handover: Handover) => {
    if (handover.revive?.id) {
      removeDiscussionRevives(discussion, handover.revive?.id);
    }

    const previousHandoverIndex =
      discussion?.lastSubject?.handovers?.findIndex(
        (h) => h.id === handover.id,
      ) ?? -1;
    if (previousHandoverIndex < 0) {
      return;
    }
    discussion.lastSubject.handovers.splice(previousHandoverIndex, 1);
  },
);

export const addHandoverInDiscussion = produce(
  (
    discussion: Discussion,
    handover: Handover,
    subjectId: Identifier,
  ): Discussion => {
    if (
      !discussion ||
      discussion.lastSubject?.id !== subjectId ||
      discussion.lastSubject?.handovers?.some((h) => h.id === handover.id)
    ) {
      return;
    }

    if (discussion.lastSubject) {
      if (discussion.lastSubject.handovers) {
        discussion.lastSubject.handovers.unshift(handover);
      } else {
        discussion.lastSubject.handovers = [handover];
      }
    }

    if (handover.revive) {
      mutateDiscussionRevives(discussion, handover.revive);
    }
  },
);

export const mutateDiscussionWithRevive = (
  discussion: Discussion,
  revive: HandoverRevive,
) => {
  const handover = discussion.lastSubject?.handovers?.find(
    (h) => String(h.id) === String(revive.handoverId),
  );
  if (handover) {
    handover.revive = revive;
  }
  mutateDiscussionRevives(discussion, revive);
};

export const addHandoverReviveInDiscussion = produce(
  (discussion: Discussion, revive: HandoverRevive): Discussion | undefined => {
    if (!discussion) {
      return;
    }

    if (String(discussion.id) !== String(revive.discussionId)) {
      return;
    }

    mutateDiscussionWithRevive(discussion, revive);
  },
);

export const deleteHandoverReviveInDiscussion = produce(
  (
    discussion: Discussion,
    reviveId: HandoverRevive['id'],
  ): Discussion | undefined => {
    if (!discussion) {
      return discussion;
    }
    removeDiscussionRevives(discussion, reviveId);
  },
);

export const getHandoverDate = (
  subject: HistoryItem,
  discussionContext: DiscussionContext,
  referenceDate?: Dayjs,
) => {
  let subjectDateString = '';
  let handoverDateString = '';
  const subjectDate =
    (subject.type !== 'Consultation' &&
      (subject.firstMessageAt ?? subject.end)) ||
    subject.start;

  // calculate age or amenorrhea weeks from subject data
  const now = dayjs(subjectDate);
  if (referenceDate) {
    if (discussionContext === DiscussionContext.MIDWIFE) {
      if (now.isAfter(referenceDate)) {
        subjectDateString = `${Math.floor(now.diff(referenceDate, 'weeks'))}SA`;
      }
    } else {
      subjectDateString = `${getAgeShort(referenceDate.toDate(), subjectDate)}`;
    }
  }

  if (subject.type === 'Consultation') {
    return subjectDateString ? ` (${subjectDateString})` : '';
  }

  // calculate age or amenorrhea weeks from stored handover content
  if (discussionContext === DiscussionContext.MIDWIFE) {
    const handover = subject.handovers?.find((h) => h.amenorrheaWeeks);
    if (handover?.amenorrheaWeeks) {
      handoverDateString = `${handover.amenorrheaWeeks}SA`;
    }
  } else {
    const handover = subject.handovers?.find((h) =>
      isFinite(h.patientAgeInDays),
    );
    if (handover?.patientAgeInDays) {
      const birthDay = new Date(
        dayjsTz(handover.createdAt, 'utc')
          .subtract(handover.patientAgeInDays, 'days')
          .format('YYYY-MM-DD'),
      );
      handoverDateString = getAgeShort(birthDay, subjectDate);
    }
  }

  if (handoverDateString && handoverDateString !== subjectDateString) {
    return ` (${subjectDateString}/${handoverDateString} stocké)`;
  }

  return subjectDateString ? ` (${subjectDateString})` : '';
};
