import { Toolbar, SaveButton, useTranslate } from 'react-admin';

import { Button } from './generic/Button';

const style = {
  justifyContent: 'space-between',
};

export const EditProfileToolbar = (props) => {
  const translate = useTranslate();
  return (
    <Toolbar {...props} style={style}>
      <Button to="/profile-password">{translate('auth.changePassword')}</Button>
      <SaveButton />
    </Toolbar>
  );
};
