import { FlatList } from 'react-native/types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge, IconButton } from '@mui/material';

import { COLORS } from '../../../themes';

export const ScrollToBottom = ({
  unseenCount,
  flatListRef,
}: {
  unseenCount: number;
  flatListRef: React.RefObject<FlatList>;
}) => {
  return (
    <Badge
      sx={{
        position: 'absolute',
        bottom: 20,
        right: 10,
        backgroundColor: '#ffffff99',
        borderRadius: 20,
        border: `1px solid ${COLORS.GREEN['500']}44`,
      }}
      badgeContent={unseenCount}
      color="primary"
    >
      <IconButton
        color="primary"
        //@ts-ignore
        onClick={() => {
          flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
        }}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Badge>
  );
};
