import { useNotify } from 'ra-core';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useMutation } from '@hooks/queryWrappers';
import { Link } from '@mui/material';

import { TextInput } from '../../components/forms/textInput';
import { Button } from '../../components/generic/Button';
import { AuthFormContainer } from '../components/styled';

interface Reset {
  email?: string;
}

interface Props {
  reset: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResetForm = (props: Props) => {
  const notify = useNotify();
  const { mutateAsync: send } = useMutation<any, any, Reset>(
    'send_email',
    (data: Reset) => ({
      method: 'POST',
      url: '/api/auth/send_email',
      data,
    }),
  );
  const sendEmail = async (auth: Reset) => {
    try {
      await send(auth);
      notify('auth.forms.success.email_sent', { type: 'success' });
    } catch {
      notify('auth.forms.errors.invalid_credentials', { type: 'error' });
    }
  };
  const { handleSubmit, control, formState } = useForm();
  const { isSubmitting } = formState;

  return (
    <>
      <form onSubmit={handleSubmit(sendEmail)} noValidate>
        <AuthFormContainer>
          <TextInput
            control={control}
            disabled={isSubmitting}
            name={'email'}
            rules={{ required: true, pattern: /.+@.+\..+/ }}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            style={{ marginTop: '2rem' }}
            loading={isSubmitting}
            value="Envoyer"
          />
        </AuthFormContainer>
      </form>
      <p style={{ textAlign: 'center', margin: '0.25rem' }}>
        <Link
          onClick={() => props.reset(false)}
          style={{
            fontSize: '0.75rem',
            cursor: 'pointer',
          }}
          underline="hover"
        >
          {"Revenir à l'écran de connexion"}
        </Link>
      </p>
    </>
  );
};
