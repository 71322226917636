import { useMutation } from './queryWrappers';

const useTransform = (base: string, fileField: string) => {
  const { mutateAsync: S3Fetch } = useMutation<
    { url: string; Key: string; readUrl: string },
    any,
    { type: string; name: string }
  >(['s3'], (file: { type: string; name: string }) => ({
    method: 'POST',
    url: `/api/${base}/s3-uploader`,
    data: {
      ContentType: file.type,
      filename: file.name,
    },
  }));

  const { mutateAsync: uploadFile } = useMutation<
    any,
    any,
    { url: string; body: File }
  >(['s3 upload'], ({ url, body }) => ({
    method: 'PUT',
    url,
    data: body,
    withCredentials: false,
    headers: {
      'Content-Type': body.type,
    },
  }));

  const transform = async (payload: Record<string, any>) => {
    const _file = payload[fileField];
    if (!_file) {
      return payload;
    }
    const file = _file.rawFile;
    if (!file) {
      return payload;
    }

    const { url, readUrl } = await S3Fetch(file);

    await uploadFile({ url, body: file });
    return {
      ...payload,
      [fileField]: readUrl,
    };
  };

  return { transform };
};

export default useTransform;
