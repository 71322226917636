import dayjs from 'dayjs';

export const getAge = (date: string | Date, reference?: string | Date) => {
  let now = reference ? dayjs(reference) : dayjs();
  let birthdate = dayjs(date);
  const years = now.diff(birthdate, 'years');
  birthdate = birthdate.add(years, 'years');
  const months = now.diff(birthdate, 'months');
  birthdate = birthdate.add(months, 'months');
  const days = now.diff(birthdate, 'days');
  return [
    years && `${years} an${years > 1 ? 's' : ''}`,
    months && years <= 5 && `${months} mois`,
    days && years < 1 && `${days} jour${days > 1 ? 's' : ''}`,
    !years && !months && !days && 'Naissance',
  ]
    .filter(Boolean)
    .join(' ');
};

export const getAgeShort = (date: string | Date, reference?: string | Date) => {
  let now = reference ? dayjs(reference) : dayjs();
  let birthdate = dayjs(date);
  const years = now.diff(birthdate, 'years');
  birthdate = birthdate.add(years, 'years');
  const months = now.diff(birthdate, 'months');
  birthdate = birthdate.add(months, 'months');
  const days = now.diff(birthdate, 'days');
  return [
    years && `${years} an${years > 1 ? 's' : ''}`,
    months && years <= 5 && `${months}m`,
    days && years < 1 && `${days}j`,
    !years && !months && !days && 'Naissance',
  ]
    .filter(Boolean)
    .join(' ');
};

export const getAgeComplete = (date: string | Date, locales = 'fr') => {
  const _birthDate = new Date(date);
  const birthDate = _birthDate.toLocaleDateString(locales);
  const age = getAge(date);
  return `${age} (${birthDate})`;
};

export const isToday = (date: string | Date) => {
  return dayjs(date).isSame(dayjs(), 'day');
};
