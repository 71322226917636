import { BooleanField, Datagrid, EditButton, TextField } from 'react-admin';

import { List } from '../../components/generic/List';

export const CompanyPlanList = (props) => (
  <List {...props} title="Plan d'entreprise" sx={{ padding: '1rem' }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="alias" label="Alias" />
      <TextField source="description" label="Description" />
      <BooleanField source="active" label="Active" />
      <EditButton />
    </Datagrid>
  </List>
);
