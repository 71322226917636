import { Identifier } from 'react-admin';

import { Slot } from './slot';
import { User } from './user';

type PlanningAvailDefinedValue = 0 | 1;

export enum PlanningAvailValue {
  NO = 0,
  YES = 1,
}

export enum PlanningStatus {
  DRAFT = 'draft',
  SLOTS_CREATED = 'slots_created',
  ADMIN_SLOTS_ASSIGNED = 'admin_slots_assigned',
  HEALTH_PRO_ASSIGNED = 'health_pro_assigned',
  CALCULATION_PERFORMED = 'calculation_performed',
  DONE = 'done',
}

export enum PlanningJob {
  NURSE = 'nurse',
  MIDWIFE = 'midwife',
  DOCTOR = 'doctor',
}

export const statusToString = {
  [PlanningStatus.DRAFT]: 'Brouillon',
  [PlanningStatus.SLOTS_CREATED]: 'Template créé',
  [PlanningStatus.ADMIN_SLOTS_ASSIGNED]: 'Saisie dispos',
  [PlanningStatus.HEALTH_PRO_ASSIGNED]: 'Saisie clôturée',
  [PlanningStatus.CALCULATION_PERFORMED]: 'A valider',
  [PlanningStatus.DONE]: 'Validé',
};
export const jobToString = {
  [PlanningJob.NURSE]: 'IPDE',
  [PlanningJob.MIDWIFE]: 'Sage-femme',
  [PlanningJob.DOCTOR]: 'Médecin/Pédiatre',
};

export interface Planning {
  id: string;
  title: string;
  begin: Date | string;
  end: Date | string;
  job: PlanningJob;
  status: PlanningStatus;
  slots: Slot[];
  runningSolver: boolean;
  deadlineAvailabilities: Date | string;
}

export interface UserPlanningAvailabilities {
  slots: Identifier[];
  values: PlanningAvailDefinedValue[];
  staffUserId: number;
}

export interface PlanningCondensedAvailabilities {
  slotId: Identifier;
  yesCount: number;
}

export interface PlanningTracker {
  planningId: Identifier;
  staffUserId: number;
  updatedAt: Date;
}
export interface PlanningDetail extends Planning {
  availabilities: UserPlanningAvailabilities;
  trackers: PlanningTracker[];
}

export interface PlanningAttribution {
  staffUserId: number;
  slotId: Identifier;
  onCallActivated?: boolean;
}

export interface PlanningAttributionSlot extends PlanningAttribution {
  slot: Slot;
}

export interface PlanningAdditionalHours {
  id: number;
  staffUserId: number;
  validatedById?: Identifier | null;
  start: Date | string;
  end: Date | string;
  comment?: string | null;
  planningId?: string;
}

export type PlanningUpdate = {
  title: string;
  begin: Date | string;
  end: Date | string;
  job: PlanningJob;
  deadlineAvailabilities: Date | string;
};

export type PlanningUpdateStatus = {
  status: PlanningStatus;
};

export type PlanningAvailabilitiesCreate = {
  slots: Identifier[];
  values: PlanningAvailDefinedValue[];
};

export interface PlanningPreferences {
  staffUserId: number;
  maximumPerPlanning: number;
  maximumPerWeek?: number;
  maximumNightsPerWeek?: number;
  maximumSlotsOnWeekends?: number;
  minimumSlotsWanted?: number;
  workWholeWeekend?: boolean;
}

export type PlanningCounts = {
  count: number;
  onCall: number;
  weekend: number;
  weekendNb: number;
  nights: number;
  maxNightsPerWeek: number;
  maxCountPerWeek: number;
  nightsPerWeek: Record<number, number>;
  countsPerWeek: Record<number, number>;
  countsWeekend: Record<number, number>;
};

interface InvoiceRow {
  title: string;
  price: number;
  total: number;
  unit: number;
}

export interface UserInvoiceData {
  staffUser: User;
  total: number;
  hoursList: InvoiceRow[];
  extraHoursList: InvoiceRow[];
  consultationList: InvoiceRow[];
  details: {
    slotList?: {
      title: string;
      price: number;
    }[];
    extraHoursList?: {
      title: string;
      price: number;
    }[];
    consultationList?: {
      title: string;
      price: number;
    }[];
  };
  vat: boolean;
}
