import { useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import { EuroSymbol, Work } from '@mui/icons-material';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { useSelector } from '../../store';
import { COLORS } from '../../themes';

export const PricingCard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);

  const isAdmin = roles.includes(Roles.ADMIN);
  if (!isAdmin && !roles?.includes(Roles.AFFILIATIONS_MANAGER)) {
    return null;
  }

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="subtitle1" color="primary" sx={{ paddingBottom: 1 }}>
        {translate('admin.pricing.title')}
      </Typography>
      <List dense>
        {isAdmin && (
          <ListItem disablePadding>
            <ListItemButton disableGutters component="a" href="/#/pricing-pro">
              <ListItemIcon sx={{ minWidth: '2rem' }}>
                <EuroSymbol fontSize="small" sx={{ color: COLORS.GREY_TEXT }} />
              </ListItemIcon>
              <ListItemText primary={translate('admin.pricing.slots')} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          <ListItemButton
            disableGutters
            component="a"
            href="/#/code-affiliations"
          >
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <Work fontSize="small" sx={{ color: COLORS.GREY_TEXT }} />
            </ListItemIcon>
            <ListItemText primary={translate('admin.pricing.affiliations')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
};
