import { useTranslate } from 'ra-core';
import { useState } from 'react';
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { Button } from '@components/generic/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material';
import {
  Operator,
  Question,
  QuestionType,
  ScoreMode,
} from '@teammay/form-core';

import { COLORS } from '../../../../themes';

export type ScoringRulesForm = Question;
const implementedOperators = [Operator.EQUAL, Operator.NOT_EQUAL];

const OneRuleForm = ({
  index,
  onBlur,
}: {
  onBlur: () => void;
  field: FieldArrayWithId<ScoringRulesForm, 'scoringRules', 'id'>;
  index: number;
}) => {
  const translate = useTranslate();
  const { control, setValue } = useFormContext<ScoringRulesForm>();
  const [type, options] = useWatch({ control, name: ['type', 'options'] });
  const [selectedKey, setSelectedKey] = useState<string>(
    options?.[0]?.key ?? '',
  );
  const mode = useWatch({ control, name: `scoringRules.${index}.mode` });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: COLORS.GREY_TEXT_LIGHT,
        p: '2rem',
        borderRadius: '1rem',
        flex: 1,
      }}
    >
      <SelectInput
        variant="outlined"
        control={control}
        name={`scoringRules.${index}.mode`}
        label={'Mode'}
      >
        <MenuItem key={ScoreMode.NUMBER} value={ScoreMode.NUMBER}>
          {translate(`forms.scoreMode.${ScoreMode.NUMBER}`)}
        </MenuItem>
        <MenuItem key={ScoreMode.TAG} value={ScoreMode.TAG}>
          {translate(`forms.scoreMode.${ScoreMode.TAG}`)}
        </MenuItem>
      </SelectInput>
      <SelectInput
        variant="outlined"
        control={control}
        name={`scoringRules.${index}.operator`}
        label={'Mode'}
      >
        {implementedOperators.map((operator) => (
          <MenuItem key={operator} value={operator}>
            {translate(`forms.operator.${operator}`)}
          </MenuItem>
        ))}
      </SelectInput>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextInput
          sx={{ flex: 1 }}
          control={control}
          name={`scoringRules.${index}.expectedValue`}
          label={translate('forms.scoringRule.expectedValue')}
          rules={{ required: true }}
          onBlur={onBlur}
        />
        {type === QuestionType.RADIO && (
          <>
            <IconButton
              onClick={() =>
                setValue(`scoringRules.${index}.expectedValue`, selectedKey)
              }
              sx={{
                marginLeft: 1,
                borderRadius: 1,
                width: 20,
                height: '100%',
                border: '1px solid',
                borderColor: COLORS.GREY,
                mx: 1,
              }}
            >
              {'<'}
            </IconButton>
            <Select
              value={selectedKey ?? options?.[0]?.key ?? ''}
              onChange={(e) => setSelectedKey(e.target.value)}
            >
              {options?.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Box>
      <TextInput
        sx={{ flex: 1 }}
        control={control}
        name={`scoringRules.${index}.scoringValue`}
        label={translate('forms.scoringRule.scoringValue', {
          number_or_string: mode,
        })}
        rules={{ required: true }}
        onBlur={onBlur}
        type={mode === ScoreMode.NUMBER ? 'number' : 'text'}
      />
    </Box>
  );
};

export const ScoringRulesEdit = ({ onBlur }: { onBlur: () => void }) => {
  const { control } = useFormContext<ScoringRulesForm>();
  const translate = useTranslate();

  const [questionId, title] = useWatch({ control, name: ['id', 'title'] });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'scoringRules',
  });

  return (
    <>
      <Typography variant="h5" color="primary" noWrap>
        {translate('forms.scoringRule.formTitle', { question: title })}
      </Typography>
      {fields.map((field, index) => (
        <Box key={field.id} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={() => remove(index)}>
            <DeleteIcon />
          </IconButton>
          <OneRuleForm field={field} index={index} onBlur={onBlur} />
        </Box>
      ))}
      <Button
        onClick={() =>
          append({
            id: self.crypto.randomUUID(),
            questionId,
            expectedValue: '',
            scoringValue: '',
            operator: Operator.EQUAL,
            mode: ScoreMode.TAG,
          })
        }
      >
        {translate('forms.scoringRule.add')}
      </Button>
    </>
  );
};
