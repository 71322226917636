import { Identifier, usePermissions } from 'react-admin';

import { useMutation, useQuery, useQueryClient } from '@hooks/queryWrappers';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';

import { Roles } from '../../../types/user';

const useWhiteListQuery = (id: Identifier) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN) ?? false;

  const queryClient = useQueryClient();

  const { mutate: putOnWhiteList } = useMutation<
    { whitelist: boolean },
    any,
    void
  >(
    ['appUser', id, 'whitelist'],
    () => ({
      method: 'PUT',
      url: `/api/appUsers/${id}/whitelist`,
    }),
    {
      onSuccess(data) {
        queryClient.setQueryData(['appUser', id, 'whitelist'], data);
      },
    },
  );

  const { mutate: putOffWhiteList } = useMutation<
    { whitelist: boolean },
    any,
    void
  >(
    ['appUser', id, 'whitelist'],
    () => ({
      method: 'PUT',
      url: `/api/appUsers/${id}/unWhitelist`,
    }),
    {
      onSuccess(data) {
        queryClient.setQueryData(['appUser', id, 'whitelist'], data);
      },
    },
  );

  const queryRes = useQuery<{ whitelist: boolean }>(
    ['appUser', id, 'whitelist'],
    () => ({
      method: 'GET',
      url: `/api/appUsers/${id}/whitelist`,
    }),
    { enabled: isAdmin },
  );

  return { ...queryRes, putOnWhiteList, putOffWhiteList };
};

export const PhoneForm = ({ id }: { id: Identifier }) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN) ?? false;
  const { data, isLoading, isError, putOffWhiteList, putOnWhiteList } =
    useWhiteListQuery(id);

  if (!isAdmin) {
    return null;
  }
  return (
    <Paper sx={{ p: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PhoneLockedIcon sx={{ mx: 1 }} />
        <Typography sx={{ mx: 1, fontWeight: '500' }}>Twilio</Typography>
        {isLoading && <CircularProgress />}
        {isError && (
          <Typography color="error">{'Impossible de récupérer'}</Typography>
        )}
        {!isLoading && !isError && (
          <>
            {data?.whitelist && (
              <Typography sx={{ mx: 1 }}>Sur whitelist</Typography>
            )}
            {data?.whitelist ? (
              <Button onClick={() => putOffWhiteList()} variant="text">
                Retirer de la whitelist
              </Button>
            ) : (
              <Button onClick={() => putOnWhiteList()} variant="text">
                Mettre sur whitelist
              </Button>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
};
