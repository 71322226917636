import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';
import { useStore } from 'reactflow';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { Box, IconButton, MenuItem, Select } from '@mui/material';
import { Operator, Question, QuestionType } from '@teammay/form-core';

import { type ConditionForm } from './ConditionEdit';
import { COLORS } from '../../../../themes';

export const QuestionRuleEdit = ({
  onBlur,
  field,
  index,
}: {
  onBlur: () => void;
  field: FieldArrayWithId<ConditionForm, 'questionRules', 'id'>;
  index: number;
}) => {
  const translate = useTranslate();
  const { control } = useFormContext<ConditionForm>();
  const questions = useStore((store) =>
    store
      .getNodes()
      .filter((node) => node.type === 'question')
      .map((node) => node.data.question as Question),
  );

  const selectedQuestionId = useWatch({
    control,
    name: `questionRules.${index}.questionId`,
  });
  const selectedQuestion = useStore((store) =>
    selectedQuestionId
      ? (store
          .getNodes()
          .find((node) => node.data?.question?.id === selectedQuestionId)?.data
          ?.question as Question)
      : null,
  );
  const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);
  const { setValue } = useFormContext<ConditionForm>();

  useEffect(() => {
    if (selectedQuestion?.type === QuestionType.RADIO) {
      setSelectedKey(selectedQuestion.options[0]?.key ?? '');
    }
  }, [selectedQuestion]);

  return (
    <Box
      key={field.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: COLORS.GREY_TEXT_LIGHT,
        p: '2rem',
        borderRadius: '1rem',
        flex: 1,
      }}
    >
      <SelectInput
        variant="outlined"
        control={control}
        name={`questionRules.${index}.questionId`}
        label={translate('forms.conditions.questionRules.question')}
        defaultValue={questions[0]?.id ?? ''}
      >
        {questions.map((question) => (
          <MenuItem key={question.id} value={question.id}>
            {question.title}
          </MenuItem>
        ))}
      </SelectInput>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextInput
          sx={{ flex: 1 }}
          control={control}
          name={`questionRules.${index}.expectedValue`}
          label={translate('forms.conditions.questionRules.expectedValue')}
          rules={{ required: true }}
          onBlur={onBlur}
        />
        {selectedQuestion?.type === QuestionType.RADIO && (
          <>
            <IconButton
              onClick={() =>
                setValue(`questionRules.${index}.expectedValue`, selectedKey)
              }
              sx={{
                marginLeft: 1,
                borderRadius: 1,
                width: 20,
                height: '100%',
                border: '1px solid',
                borderColor: COLORS.GREY,
                mx: 1,
              }}
            >
              {'<'}
            </IconButton>
            <Select
              value={selectedKey ?? selectedQuestion.options[0]?.key ?? ''}
              onChange={(e) => setSelectedKey(e.target.value)}
            >
              {selectedQuestion.options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Box>
      <SelectInput
        variant="outlined"
        control={control}
        name={`questionRules.${index}.operator`}
        label={translate('forms.conditions.questionRules.operator')}
        rules={{ required: true }}
        defaultValue={Operator.EQUAL}
      >
        {Object.values(Operator).map((operator) => (
          <MenuItem key={operator} value={operator}>
            {operator}
          </MenuItem>
        ))}
      </SelectInput>
    </Box>
  );
};
