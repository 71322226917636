import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';

import { Autocomplete, FormControl, Paper, TextField } from '@mui/material';

import { useSelector } from '../../store';
import { setTimezone } from '../../store/timezone';

const timezones = Intl.supportedValuesOf('timeZone');

const styles = {
  paper: {
    padding: '1rem',
  },
};

export const TimezoneSelector = () => {
  const translate = useTranslate();
  const tz = useSelector((state) => state.timezone);
  const dispatch = useDispatch();

  return (
    <Paper elevation={0} style={styles.paper}>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          autoHighlight
          id="timezone-autocomplete"
          value={tz}
          options={timezones}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate('timezoneSelector.label')}
              variant="standard"
            />
          )}
          onChange={(_, timezone) => {
            timezone && dispatch(setTimezone({ timezone }));
          }}
        />
      </FormControl>
    </Paper>
  );
};
