import { useTranslate } from 'ra-core';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Tooltip } from '@mui/material';

export const WarningTile = ({ invalidFields }: { invalidFields: string[] }) => {
  const translate = useTranslate();

  if (!invalidFields?.length) {
    return null;
  }
  return (
    <Tooltip
      title={translate('forms.errors.invalid_fields', {
        invalidFields: invalidFields.join(','),
      })}
    >
      <Box
        sx={{
          position: 'absolute',
          top: -16,
          left: -16,
          height: 32,
          width: 32,
          borderRadius: '50%',
          border: '1px solid',
          backgroundColor: 'background.paper',
        }}
      >
        <WarningAmberIcon color="warning" />
      </Box>
    </Tooltip>
  );
};
