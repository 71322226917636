import dayjs from 'dayjs';
import React, { MouseEvent, useState } from 'react';
import { Identifier, useTranslate } from 'react-admin';
import { useLocation } from 'react-router';

import { Discussion } from '@boTypes';
import { MinimalDiscussionEvent } from '@boTypes/discussion';
import { DiscussionEventType } from '@boTypes/discussionEvent';
import { FamilyPopulated } from '@boTypes/family';
import { Filters, Subject } from '@boTypes/subject';
import { JobTitle } from '@boTypes/user';
import { useSubjectFilter } from '@hooks/subjects';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArticleIcon from '@mui/icons-material/Article';
import { Box, Card, Popover, Tooltip, Typography } from '@mui/material';

import { CategoryChipSelect } from './CategoryChipSelect';
import { HandoverCard } from './handoverList';
import { DiscussionContext } from '../../common';
import {
  DiscussionContextProvider,
  useDiscussionContext,
} from '../../hooks/useDiscussionContext';
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams';
import { COLORS } from '../../themes';
import { Avatar } from '../fields/AvatarField';
import { DateTimeAgo } from '../fields/DateTimeAgoField';
import { PatientName } from '../patient/patientName';

interface SubjectCardProps {
  item: Discussion;
  family: FamilyPopulated;
  searchText?: string;
}

const MayUserAvatar = ({ discussion }: { discussion: Discussion }) => {
  const discussionContext = useDiscussionContext();
  const notAttributingJob =
    discussionContext === DiscussionContext.NURSE
      ? JobTitle.MIDWIFE
      : JobTitle.NURSERY_NURSE;

  const hasNurseIntervention = discussion?.lastSubject?.withNurseIntervention;
  let jobTitles: JobTitle[] | undefined;
  if (
    (discussionContext === DiscussionContext.NURSE && hasNurseIntervention) ||
    (discussionContext === DiscussionContext.MIDWIFE && !hasNurseIntervention)
  ) {
    jobTitles = Object.values(JobTitle).filter((j) => j !== notAttributingJob);
  }

  const filter = useSubjectFilter();
  if (filter === Filters.REVIVE) {
    const revive = discussion?.revives?.find((r) => r.attributedStaffUserId);
    return revive ? (
      <LastMaySender userId={revive.attributedStaffUserId} />
    ) : null;
  }

  if (discussion?.lastSubject?.mayAnswered && discussion.lastMaySender) {
    return (
      <LastMaySender userId={discussion.lastMaySender} jobTitles={jobTitles} />
    );
  }
  return null;
};

export const BottomLeftFooter = ({
  discussion,
}: {
  discussion: Discussion;
}) => {
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <MayUserAvatar discussion={discussion} />
      {discussion.lastSubject?.handovers?.length ? (
        <>
          <Typography
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{
              lineHeight: 0,
            }}
          >
            <ArticleIcon
              sx={{
                color: COLORS.TEXT,
                fontSize: '1.25rem',
              }}
            />
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box sx={{ padding: '0.5rem 0.5rem 0.25rem 0.5rem' }}>
              {discussion.lastSubject.handovers.map((handover) => (
                <HandoverCard handover={handover} key={handover.id} />
              ))}
            </Box>
          </Popover>
        </>
      ) : (
        <Tooltip title={t('handover.noneAvailable')}>
          <ArticleIcon
            sx={{ fontSize: '1.125rem', color: COLORS.GREY_TEXT_LIGHT }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export const getLastMessage = (discussion: Discussion) => {
  if (!discussion.lastEvent) {
    return 'Aucun message';
  }
  if (!discussion.lastEvent.active) {
    return '[Message supprimé]';
  }
  switch (discussion.lastEvent.type) {
    case DiscussionEventType.IMAGE:
      return 'Image';
    case DiscussionEventType.VIDEO:
      return 'Vidéo';
    case DiscussionEventType.POST:
      return 'Article';
    case DiscussionEventType.GUIDE:
      return 'Fiche';
    case DiscussionEventType.MASTERCLASS:
      return 'Masterclass';
    case DiscussionEventType.DAILY_TIP:
      return 'Daily tip';
    case DiscussionEventType.PARTNER:
      return 'Livi';
    case DiscussionEventType.FOLDER:
      return 'Dossier';
  }
  return discussion.lastEvent?.content || 'Aucun message';
};

export const subjectTypeExtraStyle = ({
  lastEvent,
  lastSubject,
}: {
  lastEvent?: MinimalDiscussionEvent;
  lastSubject?: Pick<
    Subject,
    | 'empty'
    | 'mayAnswered'
    | 'isPriority'
    | 'withDoctorIntervention'
    | 'withNurseIntervention'
  >;
} = {}) => {
  let borderColor = 'transparent';
  let color = COLORS.TEXT;

  if (lastSubject && !lastSubject.empty) {
    if (!lastSubject.mayAnswered) {
      borderColor = COLORS.PINK['500'];
    } else if (lastSubject.withDoctorIntervention) {
      borderColor = COLORS.BLUE_DARK['300'];
    } else if (lastSubject.withNurseIntervention) {
      borderColor = COLORS.BLUE['300'];
    } else if (lastEvent && !lastEvent.isMay) {
      borderColor = COLORS.GREEN['500'];
    } else if (lastSubject.isPriority) {
      borderColor = COLORS.YELLOW['500'];
    }
  }
  if (lastSubject?.isPriority) {
    color = COLORS.PINK['500'];
  }

  return {
    borderLeft: `4px solid ${borderColor}`,
    color,
  };
};

const dateToColor = (reviveAt: number) => {
  const limit = new Date(reviveAt);
  if (limit.getTime() > Date.now() + 15 * 60 * 1000) {
    return undefined;
  }
  if (limit.getTime() < Date.now() - 15 * 60 * 1000) {
    return COLORS.PINK['500'];
  }
  return COLORS.ORANGE;
};

export const LastMaySender = ({
  userId,
  jobTitles,
}: {
  userId: Identifier;
  jobTitles?: JobTitle[];
}) => {
  // Here we only retrieve data fetched in parent (HandoverList or SubjectList) components hence the "enabled: false"
  const { data } = useGetVaryingMany('users', [userId], { enabled: false });

  if (!data?.length || !data[0]) {
    return null;
  }

  if (jobTitles && !jobTitles.includes(data[0].jobTitle)) {
    return null;
  }

  return <Avatar user={data[0]} size={'tiny'} />;
};

export const SubjectCard = React.memo(({ item, family }: SubjectCardProps) => {
  const navigate = useNavigateWithParams();
  const location = useLocation();
  const basePath = location.pathname.split('/')[1];
  const selected = location.pathname.split('/')[2] === item.id.toString();

  const discussionContextRaw = useDiscussionContext();

  const discussionContext = item?.kidId
    ? DiscussionContext.NURSE
    : DiscussionContext.MIDWIFE;

  const reviveAt =
    item.revives?.length &&
    Math.min(...item.revives.map((r) => new Date(r.reviveAt).getTime()));

  const lastMessage = getLastMessage(item);

  const appUserId =
    typeof item.appUser?.id !== 'undefined'
      ? item.appUser?.id
      : Number(item.appUser);
  const patient = item.kidId
    ? family?.children?.find((k) => String(k.id) === String(item.kidId))
    : family?.appUsers?.find((u) => String(u.id) === String(appUserId));

  return (
    <DiscussionContextProvider discussionContext={discussionContext}>
      <Card
        onClick={() => navigate(`/${basePath}/${item.id}`)}
        sx={{
          p: '8px 4px 0 8px',
          m: '0',
          cursor: 'pointer',
          borderRadius: 0,
          backgroundColor: selected ? COLORS.GREEN['100'] : 'white',
          maxWidth: '100%',
          ...subjectTypeExtraStyle(item),
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <PatientName patient={patient} family={family} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <DateTimeAgo
                date={item.lastEvent?.firstOfUser}
                sx={{ fontSize: 10 }}
              />
              {Boolean(reviveAt) && (
                <Typography
                  sx={{
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  color={dateToColor(reviveAt)}
                >
                  <AccessAlarmIcon
                    sx={{ fontSize: 14, marginBottom: '0.1em' }}
                  />
                  {'le ' + dayjs(reviveAt).format('DD/MM HH:mm')}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '16px',
              paddingBottom: '4px',
              alignItems: 'center',
              '&::after': {
                width:
                  item.lastSubject?.withDoctorIntervention ||
                  item.lastSubject?.withNurseIntervention
                    ? '0.5rem'
                    : 0,
                height:
                  item.lastSubject?.withDoctorIntervention ||
                  item.lastSubject?.withNurseIntervention
                    ? '0.5rem'
                    : 0,
                content: '""',
                display: 'inline-block',
                backgroundColor: item.lastSubject?.withDoctorIntervention
                  ? COLORS.BLUE_DARK['300']
                  : COLORS.BLUE['300'],
                borderColor: item.lastSubject?.withDoctorIntervention
                  ? COLORS.BLUE_DARK['400']
                  : COLORS.BLUE['400'],
                borderRadius: '1rem',
                right: '-2px',
                position: 'relative',
              },
            }}
          >
            <Tooltip title={lastMessage} placement="right">
              <Box
                sx={{
                  flexShrink: 1,
                  width: '100%',
                  height: '1.5rem',
                  alignItems: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: COLORS.GREY_TEXT,
                  whiteSpace: 'nowrap',
                  wordBreak: 'break-word',
                }}
              >
                <Typography
                  component={'span'}
                  sx={{
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    fontWeight: 200,
                    height: '1.25rem',
                    color: item.lastSubject?.isPriority
                      ? COLORS.PINK['500']
                      : COLORS.GREY_TEXT,
                  }}
                >
                  {lastMessage}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: '4px',
            borderBottom: `1px solid #f0f0f0`,
          }}
        >
          <DiscussionContextProvider discussionContext={discussionContextRaw}>
            <BottomLeftFooter discussion={item} />
          </DiscussionContextProvider>
          <CategoryChipSelect subject={item.lastSubject} />
        </Box>
      </Card>
    </DiscussionContextProvider>
  );
});
