const NurseChat = () => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 360 360"
    width={24}
    height={24}
  >
    <path
      fill="currentColor"
      d="M281.5,12.5H77c-35.8,0-64.9,29.1-64.9,64.9V236c0,35.8,29.1,64.9,64.9,64.9h36.1v39.8c0,3.9,2.1,7.5,5.6,9.4
	c1.6,0.9,3.4,1.4,5.2,1.4c2,0,4-0.6,5.7-1.6l77.9-48.7c0.1-0.1,0.3-0.2,0.4-0.3h73.5c35.8,0,64.9-29.1,64.9-64.9V77.4
	C346.3,41.6,317.2,12.5,281.5,12.5z M196.1,282.9l-61.4,38.4v-31.2c0-5.9-4.8-10.8-10.8-10.8H77c-23.9,0-43.3-19.4-43.3-43.3V77.4
	C33.7,53.5,53.1,34,77,34h204.4c23.9,0,43.3,19.4,43.3,43.3V236c0,23.9-19.4,43.3-43.3,43.3h-76.9
	C201.2,279.5,196.1,282.9,196.1,282.9L196.1,282.9z"
    />
    <circle fill="currentColor" cx="163.4" cy="144.4" r="9.3" />
    <circle fill="currentColor" cx="201.3" cy="144.4" r="9.3" />
    <path
      fill="currentColor"
      d="M148.2,170.9h67.9c0,0-9.5,22.9-33.7,22.9S148.2,170.9,148.2,170.9z"
    />
    <path
      fill="currentColor"
      d="M264.7,155.6c0-13.3-8.6-24.9-21-29c-11.3-23.7-35.2-38.9-61.6-38.9s-50.4,15.2-61.6,38.9
		c-12.3,4.1-20.8,15.7-20.8,28.9c0,13.2,8.4,24.5,20.4,28.8c11.1,24.3,35.1,39.9,62.1,39.9s51-15.5,62.1-39.8
		C256.2,180.3,264.7,168.7,264.7,155.6z M237.6,171.5c-2.1,0.5-3.8,1.8-4.8,3.6c-0.3,0.4-0.5,0.8-0.7,1.3c-8.3,20.4-28,33.6-50,33.6
		s-42-13.4-50.2-34.1l0,0c-0.2-0.5-0.4-0.9-0.7-1.3c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.4c0,0,0,0-0.1,0c-0.8-0.7-1.8-1.2-2.9-1.4c0,0-0.1,0-0.1,0
		c0,0,0,0,0,0c-7.5-1.6-12.8-8.3-12.8-15.9c0-7.6,5.5-14.5,13.1-15.9c2.6-0.5,4.6-2.4,5.4-4.8c0.1-0.2,0.2-0.4,0.3-0.6
		c8.6-19.6,28-32.2,49.4-32.2s41.1,12.8,49.6,32.7c0.2,0.4,0.4,0.8,0.7,1.2c1,1.9,2.7,3.4,5,3.8c7.6,1.5,13.1,8.2,13.1,15.9
		S245,170,237.6,171.5L237.6,171.5z"
    />
  </svg>
);

export default NurseChat;
