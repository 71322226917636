import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import { Loader } from '@components/Loader';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';

import { useFutureSlots } from './hooks';
import { ownSlotTitle } from '../../entities/plannings/utils';
import { dayjsTz, getTimeTo } from '../../utils/date';

export const FutureSlotsCard = () => {
  const translate = useTranslate();
  const { slots, isLoading } = useFutureSlots();

  return (
    <Card elevation={2} sx={{ height: '100%' }}>
      <CardContent sx={{ height: 'calc(100% - 3rem)' }}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('slots.toComeTitle')}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ marginBottom: '1rem' }}
        >
          {translate('slots.toComeSubtitle')}
        </Typography>

        {isLoading ? (
          <Loader />
        ) : slots.length > 0 ? (
          slots.map(({ slot }) => (
            <Box
              key={slot.id}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography
                  color="gray"
                  sx={{ marginRight: '0.5rem', minWidth: '5.5rem' }}
                >
                  {dayjsTz(slot.start).format('DD/MM HH:mm')}
                </Typography>
                <Typography>{ownSlotTitle(slot)}</Typography>
              </Box>
              <Typography
                color="gray"
                sx={{ marginRight: '0.5rem' }}
                variant="caption"
              >
                {translate('common.in')} {getTimeTo(slot.start)}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            {translate('slots.empty')}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="text"
          color="primary"
          component={Link}
          to="/staffUserPlanning"
        >
          {translate('planning.goToPlanning')}
        </Button>
      </CardActions>
    </Card>
  );
};
