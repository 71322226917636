import debounce from 'lodash/debounce';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DailyTip } from '@boTypes/dailyTip';
import { DiscussionEventType, LookupDTO } from '@boTypes/discussionEvent';
import { Guide } from '@boTypes/guide';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import { WeeklyTip } from '@boTypes/weeklyTip';
import { useQuery } from '@hooks/queryWrappers';
import { SelectChangeEvent } from '@mui/material';

import { ContentType } from './contentTextList';
import { libraryMode } from './layout';
import {
  logLibraryContentTypeSelected,
  logLibraryFilter,
  logLibraryModeSelect,
  logLibrarySearch,
} from '../../analytics/events';
import { LibraryContext } from '../../common/context';
import {
  usePostsList,
  useGuidesList,
  useMacrosList,
  useMasterclassesList,
  useDailyTipsList,
  useWeeklyTipsList,
  useAgeLevelsList,
  useCategoriesList,
  useFoldersList,
  usePregnancyQuestionsList,
  useItemSuggestionsList,
} from '../../hooks/cms';
import { useSelector } from '../../store';
import { setReduxSearch } from '../../store/librarySearch';
import { useSearchKey } from '../../utils/search';

export const useLibrary = (selectedContent: ContentType | null) => {
  const initialSearch = useSelector(
    (state) => state.librarySearch,
    () => true, // only run on first render
  );
  const [search, setSearch] = useState(initialSearch);
  const dispatch = useDispatch();
  const [searchForCMSCalls, setSearchForCMSCalls] = useState('');
  const [selectedAgeLevel, _setAgeLevel] = useState<number>();
  const [selectedCategory, _setCategory] = useState<number>();
  const _pregnancy = useSelector((state) => state.libraryFilters);

  const setAgeLevel = (ageLevel: number) => {
    logLibraryFilter({ ageLevel: ageLevel, category: selectedCategory });
    _setAgeLevel(ageLevel);
  };

  const setCategory = (category: number) => {
    logLibraryFilter({ ageLevel: selectedAgeLevel, category: category });
    _setCategory(category);
  };

  const setActualSearch = useCallback(
    (_search: string) => {
      logLibrarySearch(_search, selectedAgeLevel, selectedCategory, _pregnancy);
      setSearchForCMSCalls(_search);
    },
    [selectedAgeLevel, selectedCategory, _pregnancy],
  );

  const pregnancy =
    _pregnancy === null ? null : _pregnancy === LibraryContext.PREGNANCY;
  // Debounce needs to be outside useCallback scope to "persist" in its behavior
  const debounceSearch = useMemo(
    () => debounce(setActualSearch, 350),
    [setActualSearch],
  );

  const searchKey = useSearchKey();

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const _search = e.target.value;
      setSearch(_search);
      debounceSearch(_search);
      dispatch(setReduxSearch({ search: _search }));
    },
    [debounceSearch, dispatch],
  );

  const resetSearch = useCallback(() => {
    setSearch('');
    debounceSearch('');
    dispatch(setReduxSearch({ search: '' }));
  }, [debounceSearch, dispatch]);

  useEffect(() => {
    if (initialSearch) {
      debounceSearch(initialSearch);
    }
  }, [debounceSearch, initialSearch]);

  const {
    data: postsList,
    isLoading: isLoadingPosts,
    hasNextPage: postsHasNextPage,
    fetchNextPage: postsFetchNextPage,
    isFetchingNextPage: postsIsFetchingNextPage,
  } = usePostsList(
    searchForCMSCalls,
    selectedContent === ContentType.POSTS,
    selectedAgeLevel,
    selectedCategory,
    pregnancy,
    searchKey,
  );

  const {
    data: macrosList,
    isLoading: isLoadingMacros,
    hasNextPage: macrosHasNextPage,
    fetchNextPage: macrosFetchNextPage,
    isFetchingNextPage: macrosIsFetchingNextPage,
  } = useMacrosList(
    searchForCMSCalls,
    selectedContent === ContentType.MACRO,
    selectedAgeLevel,
    selectedCategory,
    pregnancy,
    searchKey,
  );
  const {
    data: foldersList,
    isLoading: isLoadingFolders,
    hasNextPage: foldersHasNextPage,
    fetchNextPage: foldersFetchNextPage,
    isFetchingNextPage: foldersIsFetchingNextPage,
  } = useFoldersList(
    searchForCMSCalls,
    selectedContent === ContentType.FOLDER,
    selectedAgeLevel,
    selectedCategory,
    searchKey,
  );
  const {
    data: guidesList,
    isLoading: isLoadingGuides,
    hasNextPage: guidesHasNextPage,
    fetchNextPage: guidesFetchNextPage,
    isFetchingNextPage: guidesIsFetchingNextPage,
  } = useGuidesList(
    searchForCMSCalls,
    selectedContent === ContentType.GUIDES,
    selectedAgeLevel,
    selectedCategory,
    pregnancy,
    searchKey,
  );
  const {
    data: masterclassesList,
    isLoading: isLoadingMasterclasses,
    hasNextPage: masterclassesHasNextPage,
    fetchNextPage: masterclassesFetchNextPage,
    isFetchingNextPage: masterclassesIsFetchingNextPage,
  } = useMasterclassesList(
    searchForCMSCalls,
    selectedContent === ContentType.MASTERCLASSES,
    selectedAgeLevel,
    selectedCategory,
    pregnancy,
    searchKey,
  );
  const {
    data: dailyTipsList,
    isLoading: isLoadingDailyTips,
    hasNextPage: dailyTipsHasNextPage,
    fetchNextPage: dailyTipsFetchNextPage,
    isFetchingNextPage: dailyTipsIsFetchingNextPage,
  } = useDailyTipsList(
    searchForCMSCalls,
    selectedContent === ContentType.DAILY_TIPS,
    pregnancy,
    searchKey,
  );
  const {
    data: weeklyTipsList,
    isLoading: isLoadingWeeklyTips,
    hasNextPage: weeklyTipsHasNextPage,
    fetchNextPage: weeklyTipsFetchNextPage,
    isFetchingNextPage: weeklyTipsIsFetchingNextPage,
  } = useWeeklyTipsList(
    searchForCMSCalls,
    selectedContent === ContentType.WEEKLY_TIPS,
    searchKey,
  );
  const {
    data: pregnancyQuestionsList,
    hasNextPage: pregnancyQuestionsHasNextPage,
    fetchNextPage: pregnancyQuestionsFetchNextPage,
    isFetchingNextPage: pregnancyQuestionsIsFetchingNextPage,
  } = usePregnancyQuestionsList(
    searchForCMSCalls,
    selectedContent === ContentType.PREGNANCY_QUESTIONS,
    searchKey,
  );

  const {
    data: itemSuggestionsList,
    hasNextPage: itemSuggestionsHasNextPage,
    fetchNextPage: itemSuggestionsFetchNextPage,
    isFetchingNextPage: itemSuggestionsIsFetchingNextPage,
  } = useItemSuggestionsList(
    searchForCMSCalls,
    selectedContent === ContentType.ITEM_SUGGESTIONS,
    searchKey,
  );

  // search agnostic data
  const { data: ageLevelsList, isLoading: isAgeLevelsLoading } =
    useAgeLevelsList();
  const { data: categoriesList, isLoading: isCategoriesLoading } =
    useCategoriesList(pregnancy);

  const isLoading =
    isLoadingMacros ||
    isLoadingPosts ||
    isLoadingGuides ||
    isLoadingMasterclasses ||
    isLoadingDailyTips ||
    isLoadingFolders ||
    isLoadingWeeklyTips ||
    search !== searchForCMSCalls;

  const isLoadingFilters = isAgeLevelsLoading || isCategoriesLoading;

  return {
    ageLevelsList,
    categoriesList,
    dailyTipsList,
    weeklyTipsList,
    foldersList,
    guidesList,
    isLoading,
    isLoadingFilters,
    itemSuggestionsList,
    macrosList,
    masterclassesList,
    onSearchChange,
    postsList,
    pregnancy,
    pregnancyQuestionsList,
    resetSearch,
    search,
    selectedAgeLevel,
    selectedCategory,
    setAgeLevel,
    setCategory,
    postsFetchNextPage,
    postsHasNextPage,
    postsIsFetchingNextPage,
    guidesFetchNextPage,
    guidesHasNextPage,
    guidesIsFetchingNextPage,
    macrosFetchNextPage,
    macrosHasNextPage,
    macrosIsFetchingNextPage,
    itemSuggestionsFetchNextPage,
    itemSuggestionsHasNextPage,
    itemSuggestionsIsFetchingNextPage,
    foldersFetchNextPage,
    foldersHasNextPage,
    foldersIsFetchingNextPage,
    masterclassesFetchNextPage,
    masterclassesHasNextPage,
    masterclassesIsFetchingNextPage,
    dailyTipsFetchNextPage,
    dailyTipsHasNextPage,
    dailyTipsIsFetchingNextPage,
    weeklyTipsFetchNextPage,
    weeklyTipsHasNextPage,
    weeklyTipsIsFetchingNextPage,
    pregnancyQuestionsFetchNextPage,
    pregnancyQuestionsHasNextPage,
    pregnancyQuestionsIsFetchingNextPage,
  };
};

export const useSentContent = (discussionId: number, open?: boolean) => {
  const { data = [], refetch } = useQuery<LookupDTO[]>(
    ['lookups', discussionId],
    () => ({
      url: `/api/discussion_events/lookups/${discussionId}`,
      method: 'get',
    }),
    {
      enabled: Boolean(discussionId),
    },
  );

  // Used to refetch data when the modal is opened with the suggested content usecase
  useEffect(() => {
    open && refetch();
  }, [open, refetch]);

  return data;
};

export const useLibraryList = (
  discussionId: number,
  selectedContent: ContentType | null,
) => {
  const {
    ageLevelsList,
    categoriesList,
    dailyTipsList: rawDailyTipsList,
    weeklyTipsList: rawWeeklyTipsList,
    foldersList: rawFoldersList,
    guidesList: rawGuideList,
    isLoading,
    isLoadingFilters,
    itemSuggestionsList,
    macrosList,
    masterclassesList: rawMasterclassesList,
    onSearchChange,
    postsList: rawPostList,
    pregnancy,
    pregnancyQuestionsList,
    resetSearch,
    search,
    selectedAgeLevel,
    selectedCategory,
    setAgeLevel,
    setCategory,
    postsFetchNextPage,
    postsHasNextPage,
    postsIsFetchingNextPage,
    guidesFetchNextPage,
    guidesHasNextPage,
    guidesIsFetchingNextPage,
    macrosFetchNextPage,
    macrosHasNextPage,
    macrosIsFetchingNextPage,
    itemSuggestionsFetchNextPage,
    itemSuggestionsHasNextPage,
    itemSuggestionsIsFetchingNextPage,
    foldersFetchNextPage,
    foldersHasNextPage,
    foldersIsFetchingNextPage,
    masterclassesFetchNextPage,
    masterclassesHasNextPage,
    masterclassesIsFetchingNextPage,
    dailyTipsFetchNextPage,
    dailyTipsHasNextPage,
    dailyTipsIsFetchingNextPage,
    weeklyTipsFetchNextPage,
    weeklyTipsHasNextPage,
    weeklyTipsIsFetchingNextPage,
    pregnancyQuestionsFetchNextPage,
    pregnancyQuestionsHasNextPage,
    pregnancyQuestionsIsFetchingNextPage,
  } = useLibrary(selectedContent);

  const sentContent = useSentContent(Number(discussionId));

  const guidesList = useMemo<Guide[]>(() => {
    const lookupToSent = sentContent.reduce((acc, { type, content }) => {
      if (type === DiscussionEventType.GUIDE) {
        acc[content] = true;
      }
      return acc;
    }, {});
    return rawGuideList?.map((guide) => ({
      ...guide,
      sent: !!lookupToSent[guide.slug],
    }));
  }, [rawGuideList, sentContent]);

  const dailyTipsList = useMemo<DailyTip[]>(() => {
    const lookupToSent = sentContent.reduce((acc, { type, content }) => {
      if (type === DiscussionEventType.DAILY_TIP) {
        acc[content] = true;
      }
      return acc;
    }, {});
    return rawDailyTipsList?.map((dt) => ({
      ...dt,
      sent: !!lookupToSent[dt.id],
    }));
  }, [rawDailyTipsList, sentContent]);

  const weeklyTipsList = useMemo<WeeklyTip[]>(() => {
    const lookupToSent = sentContent.reduce((acc, { type, content }) => {
      if (type === DiscussionEventType.WEEKLY_TIP) {
        acc[content] = true;
      }
      return acc;
    }, {});
    return rawWeeklyTipsList?.map((wt) => ({
      ...wt,
      sent: !!lookupToSent[wt.id],
    }));
  }, [rawWeeklyTipsList, sentContent]);

  const postsList = useMemo<Post[]>(() => {
    const lookupToSent = sentContent.reduce((acc, { type, content }) => {
      if (type === DiscussionEventType.POST) {
        acc[content] = true;
      }
      return acc;
    }, {});
    return rawPostList?.map((dt) => ({
      ...dt,
      sent: !!lookupToSent[dt.slug],
    }));
  }, [rawPostList, sentContent]);

  const masterclassesList = useMemo<Masterclass[]>(() => {
    const lookupToSent = sentContent.reduce((acc, { type, content }) => {
      if (type === DiscussionEventType.MASTERCLASS) {
        acc[content] = true;
      }
      return acc;
    }, {});
    return rawMasterclassesList?.map((dt) => ({
      ...dt,
      sent: !!lookupToSent[dt.id],
    }));
  }, [rawMasterclassesList, sentContent]);

  const foldersList = useMemo(() => {
    const lookupToSent = sentContent.reduce((acc, { type, content }) => {
      if (type === DiscussionEventType.FOLDER) {
        acc[content] = true;
      }
      return acc;
    }, {});
    return rawFoldersList?.map((folder) => ({
      ...folder,
      sent: !!lookupToSent[folder.id],
    }));
  }, [rawFoldersList, sentContent]);

  const infiniteQueryMapping = {
    [ContentType.MACRO]: {
      loadNextPage: macrosFetchNextPage,
      hasNextPage: macrosHasNextPage,
      isNextPageLoading: macrosIsFetchingNextPage,
    },
    [ContentType.GUIDES]: {
      loadNextPage: guidesFetchNextPage,
      hasNextPage: guidesHasNextPage,
      isNextPageLoading: guidesIsFetchingNextPage,
    },
    [ContentType.POSTS]: {
      loadNextPage: postsFetchNextPage,
      hasNextPage: postsHasNextPage,
      isNextPageLoading: postsIsFetchingNextPage,
    },
    [ContentType.MASTERCLASSES]: {
      loadNextPage: masterclassesFetchNextPage,
      hasNextPage: masterclassesHasNextPage,
      isNextPageLoading: masterclassesIsFetchingNextPage,
    },
    [ContentType.FOLDER]: {
      loadNextPage: foldersFetchNextPage,
      hasNextPage: foldersHasNextPage,
      isNextPageLoading: foldersIsFetchingNextPage,
    },
    [ContentType.DAILY_TIPS]: {
      loadNextPage: dailyTipsFetchNextPage,
      hasNextPage: dailyTipsHasNextPage,
      isNextPageLoading: dailyTipsIsFetchingNextPage,
    },
    [ContentType.PREGNANCY_QUESTIONS]: {
      loadNextPage: pregnancyQuestionsFetchNextPage,
      hasNextPage: pregnancyQuestionsHasNextPage,
      isNextPageLoading: pregnancyQuestionsIsFetchingNextPage,
    },
    [ContentType.ITEM_SUGGESTIONS]: {
      loadNextPage: itemSuggestionsFetchNextPage,
      hasNextPage: itemSuggestionsHasNextPage,
      isNextPageLoading: itemSuggestionsIsFetchingNextPage,
    },
    [ContentType.WEEKLY_TIPS]: {
      loadNextPage: weeklyTipsFetchNextPage,
      hasNextPage: weeklyTipsHasNextPage,
      isNextPageLoading: weeklyTipsIsFetchingNextPage,
    },
  };

  return {
    ageLevelsList,
    categoriesList,
    dailyTipsList,
    weeklyTipsList,
    foldersList,
    guidesList,
    isLoading,
    isLoadingFilters,
    itemSuggestionsList,
    macrosList,
    masterclassesList,
    onSearchChange,
    postsList,
    pregnancy,
    pregnancyQuestionsList,
    resetSearch,
    search,
    selectedAgeLevel,
    selectedCategory,
    setAgeLevel,
    setCategory,
    ...infiniteQueryMapping[selectedContent],
  };
};

export const useLibraryMode = (
  setContentType: React.Dispatch<React.SetStateAction<ContentType | null>>,
) => {
  const [mode, setMode] = useState<libraryMode>(libraryMode.SEARCH);
  const onModeChange = useCallback(
    (event: SelectChangeEvent) => {
      const _mode = event.target.value as libraryMode;
      logLibraryModeSelect(_mode);
      setMode(_mode);
      setContentType(_mode === libraryMode.SEARCH ? null : ContentType.MACRO);
    },
    [setMode, setContentType],
  );

  return { mode, onModeChange };
};

export const useContentType = () => {
  const [selectedContentType, setContentType] = useState<ContentType | null>();
  const onTypeChange = (event: SelectChangeEvent) => {
    const contentType = event.target.value as ContentType;
    logLibraryContentTypeSelected(contentType);
    setContentType(contentType);
  };

  return { selectedContentType, onTypeChange, setContentType };
};
