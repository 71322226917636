import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DiscussionContext } from '../common';
import { useSelector } from '../store';
import { setAttributionFilter } from '../store/attributionFilters';
import { AttributionFilter } from '../types/discussion';

export const useAttributionFilterState = (
  filterType: DiscussionContext,
): [AttributionFilter, (filter: AttributionFilter) => void] => {
  const quickFilter = useSelector(
    (state) => state.attributionFilters[filterType],
  );
  const dispatch = useDispatch();

  const onFilterClick = useCallback(
    (filter: AttributionFilter) => {
      dispatch(setAttributionFilter({ filter, filterType }));
    },
    [dispatch, filterType],
  );

  return [quickFilter, onFilterClick];
};
