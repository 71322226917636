import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import { DailyTip } from '@boTypes/dailyTip';
import Folder from '@boTypes/folder';
import { Guide } from '@boTypes/guide';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import { DailyTipItem } from '@components/DailyTip/DailyTipItem';
import { FolderItem } from '@components/Folder/FolderItem';
import GuideItem from '@components/Guide/GuideItem';
import { Loader } from '@components/Loader';
import { MacroItem } from '@components/Macro/MacroItem';
import { MasterclassItem } from '@components/Masterclass/MasterclassItem';
import { PostItem } from '@components/Post/PostItem';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';

import { useLatestContent } from './hooks';

const ContentGallery = ({
  content,
}: {
  content: {
    posts: Post[];
    guides: Guide[];
    programs: Folder[];
    dailyTips: DailyTip[];
    masterclasses: Masterclass[];
    macros: Macro[];
  };
}) => {
  const translate = useTranslate();
  const count = Object.values(content).reduce(
    (acc, curr) => acc + curr.length,
    0,
  );

  if (count === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        {translate('latestContent.empty')}
      </Typography>
    );
  }
  return (
    <Box
      sx={{
        marginTop: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '1rem',
        flexWrap: 'wrap',
        rowGap: '0.5rem',
      }}
    >
      {content.posts.map((item) => (
        <PostItem item={item} key={item.id} />
      ))}
      {content.guides.map((item) => (
        <GuideItem item={item} key={item.id} />
      ))}
      {content.programs.map((item) => (
        <FolderItem item={item} key={item.id} />
      ))}
      {content.dailyTips.map((item) => (
        <DailyTipItem item={item} key={item.id} />
      ))}
      {content.masterclasses.map((item) => (
        <MasterclassItem item={item} key={item.id} />
      ))}
      {content.macros.map((item) => (
        <MacroItem item={item} key={item.id} />
      ))}
    </Box>
  );
};

export const LatestContentCard = ({
  withinLibrary = false,
}: {
  withinLibrary?: boolean;
}) => {
  const translate = useTranslate();
  const { data: content, isLoading, isError } = useLatestContent();

  return (
    <Card
      elevation={withinLibrary ? 0 : 2}
      sx={withinLibrary ? { boxShadow: 'none', borderRadius: 0 } : undefined}
    >
      <CardContent>
        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('latestContent.title')}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ marginBottom: '0.5rem' }}
        >
          {translate('latestContent.subtitle')}
        </Typography>
        {isError ? (
          <Typography color="error" sx={{ marginBottom: '0.5rem' }}>
            {translate('latestContent.loadError')}
          </Typography>
        ) : isLoading ? (
          <Loader />
        ) : (
          <ContentGallery content={content} />
        )}
      </CardContent>
      {!withinLibrary && (
        <CardActions>
          <Button variant="text" color="primary" component={Link} to="/library">
            {translate('latestContent.goToLibrary')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
