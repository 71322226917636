import uniq from 'lodash/uniq';

import { getWeek, isNight, isWeekend } from './date';
import { User } from '../types';
import {
  Planning,
  PlanningAttribution,
  PlanningCounts,
  UserPlanningAvailabilities,
} from '../types/planning';
import { Slot } from '../types/slot';
import { titleToPlanningJob } from '../types/user';

const isWeekendSlot = (slot: Slot) => {
  return isWeekend(slot.start);
};

const isNightSlot = (slot: Slot) => {
  return isNight(slot.end);
};

export const getAttributionsCount = (
  planningAttributions: PlanningAttribution[],
  slotsById: Record<number, Slot>,
) => {
  return planningAttributions.reduce(
    (acc, attribution) => {
      if (!acc[attribution.staffUserId]) {
        acc[attribution.staffUserId] = {
          count: 0,
          weekend: 0,
          weekendNb: 0,
          onCall: 0,
          nights: 0,
          maxNightsPerWeek: 0,
          maxCountPerWeek: 0,
          nightsPerWeek: {},
          countsPerWeek: {},
          countsWeekend: {},
        };
      }
      acc[attribution.staffUserId].count += 1;

      const week = getWeek(slotsById[attribution.slotId].start);

      if (slotsById[attribution.slotId]?.onCall) {
        acc[attribution.staffUserId].onCall += 1;
      }
      if (isWeekendSlot(slotsById[attribution.slotId])) {
        acc[attribution.staffUserId].weekend += 1;
        if (!acc[attribution.staffUserId].countsPerWeek[week]) {
          acc[attribution.staffUserId].countsPerWeek[week] = 0;
        }
        if (!acc[attribution.staffUserId].countsWeekend[week]) {
          acc[attribution.staffUserId].countsWeekend[week] = 0;
        }
        acc[attribution.staffUserId].countsWeekend[week] += 1;
        acc[attribution.staffUserId].weekendNb = Object.keys(
          acc[attribution.staffUserId].countsWeekend,
        ).length;
      } else {
        if (!acc[attribution.staffUserId].countsPerWeek[week]) {
          acc[attribution.staffUserId].countsPerWeek[week] = 0;
        }
        acc[attribution.staffUserId].countsPerWeek[week] += 1;
        acc[attribution.staffUserId].maxCountPerWeek = Math.max(
          acc[attribution.staffUserId].countsPerWeek[week],
          acc[attribution.staffUserId].maxCountPerWeek,
        );
      }
      if (isNightSlot(slotsById[attribution.slotId])) {
        acc[attribution.staffUserId].nights += 1;
        if (!acc[attribution.staffUserId].nightsPerWeek[week]) {
          acc[attribution.staffUserId].nightsPerWeek[week] = 0;
        }
        acc[attribution.staffUserId].nightsPerWeek[week] += 1;
        acc[attribution.staffUserId].maxNightsPerWeek = Math.max(
          acc[attribution.staffUserId].nightsPerWeek[week],
          acc[attribution.staffUserId].maxNightsPerWeek,
        );
      }
      return acc;
    },
    {} as Record<number, PlanningCounts>,
  );
};

export const implicatedUsers = ({
  planning,
  staffUsers = [],
  availabilities = [],
  attributions = [],
}: {
  planning: Pick<Planning, 'job'>;
  staffUsers?: User[];
  availabilities?: Pick<UserPlanningAvailabilities, 'staffUserId'>[];
  attributions?: Pick<PlanningAttribution, 'staffUserId'>[];
}): number[] => {
  return uniq(
    staffUsers
      .filter((user) => titleToPlanningJob[user.jobTitle] === planning?.job)
      .sort(
        // sort by name
        (
          { firstName: afn, lastName: aln },
          { firstName: bfn, lastName: bln },
        ) => afn.localeCompare(bfn) || aln.localeCompare(bln),
      )
      .map((user) => user.id)
      .concat(availabilities.map((v) => Number(v.staffUserId)))
      .concat(attributions.map((a) => Number(a.staffUserId))),
  );
};
