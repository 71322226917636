import { Fragment } from 'react';
import {
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  List,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { MayTopToolbar } from '@components/mayTopToolbar';
import { Box, Typography } from '@mui/material';

import { EditButton } from '../../../../components/generic/EditButton';

const Toolbar = () => (
  <MayTopToolbar>
    <Typography variant="subtitle2">Grossesses</Typography>
  </MayTopToolbar>
);

const EmptyList = () => {
  const translate = useTranslate();
  return (
    <Box sx={{ paddingTop: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="body2" sx={{ ml: 0.5 }}>
        {translate('pregnancies.empty')}
      </Typography>
    </Box>
  );
};

export const InlinePregnanciesList = () => {
  const { id } = useParams<'id'>();
  return (
    <List
      resource="pregnancies"
      exporter={false}
      pagination={null}
      perPage={50}
      filter={{ appUser: id, active: true }}
      actions={<Toolbar />}
      empty={<EmptyList />}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <DateField locales="fr-FR" source="expectedEnd" label="Age" />
        <BooleanField source="active" label="✔️" />
        <BooleanField source="multiple" label="Mult." />
        <BooleanField source="interrupted" label="Interrompue" />
        <BooleanField source="born" label="Né·e" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
