import { useCallback } from 'react';
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  ReferenceField,
  ReferenceInput,
  SingleFieldList,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { Discussion } from '@boTypes';
import { useMutation } from '@hooks/queryWrappers';
import { Search } from '@mui/icons-material';
import { Button } from '@mui/material';

import { AgeField, FullNameField } from '../../components/fields';
import { List } from '../../components/generic/List';
import { COLORS } from '../../themes';

const ChildrenFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Filtrer par nom"
      autoFocus
      InputProps={{
        startAdornment: <Search sx={{ color: COLORS.GREY_TEXT }} />,
      }}
      placeholder="Arthie"
      source="firstName"
      alwaysOn
      variant="standard"
    />
    <ReferenceInput source="appUser" reference="appUsers" alwaysOn>
      <AutocompleteInput
        label="Parent"
        variant="standard"
        optionText={(record) =>
          record?.name ? `${record.name} (${record.email})` : '---'
        }
      />
    </ReferenceInput>
  </Filter>
);

const GoToDiscussionField = () => {
  const { id } = useRecordContext();
  const navigate = useNavigate();
  const { mutateAsync: getDiscussion } = useMutation<Discussion, any, void>(
    ['kidDiscussion', id],
    () => ({
      url: `/api/discussions/fromKid/${id}`,
      method: 'GET',
    }),
  );

  const goToDiscussionPage = useCallback(async () => {
    if (!id) {
      return;
    }
    const discussion = await getDiscussion();
    navigate(`/nursesubjects/${discussion.id}`);
  }, [id, getDiscussion, navigate]);

  return (
    <Button size="small" onClick={goToDiscussionPage}>
      Voir la discussion
    </Button>
  );
};

export const ChildrenList = () => (
  <List
    hasCreate={false}
    filters={<ChildrenFilter />}
    sx={{ overflow: 'auto', minHeight: '100%', padding: '0 1rem' }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <FullNameField label="Nom" />
      <AgeField source="birthDay" label="Age" />
      <ReferenceField label="Parent" source="family.id" reference="family">
        <ArrayField source="appUsers">
          <SingleFieldList>
            <TextField source="firstName" sx={{ marginRight: '0.5rem' }} />
          </SingleFieldList>
        </ArrayField>
      </ReferenceField>
      <BooleanField source="active" label="Actif" />
      <GoToDiscussionField />
      <EditButton />
    </Datagrid>
  </List>
);
