import { memoize } from 'lodash';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';

import { ChatDiscussionEvent, DiscussionEvent } from '@boTypes/discussionEvent';
import { SubjectExplorer } from '@boTypes/subject';
import { enrichAndTransformMessageList } from '@hooks/discussionEvents';
import { useInfiniteQuery, useQuery } from '@hooks/queryWrappers';
import { useDebouncedCallback } from '@hooks/useDebouncedCallback';

import { SearchExplorerInputs } from './subjectExplorer';
import { useSelector } from '../../store';

export const useSubjects = ({ filter }) => {
  const [debouncedFilters, setDebouncedFilters] =
    useState<SearchExplorerInputs>(filter);

  const { data, ...rest } = useInfiniteQuery<
    SubjectExplorer[],
    any,
    { data: SubjectExplorer[] }
  >(
    ['subjects-explorer-list', debouncedFilters],
    ({ pageParam = 0 }) => ({
      method: 'GET',
      url: '/api/subjects-explorer/search',
      params: { ...debouncedFilters, pageSize: 20, pageStart: pageParam },
    }),
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = lastPage?.data?.length;
        if (nextPage === 0) {
          return undefined;
        }
        return pages.length * 20;
      },
    },
  );

  const debounceSetFilters = useDebouncedCallback(setDebouncedFilters, 300);

  useEffect(() => {
    debounceSetFilters(filter);
  }, [debounceSetFilters, filter]);

  return { data: data?.pages?.flatMap((page) => page.data) ?? [], ...rest };
};

export const useSubjectMessages = (subject?: SubjectExplorer) => {
  const email = useSelector((state) => state.user.email);

  const discussionId = subject?.discussionId ?? subject?.discussion?.id;
  const selectFn = useMemo(
    () =>
      memoize((data: DiscussionEvent[]) => {
        return data ? enrichAndTransformMessageList(data, email) : [];
      }),
    [email],
  );

  return useQuery<DiscussionEvent[], any, ChatDiscussionEvent[]>(
    ['subjectMessages', subject?.id],
    {
      method: 'GET',
      url: `/api/discussion_events/${discussionId}/subject`,
      params: {
        subjectId: subject?.id,
      },
    },
    {
      enabled: !!subject && !!discussionId,
      select: selectFn,
    },
  );
};

export const useSubject = (subjectId?: number) => {
  return useQuery<SubjectExplorer, any, SubjectExplorer>(
    ['subject', subjectId],
    {
      method: 'GET',
      url: `/api/subjects-explorer/${subjectId}`,
    },
    {
      enabled: !!subjectId,
    },
  );
};
