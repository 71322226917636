import { useFormContext, useWatch } from 'react-hook-form';

import { Question, QuestionType } from '@teammay/form-core';

import { CommonQuestionEdit } from './CommonQuestionEdit';
import { RadioQuestionEdit } from './RadioQuestionEdit';
import { SelectQuestionEdit } from './SelectQuestionEdit';
import { TextQuestionEdit } from './TextQuestionEdit';

export type QuestionForm = Omit<Question, 'id' | 'createdAt' | 'updatedAt'>;

export const QuestionEdit = ({ onBlur }: { onBlur: () => void }) => {
  const { control } = useFormContext<QuestionForm>();
  const type = useWatch({ control, name: 'type' });
  return (
    <>
      <CommonQuestionEdit control={control} onBlur={onBlur} />
      {type === QuestionType.RADIO && <RadioQuestionEdit onBlur={onBlur} />}
      {type === QuestionType.SELECT && <SelectQuestionEdit onBlur={onBlur} />}
      {type === QuestionType.TEXT && <TextQuestionEdit onBlur={onBlur} />}
    </>
  );
};
