import { MouseEvent } from 'react';
import { useRecordContext } from 'react-admin';

import { Chip, ChipProps } from '@mui/material';

import { COLORS } from '../../themes';
import { Category } from '../../types';

type ChipCategory = Pick<Category, 'pregnancy' | 'slug' | 'name'> & {
  metaCategory?: Category['metaCategory'];
};

export const categoryColors: Record<string, string> = {
  'blue-light': COLORS.BLUE['300'],
  'blue-dark': COLORS.BLUE['500'],
  'green-light': COLORS.GREEN['300'],
  'green-dark': COLORS.GREEN['400'],
  'yellow-light': COLORS.YELLOW['100'],
  'yellow-dark': COLORS.YELLOW['300'],
  'red-light': COLORS.PINK['100'],
  'red-dark': COLORS.PINK['200'],
  default: COLORS.GREY,
};

interface CategoriesChipFieldProps {
  label?: string;
  size?: 'small' | 'medium';
}

export const getCategoryColor = (category: ChipCategory) =>
  categoryColors[category.metaCategory || 'default'];

const getCategoryStyle = (category: ChipCategory, isValidated: boolean) => {
  const backgroundColor = getCategoryColor(category);
  return {
    backgroundColor,
    border: isValidated ? 'none' : `2px dashed ${COLORS.GREY_LAYOUT}`,
  };
};

export const CategoryChip = ({
  category,
  size,
  isValidated = true,
  onClick = () => {},
  ...props
}: {
  category?: ChipCategory;
  size?: 'small' | 'medium';
  isValidated?: boolean;
  onClick?: (clickEvent: MouseEvent<HTMLElement>) => void;
} & ChipProps) => {
  if (!category) {
    return null;
  }
  const categoryStyle = getCategoryStyle(category, isValidated);
  return (
    <Chip
      {...props}
      label={category.name}
      sx={{ ...categoryStyle, ...props.sx }}
      size={size}
      onClick={onClick}
    />
  );
};

export const CategoriesChipField = ({ size }: CategoriesChipFieldProps) => {
  const record = useRecordContext<Category>();
  return record ? <CategoryChip category={record} size={size} /> : null;
};
