import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useTranslate } from 'ra-core';
import { Controller, Path } from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { ExposedControllerProps } from './type';
import { errorMessage } from './utils';

dayjs.extend(utc);
dayjs.extend(timezone);

type DateTimeInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<TextFieldProps, 'onChange' | 'value'>;

export function DateInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  variant,
  ...other
}: Omit<DateTimeInputProps<TFieldValues, TName>, 'onError' | 'defaultValue'>) {
  const translate = useTranslate();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        return (
          // @ts-ignore
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <MobileDatePicker
              type="datetime-local"
              error={Boolean(fieldState.error)}
              disabled={formState.isSubmitting}
              helperText={errorMessage(fieldState.error, translate)}
              {...field}
              value={field.value ? dayjs(field.value) : null}
              onChange={(date: Dayjs) => {
                field.onChange(date.toDate());
              }}
              format="DD/MM/YYYY"
              label={label ?? name}
              slotProps={{ textField: { variant } }}
              {...other}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}

export function DateTimeInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  ...other
}: DateTimeInputProps<TFieldValues, TName>) {
  const translate = useTranslate();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        return (
          <TextField
            type="datetime-local"
            error={Boolean(fieldState.error)}
            disabled={formState.isSubmitting}
            helperText={errorMessage(fieldState.error, translate)}
            {...field}
            label={label ?? name}
            variant="standard"
            {...other}
          />
        );
      }}
    />
  );
}
