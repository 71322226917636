import {
  BooleanInput,
  DateInput,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { FormProps } from '../../types';

export const CompanyPlanCodeForm = (props: FormProps) => (
  <SimpleForm {...props}>
    <TextInput
      label="Nom"
      source="name"
      validate={required()}
      variant="standard"
    />
    <TextInput
      label="Code"
      source="code"
      validate={required()}
      variant="standard"
    />
    <BooleanInput
      label="Actif"
      source="active"
      defaultValue={true}
      variant="standard"
    />
    <DateInput
      label="Date d'expiration"
      source="expiresAt"
      validate={required()}
      variant="standard"
    />
    <NumberInput
      label="Max d'utilisation (-1 pour infini)"
      source="maxUsage"
      variant="standard"
      defaultValue={-1}
    />
    {!props.record?.companyPlan && (
      <ReferenceInput
        source="companyPlan.id"
        reference="company-plans"
        variant="standard"
      >
        <SelectInput
          label="Plan d'entreprise"
          optionText="alias"
          variant="standard"
          validate={required()}
        />
      </ReferenceInput>
    )}
  </SimpleForm>
);
