import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const macroSlice = createSlice({
  name: 'macro',
  initialState: { macroId: undefined, macroSuggestionId: undefined },
  reducers: {
    setMacroSuggestion(
      state,
      {
        payload: { macroId, macroSuggestionId },
      }: PayloadAction<{ macroId: number; macroSuggestionId: number }>,
    ) {
      return { macroId, macroSuggestionId };
    },
  },
});

export const { setMacroSuggestion } = macroSlice.actions;

export default macroSlice.reducer;
