import { ChipField, useRecordContext } from 'react-admin';

import { Company } from '../../types';

interface CompanyPlanFieldProps {
  label?: string;
}

export const CompanyPlanField = ({}: CompanyPlanFieldProps) => {
  const record = useRecordContext<Company>();
  const plans = record.companyPlans || [];
  return (
    <>
      {plans
        .filter((plan) => plan.active)
        .map((plan) => (
          <ChipField source="alias" record={plan} key={plan.id} />
        ))}
    </>
  );
};
