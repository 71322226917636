import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, IconButtonProps } from '@mui/material';
import { Question, QuestionRule, Template } from '@teammay/form-core';

import { EditWrapper, EditWrapperProps } from './EditWrapper';

export const EditNodeButtonUI = ({
  onClick,
}: {
  onClick: IconButtonProps['onClick'];
}) => (
  <IconButton
    sx={{
      position: 'absolute',
      top: -16,
      right: -16,
      height: 32,
      width: 32,
      borderRadius: '50%',
      border: '1px solid',
      backgroundColor: 'background.paper',
    }}
    onClick={onClick}
  >
    <EditIcon />
  </IconButton>
);

export function EditNodeButton<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>(
  props: Omit<EditWrapperProps<Content>, 'open' | 'onSave' | 'onCancel'> & {
    onChange: (content: Content) => void;
  },
) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <EditNodeButtonUI onClick={() => setOpen(true)} />
      <EditWrapper<Content>
        {...props}
        open={open}
        onCancel={(reason) => reason !== 'cancelClicked' && setOpen(false)}
        onSave={(content) => {
          props.onChange(content);
          setOpen(false);
        }}
      />
    </>
  );
}
