import { Create, RaRecord } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { AffiliationsForm } from './form';

interface PatientRecord extends RaRecord {
  patientId: string;
}
interface RedirectLocation extends Location {
  state: { redirect: string; record: PatientRecord };
}

export const AffiliationsCreate = () => {
  const location = useLocation() as RedirectLocation;
  return (
    <Create
      resource="affiliations"
      title="Ajouter une affiliation"
      redirect={location?.state?.redirect || 'list'}
    >
      <AffiliationsForm
        defaultValues={{
          patientId: Number(location?.state?.record?.patientId ?? 0),
        }}
      />
    </Create>
  );
};
