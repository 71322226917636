import { useCallback, useState } from 'react';

import { PlanningJob } from '@boTypes/planning';
import { useDebouncedCallback } from '@hooks/useDebouncedCallback';

import { CalendarEvent } from '.';

export const useSlotActions = (
  drawerCallback?: {
    create: (slot: CalendarEvent) => void;
    update: (slot: CalendarEvent) => void;
    remove: (slot: CalendarEvent) => void;
  },
  displayActions?: {
    update: (slot: CalendarEvent) => boolean;
    delete: (slot: CalendarEvent) => boolean;
  },
  isAdmin: boolean = false,
  job?: PlanningJob,
) => {
  const [showPopover, setShowPopover] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null,
  );

  const [drawerRecord, setDrawerRecord] = useState<CalendarEvent | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const handleSelectSlot = useCallback(
    ({ start, end }: CalendarEvent) => {
      const slot = { start, end } as Partial<CalendarEvent>;
      if (isAdmin) {
        slot.job = job;
        slot.isTriage = false;
        slot.onCall = false;
        slot.onCallActivated = false;
        slot.staffUserId = undefined;
      }
      // @ts-ignore
      setDrawerRecord(slot);
      setShowDrawer(true);
    },
    [setShowDrawer, isAdmin, job],
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const onSelectEventInner = useDebouncedCallback(
    useCallback(
      (calendarEvent: CalendarEvent, ev: MouseEvent) => {
        setSelectedEvent(calendarEvent);
        setAnchorEl(ev.currentTarget || ev.target);
        setShowPopover(true);
      },
      [setSelectedEvent, setAnchorEl, setShowPopover],
    ),
    100,
  );

  const onSelectEvent = useCallback(
    (calendarEvent: CalendarEvent, ev: MouseEvent) => {
      if (showPopover) {
        ev.stopPropagation();
        ev.preventDefault();
        setShowPopover(false);
        onSelectEventInner?.cancel();
        return;
      }
      onSelectEventInner(calendarEvent, ev);
    },
    [onSelectEventInner, showPopover],
  );

  const onSlotCallback = useCallback(
    (slot: CalendarEvent) => {
      if (slot.id) {
        drawerCallback?.update(slot);
      } else {
        drawerCallback?.create(slot);
      }
    },
    [drawerCallback],
  );

  const handleDoubleClickEvent = useCallback(
    (calendarEvent: CalendarEvent) => {
      onSelectEventInner?.cancel();
      if (displayActions?.update(calendarEvent) && drawerCallback?.update) {
        setDrawerRecord(calendarEvent);
        setShowDrawer(true);
      }
    },
    [displayActions, drawerCallback, onSelectEventInner],
  );

  return {
    anchorEl,
    drawerRecord,
    handleDoubleClickEvent,
    handleSelectSlot,
    onSelectEvent,
    onSlotCallback,
    selectedEvent,
    setAnchorEl,
    setDrawerRecord,
    setSelectedEvent,
    setShowDrawer,
    setShowPopover,
    showDrawer,
    showPopover,
  };
};
