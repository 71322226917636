import dayjs, { Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useCallback, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { StaffUser } from '@boTypes/staffUser';
import { StaffSelectInput } from '@components/forms/staffSelectInput';
import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { useMutation } from '@hooks/queryWrappers';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  planningRequest,
  useAdditionalHoursMutations,
} from '../../../hooks/plannings';
import { useSelector } from '../../../store';
import {
  Planning,
  PlanningAdditionalHours,
  PlanningStatus,
} from '../../../types/planning';
import { titleToPlanningJob } from '../../../types/user';

dayjs.extend(LocalizedFormat);

export const AdditionalHourDialog = ({
  open,
  onCreated,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  onCreated: (arg: PlanningAdditionalHours) => void;
}) => {
  const translate = useTranslate();
  const [start, setStart] = useState<Dayjs>(
    dayjs(Math.round((Date.now() - 1800000) / 300000) * 300000),
  );
  const [end, setEnd] = useState<Dayjs>(
    dayjs(Math.round(Date.now() / 300000) * 300000),
  );
  const [comment, setComment] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const error =
    !start ||
    !end ||
    !start.isSame(end, 'day') ||
    end.diff(start, 'minute') < 15 ||
    end.diff(start, 'minute') > 120;
  const job = useSelector((state) =>
    state.user?.jobTitle ? titleToPlanningJob[state.user.jobTitle] : undefined,
  );
  const userId = useSelector((state) => state.user?.userId);
  const notify = useNotify();
  const { createAdditionalHour } = useAdditionalHoursMutations();

  const { mutateAsync: getPlannings } = useMutation<Planning[], any, Date>(
    ['addHourPlannings'],
    (date) =>
      planningRequest({
        status: PlanningStatus.DONE,
        date,
        job,
      }),
  );

  const onValidate = useCallback(async () => {
    setLoading(true);
    try {
      const plannings = await getPlannings(start.toDate());
      if (plannings.length) {
        createAdditionalHour(
          {
            planningId: plannings[0].id,
            start: start.toDate().toISOString(),
            staffUserId: userId,
            end: end.toDate().toISOString(),
            comment,
          },
          {
            onSuccess(data) {
              setComment('');
              onCreated(data);
            },
            onError() {
              notify('Erreur lors de la création. (15min minimum, 2h max)', {
                type: 'warning',
              });
            },
            onSettled() {
              setLoading(false);
            },
          },
        );
      } else {
        notify('Aucun planning trouvé', { type: 'warning' });
      }
    } catch {
      notify('Erreur lors de la création', { type: 'warning' });
      setLoading(false);
    } finally {
    }
  }, [
    end,
    notify,
    start,
    getPlannings,
    userId,
    createAdditionalHour,
    onCreated,
    comment,
  ]);

  const errorMessage = translate('additionalHours.error');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('additionalHours.add')}
      </DialogTitle>
      {/* @ts-ignore */}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DateTimePicker
              label={'Début : '}
              value={start}
              onChange={(newValue) => setStart(newValue)}
              minutesStep={5}
              ampm={false}
              slotProps={{
                textField: {
                  variant: 'standard',
                  sx: { width: 180, marginRight: 1, minWidth: 180 },
                  helperText: error && errorMessage,
                },
              }}
            />
            <DateTimePicker
              label={'Fin : '}
              value={end}
              onChange={(newValue) => setEnd(newValue)}
              minutesStep={5}
              ampm={false}
              slotProps={{
                textField: {
                  variant: 'standard',
                  sx: { width: 180, marginLeft: 1, minWidth: 180 },
                },
              }}
            />
          </Box>
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            name="comment"
            label={translate('additionalHours.comment')}
            multiline
            fullWidth
            sx={{ mt: 2 }}
            variant="standard"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Button onClick={onClose} variant="text">
            {translate('common.cancel')}
          </Button>
          <Button
            disabled={error || loading}
            variant="contained"
            onClick={onValidate}
          >
            {loading ? <CircularProgress /> : translate('common.save')}
          </Button>
        </Box>
      </LocalizationProvider>
    </Dialog>
  );
};

export const AdditionalHourDialogWithPlanning = ({
  open,
  onCreated,
  onClose,
  planningId,
  staffUsers,
  isLoadingStaffUsers,
}: {
  open: boolean;
  onClose: () => void;
  onCreated: (arg: PlanningAdditionalHours) => void;
  planningId: string;
  staffUsers: StaffUser[];
  isLoadingStaffUsers: boolean;
}) => {
  const translate = useTranslate();
  const [start, setStart] = useState<Dayjs>(
    dayjs(Math.round((Date.now() - 1800000) / 300000) * 300000),
  );
  const [end, setEnd] = useState<Dayjs>(
    dayjs(Math.round(Date.now() / 300000) * 300000),
  );
  const [loading, setLoading] = useState(false);

  const error =
    !start.isSame(end, 'day') ||
    end.diff(start, 'minute') < 15 ||
    end.diff(start, 'minute') > 120;

  // We should migrate the whole form to react-hook-form
  const { control, watch, reset } = useForm<{
    staffUserId: number;
    comment: string;
  }>({
    defaultValues: {
      staffUserId: undefined,
      comment: '',
    },
  });

  const errorMessage = translate('additionalHours.error');

  const staffUserId = watch('staffUserId');
  const comment = watch('comment');

  const notify = useNotify();
  const { createAdditionalHour } = useAdditionalHoursMutations();

  const onValidate = useCallback(async () => {
    setLoading(true);
    try {
      createAdditionalHour(
        {
          planningId,
          start: start.toDate().toISOString(),
          staffUserId,
          end: end.toDate().toISOString(),
          comment,
        },
        {
          onSuccess(data) {
            reset();
            onCreated(data);
          },
          onError() {
            notify('Erreur lors de la création. (15min minimum, 2h max)', {
              type: 'warning',
            });
          },
          onSettled() {
            setLoading(false);
          },
        },
      );
    } catch {
      notify('Erreur lors de la création', { type: 'warning' });
      setLoading(false);
    } finally {
    }
  }, [
    end,
    notify,
    start,
    createAdditionalHour,
    onCreated,
    comment,
    planningId,
    staffUserId,
    reset,
  ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('additionalHours.add')}
      </DialogTitle>
      {/* @ts-ignore */}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Box sx={{ px: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DateTimePicker
              label={'Début : '}
              value={start}
              onChange={(newValue) => setStart(newValue)}
              minutesStep={5}
              ampm={false}
              slotProps={{
                textField: {
                  variant: 'standard',
                  helperText: error && errorMessage,
                  sx: { width: 180, marginRight: 1, minWidth: 180 },
                },
              }}
            />
            <DateTimePicker
              label={'Fin : '}
              value={end}
              onChange={(newValue) => setEnd(newValue)}
              minutesStep={5}
              ampm={false}
              slotProps={{
                textField: {
                  variant: 'standard',
                  sx: { width: 180, marginLeft: 1, minWidth: 180 },
                },
              }}
            />
          </Box>
          <StaffSelectInput
            label={translate('common.pro')}
            staffUsers={staffUsers}
            isLoadingStaff={isLoadingStaffUsers}
            withColor={true}
            control={control}
            name="staffUserId"
            variant="standard"
            fullWidth={true}
            required={true}
          />
          <TextInput
            control={control}
            name="comment"
            label={translate('additionalHours.comment')}
            multiline
            fullWidth
            sx={{ mt: 2 }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Button onClick={onClose} variant="text">
            {translate('common.cancel')}
          </Button>
          <Button
            disabled={error || loading}
            variant="contained"
            onClick={onValidate}
          >
            {loading ? <CircularProgress /> : translate('common.save')}
          </Button>
        </Box>
      </LocalizationProvider>
    </Dialog>
  );
};
