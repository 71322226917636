import { Discussion } from '@boTypes';
import { AttributionFilter } from '@boTypes/discussion';
import { attributionFilters } from '@components/discussions/hooks';
import { getFamilyIdFromDiscussion } from '@utils/family';

export const getUnattributedCount = (
  activeList: {
    lastSubjectId: Discussion['lastSubjectId'];
    lastEvent?: { isMay: boolean };
    lastSubject?: Pick<
      Discussion['lastSubject'],
      'categoryId' | 'withDoctorIntervention'
    >;
  }[],
  staffUserFromSubjectId: Record<number, number> = {},
) => {
  return activeList.reduce(
    ([unatt, uncat], d) => {
      return [
        unatt +
          (((!staffUserFromSubjectId[d.lastSubjectId] && !d.lastEvent?.isMay) || // not attributed and patient is last sender
            (!staffUserFromSubjectId[d.lastSubjectId] &&
              !d.lastSubject?.categoryId)) && // not attributed and not categorized
          !d.lastSubject?.withDoctorIntervention // exclude doc intervention
            ? 1
            : 0),
        uncat +
          (!staffUserFromSubjectId[d.lastSubjectId] && // do not count attributed
          !d.lastSubject?.categoryId // do not count categorized
            ? 1
            : 0),
      ];
    },
    [0, 0],
  );
};

export const getAttributedCount = (
  activeList: {
    lastSubjectId: Discussion['lastSubjectId'];
    lastEvent?: { isMay: boolean };
    lastSubject?: Pick<
      Discussion['lastSubject'],
      'categoryId' | 'withDoctorIntervention'
    >;
  }[],
  staffUserFromSubjectId: Record<number, number> = {},
  userId: number,
) => {
  const isMine = staffUserFromSubjectId
    ? attributionFilters[AttributionFilter.MINE](userId, staffUserFromSubjectId)
    : () => false;
  return activeList.reduce((acc, d) => {
    return acc + (isMine(d) ? 1 : 0);
  }, 0);
};

export const getAllUnattributedCount = (
  activeList: {
    lastSubjectId: Discussion['lastSubjectId'];
    lastSubject?: Pick<
      Discussion['lastSubject'],
      'categoryId' | 'withDoctorIntervention'
    >;
  }[],
  staffUserFromSubjectId: Record<number, number> = {},
): number => {
  const isUnattributed = (d: {
    lastSubjectId: number;
    lastSubject?: Pick<
      Discussion['lastSubject'],
      'categoryId' | 'withDoctorIntervention'
    >;
  }) => {
    return (
      !staffUserFromSubjectId[d.lastSubjectId] &&
      !d.lastSubject?.withDoctorIntervention
    );
  };
  return activeList.reduce((acc, d) => acc + (isUnattributed(d) ? 1 : 0), 0);
};

export const getIdsInfoFromDiscussion = (discussion: Discussion) => {
  const appUserId =
    typeof discussion?.appUser === 'number'
      ? discussion?.appUser
      : discussion?.appUser?.id;

  const familyId = getFamilyIdFromDiscussion(discussion);

  const childId = discussion?.kidId;

  return { appUserId, familyId, childId };
};
