import { Image, StyleSheet, View } from 'react-native';

import { ChatDiscussionEvent } from '@boTypes/discussionEvent';

const styles = StyleSheet.create({
  container: {
    height: 100,
  },
  image: {
    width: 150,
    height: 100,
    borderRadius: 13,
    margin: 3,
  },
});

export const ImageMessage = ({ message }: { message: ChatDiscussionEvent }) => {
  return (
    <View style={[styles.container]}>
      <a target="_blank" rel="noopener noreferrer" href={message.content}>
        <Image
          style={[styles.image]}
          source={{ uri: message.content }}
          resizeMode="cover"
        />
      </a>
    </View>
  );
};
