import { ChatDiscussionEvent } from '@boTypes/discussionEvent';
import { Typography } from '@mui/material';

import { COLORS } from '../../../../themes';

export const TextMessage = ({ message }: { message: ChatDiscussionEvent }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        paddingX: 2,
        paddingY: 0.5,
        minWidth: '10rem',
        color: message.isCurrentUser ? 'white' : COLORS.TEXT,
        whiteSpace: 'pre-line',
      }}
    >
      {message.content}
    </Typography>
  );
};
