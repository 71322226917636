import { SubjectExplorer } from '@boTypes/subject';
import { useQuery } from '@hooks/queryWrappers';

type SimilarQuestion = {
  subjectId: number;
  discussionId: number;
};

export const useSimilarQuestions = (subjectId: number) => {
  const { data: similarQuestions, isLoading } = useQuery<
    SimilarQuestion[],
    any,
    number[]
  >(
    ['similarQuestion', subjectId],
    () => ({
      method: 'POST',
      url: '/api/similar-question/search',
      data: { subjectId },
    }),
    {
      enabled: !!subjectId,
      select: (data) => data.map((s) => s.subjectId),
    },
  );

  // Load related subjects
  const { data: subjects, isLoading: isLoadingSubjects } = useQuery<
    SubjectExplorer[],
    any,
    SubjectExplorer[]
  >(
    ['subjectsByIds', similarQuestions],
    () => ({
      method: 'GET',
      url: '/api/subjects-explorer',
      // For local testing purposes, toggle the following lines with comments
      params: { ids: similarQuestions },
      // params: { ids: [19253, 19257, 19258, 19259] },
    }),
    // For local testing purposes, remove the enabled property
    {
      enabled: similarQuestions?.length > 0 && !isLoading,
    },
  );

  return { subjects, isLoading: isLoading || isLoadingSubjects };
};
