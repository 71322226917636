import { useCallback, useEffect, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { Control, UseFormSetValue, useForm, useWatch } from 'react-hook-form';

import { HandoverRevive, HandoverReviveCreate } from '@boTypes/handover';
import { PlanningAttribution } from '@boTypes/planning';
import { Slot } from '@boTypes/slot';
import { JobTitle, User } from '@boTypes/user';
import { StaffSelectInput } from '@components/forms/staffSelectInput';
import { useAttributions, useFutureRevives } from '@hooks/plannings';
import { useAllStaffUsersSorted } from '@hooks/useAllStaffUsers';
import { useDiscussionContext } from '@hooks/useDiscussionContext';
import ContentSave from '@mui/icons-material/Save';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from '@mui/material';

import { DiscussionContext } from '../../common';
import { useSelector } from '../../store';
import { formatTZ, isInInterval } from '../../utils/date';
import { DateTimeInput } from '../forms/dateInput';
import { SelectInput } from '../forms/selectInput';
import { SwitchInput } from '../forms/switchInput';
import { TextInput } from '../forms/textInput';
import { DialogTitle } from '../generic/Dialog';

interface ReviveFormState {
  reviveAt: string;
  plannedText: string;
  attributed: boolean;
  attributedStaffUserId: number | null;
  slotId?: number;
}

const sortAttributions = (a: { slot?: Slot }, b: { slot?: Slot }) =>
  new Date(a.slot?.start).getTime() - new Date(b.slot?.start).getTime();

const useReviveFormAttributions = (
  control: Control<ReviveFormState>,
  setValue: UseFormSetValue<ReviveFormState>,
  handoverRevive?: HandoverRevive,
) => {
  const [staffUserId] = useWatch({
    control,
    name: ['attributedStaffUserId'],
  });
  const filters = useMemo(
    () => ({
      begin: formatTZ(new Date(), 'YYYY-MM-DD'),
      end: formatTZ(new Date(Date.now() + 45 * 24 * 3600 * 1000), 'YYYY-MM-DD'),
      staffUserId,
      onCall: false,
    }),
    [staffUserId],
  );
  const res = useAttributions(filters, {
    select: (data) =>
      data.filter(
        (att) => new Date(att.slot.start).getTime() > new Date().getTime(),
      ),
  });

  const attributions = res.data;
  useEffect(() => {
    if (attributions && attributions.length > 0) {
      if (!handoverRevive || !handoverRevive.attributedStaffUserId) {
        setValue(
          'slotId',
          Number(attributions.sort(sortAttributions)[0].slotId),
        );
      } else {
        let matchingSlot = attributions.find((attribution) =>
          isInInterval(
            handoverRevive.reviveAt,
            attribution.slot.start,
            attribution.slot.end,
          ),
        );
        setValue(
          'slotId',
          Number(matchingSlot?.slotId) ||
            Number(attributions.sort(sortAttributions)[0].slotId),
        );
      }
    }
  }, [attributions, handoverRevive, setValue]);

  return res;
};

export const ReviveForm = ({
  attributionQuery,
  control,
  setValue,
  futureRevives,
}: {
  attributionQuery?: {
    data: (PlanningAttribution & { slot?: Slot })[];
    isLoading: boolean;
  };
  control: Control<ReviveFormState>;
  setValue: UseFormSetValue<ReviveFormState>;
  futureRevives?: HandoverRevive[];
}) => {
  const isAttributed = useWatch({ control, name: 'attributed' });
  const translate = useTranslate();
  const { data: attributions, isLoading } = attributionQuery;

  const revivesMapping = useMemo(
    () =>
      futureRevives?.reduce(
        (acc, revive) => {
          const staffUser = String(
            revive.attributedStaffUserId ?? 'unattributed',
          );
          if (!acc[staffUser]) {
            acc[staffUser] = [];
          }
          acc[staffUser].push(revive);
          return acc;
        },
        {} as Record<string, HandoverRevive[]>,
      ),
    [futureRevives],
  );

  const context = useDiscussionContext();
  const filters = useCallback(
    (u: User) => {
      if (!u.active) {
        return false;
      }
      if (context === DiscussionContext.MIDWIFE) {
        return u.jobTitle === JobTitle.MIDWIFE;
      } else {
        return u.jobTitle !== JobTitle.MIDWIFE && u.jobTitle !== JobTitle.ADMIN;
      }
    },
    [context],
  );

  const { data: staffUsers, isLoading: isLoadingStaff } =
    useAllStaffUsersSorted(filters);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <TextInput
        sx={{ p: 1, alignSelf: 'stretch' }}
        control={control}
        name="plannedText"
        label={translate('revives.plannedText')}
        rules={{ required: true }}
      />
      <SwitchInput
        sx={{ p: 1 }}
        control={control}
        name="attributed"
        label={
          !isAttributed
            ? translate('revives.nonAttributed')
            : translate('revives.attributed')
        }
      />
      {isAttributed && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <StaffSelectInput
            sx={{ p: 1, minWidth: 200 }}
            variant="outlined"
            control={control}
            name="attributedStaffUserId"
            label={translate('revives.attributedStaffUserId')}
            isLoadingStaff={isLoadingStaff}
            staffUsers={staffUsers}
            onChangeCallback={() => {
              setValue('slotId', undefined);
            }}
            groupByJobTitle
          />
          <SelectInput
            sx={{ p: 1, minWidth: 200 }}
            control={control}
            name="slotId"
            label={translate('revives.slot')}
            disabled={isLoading}
            variant="outlined"
          >
            {isLoading && (
              <MenuItem disabled>{translate('revives.loading')}</MenuItem>
            )}
            {attributions?.sort(sortAttributions).map((attribution) => {
              const revives =
                revivesMapping?.[String(attribution.staffUserId)]?.filter(
                  (revive) =>
                    isInInterval(
                      revive.reviveAt,
                      attribution.slot.start,
                      attribution.slot.end,
                    ),
                ) ?? [];
              const unattributedRevives =
                revivesMapping?.unattributed?.filter((revive) =>
                  isInInterval(
                    revive.reviveAt,
                    attribution.slot.start,
                    attribution.slot.end,
                  ),
                )?.length ?? 0;
              return (
                <MenuItem value={attribution.slotId} key={attribution.slotId}>
                  {formatTZ(attribution.slot.start, 'DD/MM/YYYY HH:mm')}
                  {' - ' +
                    (revives?.length > 0
                      ? translate('revives.revives', {
                          smart_count: revives?.length,
                        })
                      : translate('revives.noRevives'))}
                  {unattributedRevives > 0 &&
                    ` - ${unattributedRevives} ${translate('revives.unattributed', { smart_count: unattributedRevives })}`}
                </MenuItem>
              );
            })}
          </SelectInput>
        </Box>
      )}
      {!isAttributed && (
        <DateTimeInput
          sx={{ p: 1 }}
          control={control}
          name="reviveAt"
          label={translate('revives.reviveAt')}
          disabled={isAttributed}
        />
      )}
    </Box>
  );
};

export const ReviveFormCreateController = ({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (arg: Omit<HandoverReviveCreate, 'handoverId'>) => Promise<void>;
}) => {
  const staffUserId = useSelector((state) => state.user?.userId);
  const translate = useTranslate();
  const { control, handleSubmit, setValue, formState } =
    useForm<ReviveFormState>({
      defaultValues: {
        reviveAt:
          formatTZ(new Date(Date.now() + 24 * 3600000), 'YYYY-MM-DD') +
          'T10:00',
        attributed: false,
        attributedStaffUserId: Number(staffUserId),
      },
    });
  const { isValid, isSubmitting } = formState;
  const attributionQuery = useReviveFormAttributions(control, setValue);
  const attributions = attributionQuery.data;
  const { data: futureRevives } = useFutureRevives();

  const handleSave = handleSubmit(
    async ({
      slotId,
      attributed,
      attributedStaffUserId,
      reviveAt,
      ...other
    }: {
      slotId?: number;
      attributed: boolean;
      attributedStaffUserId: number | null;
      reviveAt: string;
      plannedText: string;
    }) => {
      await onSubmit({
        ...other,
        slotId: attributed ? Number(slotId) : undefined,
        reviveAt: attributed
          ? new Date(
              attributions.find((a) => a.slotId === slotId)?.slot?.start,
            ).toISOString()
          : new Date(reviveAt).toISOString(),
        attributedStaffUserId: attributed
          ? Number(attributedStaffUserId)
          : null,
      });
    },
  );

  return (
    <>
      <DialogContent>
        <ReviveForm
          control={control}
          attributionQuery={attributionQuery}
          futureRevives={futureRevives}
          setValue={setValue}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '1rem',
        }}
      >
        <Button
          startIcon={isSubmitting ? <CircularProgress /> : <ContentSave />}
          onClick={handleSave}
          variant="contained"
          disabled={isSubmitting || !isValid}
        >
          {translate('common.save')}
        </Button>
        <Button color="inherit" onClick={onCancel}>
          {translate('common.cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export const ReviveFormUpdateController = ({
  handoverRevive,
  onCancel,
  onSubmit,
}: {
  handoverRevive: HandoverRevive;
  onCancel: () => void;
  onSubmit: (arg: Omit<HandoverReviveCreate, 'handoverId'>) => Promise<void>;
}) => {
  const translate = useTranslate();
  const { control, handleSubmit, setValue, formState } =
    useForm<ReviveFormState>({
      defaultValues: {
        reviveAt: formatTZ(
          new Date(handoverRevive.reviveAt),
          'YYYY-MM-DDTHH:mm',
        ),
        plannedText: handoverRevive.plannedText,
        attributed: Boolean(handoverRevive.attributedStaffUserId),
        attributedStaffUserId: handoverRevive.attributedStaffUserId,
      },
    });
  const { isSubmitting, isValid } = formState;

  const attributionQuery = useReviveFormAttributions(
    control,
    setValue,
    handoverRevive,
  );
  const { data: futureRevives } = useFutureRevives();

  const attributions = attributionQuery.data;
  const handleSave = handleSubmit(
    async ({
      slotId,
      attributed,
      attributedStaffUserId,
      reviveAt,
      ...other
    }: {
      slotId?: number;
      attributed: boolean;
      attributedStaffUserId: number | null;
      reviveAt: string;
      plannedText: string;
    }) => {
      await onSubmit({
        ...other,
        slotId: attributed ? Number(slotId) : undefined,
        reviveAt: attributed
          ? new Date(
              attributions.find((a) => a.slotId === slotId)?.slot?.start,
            ).toISOString()
          : new Date(reviveAt).toISOString(),
        attributedStaffUserId: attributed
          ? Number(attributedStaffUserId)
          : null,
      });
    },
  );

  return (
    <>
      <DialogContent>
        <ReviveForm
          control={control}
          attributionQuery={attributionQuery}
          setValue={setValue}
          futureRevives={futureRevives}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '1rem',
        }}
      >
        <Button
          startIcon={isSubmitting ? <CircularProgress /> : <ContentSave />}
          onClick={handleSave}
          variant="contained"
          disabled={isSubmitting || !isValid}
        >
          {translate('common.save')}
        </Button>
        <Button color="inherit" onClick={onCancel}>
          {translate('common.cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export const ReviveDialog = ({
  open,
  handoverRevive,
  onClose,
  onSubmit,
}: {
  open: boolean;
  handoverRevive?: HandoverRevive;
  onClose: () => void;
  onSubmit: (arg: Omit<HandoverReviveCreate, 'handoverId'>) => Promise<void>;
}) => {
  const translate = useTranslate();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={translate('revives.createOne')}
      aria-describedby={translate('revives.createOne')}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle onClose={onClose}>
        {translate('revives.createOne')}
      </DialogTitle>
      {handoverRevive ? (
        <ReviveFormUpdateController
          handoverRevive={handoverRevive}
          onCancel={onClose}
          onSubmit={onSubmit}
        />
      ) : (
        <ReviveFormCreateController onCancel={onClose} onSubmit={onSubmit} />
      )}
    </Dialog>
  );
};
