import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { useParams } from 'react-router';
import { utils as xlsUtils, WorkBook, writeFileXLSX } from 'xlsx';

import { useMutation } from '@hooks/queryWrappers';
import { EuroSymbol } from '@mui/icons-material';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';

import {
  fullName,
  getXLSXFromUserInvoiceData,
  getXLSXSummaryData,
} from './utils';
import { usePlanning, usePlanningInvoices } from '../../hooks/plannings';
import { User } from '../../types';

const settings = {
  french: { separator: ';', decimal: ',' },
  international: { separator: ',', decimal: '.' },
};

type Row = {
  id: string;
  name: string;
  total: number;
  vat: boolean;
  xlsx: WorkBook;
  staffUser: User;
};

const GridToolbarSepa = () => {
  const ctx = useGridApiContext();
  const { planningId } = useParams();
  const notify = useNotify();

  const { mutate: generateSepa } = useMutation<string, AxiosError, string[]>(
    ['generateSepa'],
    (ids: string[]) => ({
      method: 'post',
      url: `/api/billing/invoices/${planningId}/sepa`,
      data: { ids },
    }),
    {
      onSuccess(res) {
        notify(`Fichier SEPA généré`, { type: 'success' });
        // Download file
        const blob = new Blob([res], { type: 'text/xml' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = `sepa-${dayjs().format('YYYY-MM-DD')}.xml`;
        link.click();
        window.URL.revokeObjectURL(url);
      },
      onError() {
        notify(`Erreur lors de la génération - vérifiez les iban/bic`, {
          type: 'error',
        });
      },
    },
  );

  const selectedRows = ctx.current.getSelectedRows();
  const count = selectedRows.size;
  // row id is staffUserId
  const staffUserIds = Array.from(selectedRows.keys()) as string[];

  return (
    <Button
      disabled={count === 0}
      onClick={() => generateSepa(staffUserIds)}
      startIcon={<EuroSymbol />}
      color="success"
    >
      Générer document virement SEPA ({count} sélectionné{count > 1 ? 's' : ''})
    </Button>
  );
};

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarSepa />
  </GridToolbarContainer>
);

export const Invoices = () => {
  const { planningId } = useParams();
  const { data: planning } = usePlanning(planningId);
  const { data } = usePlanningInvoices(planningId);
  const [format, setFormat] = useState<keyof typeof settings>('french');

  const notify = useNotify();
  const { mutate: sendAllMail } = useMutation<void, AxiosError>(
    ['invoicesSendAllMail'],
    () => ({
      method: 'post',
      url: `/api/billing/invoices/${planningId}/mailAll`,
    }),
    {
      onError(error) {
        notify(`Erreur : ${error.message}`, { type: 'error' });
      },
      onSuccess() {
        notify('Mails envoyés', { type: 'success' });
      },
    },
  );

  const { mutate: sendOneMail } = useMutation<void, AxiosError, number>(
    ['invoiceSendOneMail'],
    (staffUserId: number) => ({
      method: 'post',
      url: `/api/billing/invoices/${planningId}/mail/${staffUserId}`,
    }),
    {
      onError(error) {
        notify(`Erreur : ${error.message}`, { type: 'error' });
      },
      onSuccess() {
        notify('Mail envoyé', { type: 'success' });
      },
    },
  );

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'name',
        headerName: 'Nom',
        flex: 1,
        editable: false,
      },
      {
        field: 'allIncluded',
        headerName: 'Total (TTC)',
        editable: false,
        flex: 1,
      },
      {
        field: 'xlsx',
        headerName: 'XLSX',
        editable: false,
        flex: 2,
        renderCell: (params) => (
          <Button
            onClick={() => {
              writeFileXLSX(
                params.row.xlsx,
                `${params.row.name.trim().replace(' ', '_')}.xlsx`,
              );
            }}
            variant="outlined"
          >
            Télécharger XLSX
          </Button>
        ),
      },
      {
        field: 'mail',
        headerName: 'Mail',
        editable: false,
        flex: 2,
        renderCell: (params) => (
          <Button
            onClick={() => {
              sendOneMail(params.row.id);
            }}
            variant="outlined"
          >
            Envoyer le bilan par mail
          </Button>
        ),
      },
    ],
    [sendOneMail],
  );

  const rows: Row[] = useMemo(
    () =>
      sortBy(
        Object.keys(data ?? {}).map((id) => {
          const invoice = data[id];
          const workbook = xlsUtils.book_new();
          const workSheet = getXLSXFromUserInvoiceData(
            invoice,
            planning,
            settings[format],
          );
          xlsUtils.book_append_sheet(workbook, workSheet, 'Facture');
          return {
            id,
            name: fullName(invoice.staffUser),
            total: invoice.total,
            allIncluded: invoice.vat ? invoice.total * 1.2 : invoice.total,
            vat: invoice.vat,
            xlsx: workbook,
            staffUser: invoice.staffUser,
          };
        }),
        'name',
      ),
    [data, format, planning],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '1rem',
      }}
    >
      <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
        Bilan
      </Typography>
      <Box>
        <Typography variant="body2" component={'span'}>
          format
        </Typography>
        <Select<keyof typeof settings>
          sx={{ m: 1 }}
          value={format}
          onChange={(event) =>
            setFormat(event.target.value as keyof typeof settings)
          }
          label="format"
          size="small"
        >
          <MenuItem value="french">Français</MenuItem>
          <MenuItem value="international">International</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            const workbook = xlsUtils.book_new();
            xlsUtils.book_append_sheet(
              workbook,
              getXLSXSummaryData(rows, planning),
              'recap',
            );
            writeFileXLSX(workbook, `recap-${dayjs().format('YY-MM-DD')}.xlsx`);
          }}
        >
          Télécharger le récap fichier compta
        </Button>

        <Button
          variant="outlined"
          onClick={() => {
            sendAllMail();
          }}
        >
          Envoyer le récap à tous par email
        </Button>
      </Box>

      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[100]}
        sx={{ cursor: 'pointer', flex: 1 }}
        disableColumnFilter
        disableColumnMenu
        checkboxSelection={true}
        isRowSelectable={(r: GridRowParams<Row>) =>
          r.row.staffUser.iban && r.row.staffUser.bic
        }
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </Box>
  );
};
