import { View, StyleSheet } from 'react-native';

import { ChatDiscussionEvent } from '@boTypes/discussionEvent';
import { Typography } from '@mui/material';

import { COLORS } from '../../../../themes';

const endQuestionStyles = StyleSheet.create({
  horizontalLine: {
    backgroundColor: COLORS.GREY_LAYOUT,
    flex: 2,
    height: 1,
  },
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: '1rem',
  },
  textWrapper: {
    flex: 1,
  },
});

export const SystemMessage = ({
  message,
}: {
  message: ChatDiscussionEvent;
}) => {
  if (message.content === 'NEW_SUBJECT') {
    return null;
  }

  if (message.content === 'END_SUBJECT') {
    return (
      <View style={endQuestionStyles.wrapper}>
        <View style={endQuestionStyles.horizontalLine} />
        <View style={endQuestionStyles.textWrapper}>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '0.625rem',
              color: COLORS.GREY_TEXT,
            }}
          >
            Fin de la question
          </Typography>
        </View>
        <View style={endQuestionStyles.horizontalLine} />
      </View>
    );
  }

  return null;
};
