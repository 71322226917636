import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

import { Paper, Typography } from '@mui/material';

import { EditProfileToolbar } from '../../components/editProfileToolbar';
import { titleChoices } from '../../entities/common';
import { useSelector } from '../../store';

export const EditProfile = () => {
  // We use id from state. A get on profile returns the logged in user anyway...
  // Update route uses id though. Could be rework.
  const user = useSelector((state) => state.user);
  const translate = useTranslate();

  return (
    <Paper sx={{ minHeight: '100%', padding: '1rem' }} elevation={0}>
      <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
        {translate('profile.title')}
      </Typography>
      <Edit
        id={user.userId}
        resource="profiles"
        redirect={false}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<EditProfileToolbar />}>
          <TextInput disabled label="Nom" source="fullName" />
          <TextInput
            disabled
            label="Téléphone (format +336...)"
            source="phone"
          />
          <TextInput
            label="Nom commercial (factures)"
            source="commercialName"
          />
          <TextInput label="IBAN" source="iban" />
          <TextInput label="BIC" source="bic" />
          <SelectInput
            label="Spécialité"
            source="jobTitle"
            choices={titleChoices}
          />
        </SimpleForm>
      </Edit>
    </Paper>
  );
};
