import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& > *': {
    margin: theme.spacing(0.25),
  },
  flexShrink: 0,
  overflowX: 'auto',
  paddingBottom: theme.spacing(0.5),
  gap: '0.25rem',
}));

export const LibraryFilterContainer = (props: BoxProps) => {
  return <StyledBox className="scrollbar" {...props} />;
};
