import { useMemo } from 'react';
import { useGetList } from 'react-admin';

import { User } from '@boTypes/user';
import { meFirstSort } from '@utils/staffUser';

import { useSelector } from '../store';

export const activeUsersFilter = (user: User) => user.active;

export const useAllStaffUsersSorted = (
  filter: (u: User) => boolean = () => true,
) => {
  const { data: allStaffUsers, isLoading } = useGetList<User>('users', {
    pagination: { page: 1, perPage: 1000 },
  });
  const userId = useSelector((state) => state.user.userId);
  return useMemo(
    () => ({
      data: allStaffUsers?.filter(filter).sort(meFirstSort(Number(userId))) ?? [
        { id: Number(userId), firstName: 'Moi', lastName: '' } as User,
      ],
      isLoading,
    }),
    [allStaffUsers, filter, isLoading, userId],
  );
};
