export const TRIALING = 'Trialing';
export const ALL = 'Tous';
export const CANCELED = 'Annulé';
export const CLOSED = 'Clôturé';
export const NURSE = 'Nurse';
export const TO_DO = 'A traiter';
export const MINE = 'Mes consultations';

export enum Filters {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REVIVE = 'Relance',
}
