import { Title, useTranslate } from 'react-admin';

import { Grid, Paper, Typography } from '@mui/material';

import { CategoryCard } from './categoryCard';
import { FormCard } from './formCard';
import { PlanningCard } from './planningCard';
import { PricingCard } from './pricingCard';
import { ScenarioCard } from './scenarioCard';

export const Admin = () => {
  const translate = useTranslate();

  return (
    <Paper
      sx={{ padding: '1rem', minHeight: '100%', zIndex: 10 }}
      elevation={0}
    >
      <Title title={translate('title.admin')} />
      <Typography
        variant="h5"
        color="primary"
        sx={{ mb: 1, fontWeight: 'bold' }}
      >
        {translate('title.admin')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <PlanningCard />
        </Grid>
        <FormCard />
        <Grid item xs={12} md={4}>
          <CategoryCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <PricingCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <ScenarioCard />
        </Grid>
      </Grid>
    </Paper>
  );
};
