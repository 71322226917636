import { Create } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Location } from 'react-router-dom';

import { CompanyPlanForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const CompanyPlanCreate = () => {
  const location = useLocation() as RedirectLocation;
  return (
    <Create
      resource="company-plans"
      title="Ajouter une plan d'entreprise"
      redirect={location?.state?.redirect || 'list'}
      sx={{ padding: '1rem' }}
    >
      <CompanyPlanForm />
    </Create>
  );
};
