import { forwardRef } from 'react';

import { Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material';

export const ColoredBadge = (
  color: string,
  textColor = 'white',
  anchorOrigin: { vertical: 'bottom' | 'top'; horizontal: 'left' | 'right' } = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  right = '12%',
) =>
  styled(
    forwardRef<any, BadgeProps>(({ children, ...props }: BadgeProps, ref) => (
      <Badge
        color="default"
        anchorOrigin={anchorOrigin}
        overlap="circular"
        ref={ref}
        {...props}
      >
        {children}
      </Badge>
    )),
  )(() => ({
    '& .MuiBadge-badge': {
      height: 12,
      minWidth: 12,
      padding: 0,
      fontSize: '0.5rem',
      backgroundColor: color,
      color: textColor,
      right,
    },
  }));
