import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';
import { View } from 'react-native';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { AppUser, FamilyPopulated } from '@boTypes/family';
import { ATTRIBUTION_OPTIONS_ENUM, Filters } from '@boTypes/subject';
import { useDiscussionFamilies } from '@hooks/family';
import { useGiveMeMore } from '@hooks/subjectAttribution';
import { useActiveTab, useSubjectFilter } from '@hooks/subjects';
import { useActiveSessions } from '@hooks/useActiveSessions';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import AddIcon from '@mui/icons-material/Add';
import { Box, Chip, Fab, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { SubjectCard } from './subjectCard';
import { logRequestMoreAttributions } from '../../analytics/events';
import { useSelector } from '../../store';
import { COLORS } from '../../themes';
import { Discussion } from '../../types';

const fullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName?: string;
}) => (firstName + (lastName ? ' ' + lastName : '')).toLowerCase();

const isMatched = (
  subject: Discussion,
  family: FamilyPopulated | undefined,
  searchText: string,
) => {
  const lowerSearchText = searchText.toLowerCase();
  if (subject.lastEvent?.content?.toLowerCase().includes(lowerSearchText)) {
    return true;
  }

  if (!family) {
    return false;
  }

  if (subject.kidId) {
    // match kid name
    const kidMatch = family.children
      .filter((child) => String(child.id) === String(subject.kidId))
      .some((kid) => fullName(kid).includes(lowerSearchText));
    if (kidMatch) {
      return true;
    }
    // match parents name
    return family.appUsers.some((user) =>
      fullName(user).includes(lowerSearchText),
    );
  } else {
    const appUserId = subject.appUser?.id || Number(subject.appUser);
    // match parents name
    return (
      family.appUsers
        // @ts-ignore
        ?.filter?.(
          (appUser: AppUser) => String(appUser.id) === String(appUserId),
        )
        .some((user) => fullName(user).includes(lowerSearchText))
    );
  }
};

const ListEmptyComponent = () => {
  return (
    <Typography
      key="empty"
      variant="caption"
      sx={{ paddingLeft: 1, paddingTop: 1, fontSize: '0.875rem' }}
    >
      {'Aucune question ne correspond à ce(s) filtre(s)'}
    </Typography>
  );
};

let keyExtractor = (
  index: number,
  data: { subject: Discussion; family?: string }[],
) => {
  return data[index]?.subject.id ?? index;
};

const GetMoreQuestionFab = () => {
  const activeTab = useActiveTab();
  const [blocked, setBlocked] = useState(false);
  const { mutate, isLoading } = useGiveMeMore();
  const { data: sessions } = useActiveSessions();
  const userId = useSelector((state) => state.user.userId);
  const active = sessions?.some((s) => String(s) === String(userId));

  useEffect(() => {
    if (blocked) {
      const timeout = setTimeout(() => {
        setBlocked(false);
      }, 1000 * 30);
      return () => clearTimeout(timeout);
    }
  }, [blocked]);

  return active && activeTab === ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS ? (
    <Fab
      color="primary"
      aria-label="add"
      size="small"
      sx={{
        position: 'absolute',
        bottom: 8,
        right: 8,
      }}
      disabled={isLoading || blocked}
      onClick={() => {
        logRequestMoreAttributions();
        mutate(undefined, { onSuccess: () => setBlocked(true) });
      }}
    >
      {isLoading ? <CircularProgress size="small" /> : <AddIcon />}
    </Fab>
  ) : null;
};

export const SubjectList = ({
  loading,
  subjects,
  uncategorizedSubjects,
  searchText,
}: {
  loading: boolean;
  subjects?: Discussion[];
  uncategorizedSubjects: Discussion[];
  searchText?: string;
}) => {
  const isRevive = useSubjectFilter() === Filters.REVIVE;
  const [itemHeight, setItemHeight] = useState(isRevive ? 88 : 76);

  const { data: families } = useDiscussionFamilies(subjects);
  const { data: uncatFamilies } = useDiscussionFamilies(uncategorizedSubjects);
  const translate = useTranslate();

  const displayedSubjects = useMemo(() => {
    return subjects
      ?.map((subject, index) => ({ subject, family: families[index] }))
      ?.filter(
        ({ subject: s }, index) =>
          !searchText ||
          (families[index] && isMatched(s, families[index], searchText)),
      );
  }, [searchText, subjects, families]);

  const displayedUncatSubjects = useMemo(() => {
    return uncategorizedSubjects
      ?.map((subject, index) => ({ subject, family: uncatFamilies[index] }))
      ?.filter(
        ({ subject: s }, index) =>
          !searchText ||
          (uncatFamilies[index] &&
            isMatched(s, uncatFamilies[index], searchText)),
      );
  }, [searchText, uncategorizedSubjects, uncatFamilies]);

  // fetch all users at once
  const maySenders = useMemo(
    () => subjects.map((subject) => subject.lastMaySender),
    [subjects],
  );
  useGetVaryingMany('users', maySenders);

  const SubjectCardRender = useCallback(
    ({
      data,
      index,
      style,
    }: {
      data: {
        subject: Discussion;
        family: FamilyPopulated;
      }[];
      index: number;
      style: CSSProperties;
    }) => {
      const { subject, family } = data[index];
      return (
        <div style={style}>
          <View
            onLayout={(event) => {
              setItemHeight(event.nativeEvent.layout.height);
            }}
          >
            <SubjectCard
              item={subject}
              searchText={searchText}
              family={family}
            />
          </View>
        </div>
      );
    },
    [searchText],
  );

  if (loading) {
    return (
      <Box
        key="loading"
        sx={{
          paddingTop: 1,
          paddingLeft: 1,
        }}
      >
        <CircularProgress color="primary" size={24} />
      </Box>
    );
  }

  return subjects?.length ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {Boolean(uncategorizedSubjects?.length) && (
        <>
          <Box
            sx={{
              paddingTop: 1,
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: '0.75rem',
            }}
          >
            <Typography
              sx={{
                paddingLeft: '0.3rem',
                fontWeight: 600,
              }}
              variant="body1"
            >
              {translate('subject.uncategorized')}
            </Typography>
            <Chip label="IAO" size="small" />
          </Box>
          <Box
            className="scrollbar"
            sx={{
              width: '100%',
              maxHeight: '40%',
              position: 'relative',
              overflow: 'auto',
              borderBottom: `1px solid ${COLORS.GREY_LAYOUT}`,
            }}
          >
            {displayedUncatSubjects.map(({ subject, family }) => (
              <SubjectCard
                key={subject.id}
                item={subject}
                searchText={searchText}
                family={family}
              />
            ))}
          </Box>
          <Typography
            variant="body1"
            sx={{ paddingLeft: '0.3rem', fontWeight: 600, paddingTop: 1 }}
          >
            {translate('subject.myQuestions')}
          </Typography>
        </>
      )}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="scrollbar"
              height={height}
              itemData={displayedSubjects}
              itemCount={displayedSubjects.length}
              itemSize={itemHeight}
              itemKey={keyExtractor}
              width={width}
            >
              {SubjectCardRender}
            </List>
          )}
        </AutoSizer>
        <GetMoreQuestionFab />
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <ListEmptyComponent />
      <GetMoreQuestionFab />
    </Box>
  );
};
