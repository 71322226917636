import {
  BooleanField,
  Datagrid,
  EditButton,
  ImageField,
  TextField,
} from 'react-admin';

import { List } from '../../components/generic/List';

export const ConsultationCategoriesList = (props) => (
  <List {...props} title="Consultation categories">
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="Nom" />
      <ImageField
        source="image"
        label="Image"
        sx={{ '& > img': { maxWidth: 250, maxHeight: 250 } }}
      />
      <BooleanField source="active" label="Actif" />
      <EditButton />
    </Datagrid>
  </List>
);
