import { Fragment } from 'react';
import { Datagrid, EditButton, NumberField, TextField } from 'react-admin';

import { List } from '../../components/generic/List';

export const PricingProCustomList = () => {
  return (
    <List title={<Fragment />} hasCreate={true} resource={'pricing-pro-custom'}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="Nom" source="name" />
        <NumberField label="Base" source="pricePerUnitBase" />
        <NumberField label="Nuit" source="pricePerUnitNight" />
        <NumberField label="Férié" source="pricePerUnitSundayAndHoliday" />
        <NumberField label="Coeff astreinte" source="onCallNonActivatedRate" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
