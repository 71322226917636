import {
  Pressable,
  PressableProps,
  RegisteredStyle,
  StyleProp,
  ViewStyle,
} from 'react-native';

export const PressableOpacity = (
  props: PressableProps & {
    style?: StyleProp<ViewStyle> | RegisteredStyle<ViewStyle>;
  },
) => (
  <Pressable
    {...props}
    style={({ pressed }) => [
      { opacity: pressed ? 0.5 : 1.0 },
      ...(Array.isArray(props.style) ? props.style : [props.style]),
    ]}
  />
);
