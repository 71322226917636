import { NodeProps, useReactFlow } from 'reactflow';

import { ShyText } from '@components/fields';
import { Box } from '@mui/material';
import { Condition, Template } from '@teammay/form-core';

import { TemplateNodeType } from '../../types';
import { isTemplateValid } from '../../utils/validityCheck';
import { EditNodeButton } from '../EditNodeButton';
import { HideHandle } from '../handles/HideHandle';
import { MenuWrapper } from '../MenuWrapper';
import {
  ImageTemplate,
  ImageTemplateEdit,
} from '../templates/ImageTemplateEdit';
import { WarningTile } from '../WarningTile';

export function TemplateNode({
  id,
  data: { template, onChange },
}: NodeProps<TemplateNodeType['data']>) {
  const { setNodes } = useReactFlow();
  const handleChanged = (result: Partial<Template>) => {
    onChange({ updatedTemplate: result as Template });
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, template: result };
        }
        return node;
      }),
    );
  };

  const invalidFields = isTemplateValid(template);
  return (
    <>
      <MenuWrapper
        content={template as ImageTemplate}
        onChange={handleChanged}
        FormComponent={ImageTemplateEdit}
        onDelete={() => {
          onChange({ deletedTemplates: template.id });
        }}
      >
        <WarningTile invalidFields={invalidFields} />
        <Box sx={{ width: '100%', height: '100%', p: 1 }}>
          <ShyText source="title" record={template} oneline />
          {/* <ShyText source="image" record={template} oneline /> */}
        </Box>
      </MenuWrapper>
      <EditNodeButton
        content={template as ImageTemplate}
        onChange={handleChanged}
        FormComponent={ImageTemplateEdit}
      />
      <HideHandle
        id={id}
        type="question"
        content={template}
        onClick={(condition: Condition) =>
          onChange({
            addHideCondition: { templateId: template.id, condition },
          })
        }
      />
    </>
  );
}
