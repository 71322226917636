import { Fragment } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { Roles } from '@boTypes/user';
import { CreateButton } from '@components/generic/CreateButton';
import { EditButton } from '@components/generic/EditButton';
import { MayTopToolbar } from '@components/mayTopToolbar';
import { Box, Typography } from '@mui/material';

const AffiliationCreateButton = () => {
  const { id } = useParams<'id'>();
  const record = { patientId: id };

  return (
    <CreateButton
      redirect={`/patients/${id}`}
      record={record}
      ctaText="affiliations.create"
    />
  );
};

const Toolbar = () => {
  const { permissions } = usePermissions();
  const displayCreateButton = permissions?.includes(Roles.ADMIN);
  const translate = useTranslate();
  return (
    <MayTopToolbar>
      <Typography variant="subtitle2">
        {translate('affiliations.title')}
      </Typography>
      {displayCreateButton && <AffiliationCreateButton />}
    </MayTopToolbar>
  );
};

const EmptyList = () => {
  const translate = useTranslate();
  return (
    <Box sx={{ paddingTop: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="body2" sx={{ ml: 0.5 }}>
        {translate('affiliations.empty')}
      </Typography>
    </Box>
  );
};

export const InlineAffiliationsList = () => {
  const { id } = useParams<'id'>();
  const { permissions } = usePermissions();

  return (
    <List
      resource="affiliations"
      exporter={false}
      pagination={null}
      perPage={50}
      filter={{ patientId: id }}
      actions={<Toolbar />}
      empty={<EmptyList />}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="mode" />
        <TextField source="description" />
        <DateField label="Date de création" source="createdAt" />
        <DateField label="Date d'expiration" source="expiresAt" />
        <BooleanField source="active" />
        <NumberField
          label="Nb consultations offertes"
          source="consultationsNumber"
        />
        {permissions?.includes(Roles.ADMIN) && <EditButton />}
      </Datagrid>
    </List>
  );
};
