import { BooleanInput, SimpleForm, TextInput, required } from 'react-admin';

import type { FormProps } from '../../types';

export const CategoriesForm = (props: FormProps) => (
  <SimpleForm {...props}>
    <TextInput
      label="Nom"
      source="name"
      validate={required()}
      variant="standard"
    />
    <TextInput
      label="Slug"
      source="slug"
      validate={required()}
      helperText="Identifiant unique. Ne pas mettre à jour."
      variant="standard"
    />
    <BooleanInput label="Grossesse" source="pregnancy" variant="standard" />
    <BooleanInput
      label="Pas pour les IAO"
      source="excludeFromTriage"
      variant="standard"
      defaultValue={false}
    />
  </SimpleForm>
);
