import { useTranslate } from 'react-admin';
import { StyleSheet, Text, View } from 'react-native';

import { COLORS } from '../../../../themes';
import { LiviIcon } from '../liviIcon';

const partnerStyles = StyleSheet.create({
  container: {
    padding: 8,
    alignItems: 'center',
    backgroundColor: COLORS.GREEN['500'],
    height: 60,
    width: 280,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    flexDirection: 'column',
    paddingLeft: 16,
  },
  logo: {
    backgroundColor: COLORS.WHITE_TEXT,
    borderRadius: 8,
    width: 48,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: COLORS.WHITE_TEXT,
    maxWidth: '75%',
    fontSize: 16,
  },
  description: {
    color: COLORS.WHITE_TEXT,
    fontSize: 12,
  },
});

export const PartnerMessage = ({ partner }: { partner: string }) => {
  const translate = useTranslate();

  if (partner !== 'livi') {
    return null;
  }
  return (
    <View style={partnerStyles.container}>
      <View style={partnerStyles.logo}>
        <LiviIcon />
      </View>
      <View style={partnerStyles.text}>
        <Text style={partnerStyles.title}>
          {translate('partner.livi.title')}
        </Text>
        <Text style={partnerStyles.description}>
          {translate('partner.livi.description')}
        </Text>
      </View>
    </View>
  );
};
