import { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import AutoSizer from 'react-virtualized-auto-sizer';

import { SubjectExplorer, SubjectExplorerPopulated } from '@boTypes/subject';
import { useDiscussionFamilies } from '@hooks/family';
import { Box, CircularProgress, Typography } from '@mui/material';

import { SubjectListWrapper } from './subjectExplorerListWrapper';

const ListEmptyComponent = () => {
  const translate = useTranslate();

  return (
    <Typography
      variant="caption"
      sx={{ paddingLeft: 1, paddingTop: 1, fontSize: '0.875rem' }}
    >
      {translate('subjectExplorer.noSubjectWithFilters')}
    </Typography>
  );
};

export const SubjectExplorerList = ({
  loading,
  subjects,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}: {
  loading: boolean;
  subjects: SubjectExplorer[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
}) => {
  const { data: families } = useDiscussionFamilies(
    subjects?.map((subject) => subject.discussion),
  );
  const subjectsAndFamily: SubjectExplorerPopulated[] = useMemo(
    () =>
      subjects?.map((subject, index) => ({ subject, family: families[index] })),
    [subjects, families],
  );

  if (loading) {
    return (
      <Box
        key="loading"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <CircularProgress color="primary" size="small" />
      </Box>
    );
  }

  return subjectsAndFamily?.length ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <SubjectListWrapper
              hasNextPage={hasNextPage}
              isNextPageLoading={isFetchingNextPage}
              items={subjectsAndFamily}
              loadNextPage={fetchNextPage}
              height={height}
              width={width}
            />
          )}
        </AutoSizer>
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <ListEmptyComponent />
    </Box>
  );
};
