import React from 'react';
import { Loading } from 'react-admin';
import Carousel from 'react-material-ui-carousel';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { styled } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';

import { logContentView } from '../../analytics/events';
import { useGuideItem } from '../../hooks/cms';
import { COLORS } from '../../themes';
import { Guide } from '../../types';
import { SendIcon } from '../discussions/SendIcon';

export const WIDTH = 148;
export const HEIGHT = 96;

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  '&:first-of-type': {
    padding: 0,
  },
  backgroundColor: COLORS.WHITE,
});

const StyledPaper = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  height: `calc(100vh - 96px)`,
  maxHeight: '600px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledImage = styled('img')({
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  alignSelf: 'center',
});

interface GuideCarouselProps {
  slug: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GuideCarousel = ({ slug, open, setOpen }: GuideCarouselProps) => {
  const { data, isLoading } = useGuideItem(slug);
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <StyledDialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Carousel
            autoPlay={false}
            className={'carousel'}
            navButtonsAlwaysVisible={data?.files.length > 1}
            indicatorContainerProps={{
              className: 'carouselIndicatorContainer',
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: COLORS.TEXT,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                color: COLORS.GREY_LAYOUT,
              },
            }}
            animation="slide"
          >
            {(data?.files ?? []).map((file, index) => (
              <StyledPaper key={index}>
                <StyledImage src={file.url} />
              </StyledPaper>
            ))}
          </Carousel>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

export const GuideItem = ({
  item,
  onSend,
}: {
  item: Guide;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
}) => {
  const { title } = item;
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    logContentView('guide', item.id);
    setOpen(true);
  };

  const itemInfo = {
    type: DiscussionEventType.GUIDE,
    content: item.slug,
  };

  return (
    <CMSCard
      width={WIDTH}
      height={HEIGHT}
      sx={{ backgroundColor: COLORS.BLUE_DARK['500'] }}
    >
      {open ? (
        <GuideCarousel open={open} setOpen={setOpen} slug={item.slug} />
      ) : null}
      <CMSCardActionArea
        onClick={onClick}
        height={HEIGHT}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        {item.sent && <CMSCardSentOverlay />}
        <CMSCardContent
          sx={{
            color: `${COLORS.WHITE_TEXT}`,
            backgroundColor: COLORS.BLUE_DARK['500'],
          }}
        >
          <CMSCardTitle variant="subtitle2" component="h2" clamp={4}>
            {title}
          </CMSCardTitle>
          {item.sent && (
            <DownloadDoneIcon
              sx={(theme) => ({
                position: 'absolute',
                left: theme.spacing(1),
                bottom: theme.spacing(0),
              })}
            />
          )}
        </CMSCardContent>
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0, position: 'relative', zIndex: 20 }}>
          <SendIcon
            onClick={() => onSend(itemInfo, DiscussionEventType.GUIDE, item.id)}
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};

export default GuideItem;
