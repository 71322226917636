import { Discussion } from '@boTypes/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const chatMemorySlice = createSlice({
  name: 'chatMemory',
  initialState: {} as Record<Discussion['id'], string>,
  reducers: {
    setDiscussionMessage(
      state,
      {
        payload: { message, discussionId },
      }: PayloadAction<{ message: string; discussionId: Discussion['id'] }>,
    ) {
      if (message) {
        state[discussionId] = message;
      } else {
        delete state[discussionId];
      }
      return state;
    },
  },
});

export const { setDiscussionMessage } = chatMemorySlice.actions;

export default chatMemorySlice.reducer;
