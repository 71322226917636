import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import DownloadIcon from '@mui/icons-material/Download';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { openNewSecureTab } from '@utils/urls';

import { AdminPlanning } from './components/adminPlanning';
import { PlanningHoursValidation } from './components/planningHoursValidation';
import { PlanningSummary } from './components/planningSummary';
import { ToggleButtons } from './components/toggleButtons';
import { getPlanningInterval } from './utils';
import { baseURL } from '../../common';
import { Loader } from '../../components/Loader';
import {
  usePlanningAttributions,
  usePlanningDetail,
} from '../../hooks/plannings';
import { PlanningStatus } from '../../types/planning';

export const PlanningResults = () => {
  const { planningId } = useParams();
  const [agendaDate, setAgendaDate] = useState<Date>();
  const [displayedResult, setDisplayedResult] = useState<
    'attributions' | 'summary' | 'availabilities' | 'additionalHours'
  >('attributions');

  const { data: planning, isLoading } = usePlanningDetail(planningId);

  // do not put in useEffect or other usePlanningDetail call will fire the onSuccess
  useEffect(() => {
    if (planning?.slots) {
      // if current date is not in planning, set agenda date to first slot. Else keep current date
      const [start, end] = getPlanningInterval({ slots: planning.slots });
      if (!agendaDate) {
        const now = new Date();
        if (now.getTime() < start.getTime() || now.getTime() > end.getTime()) {
          setAgendaDate(start);
        } else {
          setAgendaDate(now);
        }
      } else if (
        agendaDate.getTime() < start.getTime() ||
        agendaDate.getTime() > end.getTime()
      ) {
        setAgendaDate(start);
      }
    }
    // do not trigger on agendaDate change : we only want it once to focus the agenda in the good dates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planning?.slots]);

  useEffect(() => {
    if (
      planning?.status &&
      planning.status !== PlanningStatus.CALCULATION_PERFORMED &&
      planning.status !== PlanningStatus.DONE
    ) {
      setDisplayedResult('availabilities');
    }
  }, [planning?.status]);
  const { isLoading: isLoadingAttributions, data: attributions } =
    usePlanningAttributions(planningId);

  if (isLoading || isLoadingAttributions || !planning) {
    return <Loader />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        padding: '0.5rem',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          flex: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 0,
          }}
        >
          <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
            {planning.title}
          </Typography>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {planning.status === PlanningStatus.CALCULATION_PERFORMED ||
            planning.status === PlanningStatus.DONE ? (
              <ToggleButtons<
                | 'attributions'
                | 'summary'
                | 'availabilities'
                | 'additionalHours'
              >
                value={displayedResult}
                options={{
                  attributions: `Attributions ${attributions.length}/${planning.slots.length}`,
                  availabilities: 'Disponibilités',
                  summary: 'Résumé',
                  additionalHours: 'Renfort',
                }}
                onChange={setDisplayedResult}
              />
            ) : (
              <ToggleButtons<'attributions' | 'availabilities' | 'summary'>
                value={displayedResult}
                options={{
                  attributions: 'Attributions',
                  availabilities: 'Disponibilités',
                  summary: 'Résumé',
                }}
                onChange={setDisplayedResult}
              />
            )}
            {(planning.status === PlanningStatus.CALCULATION_PERFORMED ||
              planning.status === PlanningStatus.DONE) && (
              <Button
                onClick={() =>
                  openNewSecureTab(
                    `${baseURL}/api/planning/${planningId}/simplifiedOutput.csv`,
                  )
                }
                startIcon={<DownloadIcon />}
                sx={{ m: 1 }}
              >
                CSV
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {['attributions', 'availabilities'].includes(displayedResult) && (
        <AdminPlanning
          // @ts-ignore
          display={displayedResult}
          date={agendaDate}
          onChangeDate={setAgendaDate}
        />
      )}
      {displayedResult === 'summary' && <PlanningSummary />}
      {displayedResult === 'additionalHours' && (
        <PlanningHoursValidation job={planning?.job} />
      )}
    </Box>
  );
};
