import { useTranslate } from 'react-admin';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useNodes } from 'reactflow';

import { SelectInput } from '@components/forms/selectInput';
import { Button } from '@components/generic/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import { BooleanOperator, Condition, Operator } from '@teammay/form-core';

import { DataRuleEdit } from './DataRuleEdit';
import { QuestionRuleEdit } from './QuestionRuleEdit';
import { useAvailableDataSlugs } from '../../hooks/useAvailableDataSlugs';
import { QuestionNodeType } from '../../types';

export type ConditionForm = Condition;

export const ConditionEdit = ({ onBlur }: { onBlur: () => void }) => {
  const { control } = useFormContext<ConditionForm>();
  const translate = useTranslate();

  const {
    fields: questionRules,
    append: appendQuestionRules,
    remove: removeQuestionRules,
  } = useFieldArray({
    control,
    name: 'questionRules',
  });
  const {
    fields: dataRules,
    append: appendDataRules,
    remove: removeDataRules,
  } = useFieldArray({
    control,
    name: 'dataRules',
  });
  const booleanOperator = useWatch({ control, name: 'booleanOperator' });
  const availableDataSlugs = useAvailableDataSlugs();
  const questions = (
    useNodes().filter((node) => node.type === 'question') as QuestionNodeType[]
  ).map((node) => node.data.question);

  return (
    <>
      <SelectInput
        variant="outlined"
        control={control}
        name={`booleanOperator`}
        label={translate('forms.conditions.booleanOperator')}
        defaultValue={booleanOperator || BooleanOperator.AND}
      >
        <MenuItem key={BooleanOperator.AND} value={BooleanOperator.AND}>
          {BooleanOperator.AND}
        </MenuItem>
        <MenuItem key={BooleanOperator.OR} value={BooleanOperator.OR}>
          {BooleanOperator.OR}
        </MenuItem>
      </SelectInput>
      <Typography variant="h5" color="primary" noWrap>
        {translate('forms.conditions.questionRules.title')}
      </Typography>
      {questionRules.map((field, index) => (
        <Box key={field.id} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={() => removeQuestionRules(index)}>
            <DeleteIcon />
          </IconButton>
          <QuestionRuleEdit field={field} index={index} onBlur={onBlur} />
        </Box>
      ))}
      <Button
        onClick={() => {
          appendQuestionRules({
            id: self.crypto.randomUUID(),
            expectedValue: '',
            questionId: questions[0].id,
            operator: Operator.ALWAYS_TRUE,
          });
          onBlur();
        }}
      >
        {translate('forms.conditions.questionRules.add')}
      </Button>
      <Typography variant="h5" color="primary" noWrap>
        {translate('forms.conditions.dataRules.title')}
      </Typography>
      {dataRules.map((field, index) => (
        <Box key={field.id} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={() => removeDataRules(index)}>
            <DeleteIcon />
          </IconButton>
          <DataRuleEdit field={field} index={index} onBlur={onBlur} />
        </Box>
      ))}
      <Button
        onClick={() => {
          appendDataRules({
            id: self.crypto.randomUUID(),
            expectedValue: '',
            dataSlug: availableDataSlugs[0] ?? '',
            operator: Operator.ALWAYS_TRUE,
          });
          onBlur();
        }}
      >
        {translate('forms.conditions.dataRules.add')}
      </Button>
    </>
  );
};
