import { Paper } from '@mui/material';

import { LibraryList } from './library';

export const Library = () => (
  <Paper
    elevation={0}
    sx={{
      padding: 0,
      margin: 0,
      marginTop: '1rem',
      height: '100%',
      overflow: 'auto',
    }}
  >
    <LibraryList />
  </Paper>
);
