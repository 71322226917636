import { Button, DialogTitle, DialogActions, Dialog } from '@mui/material';

export const ConfirmationDialog = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open}>
    <DialogTitle id="confirmation-dialog-title">
      Êtes vous sûr(e) de vouloir vous déconnecter?
    </DialogTitle>
    <DialogActions>
      <Button onClick={onClose} variant="contained" color="primary">
        Me déconnecter
      </Button>
    </DialogActions>
  </Dialog>
);
