import { useAuthenticated, useRedirect } from 'ra-core';
import { useEffect } from 'react';

import { useSelector } from '../store';
import { JobTitle } from '../types/user';
import { menuFilterFunction } from '../utils/accessControl';

export const RedirectAfterLogin = () => {
  const redirect = useRedirect();
  useAuthenticated();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user?.roles) {
      return;
    }
    // if user has access to discussions, redirect to discussions else to library
    if (menuFilterFunction(user.roles)({ name: 'nursesubjects', icon: true })) {
      if (!user?.jobTitle) {
        return;
      }
      if (user.jobTitle === JobTitle.MIDWIFE) {
        redirect('/midwifesubjects');
      } else {
        redirect('/nursesubjects');
      }
    } else {
      redirect('/library');
    }
  }, [redirect, user]);

  return null;
};
