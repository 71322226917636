import { Controller, Path } from 'react-hook-form';

import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

import { ExposedControllerProps } from './type';

type CheckboxInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<FormControlLabelProps, 'checked' | 'control'> &
  CheckboxProps;

export function CheckboxInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  sx,
  ...other
}: CheckboxInputProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => (
        <FormControlLabel
          disabled={formState.isSubmitting}
          control={
            <Checkbox
              checked={field.value ?? false}
              onChange={(e) => field.onChange(e.target.checked)}
              sx={sx}
              size={other.size}
            />
          }
          label={label ?? name}
          {...other}
        />
      )}
    />
  );
}
