import { AxiosError } from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { Subject } from '@boTypes/subject';
import {
  SubjectImpact,
  SubjectIntent,
  subjectImpactChoices,
} from '@boTypes/subjectImpact';
import { useQuery, useMutation } from '@hooks/queryWrappers';
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  MenuItem,
  Typography,
} from '@mui/material';

import { SelectInput } from '../forms/selectInput';
import { Button } from '../generic/Button';
import { DialogTitle } from '../generic/Dialog';
import { Loader } from '../Loader';

export const SubjectImpactDialog = React.memo(
  ({
    open,
    setOpen,
    subjectId,
    callback,
  }: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    subjectId: Subject['id'];
    callback?: () => void;
  }) => {
    const close = useCallback(() => setOpen(false), [setOpen]);
    const translate = useTranslate();

    const { data: subjectImpact, isLoading } = useQuery<SubjectImpact>(
      ['subjectImpact', subjectId],
      {
        url: `/api/subjects-impact/${subjectId}`,
        method: 'get',
      },
      {
        enabled: Boolean(subjectId && open),
        retry: false,
      },
    );

    const { mutateAsync } = useMutation<
      SubjectImpact,
      AxiosError,
      SubjectImpact
    >(
      ['subjectImpact'],
      (values: SubjectImpact) => ({
        method: subjectImpact?.id ? 'put' : 'post',
        url: `/api/subjects-impact${
          subjectImpact?.id ? `/${subjectImpact.id}` : ''
        }`,
        data: { ...values, subjectId: Number(subjectId) },
      }),
      {
        onSuccess: () => {
          callback?.();
          close();
        },
      },
    );

    const { handleSubmit, control, reset, setValue, formState } =
      useForm<SubjectImpact>({
        defaultValues: {
          subjectId,
          initialIntent: subjectImpact?.initialIntent ?? SubjectIntent.MAY,
          finalIntent: subjectImpact?.finalIntent ?? SubjectIntent.NA,
        },
      });

    useEffect(() => {
      if (!subjectImpact) {
        return;
      }
      setValue(
        'initialIntent',
        subjectImpact?.initialIntent ?? SubjectIntent.MAY,
      );
      setValue('finalIntent', subjectImpact?.finalIntent ?? SubjectIntent.NA);
    }, [subjectImpact, setValue]);

    useEffect(() => {
      if (!open) {
        reset();
      }
    }, [open, reset]);

    const onSubmit = handleSubmit(async (values: SubjectImpact) => {
      await mutateAsync(values);
    });
    const { isSubmitting } = formState;

    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby={translate('subjectImpact.title')}
        aria-describedby={translate('subjectImpact.description')}
        maxWidth="md"
      >
        <DialogTitle onClose={close}>
          {translate('subjectImpact.title')}
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent sx={{ padding: '0 1.5rem 0.5rem 1.5rem' }}>
            <>
              <Alert severity="info" sx={{ marginBottom: '1rem' }}>
                <AlertTitle>Mesure d'impact</AlertTitle>
                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                  Nous souhaitons mesurer l'impact de May sur le recours au
                  système de santé, en comparant :
                </Typography>
                <List dense>
                  <ListItem>
                    <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                      - L'intention initiale du (futur) parent : que prévoit-il
                      de faire au moment où il vient poser sa question ?
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                      - L'orientation proposée par May : que lui suggérons nous
                      de faire ?
                    </Typography>
                  </ListItem>
                </List>
                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                  Si vous avez un gros doute, ou si vous ne savez pas,
                  renseignez "Discussion May".
                </Typography>
              </Alert>

              {isLoading ? (
                <Loader sx={{ marginTop: '1rem' }} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <SelectInput
                    name="initialIntent"
                    label="Intention initiale"
                    control={control}
                    variant="standard"
                  >
                    {subjectImpactChoices.map(({ id, name }) => (
                      <MenuItem value={id} key={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                  <SelectInput
                    name="finalIntent"
                    label="Orientation proposée"
                    control={control}
                    variant="standard"
                  >
                    {subjectImpactChoices.map(({ id, name }) => (
                      <MenuItem value={id} key={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Box>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ flex: 1, margin: '1rem' }}
              variant="contained"
              type="submit"
              loading={isSubmitting}
              color="primary"
              value={subjectImpact?.id ? 'Modifier' : 'Créer'}
              fullWidth
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  },
);
