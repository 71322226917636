import { useState } from 'react';

import { AuthCard } from './components/authCard';
import { LoginForm } from './LoginForm';
import { ResetForm } from './reset/ResetForm';

export const Auth = ({
  successCallback,
}: {
  successCallback: (data: any) => void;
}) => {
  const [resetPassword, setResetPassword] = useState<boolean>(false);

  return resetPassword ? (
    <AuthCard hint="Entrez votre mail">
      <ResetForm reset={setResetPassword} />
    </AuthCard>
  ) : (
    <AuthCard hint="Veuillez entrer vos codes d'accès">
      <LoginForm successCallback={successCallback} reset={setResetPassword} />
    </AuthCard>
  );
};
