import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  required,
} from 'react-admin';

import { Typography } from '@mui/material';

import { ConsultationToolbar } from './components/ConsultationToolbar';

export const FormConsultation = (props) => {
  return (
    <Edit redirect={false} {...props} mutationMode="pessimistic">
      <Typography
        variant="h6"
        color="primary"
        sx={{ mb: 1, fontWeight: 'bold', padding: '1rem 1rem 0 1rem' }}
      >
        Compte-rendu
      </Typography>
      <SimpleForm toolbar={<ConsultationToolbar />} warnWhenUnsavedChanges>
        <ReferenceInput
          label="Thématique"
          source="consultationCategoryId"
          reference="consultation-categories"
          variant="standard"
        >
          <SelectInput
            label="Thématique"
            optionText="name"
            variant="standard"
            validate={required()}
          />
        </ReferenceInput>
        <TextInput
          multiline
          label="Compte-rendu"
          source="report"
          fullWidth
          variant="standard"
        />
        <TextInput
          multiline
          label="Contenu email destiné au parent"
          source="patientEmailContent"
          fullWidth
          variant="standard"
        />
      </SimpleForm>
    </Edit>
  );
};
