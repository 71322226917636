import { BooleanInput, SimpleForm, TextInput, required } from 'react-admin';

export const ScenariosForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      label="Nom"
      source="name"
      validate={required()}
      variant="standard"
    />
    <TextInput
      multiline
      label="Description"
      source="description"
      validate={required()}
      variant="standard"
    />
    <BooleanInput
      defaultValue={false}
      label="legacy"
      source="legacy"
      variant="standard"
    />
  </SimpleForm>
);
