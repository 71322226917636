import { useResourceContext, useTranslate } from 'react-admin';

import AddIcon from '@mui/icons-material/Add';

import { Button } from './Button';

export const CreateButton = ({
  redirect,
  record,
  ctaText,
}: {
  redirect?: string;
  record?: Object;
  ctaText?: string;
}) => {
  const resource = useResourceContext();
  const translate = useTranslate();

  return (
    <Button
      startIcon={<AddIcon />}
      to={{
        pathname: `/${resource}/create`,
        state: {
          redirect,
          record,
        },
      }}
      color="primary"
    >
      {translate(ctaText || 'common.create')}
    </Button>
  );
};
