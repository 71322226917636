import {
  BooleanField,
  ChipField,
  Datagrid,
  EditButton,
  ListProps,
  NumberField,
  TextField,
} from 'react-admin';

import { List } from '../../components/generic/List';

export const CategoriesList = (props: ListProps) => (
  <List {...props} title="Categories">
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="Nom" />
      <TextField source="slug" label="Slug" />
      <BooleanField source="pregnancy" label="Grossesse" />
      <BooleanField source="active" label="Actif" />
      <ChipField source="metaCategory" label="meta" />
      <NumberField source="priorityOrder" label="Priorité" />
      <EditButton />
    </Datagrid>
  </List>
);
