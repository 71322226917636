import { createContext, useContext, ReactNode } from 'react';

import { DiscussionContext } from '../common';

const DiscussionContextContext = createContext<DiscussionContext>(
  DiscussionContext.NURSE,
);

export const useDiscussionContext = () => {
  return useContext(DiscussionContextContext);
};

export const DiscussionContextProvider = ({
  discussionContext,
  children,
}: {
  discussionContext: DiscussionContext;
  children: ReactNode;
}) => {
  return (
    <DiscussionContextContext.Provider value={discussionContext}>
      {children}
    </DiscussionContextContext.Provider>
  );
};
