import dayjs from 'dayjs';
import { MouseEvent } from 'react';
import { useRecordContext } from 'react-admin';

import { Chip, ChipProps } from '@mui/material';

import { Pregnancy } from '../../types';

export const PregnancyDateChipField = ({
  size,
  onClick = () => {},
  ...props
}: {
  size?: 'small' | 'medium';
  onClick?: (clickEvent: MouseEvent<HTMLElement>) => void;
} & ChipProps) => {
  const record = useRecordContext<Pregnancy>();

  return (
    <Chip
      {...props}
      label={dayjs(record.expectedEnd).format('DD/MM/YYYY')}
      size={size}
      onClick={onClick}
    />
  );
};
