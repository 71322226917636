import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { logFilter } from '../analytics/events';
import { DiscussionContext } from '../common';
import { useSelector } from '../store';
import { setQuickFilter } from '../store/discussionFilters';
import { DiscussionQuickFilter } from '../types/discussion';

export const useQuickFilterState = (
  filterType: DiscussionContext,
): [DiscussionQuickFilter, (filter: DiscussionQuickFilter) => void] => {
  const quickFilter = useSelector(
    (state) => state.discussionFilters[filterType],
  );
  const dispatch = useDispatch();

  const onFilterClick = useCallback(
    (filter: DiscussionQuickFilter) => {
      dispatch(setQuickFilter({ filter, filterType }));
      logFilter(filter);
    },
    [dispatch, filterType],
  );

  return [quickFilter, onFilterClick];
};
