import { useTranslate } from 'react-admin';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';

import { Button } from '@components/generic/Button';
import { usePrompt } from '@hooks/usePrompt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress, Drawer } from '@mui/material';
import { Question, QuestionRule, Template } from '@teammay/form-core';

export type EditComponent = React.ComponentType<{
  onBlur: () => void;
}>;

export type EditWrapperProps<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
> = {
  content: Content;
  FormComponent: EditComponent;
  open: boolean;
  onCancel: (
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
  onSave: (content: Content) => void;
  onChange?: (content: Content) => void;
  defaultValues?: Partial<Content>;
};

function EditWrapperInner<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>({
  content,
  onChange,
  onSave,
  onCancel,
  FormComponent,
  defaultValues = {},
}: Omit<EditWrapperProps<Content>, 'open'>) {
  const translate = useTranslate();
  const form = useForm<Content>({
    defaultValues: { ...defaultValues, ...content } as DefaultValues<Content>,
    mode: 'onTouched',
  });
  const { handleSubmit, reset, formState } = form;

  const { isLoading, isDirty } = formState;
  usePrompt(translate('generic.notSaved'), isDirty);

  const handleChange = handleSubmit((data: Content) => {
    onChange?.(data);
  });
  const submit = handleSubmit((data: Content) => {
    onSave(data);
  });

  return (
    <>
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          py: 2,
          px: 4,
        }}
      >
        <FormProvider {...form}>
          <FormComponent onBlur={handleChange} />
          <Button
            variant={'contained'}
            size="small"
            color="primary"
            onClick={() => submit()}
            startIcon={
              isLoading ? (
                <CircularProgress size="small" />
              ) : (
                <SaveIcon sx={{ color: 'white' }} />
              )
            }
            loading={isLoading}
          >
            {translate('generic.save')}
          </Button>
          <Button
            variant={'outlined'}
            size="small"
            color="primary"
            onClick={() => {
              reset();
              onCancel('cancelClicked');
            }}
            startIcon={
              isLoading ? (
                <CircularProgress size="small" />
              ) : (
                <RestartAltIcon color="primary" />
              )
            }
            loading={isLoading}
          >
            {translate('generic.reset')}
          </Button>
        </FormProvider>
      </Box>
    </>
  );
}

export function EditWrapper<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>({ open, onCancel, ...props }: EditWrapperProps<Content>) {
  return (
    <Drawer
      open={open}
      anchor="left"
      onClose={(_, reason) => {
        onCancel(reason);
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          minWidth: '60%',
          maxWidth: '80%',
        },
      }}
      SlideProps={{
        unmountOnExit: true,
      }}
    >
      <EditWrapperInner onCancel={onCancel} {...props} />
    </Drawer>
  );
}
