import { Handle, NodeProps, Position, useNodes, useReactFlow } from 'reactflow';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { HideConditionNodeType, QuestionNodeType } from '../../types';
import { isHideConditionValid } from '../../utils/validityCheck';
import { ConditionEdit } from '../conditions/ConditionEdit';
import { MenuWrapper } from '../MenuWrapper';
import { WarningTile } from '../WarningTile';

export function HideConditionNode({
  id,
  data: { hideCondition, content },
}: NodeProps<HideConditionNodeType['data']>) {
  const { setNodes, setEdges } = useReactFlow();
  const questions = (
    useNodes().filter((n) => n.type === 'question') as QuestionNodeType[]
  ).map((n) => n.data.question);

  const invalidFields = isHideConditionValid(hideCondition, questions);

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <MenuWrapper
        content={hideCondition}
        onChange={(result) => {
          Object.assign(hideCondition, result);
        }}
        FormComponent={ConditionEdit}
        onDelete={() => {
          content.hideCondition = undefined;
          setNodes((nodes) => nodes.filter((node) => node.id !== id));
          setEdges((edges) => edges.filter((edge) => edge.target !== id));
        }}
      >
        <WarningTile invalidFields={invalidFields} />
        <VisibilityOffIcon />
      </MenuWrapper>
    </>
  );
}
