import { useCallback, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { useTranslate } from 'react-admin';
import { Control, useForm, useFormState } from 'react-hook-form';

import {
  useCreateHandover,
  useCreateHandoverRevive,
  useDeleteHandover,
  useUpdateHandover,
} from '@hooks/handover';
import { Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';

import { logCreateHandover } from '../../analytics/events';
import { DiscussionContext } from '../../common';
import { useDiscussionDetail } from '../../hooks/discussion';
import { useDiscussionContext } from '../../hooks/useDiscussionContext';
import { usePrompt } from '../../hooks/usePrompt';
import { Handover } from '../../types';
import { HandoverReviveCreate, HandoverUpdate } from '../../types/handover';
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog';
import { DialogButton } from '../DialogButton/DialogButton';
import { ReviveDialog } from '../discussions/reviveDialog';
import { TextInput } from '../forms/textInput';
import { Button } from '../generic/Button';

const HandoverFormController = ({
  control,
  title,
  onClose,
}: {
  control: Control<HandoverUpdate, any>;
  title: string;
  onClose: () => void;
}) => {
  const discussionContext = useDiscussionContext();

  const formState = useFormState({ control });
  const { isDirty } = formState;

  usePrompt(
    'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
    isDirty,
  );

  const [open, setOpen] = useState(false);
  const protectedOnClose = () => {
    if (isDirty) {
      setOpen(true);
      return;
    }
    onClose();
  };

  return (
    <>
      <ConfirmDialog
        title="Confirmer la fermeture"
        text="Vos modifications ne seront pas enregistrées, voulez vous continuer ?"
        onClick={onClose}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CloseIcon onClick={protectedOnClose} />
      </Box>
      <Box
        sx={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        {discussionContext === DiscussionContext.NURSE && (
          <TextInput
            control={control}
            name={'problem'}
            label={'Problème identifié'}
            rules={{ required: false }}
            sx={{ minWidth: 0 }}
            multiline
            autoFocus
          />
        )}

        <TextInput
          control={control}
          name={'observations'}
          label={'Observations faites'}
          rules={{ required: false }}
          sx={{ minWidth: 0 }}
          multiline
        />

        {discussionContext === DiscussionContext.NURSE && (
          <>
            <TextInput
              control={control}
              name={'advices'}
              label={'Conseils/Actions proposés'}
              rules={{ required: false }}
              sx={{ minWidth: 0 }}
              multiline
            />
          </>
        )}
      </Box>
    </>
  );
};

export const HandoverCreateForm = ({
  subjectId,
  onClose,
  activeRevives,
}: {
  subjectId: number;
  onClose: () => void;
  activeRevives: number;
}) => {
  const discussion = useDiscussionDetail();
  const notify = useNotify();
  const translate = useTranslate();

  const { handleSubmit, control, formState } = useForm<HandoverUpdate>({
    defaultValues: {
      advices: '',
      observations: '',
      problem: '',
    },
  });
  const { isSubmitting } = formState;

  const { mutateAsync: createHandoverAsync } = useCreateHandover();
  const [openReviveDialog, setOpenReviveDialog] = useState(false);

  const { mutateAsync: createRevive } = useCreateHandoverRevive();

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (_data: HandoverUpdate) => {
        try {
          const result = await createHandoverAsync({
            ..._data,
            subjectId,
          });
          discussion.createHandover({ handover: result, subjectId });
          logCreateHandover(subjectId, false);
          notify('generic.createSuccess', { type: 'success' });
          onClose();
        } catch (error: any) {
          notify(`Erreur : ${error.message}`, { type: 'error' });
        }
      }),
    [createHandoverAsync, discussion, handleSubmit, notify, onClose, subjectId],
  );

  const handleSubmitWithRevive = useCallback(
    async (values: Omit<HandoverReviveCreate, 'handoverId'>) => {
      let handover: Handover;
      const _createHandover = handleSubmit(async (_data: HandoverUpdate) => {
        handover = await createHandoverAsync({
          ..._data,
          subjectId,
        });
      });
      try {
        await _createHandover();
        logCreateHandover(subjectId, true);
        if (handover) {
          try {
            await createRevive({
              ...values,
              handoverId: Number(handover.id),
            });
          } catch (err: any) {
            notify(`Erreur lors de la création de la relance: ${err.message}`, {
              type: 'error',
            });
          }
        }
        onClose();
      } catch (error: any) {
        notify(`Erreur : ${error.message}`, { type: 'error' });
      }
    },
    [
      createHandoverAsync,
      createRevive,
      handleSubmit,
      notify,
      onClose,
      subjectId,
    ],
  );

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 1,
          overflow: 'auto',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <HandoverFormController
          control={control}
          onClose={onClose}
          title={translate('handover.create')}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 0,
            justifyContent: 'flex-end',
            alignItems: 'stretch',
          }}
        >
          <Button
            sx={{ margin: '0.5rem 0' }}
            variant="contained"
            color="primary"
            loading={isSubmitting}
            value={translate('common.create')}
            fullWidth
            onClick={onSubmit}
          />
          <Button
            sx={{ margin: '0.25rem 0', display: 'block' }}
            variant="outlined"
            color="primary"
            loading={isSubmitting}
            value={`${translate('handover.createWithRevive')}${activeRevives > 0 ? ` (${activeRevives})` : ''}`}
            fullWidth
            onClick={() => setOpenReviveDialog(true)}
          />
          {activeRevives > 0 && (
            <Typography
              variant="caption"
              color="error"
              sx={{ textAlign: 'center' }}
            >
              {translate('common.already')} {activeRevives}{' '}
              {translate('handover.activeRevives')}
            </Typography>
          )}
          <ReviveDialog
            open={openReviveDialog}
            onClose={() => setOpenReviveDialog(false)}
            onSubmit={handleSubmitWithRevive}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const HandoverEditForm = ({
  handover,
  onClose,
}: {
  handover: Handover;
  onClose: () => void;
}) => {
  const discussion = useDiscussionDetail();
  const notify = useNotify();
  const translate = useTranslate();

  const { handleSubmit, control, formState } = useForm<HandoverUpdate>({
    defaultValues: {
      advices: handover?.advices ?? '',
      observations: handover?.observations ?? '',
      problem: handover?.problem ?? '',
    },
  });
  const { isSubmitting } = formState;

  const { mutateAsync: createHandover } = useUpdateHandover({
    handover,
  });

  const { isLoading: isLoadingDelete, mutate: deleteHandover } =
    useDeleteHandover({
      handover,
    });

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (handoverUpdate: HandoverUpdate) => {
        try {
          const result = await createHandover({
            ...handoverUpdate,
          });
          discussion.updateHandover(result);
          onClose();
          notify('generic.updateSuccess', { type: 'success' });
        } catch (error: any) {
          notify(`Erreur : ${error.message}`, { type: 'error' });
        }
      }),
    [createHandover, discussion, handleSubmit, notify, onClose],
  );

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 1,
          overflow: 'auto',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <HandoverFormController
          control={control}
          onClose={onClose}
          title={translate('handover.edit')}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 0,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ flex: 1, margin: '1rem 0' }}
            variant="contained"
            color="primary"
            loading={isSubmitting}
            value={'Modifier'}
            fullWidth
            onClick={onSubmit}
            disabled={isSubmitting || isLoadingDelete}
          />
          <DialogButton
            size="small"
            title={translate('handover.deleteConfirmTitle')}
            text={translate('handover.deleteConfirmContent')}
            cancelText={translate('handover.cancel')}
            confirmText={translate('handover.confirm')}
            onClick={() =>
              deleteHandover(handover, {
                onSuccess: onClose,
                onError: (error) => {
                  notify(`Erreur : ${error.message}`, { type: 'error' });
                },
              })
            }
            //@ts-ignore
            component={Button}
            loading={isLoadingDelete}
            disabled={isSubmitting || isLoadingDelete}
            value={translate('common.delete')}
            variant="text"
            startIcon={<Delete />}
            color="error"
            sx={{
              marginTop: '1rem',
              marginBottom: '1rem',
              marginLeft: '2px',
              flex: 1,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
