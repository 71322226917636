import { DeleteButton, Edit, SaveButton } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { MayToolbar } from '@components/mayToolbar';

import { AffiliationsForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

const EditToolbar = ({ redirect }: { redirect: string }) => {
  return (
    <MayToolbar>
      <SaveButton type="button" />
      <DeleteButton redirect={redirect} label="Désactiver" />
    </MayToolbar>
  );
};

export const AffiliationsEdit = () => {
  const location = useLocation() as RedirectLocation;
  const redirection = location?.state?.redirect || 'list';
  return (
    <Edit resource="affiliations" redirect={redirection}>
      <AffiliationsForm toolbar={<EditToolbar redirect={redirection} />} />
    </Edit>
  );
};
