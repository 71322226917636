import dayjs from 'dayjs';

import { Box, Typography } from '@mui/material';

import { User } from '../../types';
import { Avatar } from '../fields/AvatarField';

export const StaffUserSignature = ({
  staffUser,
  createdAt,
}: {
  staffUser?: User;
  createdAt: string | Date;
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    {/* In case of automatic handovers (revives) or followUp, we *could* have no staffUser */}
    {staffUser ? (
      <Avatar user={staffUser} sx={{ marginLeft: '2px' }} size="tiny" />
    ) : null}
    <Typography
      sx={{
        marginLeft: '0.25rem',
        fontSize: '0.75rem',
        fontVariantNumeric: 'tabular-nums',
        fontFamily: 'arial',
      }}
    >
      {dayjs(createdAt).format('DD/MM/YY HH:mm')}
    </Typography>
  </Box>
);
