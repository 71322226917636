import { useRef } from 'react';
import { Waypoint } from 'react-waypoint';

import {
  ChatDiscussionEvent,
  DiscussionEventType,
} from '@boTypes/discussionEvent';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { COLORS } from '../../../../themes';
import { formatTZ } from '../../../../utils/date';

enum classes {
  other = 'BUBBLE-other',
  otherFirst = 'BUBBLE-other-first',
  otherLast = 'BUBBLE-other-last',
  my = 'BUBBLE-m',
  myFirst = 'BUBBLE-my-first',
  myLast = 'BUBBLE-my-last',
  system = 'BUBBLE-system',
  intro = 'BUBBLE-intro',
}

const radius = 16;
const InnerBubble = styled(Box)(() => ({
  maxWidth: 'calc(100% - 3.5rem)',
  paddingX: 1,
  borderRadius: 4,
  [`&.${classes.other}`]: {
    borderTopRightRadius: radius,
    borderBottomRightRadius: radius,
  },
  [`&.${classes.otherFirst}`]: {
    borderTopLeftRadius: radius,
  },
  [`&.${classes.otherLast}`]: {
    borderBottomLeftRadius: radius,
  },
  [`&.${classes.my}`]: {
    borderTopLeftRadius: radius,
    borderBottomLeftRadius: radius,
  },
  [`&.${classes.myFirst}`]: {
    borderTopRightRadius: radius,
  },
  [`&.${classes.myLast}`]: {
    borderBottomRightRadius: radius,
  },
  [`&.${classes.intro}`]: {
    borderRadius: 12,
  },
  [`&.${classes.system}`]: {
    maxWidth: '100%',
  },
}));

const BubbleFooter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  paddingTop: '0.15rem',
}));

export interface BubbleProps {
  children: React.ReactNode;
  backgroundColor?: string;
  onItemDisplayed: (item: number) => void;
  message: ChatDiscussionEvent;
  withFooter?: boolean;
}
export const Bubble = ({
  backgroundColor,
  children,
  message,
  onItemDisplayed,
  withFooter,
}: BubbleProps) => {
  const upperDisplayed = useRef(false);
  const lowerDisplayed = useRef(false);

  const bubbleClasses: classes[] = [];
  if (message.isCurrentUser) {
    bubbleClasses.push(classes.my);
    if (message.isFirst) {
      bubbleClasses.push(classes.myFirst);
    }
    if (message.isLast) {
      bubbleClasses.push(classes.myLast);
    }
  } else if (message.type === DiscussionEventType.SYSTEM) {
    bubbleClasses.push(classes.system);
  } else if (message.type === DiscussionEventType.INTRO) {
    bubbleClasses.push(classes.intro);
  } else {
    bubbleClasses.push(classes.other);
    if (message.isFirst) {
      bubbleClasses.push(classes.otherFirst);
    }
    if (message.isLast) {
      bubbleClasses.push(classes.otherLast);
    }
  }

  return (
    <>
      <Waypoint
        topOffset={'20%'}
        onEnter={() => {
          if (!upperDisplayed.current && lowerDisplayed.current) {
            onItemDisplayed(new Date(message.createdAt).getTime());
          }
          upperDisplayed.current = true;
        }}
      />
      <InnerBubble
        className={bubbleClasses.join(' ')}
        sx={{
          backgroundColor,
        }}
      >
        {children}
        {withFooter && (
          <BubbleFooter>
            <Typography
              variant="caption"
              sx={{
                color: message.isCurrentUser ? 'white' : COLORS.GREY_TEXT_LIGHT,
                fontSize: '0.625rem',
                marginRight: '0.25rem',
                whiteSpace: 'nowrap',
                maxWidth: 'calc(100% - 3rem)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'default',
              }}
              title={message.authorName}
            >
              {message.isCurrentUser ? '' : `~ ${message.authorName}`}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: message.isCurrentUser ? 'white' : COLORS.GREY_TEXT_LIGHT,
                fontSize: '0.625rem',
                cursor: 'default',
              }}
            >
              {formatTZ(
                message.createdAt,
                'HH:mm',
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              ) + (message.isRead ? ` ✓` : ``)}
            </Typography>
          </BubbleFooter>
        )}
      </InnerBubble>
      <Waypoint
        topOffset={'-20%'}
        onEnter={() => {
          if (upperDisplayed.current && !lowerDisplayed.current) {
            onItemDisplayed(new Date(message.createdAt).getTime());
          }
          lowerDisplayed.current = true;
        }}
      />
    </>
  );
};
