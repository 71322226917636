import { Discussion } from '../types';

export const getFamilyIdFromDiscussion = (discussion: Discussion): string => {
  return discussion?.appUsers?.[0] &&
    typeof discussion.appUsers?.[0] === 'object'
    ? discussion.appUsers[0].familyId
    : discussion?.appUser && typeof discussion.appUser === 'object'
      ? discussion.appUser.familyId
      : '';
};
