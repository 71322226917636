import { useMemo } from 'react';

export const useByIds = <
  T extends { id: string | number },
  Id extends string | number = T['id'],
>(
  input: T[],
  idConvert = ((id: T['id']) => id) as (arg: T['id']) => Id,
) =>
  useMemo(
    () =>
      input?.reduce(
        (acc, item) => ({ ...acc, [idConvert(item.id)]: item }),
        {} as Record<ReturnType<typeof idConvert>, T>,
      ) ?? ({} as Record<ReturnType<typeof idConvert>, T>),
    // do not change if id conversion function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input],
  );
