import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { logLogin, logLogout } from './events';

async function flushAndReset() {
  try {
    await window.analytics?.flush();
    await window.analytics?.reset();
  } catch {
    // do nothing
  }
}

export const useTracking = () => {
  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' ||
      window.env.BACKEND_URL.includes('staging')
    ) {
      return;
    }

    window.analytics.load('Fh9dXPg3hSuwKcpdNhv5C5kV1asGwWnK');
    window.analytics.page();

    return () => {
      flushAndReset();
    };
  }, []);
};

interface AnalyticUser {
  id: number;
  email: string;
  jobTitle?: string;
  fullName?: string;
}

export const setUserInTracking = (user: AnalyticUser) => {
  if (
    process.env.NODE_ENV !== 'production' ||
    window.env.BACKEND_URL.includes('staging')
  ) {
    return;
  }
  logLogin(user.email);
  window.analytics?.identify(user.id, {
    name: user?.fullName,
    email: user.email,
    job: user?.jobTitle,
  });
};

export const logoutUserInTracking = async () => {
  if (
    process.env.NODE_ENV !== 'production' ||
    window.env.BACKEND_URL.includes('staging')
  ) {
    return;
  }
  await logLogout();
  flushAndReset();
};

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' ||
      window.env.BACKEND_URL.includes('staging')
    ) {
      return;
    }
    window.analytics?.page();
  }, [location]);
};

export const trackEvent = (event: string, properties?: any) => {
  if (
    process.env.NODE_ENV !== 'production' ||
    window.env.BACKEND_URL.includes('staging')
  ) {
    return;
  }
  try {
    window.analytics?.track(event, properties);
  } catch {
    // do nothing
  }
};
