import { sortBy } from 'lodash';
import { Edge, Node } from 'reactflow';

import {
  Condition,
  Form,
  FormStep,
  Question,
  StepLink,
  Template,
} from '@teammay/form-core';

import {
  FillerNodeType,
  HideConditionNodeType,
  OnChangeFormFunction,
  QuestionNodeType,
  ScoringRuleNodeType,
  StepNodeType,
  TemplateNodeType,
} from '../types';

const stepWidth = 200;
const questionHeight = 80;
const questionMarginVertical = 10;
const questionMarginHorizontal = 10;
const stepButtonHeight = 30;

export const hideConditionToNode = (
  hideCondition: Condition,
  contentNode: QuestionNodeType | TemplateNodeType,
  onChange: OnChangeFormFunction,
): HideConditionNodeType => {
  const content =
    contentNode.type === 'question'
      ? contentNode.data.question
      : contentNode.data.template;

  return {
    id: `${contentNode.id}-hide`,
    data: { hideCondition, onChange, content },
    position: {
      x: stepWidth + 2 * questionMarginHorizontal,
      y: contentNode.position.y,
    },
    style: { height: questionHeight },
    type: 'hideCondition',
    parentId: `step-${content.stepId}`,
    deletable: false,
  };
};
export const hideConditionEdge = (contentNode: Node, hideNode: Node): Edge => ({
  id: `edge-${contentNode.id}-to-${hideNode.id}`,
  source: contentNode.id,
  target: hideNode.id,
  sourceHandle: 'hide',
  deletable: false,
  zIndex: 10010,
});

export const questionToNode = (
  question: Question,
  onChange: OnChangeFormFunction,
  step: Pick<FormStep, 'id'>,
): QuestionNodeType => ({
  id: `question-${question.id}`,
  data: { question, onChange },
  position: { x: 0, y: 0 },
  parentId: `step-${step.id}`,
  type: 'question',
  deletable: false,
  zIndex: 10010,
});

export const questionToScoringRuleNode = (
  question: Partial<Question>,
  contentNode: QuestionNodeType,
  onChange: OnChangeFormFunction,
): ScoringRuleNodeType => {
  return {
    id: `${contentNode.id}-score`,
    data: { question, onChange },
    position: { x: -4 * questionMarginHorizontal, y: contentNode.position.y },
    type: 'scoringRule',
    deletable: false,
    zIndex: 10010,
    parentId: contentNode.parentId,
    style: { height: questionHeight },
  };
};

export const scoringRuleEdge = (
  contentNode: Node,
  scoringRuleNode: Node,
): Edge => ({
  id: `edge-${contentNode.id}-to-${scoringRuleNode.id}`,
  source: contentNode.id,
  target: scoringRuleNode.id,
  sourceHandle: 'score',
  deletable: false,
  zIndex: 10010,
});

export const fillerNode = (node: Node): FillerNodeType => ({
  ...node,
  type: 'filler',
  data: {
    forNode: node.id,
  },
  id: `filler-${node.id}`,
});

export const templateToNode = (
  template: Template,
  onChange: OnChangeFormFunction,
  step: Pick<FormStep, 'id'>,
): TemplateNodeType => ({
  id: `template-${template.id}`,
  data: { template, onChange },
  position: { x: 0, y: 0 },
  parentId: `step-${step.id}`,
  type: 'template',
  deletable: false,
  zIndex: 10010,
});

export const stepToNode = (
  step: FormStep,
  onChange: OnChangeFormFunction,
  link?: StepLink,
): StepNodeType => ({
  id: `step-${step.id}`,
  data: { step, link, onChange },
  position: { x: 0, y: 0 },
  style: { width: stepWidth, height: 100 },
  type: 'step',
  deletable: false,
});

export const stepsEdge = (from: FormStep['id'], to: FormStep['id']): Edge => ({
  id: `edge-${from}-to-${to}`,
  source: `step-${from}`,
  target: `step-${to}`,
  deletable: false,
});

export const positionNodesInStep = (nodes: Node[], stepNode: Node) => {
  nodes.forEach((node, nodeIndex) => {
    node.position = {
      x: questionMarginHorizontal,
      y:
        questionHeight +
        questionMarginVertical +
        nodeIndex * (questionHeight + 2 * questionMarginVertical),
    };
    node.style = {
      width: stepWidth - questionMarginHorizontal * 2,
      height: questionHeight,
    };
    node.width = stepWidth - questionMarginHorizontal * 2;
    node.height = questionHeight;
  });
  stepNode.height =
    (questionHeight + questionMarginVertical * 2) * (nodes.length + 1) +
    stepButtonHeight;
  stepNode.width = stepWidth;
  stepNode.style = {
    width: stepNode.width,
    height: stepNode.height,
  };
};

export const positionSteps = (nodes: Node[]) => {
  const xList = nodes.map((node) => node.position.x).sort();
  nodes.forEach((node, nodeIndex) => {
    node.position = {
      x: xList[nodeIndex],
      y: node.position.y,
    };
  });
};

/**
 * generates nodes and edges from form, questions and templates
 * @param form
 * @param questions
 * @param templates
 * @param onChange
 * @returns
 */
export const apiToNodes = (
  form: Form,
  questions: Question[],
  templates: Template[],
  onChange: OnChangeFormFunction,
) => {
  const stepsNodes: Node[] = form.steps.map((step) => {
    const link = form.steps
      .flatMap((s) => s.links)
      ?.find((l) => l.nextStepId === step.id);
    return stepToNode(step, onChange, link);
  });

  const stepIdToQuestions = questions.reduce(
    (acc, question) => {
      if (!acc[question.stepId]) {
        acc[question.stepId] = [];
      }
      acc[question.stepId].push(question);
      return acc;
    },
    {} as Record<string, Question[]>,
  );

  const stepIdToTemplates = templates.reduce(
    (acc, template) => {
      if (!acc[template.stepId]) {
        acc[template.stepId] = [];
      }
      acc[template.stepId].push(template);
      return acc;
    },
    {} as Record<string, Template[]>,
  );

  const innerNodes = form.steps.map((step) => {
    const stepQuestions = stepIdToQuestions[step.id] || [];
    const stepTemplates = stepIdToTemplates[step.id] || [];

    const questionsNodes = stepQuestions.map((question) =>
      questionToNode(question, onChange, step),
    );

    const templatesNodes = stepTemplates.map((template) =>
      templateToNode(template, onChange, step),
    );

    const all = sortBy([...questionsNodes, ...templatesNodes], (n) =>
      n.type === 'question' ? n.data.question?.order : n.data.template?.order,
    );
    return all;
  }, {});

  // position inner nodes inside step
  innerNodes.forEach((nodes, index) => {
    positionNodesInStep(nodes, stepsNodes[index]);
  });

  // generate hide conditions and scoringRules with edges
  const hideConditions: Node[] = [];
  const hideEdges: Edge[] = [];
  const scoringRuleNodes: Node[] = [];
  const scoringRuleEdges: Edge[] = [];

  innerNodes.flat().forEach((node) => {
    const hideCondition =
      node.type === 'question'
        ? node.data.question.hideCondition
        : node.data.template?.hideCondition;
    if (!hideCondition) {
      return null;
    }

    const hideNode = hideConditionToNode(hideCondition, node, onChange);
    hideConditions.push(hideNode);
    hideEdges.push(hideConditionEdge(node, hideNode));
  });

  innerNodes.flat().forEach((node) => {
    if (node.type === 'template') {
      return null;
    }
    const scoringRules = node.data.question.scoringRules;
    if (!scoringRules?.length) {
      return null;
    }

    const scoringRuleNode = questionToScoringRuleNode(
      node.data.question,
      node,
      onChange,
    );

    scoringRuleNodes.push(scoringRuleNode);
    scoringRuleEdges.push(scoringRuleEdge(node, scoringRuleNode));
  });

  const conditionEdges: Edge[] = [];
  form.steps.forEach((step) => {
    step.links.forEach((link) => {
      conditionEdges.push(stepsEdge(link.stepId, link.nextStepId));
    });
  });

  return {
    stepsNodes,
    innerNodes: innerNodes.flat(),
    hideConditions,
    hideEdges,
    conditionEdges,
    scoringRuleNodes,
    scoringRuleEdges,
  };
};
