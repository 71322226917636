import ReactMarkdown from 'react-markdown';

import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import { Dialog, DialogContent } from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const PregnancyQuestionDialog = ({
  item,
  open,
  close,
}: {
  item: PregnancyQuestion;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.title}
    aria-describedby={item.title}
    maxWidth="md"
  >
    <DialogTitle onClose={close}>{item.title}</DialogTitle>
    <DialogContent>
      <ReactMarkdown>{item.content}</ReactMarkdown>
    </DialogContent>
  </Dialog>
);
