import { Fragment } from 'react';
import { Datagrid, EditButton, NumberField, TextField } from 'react-admin';

import { List } from '../../components/generic/List';

export const PricingProGenericList = () => {
  return (
    <List
      hasCreate={true}
      resource={'pricing-pro-generic'}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Nom" source="name" />
        <TextField label="Profession" source="jobTitle" />
        <NumberField label="Base" source="pricePerUnitBase" />
        <NumberField label="Nuit" source="pricePerUnitNight" />
        <NumberField label="Férié" source="pricePerUnitSundayAndHoliday" />
        <NumberField label="Coeff astreinte" source="onCallNonActivatedRate" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
