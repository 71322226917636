import { User } from '@boTypes/user';

import { getFullName } from './getFullName';

export const userFullName = (
  user: Pick<User, 'id' | 'firstName' | 'lastName'>,
  userId: number,
) => (userId === user.id ? 'Moi' : getFullName(user));

export const meFirstSort = (me: number) => (a: User, b: User) => {
  if (a.id === me) {
    return -1;
  }
  if (b.id === me) {
    return 1;
  }
  return userFullName(a, me).localeCompare(userFullName(b, me));
};
