import { AxiosError } from 'axios';
import { useGetList } from 'react-admin';

import { Category } from '@boTypes/category';
import { Discussion, MinimalSubject } from '@boTypes/discussion';
import { Subject, SubjectSetCategoryDTO } from '@boTypes/subject';
import { addSubjectToWaitingList } from '@utils/subjectAttributions';

import { useMutation, useQueryClient } from './queryWrappers';
import {
  SUBJECT_WAITING_ATTRIBUTION_KEY,
  useSubjectAttributions,
} from './subjectAttribution';
import { subjectKey, updateSubject, useSubjectFilter } from './subjects';
import { useByIds } from './useByIds';
import { useDiscussionContext } from './useDiscussionContext';

export const useUpdateSubjectCategory = (
  subject: Subject | MinimalSubject,
  onMutate?: () => void,
) => {
  const queryClient = useQueryClient();
  const discussionContext = useDiscussionContext();
  const filter = useSubjectFilter();

  const { data: categoryList } = useGetList<Category>(
    'categories',
    {
      pagination: { page: 1, perPage: 1000 },
    },
    {
      enabled: false,
    },
  );

  const categoryFromId = useByIds<Category>(categoryList);
  const { data: attributions } = useSubjectAttributions();
  return useMutation<Subject, AxiosError, SubjectSetCategoryDTO>(
    ['changeCategory', subject?.id],
    {
      method: 'put',
      url: `/api/subjects/${subject?.id}/category`,
    },
    {
      onSuccess(newSubject) {
        queryClient.setQueriesData<Discussion[]>(
          [subjectKey(discussionContext), { filter, discussionContext }],
          (previousDiscussions?: Discussion[]) =>
            updateSubject(previousDiscussions, {
              newSubject,
              filter,
              discussionContext,
              categoryFromId,
            }),
        );

        if (!attributions.staffUserFromSubjectId[newSubject.id]) {
          queryClient.setQueryData<number[]>(
            SUBJECT_WAITING_ATTRIBUTION_KEY,
            (previousData) =>
              addSubjectToWaitingList(previousData, newSubject.id),
          );
        }
      },
      onMutate,
    },
  );
};
