import { useEffect, useState } from 'react';

import { ALL_PRO_ROOM } from '../pages/subjects';
import { useSelector } from '../store';
import { Socket, SocketEvents } from '../types/socket';

export const getDiscussionRoom = (discussionId: string) => {
  return `d-${discussionId}`;
};
export const getDiscussionFromRoom = (room: string) => {
  return room.replace(/^d-/, '');
};

export const useUsersInRoom = (socket: Socket, room: string) => {
  const [users, setUsers] = useState<string[]>(
    socket?.getUsersInRoom(room) ?? [],
  );
  const currentUser = useSelector((state) => state.user.userId);

  useEffect(() => {
    if (!socket) {
      return;
    }
    const callback = (eventUsers: string[]) => {
      setUsers(eventUsers.filter((user) => `${user}` !== `${currentUser}`));
    };

    const unsubscribe = socket.onRoomUsersChanged(room, callback);
    if (room !== ALL_PRO_ROOM) {
      socket.emit(SocketEvents.SOCKET_ENTERED_ROOM, { room });
    }
    return () => {
      unsubscribe();
      if (room !== ALL_PRO_ROOM && socket.connected) {
        socket.emit(SocketEvents.SOCKET_LEFT_ROOM, { room });
      }
    };
  }, [currentUser, room, socket]);

  return users;
};
