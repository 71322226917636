import React, { MouseEvent } from 'react';
import { useTranslate } from 'react-admin';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export type ConfirmDialogProps = {
  text: string | React.ReactNode;
  title?: string;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
  open?: boolean;
  onClose?: () => void;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const ConfirmDialog = ({
  title,
  text,
  open,
  cancelText,
  confirmText,
  onClick,
  onClose,
}: ConfirmDialogProps) => {
  const translate = useTranslate();
  cancelText = cancelText || translate('common.cancel');
  confirmText = confirmText || translate('common.confirm');
  return (
    <>
      <Dialog open={open} onClose={() => onClose()}>
        {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            m: '1rem',
          }}
        >
          <Button onClick={() => onClose()} color="inherit">
            {cancelText}
          </Button>
          <Button
            variant="contained"
            onClick={(event) => {
              onClose();
              onClick(event);
            }}
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
