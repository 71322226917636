import { Edit } from 'react-admin';
import { Location, useLocation } from 'react-router-dom';

import { ChildrenForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const ChildrenEdit = () => {
  const location = useLocation() as RedirectLocation;
  return (
    <Edit resource="children" redirect={location?.state?.redirect || 'list'}>
      <ChildrenForm />
    </Edit>
  );
};
