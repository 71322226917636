import {
  BooleanInput,
  NumberInput,
  TextInput,
  SimpleForm,
  required,
  number,
} from 'react-admin';

import { integer } from '../../utils/validators';

export const PlanForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      label="Nom"
      source="name"
      validate={required()}
      variant="standard"
    />
    <TextInput
      label="Description"
      source="description"
      validate={required()}
      variant="standard"
    />
    <TextInput
      label="Niveau d'accès"
      source="accessLevel"
      validate={required()}
      variant="standard"
    />
    <NumberInput
      label="Nombre de consultations offertes"
      source="consultationsNumber"
      defaultValue={0}
      variant="standard"
      validate={[required(), number(), integer()]}
    />
    <NumberInput
      label="Nombre de mois valide"
      source="monthsNumber"
      defaultValue={12}
      variant="standard"
    />
    <BooleanInput
      label="Actif"
      source="active"
      defaultValue={true}
      variant="standard"
    />
  </SimpleForm>
);
