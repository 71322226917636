import { useEffect } from 'react';
import {
  CheckForApplicationUpdate,
  CoreLayoutProps,
  Sidebar,
  useAuthState,
  useLogout,
  useSidebarState,
} from 'react-admin';

import { useCommonHooks } from '@hooks/useCommonHooks';
import { styled } from '@mui/material';

import MyAppBar from './AppBar';
import MyMenu from './Menu';
import { usePageTracking } from '../analytics';
import { useSelector } from '../store';
import { COLORS } from '../themes';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  position: 'relative',
  minHeight: '100vh',
  height: '100vh',
  backgroundColor: COLORS.WHITE,
  width: '100%',
  overflow: 'hidden',
}));

const AppFrame = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const ContentWithSidebar = styled('main')(() => ({
  display: 'flex',
  flexGrow: 1,
  height: 'calc(100% - 48px)',
  paddingTop: '48px',
}));

const Content = styled('div')(({ open }: { open: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 2,
  height: '100%',
  backgroundColor: '#fff',
  width: `calc(100% - ${open ? '200px' : '50px'})`,
  transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
}));

export const MyLayout = ({ children, dashboard }: CoreLayoutProps) => {
  useCommonHooks();
  usePageTracking();

  const logout = useLogout();
  const { isLoading, authenticated } = useAuthState();
  const isStoreLogged = useSelector((state) => Boolean(state.user.email));
  const [open] = useSidebarState();

  useEffect(() => {
    if (!isStoreLogged && !isLoading && authenticated) {
      logout();
    }
  }, [authenticated, isLoading, isStoreLogged, logout]);

  return (
    <Root>
      <AppFrame>
        <MyAppBar />
        <ContentWithSidebar>
          <Sidebar>
            <MyMenu hasDashboard={!!dashboard} />
          </Sidebar>
          <Content open={open}>{children}</Content>
          <CheckForApplicationUpdate />
        </ContentWithSidebar>
      </AppFrame>
    </Root>
  );
};

export default MyLayout;
