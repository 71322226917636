import { Edit } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { PregnanciesForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const PregnanciesEdit = () => {
  const location = useLocation() as RedirectLocation;

  return (
    <Edit resource="pregnancies" redirect={location?.state?.redirect || 'list'}>
      <PregnanciesForm />
    </Edit>
  );
};
