import { useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { SlotEvaluationFeedback } from '@boTypes/slot';
import { Button } from '@components/generic/Button';
import { useEvaluateSession } from '@hooks/useActiveSessions';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const SessionEvaluationForm = ({
  onClick,
  isLoading,
  isError,
}: {
  onClick: (evaluation: SlotEvaluationFeedback) => void;
  isLoading: boolean;
  isError?: boolean;
}) => {
  const translate = useTranslate();
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
    >
      <Typography sx={{ alignSelf: 'flex-start', marginBottom: '1rem' }}>
        {translate('planning.evaluation.feedback')}
      </Typography>
      {Object.values(SlotEvaluationFeedback).map((feedback) => (
        <Button
          key={feedback}
          onClick={() => onClick(feedback)}
          disabled={isLoading}
          sx={{ marginBottom: '1rem' }}
          variant="outlined"
        >
          {translate(`planning.evaluation.${feedback}`)}
        </Button>
      ))}
      {isError && (
        <Typography>{translate('planning.evaluation.error')}</Typography>
      )}
    </Box>
  );
};

export const SessionEvaluationDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    mutate: evaluate,
    data,
    isLoading: isLoadingEvaluate,
    isError,
    reset,
  } = useEvaluateSession();
  const translate = useTranslate();

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5">
          {translate('planning.evaluation.title')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: '500px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
          {data && !data?.participatedSubjects ? (
            <Typography>
              {translate('planning.evaluation.summaryNoCounts')}
            </Typography>
          ) : data && data.participatedSubjects ? (
            <Typography>
              {translate('planning.evaluation.summary', {
                ...data,
              })}
            </Typography>
          ) : (
            <SessionEvaluationForm
              isLoading={isLoadingEvaluate}
              onClick={(feedback) => evaluate({ feedback })}
              isError={isError}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
