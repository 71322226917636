import { TopToolbar } from 'react-admin';

import { styled } from '@mui/material';

export const MayTopToolbar = styled(TopToolbar)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
}));
