import { capitalize } from 'lodash';
import { NodeProps, useReactFlow } from 'reactflow';

import { ShyText } from '@components/fields';
import { Box, Typography } from '@mui/material';
import { Condition, Question, QuestionType } from '@teammay/form-core';

import { QuestionNodeType } from '../../types';
import { isQuestionValid } from '../../utils/validityCheck';
import { EditNodeButton } from '../EditNodeButton';
import { HideHandle } from '../handles/HideHandle';
import { ScoreHandle } from '../handles/ScoreHandle';
import { MenuWrapper } from '../MenuWrapper';
import { QuestionEdit } from '../questions/QuestionEdit';
import { RadioQuestion } from '../questions/RadioQuestionEdit';
import { WarningTile } from '../WarningTile';

const questionWithOptions = [QuestionType.RADIO, QuestionType.SELECT];

export function QuestionNode({
  id,
  data: { question, onChange },
}: NodeProps<QuestionNodeType['data']>) {
  const { setNodes } = useReactFlow();

  const handleChanged = (result: Partial<Question>) => {
    onChange({ updatedQuestion: result as Question });
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, question: result };
        }
        return node;
      }),
    );
  };

  const invalidFields = isQuestionValid(question);

  return (
    <>
      <MenuWrapper
        content={question}
        onChange={handleChanged}
        FormComponent={QuestionEdit}
        onDelete={() => {
          onChange({ deletedQuestion: question.id });
        }}
      >
        <WarningTile invalidFields={invalidFields} />
        <Box
          sx={{
            width: '100%',
            height: '100%',
            p: 1,
          }}
        >
          <Typography variant="subtitle2" color="primary" noWrap>
            {`${capitalize(question.type)}${questionWithOptions.includes(question.type) ? ` (${question.options.length})` : ''}`}
          </Typography>
          <ShyText source="title" record={question} oneline />
          <Typography
            component={'p'}
            variant="caption"
            color="textSecondary"
            noWrap
          >
            {question.description}
          </Typography>
        </Box>
      </MenuWrapper>
      <EditNodeButton
        content={question as RadioQuestion}
        onChange={handleChanged}
        FormComponent={QuestionEdit}
      />
      <HideHandle
        id={id}
        type="question"
        content={question}
        onClick={(condition: Condition) =>
          onChange({ addHideCondition: { questionId: question.id, condition } })
        }
      />
      <ScoreHandle
        id={id}
        question={question as Question}
        onClick={(questionWithRules) =>
          onChange({
            addScore: {
              questionId: question.id,
              scoringRules: questionWithRules.scoringRules,
            },
          })
        }
      />
    </>
  );
}
