import { ComponentProps, ElementType } from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { COLORS } from '../themes';

export const CMSCard = styled(Card)<
  CardProps & { width: number; height: number }
>(
  ({ theme, width, height }) => ({
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: theme.spacing(2),
    position: 'relative',
    overflow: 'unset',
  }),
  {
    shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
  },
);

export const CMSCardActionArea = styled(CardActionArea, {
  shouldForwardProp: (prop) => prop !== 'paddingVertical',
})<CardActionAreaProps & { height?: string | number }>(({ theme, height }) => ({
  height: typeof height === 'number' ? `${height}px` : height,
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

export const CMSCardSentOverlay = styled(Box)({
  position: 'absolute',
  left: '0px',
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff60',
});

export const CMSCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'paddingVertical',
})<CardContentProps & { paddingVertical?: 0 | 1 | 2 }>(
  ({ theme, paddingVertical = 1 }) => ({
    textAlign: 'center',
    padding: `${theme.spacing(paddingVertical)} ${theme.spacing(1)}`,
  }),
);

export const CMSCardTitle = styled(Typography)<
  ComponentProps<typeof Typography> & { component: ElementType; clamp?: number }
>(
  ({ clamp = 2 }) => ({
    color: COLORS.WHITE_TEXT,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: `${clamp}`,
    WebkitBoxOrient: 'vertical',
  }),
  {
    shouldForwardProp: (prop) => prop !== 'clamp',
  },
);
