import { Controller, Path } from 'react-hook-form';

import { Switch, FormControlLabel, FormControlLabelProps } from '@mui/material';

import { ExposedControllerProps } from './type';

type SwitchInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<FormControlLabelProps, 'checked' | 'control'>;

export function SwitchInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  ...other
}: SwitchInputProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => (
        <FormControlLabel
          disabled={formState.isSubmitting}
          control={
            <Switch
              checked={field.value ?? false}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          }
          label={label ?? name}
          {...other}
        />
      )}
    />
  );
}
