import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { FormProps } from '../../types';
import { User } from '../../types/user';

const optionText = (record: User) =>
  (record?.firstName && `${record.firstName} ${record.lastName}`) || '---';

export const PricingProCustomForm = (props: FormProps) => {
  return (
    <SimpleForm {...props}>
      <TextInput
        label="Nom"
        source="name"
        validate={required()}
        variant="standard"
      />
      <ReferenceInput
        label="Pro"
        source="staffUserId"
        reference="users"
        variant="standard"
      >
        <AutocompleteInput
          label="Pro"
          variant="standard"
          optionText={optionText}
          matchSuggestion={(filterValue, suggestion) => {
            if (filterValue === '') {
              return true;
            }
            const lowerFilterValue = filterValue.toLowerCase();
            const lowerSuggestion = optionText(suggestion).toLowerCase();
            return lowerSuggestion.includes(lowerFilterValue);
          }}
        />
      </ReferenceInput>
      <TextInput label="Base" source="pricePerUnitBase" variant="standard" />
      <TextInput label="Nuit" source="pricePerUnitNight" variant="standard" />
      <TextInput
        label="Férié"
        source="pricePerUnitSundayAndHoliday"
        variant="standard"
      />
      <TextInput
        label="Coeff astreinte"
        source="onCallNonActivatedRate"
        variant="standard"
      />
      <BooleanInput
        label="TVA"
        source="vat"
        variant="standard"
        defaultChecked
      />
    </SimpleForm>
  );
};
