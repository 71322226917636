import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';

import { HandoverRevive } from '@boTypes/handover';
import { JobTitle, Roles, User } from '@boTypes/user';
import { ActivityPopup } from '@components/activityPopup';
import { SessionEvaluationDialog } from '@components/SessionEvaluationDialog/SessionEvaluationDialog';
import { useGetMyHandoverRevive } from '@hooks/handover';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { logStartedSession, logStoppedSession } from '../analytics/events';
import { i18nProvider } from '../App';
import { DialogTitle } from '../components/generic/Dialog';
import {
  useActiveSessions,
  useStartSession,
  useStopSession,
} from '../hooks/useActiveSessions';
import { useSelector } from '../store';

const jobFilters: Record<JobTitle, (h1: HandoverRevive) => boolean> = {
  [JobTitle.NURSERY_NURSE]: (h1) => Boolean(h1?.discussion?.kidId),
  [JobTitle.MIDWIFE]: (h1) => !h1?.discussion?.kidId,
  [JobTitle.ADMIN]: () => true,
  [JobTitle.GENERAL_PRACTITIONER]: () => false,
  [JobTitle.PEDIATRICIAN]: () => false,
  [JobTitle.NUTRITIONIST_DOCTOR]: () => false,
  [JobTitle.CHILD_PSYCHIATRIST]: () => false,
  [JobTitle.PSYCHOLOGIST]: () => false,
};

const useSessionsData = () => {
  const { data: sessions, isLoading: isLoadingSessions } = useActiveSessions();
  const { mutate: start, isLoading: isLoadingStart } = useStartSession();
  const { mutate: stop, isLoading: isLoadingStop } = useStopSession();
  const userId = useSelector((state) => state.user.userId);
  const active = sessions?.some((s) => String(s) === String(userId));
  const isLoading = isLoadingSessions || isLoadingStart || isLoadingStop;
  return { isLoading, start, stop, active, isLoadingSessions };
};

const InactiveSessionButtonUI = ({
  onClick,
  isLoading,
  variant = 'outlined',
  text = i18nProvider.translate('attributions.start'),
}: {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  isLoading: boolean;
  variant?: 'outlined' | 'contained';
  text?: string;
}) => {
  return (
    <Button
      variant={variant}
      size="small"
      color="primary"
      onClick={onClick}
      startIcon={
        isLoading ? (
          <CircularProgress size="small" />
        ) : (
          <PlayArrowIcon
            sx={{ color: variant === 'outlined' ? 'primary' : 'white' }}
          />
        )
      }
      disabled={isLoading}
    >
      <Typography
        component={'span'}
        display={{ xs: 'none', sm: 'block' }}
        color={variant === 'outlined' ? 'primary' : 'white'}
        sx={{ fontSize: '0.85rem', fontWeight: 500 }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export const InactiveSessionButton = ({
  variant = 'outlined',
  noAttribution = false,
  onClick,
  from = 'AppBar',
}: {
  variant?: 'outlined' | 'contained';
  noAttribution?: boolean;
  onClick?: () => void;
  from?: 'AppBar' | 'ChatInput';
}) => {
  const notify = useNotify();
  const { active, isLoading, start, isLoadingSessions } = useSessionsData();

  if (active || isLoadingSessions) {
    return null;
  }

  return (
    <InactiveSessionButtonUI
      variant={variant}
      onClick={() => {
        onClick?.();
        logStartedSession({ from });
        return start(
          { noAttribution },
          {
            onError: (e) => {
              notify(`Une erreur est survenue: ${e}`, { type: 'error' });
            },
          },
        );
      }}
      text={
        noAttribution
          ? i18nProvider.translate('attributions.startNoAttrib')
          : undefined
      }
      isLoading={isLoading}
    />
  );
};

const NormalSessionButton = ({
  onStopSession,
}: {
  onStopSession: () => void;
}) => {
  const notify = useNotify();
  const { active, isLoading, start, stop } = useSessionsData();
  const jobTitle = useSelector((state) => state.user.jobTitle);

  const startRevive = useMemo(
    () => new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    [],
  );
  const endRevive = useMemo(
    () => new Date(Date.now() + 4 * 60 * 60 * 1000),
    [],
  );
  const [reviveText, setReviveText] = useState([]);

  const { data: revives } = useGetMyHandoverRevive(
    {
      start: startRevive,
      end: endRevive,
    },
    {
      enabled: Boolean(active),
    },
  );
  useEffect(() => {
    if (active && revives?.length) {
      setReviveText(
        revives
          .filter(jobFilters[jobTitle] ?? (() => true))
          .map(
            (r) =>
              r?.discussion?.child?.firstName ??
              (r?.discussion?.appUser as User)?.firstName,
          ),
      );
    }
  }, [active, jobTitle, revives]);

  const closeDialog = () => setReviveText([]);

  return (
    <>
      {Boolean(reviveText.length) && (
        <Dialog
          open={Boolean(reviveText)}
          onClose={closeDialog}
          aria-labelledby="Relances à faire"
          aria-describedby="Relances à faire"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle onClose={closeDialog}>Relances à faire</DialogTitle>
          <Box sx={{ marginLeft: 3 }}>
            <Typography>
              {`Vous avez ${reviveText.length} relance(s) planifiée(s) pour cette vacation : `}
            </Typography>
            {reviveText.map((revive) => (
              <Typography>{` - ${revive}`}</Typography>
            ))}
          </Box>
          <Button sx={{ p: 1, m: 1 }} onClick={closeDialog}>
            Fermer
          </Button>
        </Dialog>
      )}
      <ActivityPopup active={active} />
      {active ? (
        <Button
          variant="outlined"
          size="small"
          color="warning"
          onClick={() => {
            logStoppedSession();
            stop(
              {},
              {
                onSuccess: () => {
                  onStopSession();
                },
                onError: (e) => {
                  notify(`Une erreur est survenue: ${e}`, { type: 'error' });
                },
              },
            );
          }}
          startIcon={
            isLoading ? <CircularProgress size="small" /> : <StopCircleIcon />
          }
          disabled={isLoading}
          sx={{ fontSize: '0.85rem', fontWeight: 500 }}
        >
          <Typography
            component={'span'}
            color="warning"
            display={{ xs: 'none', sm: 'block' }}
            sx={{ fontSize: '0.85rem', fontWeight: 500 }}
          >
            Terminer ma vacation
          </Typography>
        </Button>
      ) : (
        <InactiveSessionButtonUI
          onClick={() => {
            logStartedSession({ from: 'AppBar' });
            start(
              {},
              {
                onError: (e) => {
                  notify(`Une erreur est survenue: ${e}`, { type: 'error' });
                },
              },
            );
          }}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export const SessionButton = () => {
  const isBoHelper = useSelector((state) =>
    state.user.roles.includes(Roles.BO_PUNCTUAL_HELPERS),
  );
  const { data: sessions } = useActiveSessions();
  const userId = useSelector((state) => state.user.userId);
  const active = sessions?.some((s) => String(s) === String(userId));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [displayEvaluation, setDisplayEvaluation] = useState(false);

  if (isBoHelper && !active) {
    return (
      <>
        <SessionEvaluationDialog
          open={displayEvaluation}
          onClose={() => {
            setDisplayEvaluation(false);
          }}
        />
        <InactiveSessionButtonUI
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            logStartedSession({ from: 'AppBar' });
          }}
          isLoading={false}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableAutoFocus={true}
          sx={{ [`& ul`]: { paddingTop: 0, paddingBottom: 0 } }}
        >
          <MenuItem sx={{ p: 1 }}>
            <InactiveSessionButton onClick={() => setAnchorEl(null)} />
          </MenuItem>
          <MenuItem sx={{ p: 1 }}>
            <InactiveSessionButton
              onClick={() => setAnchorEl(null)}
              noAttribution
            />
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <>
      <SessionEvaluationDialog
        open={displayEvaluation}
        onClose={() => {
          setDisplayEvaluation(false);
        }}
      />
      <NormalSessionButton onStopSession={() => setDisplayEvaluation(true)} />
    </>
  );
};
