import { produce } from 'immer';
import _clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _memoize from 'lodash/memoize';
import _merge from 'lodash/merge';
import React, { useContext, useMemo } from 'react';
import { Identifier, useGetList } from 'react-admin';
import { useSearchParams } from 'react-router-dom';

import {
  Category,
  Discussion,
  DiscussionEvent,
  Handover,
  Subject,
} from '@boTypes';
import { HandoverRevive } from '@boTypes/handover';
import { Socket, SocketEvents, SocketHandlerGenerator } from '@boTypes/socket';
import {
  ATTRIBUTION_OPTIONS_ENUM,
  CLASSICAL_OPTIONS_ENUM,
  Filters,
} from '@boTypes/subject';
import { isDoctorJob } from '@boTypes/user';
import { QueryClient, useQuery, useQueryClient } from '@hooks/queryWrappers';

import {
  _useSubjectAttributions,
  _useWaitingAttributions,
} from './subjectAttribution';
import { useAttributionUI } from './useAttributionUI';
import { useByIds } from './useByIds';
import { useSocketHandlers } from './useSocketHandlers';
import { DiscussionContext } from '../common';
import { useSelector } from '../store';
import {
  addHandoverInDiscussion,
  mergeHandoverInDiscussion,
  mutateDiscussionWithRevive,
  removeDiscussionRevives,
} from '../utils/handovers';

export const subjectKey = (discussionContext) =>
  discussionContext === DiscussionContext.MIDWIFE
    ? 'subjects-midwife'
    : 'subjects';

const firstMessageOfUserSort = (a: Discussion, b: Discussion) =>
  new Date(b.lastEvent.firstOfUser).getTime() -
  new Date(a.lastEvent.firstOfUser).getTime();

const midwifeDiscussionSort = (
  a: Discussion,
  b: Discussion,
  categoryFromId: Record<number, Category>,
  activeTab: ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM,
) =>
  activeTab === ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED
    ? sortUncategorizedFirst(a, b, categoryFromId)
    : firstMessageOfUserSort(a, b);

const sortIsPriority = (a: Discussion, b: Discussion) => {
  // priority questions should be on top
  if (a.lastSubject.isPriority && !b.lastSubject.isPriority) {
    return -1;
  } else if (!a.lastSubject.isPriority && b.lastSubject.isPriority) {
    return 1;
  } else if (a.lastSubject.isPriority) {
    return oldestFirstSort(a, b);
  } else {
    return 0;
  }
};

const sortUncategorizedFirst = (
  a: Discussion,
  b: Discussion,
  categoryFromId: Record<number, Category>,
) => {
  // uncategorized question should be on top to be categorized quickly
  const aCategory = a.lastSubject.categoryId
    ? categoryFromId[a.lastSubject.categoryId]
    : undefined;
  const bCategory = b.lastSubject.categoryId
    ? categoryFromId[b.lastSubject.categoryId]
    : undefined;
  if (!aCategory) {
    if (!bCategory) {
      return oldestFirstSort(a, b);
    }
    return -1;
  }
  if (!bCategory) {
    return 1;
  }
  return 0;
};

const delayThreshold = 10 * 60 * 1000; // 10 minutes
const longDelayThreshold = 45 * 60 * 1000; // 45 minutes

const sortLongThresholdDelayFirst = (a: Discussion, b: Discussion) => {
  const now = new Date().getTime();
  const aWaitingTime = now - new Date(a.lastEvent.firstOfUser).getTime();
  const bWaitingTime = now - new Date(b.lastEvent.firstOfUser).getTime();

  if (aWaitingTime > longDelayThreshold) {
    if (bWaitingTime < longDelayThreshold) {
      return -1;
    }
    return oldestFirstSort(a, b);
  }

  if (bWaitingTime > longDelayThreshold) {
    return 1;
  }

  return 0;
};

const sortPriorityPostLongQuestion = (
  a: Discussion,
  b: Discussion,
  categoryFromId: Record<number, Category>,
) => {
  const aPriorityOrder = a.lastSubject.categoryId
    ? categoryFromId[a.lastSubject.categoryId]?.priorityOrder
    : undefined;
  const bPriorityOrder = b.lastSubject.categoryId
    ? categoryFromId[b.lastSubject.categoryId]?.priorityOrder
    : undefined;
  // Category exist, let's check their priority order
  if (aPriorityOrder === bPriorityOrder) {
    return 0;
  }
  if (aPriorityOrder === 0) {
    return 1;
  }
  if (bPriorityOrder === 0) {
    return -1;
  }
  return aPriorityOrder - bPriorityOrder < 0 ? -1 : 1;
};

const mostRecentFirstSort = (a: Discussion, b: Discussion) => {
  return firstMessageOfUserSort(a, b);
};

const oldestFirstSort = (a: Discussion, b: Discussion) => {
  return firstMessageOfUserSort(b, a);
};

const sortRecentDiscussion = (a: Discussion, b: Discussion) => {
  /**
   * We have 3 sort levels:
   * - long delay threshold: more than 45 minutes since last message
   * This should appear first, with the oldest subject being on top
   * IT IS ALREADY HANDLED BY THE FIRST SORT
   * - short delay: less than 10 minutes since last message
   * This should appear right after long threshold, with the most recent subject being on top
   * This should allow direct conversation with the user, and decrease overall waiting time
   * - recent: between 10 and 45 minutes since last message
   * This should appear last, with the oldest subject being on top
   **/
  const now = new Date().getTime();
  const aWaitingTime = now - new Date(a.lastEvent.firstOfUser).getTime();
  const bWaitingTime = now - new Date(b.lastEvent.firstOfUser).getTime();

  if (aWaitingTime > longDelayThreshold) {
    if (bWaitingTime < longDelayThreshold) {
      return -1;
    }
    return oldestFirstSort(a, b);
  }

  if (bWaitingTime > longDelayThreshold) {
    return 1;
  }

  if (aWaitingTime < delayThreshold) {
    if (bWaitingTime < delayThreshold) {
      return mostRecentFirstSort(a, b);
    }
    return -1;
  }
  if (bWaitingTime < delayThreshold) {
    return 1;
  }

  return oldestFirstSort(a, b);
};

export const childDiscussionSort = (
  a: Discussion,
  b: Discussion,
  discussionStatus: Filters,
  categoryFromId: Record<number, Category>,
  activeTab: ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM,
) => {
  if (discussionStatus === Filters.ACTIVE) {
    return (
      (activeTab === ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED
        ? sortUncategorizedFirst(a, b, categoryFromId) || sortIsPriority(a, b)
        : sortIsPriority(a, b) ||
          sortUncategorizedFirst(a, b, categoryFromId)) ||
      sortLongThresholdDelayFirst(a, b) ||
      sortPriorityPostLongQuestion(a, b, categoryFromId) ||
      sortRecentDiscussion(a, b)
    );
  } else {
    return mostRecentFirstSort(a, b);
  }
};

const reviveSort = (a: Discussion, b: Discussion) => {
  const aRevive = Math.min(
    ...(a.revives?.map((r) => new Date(r.reviveAt).getTime()) || []),
  );
  const bRevive = Math.min(
    ...(b.revives?.map((r) => new Date(r.reviveAt).getTime()) || []),
  );

  return aRevive - bRevive;
};

const discussionSort =
  (
    discussionStatus: Filters,
    categoryFromId: Record<number, Category>,
    activeTab: ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM,
  ) =>
  (a: Discussion, b: Discussion) =>
    discussionStatus === Filters.REVIVE
      ? reviveSort(a, b)
      : a.kidId
        ? childDiscussionSort(a, b, discussionStatus, categoryFromId, activeTab)
        : midwifeDiscussionSort(a, b, categoryFromId, activeTab);

const isActive = (discussion: Discussion) => {
  if (!discussion.lastEvent?.createdAt || !discussion.lastSubject) {
    return false;
  }
  const currentDate = new Date(discussion.lastEvent.createdAt);
  return (
    !discussion.lastSubject.empty &&
    currentDate.getTime() > Date.now() - 2 * 24 * 86400 * 1000
  );
};

const discussionHasRevives = ({ revives }: Discussion) => {
  return (
    revives?.length &&
    revives.some(
      (r) => (typeof r.active === 'undefined' || r.active) && !r.performedAt,
    )
  );
};

const isInactive = (discussion: Discussion) => {
  if (!discussion.lastSubject) {
    return false;
  }
  return discussion.lastSubject.empty && !discussionHasRevives(discussion);
};

const isRevive = (discussion: Discussion) => {
  return discussionHasRevives(discussion);
};

const filterToEvaluation = {
  [Filters.ACTIVE]: isActive,
  [Filters.INACTIVE]: isInactive,
  [Filters.REVIVE]: isRevive,
};

const isDiscussionInContext = (
  discussionContext: DiscussionContext,
  discussion: Discussion,
) => {
  const isMidwife = discussionContext === DiscussionContext.MIDWIFE;
  return (
    isMidwife === !discussion.kidId ||
    (discussionContext === DiscussionContext.NURSE &&
      discussion.lastSubject?.withNurseIntervention)
  );
};

export const addDiscussionEventInDiscussions = produce(
  (
    previousDiscussions: Discussion[],
    {
      discussion,
      event,
      filter,
      discussionContext,
    }: {
      discussion: Discussion;
      event: DiscussionEvent;
      filter: Filters;
      discussionContext: DiscussionContext;
      categoryFromId: Record<number, Category>;
    },
  ) => {
    if (!isDiscussionInContext(discussionContext, discussion)) {
      return;
    }
    if (event.content === 'NEW_SUBJECT' || event.content === 'END_SUBJECT') {
      return;
    }

    if (!previousDiscussions) {
      previousDiscussions = [];
    }

    const previousIndex = previousDiscussions.findIndex(
      (previousDiscussion) => previousDiscussion.id === discussion.id,
    );

    let mergedDiscussion = _merge(
      previousIndex >= 0 ? previousDiscussions[previousIndex] : {},
      discussion,
      {
        lastEvent: event,
        lastSubject: { empty: false },
      },
    );
    mergedDiscussion.lastSubject.empty = false;
    const isInFilter = filterToEvaluation[filter]
      ? filterToEvaluation[filter](mergedDiscussion)
      : true;

    // remove or add discussion in the list
    if (isInFilter && previousIndex < 0) {
      previousDiscussions.unshift(mergedDiscussion);
    } else if (!isInFilter && previousIndex >= 0) {
      previousDiscussions.splice(previousIndex, 1);
    }
  },
);

export const updateEventInDiscussions = produce(
  (
    previousDiscussions: Discussion[],
    {
      discussionId,
      event,
    }: {
      event: DiscussionEvent;
      discussionId: Identifier;
      filter: Filters;
      categoryFromId: Record<number, Category>;
    },
  ) => {
    if (!previousDiscussions) {
      return previousDiscussions;
    }
    const previousDiscussion = previousDiscussions.find(
      (d) => d.id === discussionId,
    );

    if (!previousDiscussion) {
      return previousDiscussions;
    }

    // If event matches, update the discussion and sort
    if (previousDiscussion.lastEvent?.id === event.id) {
      previousDiscussion.lastEvent = event;
    }
  },
);

const closeSubject = produce(
  (
    previousDiscussions: Discussion[],
    {
      discussion,
      filter,
    }: {
      discussion: Discussion;
      filter: Filters;
      categoryFromId: Record<number, Category>;
    },
  ) => {
    if (!previousDiscussions) {
      return previousDiscussions;
    }
    if (filter === Filters.ACTIVE) {
      return previousDiscussions.filter((d) => d.id !== discussion.id);
    } else if (
      filter !== Filters.REVIVE &&
      !filterToEvaluation[filter](discussion)
    ) {
      return previousDiscussions.filter((d) => d.id !== discussion.id);
    }

    const existing = previousDiscussions.find((d) => d.id === discussion.id);
    if (existing) {
      _merge(existing, discussion);
    } else {
      previousDiscussions.push(discussion);
    }
  },
);

export const updateSubject = produce(
  (
    previousDiscussions: Discussion[],
    {
      newSubject,
      discussion: socketDiscussion,
      discussionContext,
    }: {
      newSubject: Subject;
      filter: Filters;
      discussion?: Discussion;
      discussionContext: DiscussionContext;
      categoryFromId: Record<number, Category>;
    },
  ) => {
    if (!previousDiscussions) {
      return;
    }
    let discussionIndex = previousDiscussions.findIndex(
      (d) => `${d.lastSubject?.id}` === `${newSubject.id}`,
    );
    if (
      discussionIndex < 0 &&
      (!socketDiscussion ||
        !isDiscussionInContext(discussionContext, socketDiscussion))
    ) {
      return;
    } else if (discussionIndex < 0) {
      discussionIndex = previousDiscussions.length;
      previousDiscussions.push(socketDiscussion);
    }
    const discussion = previousDiscussions[discussionIndex];
    _merge(discussion.lastSubject, newSubject);

    if (!isDiscussionInContext(discussionContext, discussion)) {
      previousDiscussions.splice(discussionIndex, 1);
      return;
    }
  },
);

export const addReviveInDiscussions = produce(
  (
    previousDiscussions: Discussion[],
    {
      revive,
      filter,
      fromOtherFilter,
    }: {
      revive: HandoverRevive;
      filter: Filters;
      fromOtherFilter?: Discussion;
      categoryFromId: Record<number, Category>;
    },
  ) => {
    if (filter !== Filters.REVIVE || !previousDiscussions) {
      return;
    }

    let previousDiscussion = previousDiscussions.find(
      (d) => d.id === revive.discussionId,
    );
    if (!previousDiscussion) {
      if (fromOtherFilter) {
        previousDiscussion = cloneDeep(fromOtherFilter);
        previousDiscussions.push(previousDiscussion);
      } else {
        return;
      }
    }

    mutateDiscussionWithRevive(previousDiscussion, revive);
  },
);

export const updateReviveInDiscussions = produce(
  (
    previousDiscussions: Discussion[],
    {
      revive,
      filter,
    }: {
      revive: HandoverRevive;
      filter: Filters;
      categoryFromId: Record<number, Category>;
    },
  ) => {
    if (filter !== Filters.REVIVE || !previousDiscussions) {
      return;
    }
    let previousDiscussion = previousDiscussions.find(
      (d) => d.id === revive.discussionId,
    );
    if (!previousDiscussion) {
      return;
    }
    mutateDiscussionWithRevive(previousDiscussion, revive);

    // remove if not in filter anymore
    if (!filterToEvaluation[filter](previousDiscussion)) {
      const previousIndex = previousDiscussions.findIndex(
        (d) => d.id === previousDiscussion?.id,
      );
      if (previousIndex >= 0) {
        previousDiscussions.splice(previousIndex, 1);
      }
    }
  },
);

export const deleteReviveInDiscussions = produce(
  (
    previousDiscussions: Discussion[],
    {
      reviveId,
      discussionId,
      filter,
    }: {
      reviveId: HandoverRevive['id'];
      discussionId: Identifier;
      filter: Filters;
      categoryFromId: Record<number, Category>;
    },
  ) => {
    if (filter !== Filters.REVIVE || !previousDiscussions) {
      return;
    }
    let previousDiscussion = previousDiscussions.find(
      (d) => String(d.id) === String(discussionId),
    );
    if (!previousDiscussion) {
      return;
    }
    removeDiscussionRevives(previousDiscussion, reviveId);

    // remove if not in filter anymore
    if (!filterToEvaluation[filter](previousDiscussion)) {
      const previousIndex = previousDiscussions.findIndex(
        (d) => d.id === previousDiscussion?.id,
      );
      if (previousIndex >= 0) {
        previousDiscussions.splice(previousIndex, 1);
      }
    }
  },
);

const socketMessageHandlerGenerator: SocketHandlerGenerator<
  [QueryClient, Filters, DiscussionContext, Record<number, Category>]
> = {
  [SocketEvents.NEW_DISCUSSION_EVENT]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    ({
      discussion,
      event,
    }: {
      discussion: Discussion;
      // @ts-ignore
      event: DiscussionMessage;
    }) => {
      queryClient.setQueryData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          addDiscussionEventInDiscussions(previousDiscussions, {
            discussion,
            event,
            discussionContext,
            filter,
            categoryFromId,
          }),
      );
    },
  // @ts-ignore
  [SocketEvents.UPDATE_DISCUSSION_EVENT]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    ({
      event,
      discussionId,
    }: {
      // @ts-ignore
      event: DiscussionEvent;
      discussionId: Identifier;
    }) => {
      queryClient.setQueryData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          updateEventInDiscussions(previousDiscussions, {
            event,
            discussionId,
            filter,
            categoryFromId,
          }),
      );
    },
  [SocketEvents.CLOSED_SUBJECT]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    (discussion: Discussion) => {
      // prevent discussion coming from backend to be added in wrong context
      if (discussion && !isDiscussionInContext(discussionContext, discussion)) {
        return;
      }
      queryClient.setQueryData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          closeSubject(previousDiscussions, {
            discussion,
            filter,
            categoryFromId,
          }),
      );
    },

  [SocketEvents.UPDATE_SUBJECT]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    ({
      subject: newSubject,
      discussion,
    }: {
      subject: Subject;
      discussion?: Discussion;
    }) => {
      // prevent discussion coming from backend to be added in wrong context
      if (discussion && !isDiscussionInContext(discussionContext, discussion)) {
        return;
      }
      queryClient.setQueriesData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          updateSubject(previousDiscussions, {
            newSubject,
            filter,
            discussion,
            discussionContext,
            categoryFromId,
          }),
      );
    },
  [SocketEvents.NEW_HANDOVER]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
    ) =>
    ({
      handover,
      subjectId,
    }: {
      handover: Handover;
      subjectId: Identifier;
    }) => {
      queryClient.setQueriesData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) => {
          return previousDiscussions?.map((discussion) =>
            addHandoverInDiscussion(discussion, handover, subjectId),
          );
        },
      );
    },
  [SocketEvents.UPDATE_HANDOVER]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
    ) =>
    (handover: Handover) => {
      queryClient.setQueriesData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) => {
          return previousDiscussions?.map((discussion) =>
            mergeHandoverInDiscussion(discussion, handover),
          );
        },
      );
    },
  [SocketEvents.NEW_HANDOVER_REVIVE]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    ({ revive }) => {
      if (filter !== Filters.REVIVE) {
        return;
      }

      const extracted = queryClient.getQueriesData<Discussion[]>({
        queryKey: [subjectKey(discussionContext)],
        predicate: (query) => {
          if (
            'filter' in (query.queryKey[1] as any) &&
            (query.queryKey[1] as any).filter === Filters.REVIVE
          ) {
            return false;
          }
          return true;
        },
      });

      let discussion: Discussion | undefined;
      for (let index = 0; index < extracted.length; index++) {
        discussion = extracted[index][1].find(
          (d) => String(d.id) === String(revive.discussionId),
        );
        if (discussion) {
          break;
        }
      }

      queryClient.setQueriesData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          addReviveInDiscussions(previousDiscussions, {
            revive,
            filter,
            fromOtherFilter: discussion,
            categoryFromId,
          }),
      );
    },

  [SocketEvents.UPDATE_HANDOVER_REVIVE]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    ({ revive }) => {
      queryClient.setQueriesData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          updateReviveInDiscussions(previousDiscussions, {
            revive,
            filter,
            categoryFromId,
          }),
      );
    },
  [SocketEvents.CLOSE_HANDOVER_REVIVE]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    ({ revive }) => {
      queryClient.setQueriesData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          updateReviveInDiscussions(previousDiscussions, {
            revive,
            filter,
            categoryFromId,
          }),
      );
    },
  [SocketEvents.DELETE_HANDOVER_REVIVE]:
    (
      queryClient: QueryClient,
      filter: Filters,
      discussionContext: DiscussionContext,
      categoryFromId: Record<number, Category>,
    ) =>
    ({ reviveId, discussionId }) => {
      queryClient.setQueriesData<Discussion[]>(
        [subjectKey(discussionContext), { filter, discussionContext }],
        (previousDiscussions?: Discussion[]) =>
          deleteReviveInDiscussions(previousDiscussions, {
            reviveId,
            discussionId,
            filter,
            categoryFromId,
          }),
      );
    },
};

const selectSubject = produce(
  (
    data: Discussion[],
    filter: Filters,
    categoryFromId: Record<number, Category>,
    activeTab: ATTRIBUTION_OPTIONS_ENUM | CLASSICAL_OPTIONS_ENUM,
  ) => {
    return data?.sort(discussionSort(filter, categoryFromId, activeTab));
  },
);

export const useSubjectsQuery = (
  socket: Socket,
  discussionContext: DiscussionContext,
  filter: Filters,
) => {
  const queryClient = useQueryClient();

  const { data: categoryList } = useGetList<Category>(
    'categories',
    {
      pagination: { page: 1, perPage: 1000 },
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );

  const categoryFromId = useByIds<Category>(categoryList);
  const activeTab = useActiveTab();

  const select = useMemo(
    () =>
      _memoize((data: Discussion[]) =>
        selectSubject(data, filter, categoryFromId, activeTab),
      ),
    [filter, categoryFromId, activeTab],
  );
  useSocketHandlers(
    socket,
    socketMessageHandlerGenerator,
    queryClient,
    filter,
    discussionContext,
    categoryFromId,
  );
  return useQuery<Discussion[]>(
    [subjectKey(discussionContext), { filter, discussionContext }],
    () => ({
      url:
        discussionContext === DiscussionContext.MIDWIFE
          ? '/api/discussions-midwife'
          : '/api/discussions',
      params: {
        filter: JSON.stringify({ status: filter }),
      },
    }),
    {
      enabled: Boolean(filter),
      select,
      refetchInterval: () => {
        if (!socket) {
          return 60 * 1000;
        }
        return 3 * 60 * 1000;
      },
    },
  );
};

const SubjectContext = React.createContext<{
  loading: boolean;
  subjects: Discussion[];
  allSubjects: Discussion[];
}>({ loading: false, subjects: [], allSubjects: [] });

export const useSubjects = () => {
  return useContext(SubjectContext);
};

export const useSubjectFilter = () => {
  const [searchParams] = useSearchParams();
  const stringFilter = searchParams.get('filter');
  try {
    return stringFilter ? JSON.parse(stringFilter).status : Filters.ACTIVE;
  } catch {
    return Filters.ACTIVE;
  }
};

const useDefaultAttributionTab = () => {
  const jobTitle = useSelector((state) => state.user.jobTitle);

  return isDoctorJob(jobTitle)
    ? ATTRIBUTION_OPTIONS_ENUM.WITH_DOCTOR_INTERVENTION
    : ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS;
};

const allTabs = [
  ...Object.values(ATTRIBUTION_OPTIONS_ENUM),
  ...Object.values(CLASSICAL_OPTIONS_ENUM),
];

export const useActiveTab = () => {
  const [searchParams] = useSearchParams();
  const attributionUI = useAttributionUI();
  const tab = searchParams.get('tab') as
    | ATTRIBUTION_OPTIONS_ENUM
    | CLASSICAL_OPTIONS_ENUM
    | undefined;
  const defaultAttributionTab = useDefaultAttributionTab();

  return tab && allTabs.includes(tab)
    ? tab
    : attributionUI
      ? defaultAttributionTab
      : CLASSICAL_OPTIONS_ENUM.ACTIVE;
};

export const SubjectProvider = ({
  socket,
  discussionContext,
  ...other
}: {
  socket: Socket | null;
  discussionContext: DiscussionContext;
  children: React.ReactElement;
}) => {
  const filter = useSubjectFilter();
  const { isLoading, data } = useSubjectsQuery(
    socket,
    discussionContext,
    filter,
  );
  const { data: attributions } = _useSubjectAttributions(socket, true);
  _useWaitingAttributions(socket, true);

  const tab = useActiveTab();
  const subjects = useMemo(() => {
    if (!data?.length) {
      return [];
    }
    if (tab === ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS) {
      return data.filter(
        (d) => attributions?.staffUserFromSubjectId?.[d.lastSubjectId],
      );
    }
    if (tab === ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED) {
      return data.filter(
        (d) =>
          !attributions?.staffUserFromSubjectId?.[d.lastSubjectId] &&
          !d.lastSubject?.withDoctorIntervention,
      );
    }
    if (tab === ATTRIBUTION_OPTIONS_ENUM.WITH_DOCTOR_INTERVENTION) {
      return data.filter((d) => d.lastSubject?.withDoctorIntervention);
    }
    return data;
  }, [attributions?.staffUserFromSubjectId, data, tab]);

  return (
    <SubjectContext.Provider
      value={{ loading: isLoading, subjects, allSubjects: data }}
      {...other}
    />
  );
};
