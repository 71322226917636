import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import { useWindowDimensions } from 'react-native';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  CircularProgress,
  Drawer,
  Fab,
  List,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { CARD_MAX_WIDTH, PlanningCard } from './components/PlanningCard';
import { PlanningForm } from './form';
import { usePlannings } from '../../hooks/plannings';
import { COLORS } from '../../themes';
import {
  jobToString,
  Planning,
  PlanningJob,
  PlanningStatus,
  statusToString,
} from '../../types/planning';

dayjs.extend(LocalizedFormat);

const PlanningByYear = ({
  plannings,
  cardPerLine,
  setOpen,
  setEditedPlanning,
}: {
  plannings: Planning[];
  cardPerLine: number;
  setOpen: (open: boolean) => void;
  setEditedPlanning: (planning: Planning) => void;
}) => {
  if (!plannings.length) {
    return null;
  }

  const groupByYear = groupBy(plannings, (planning) =>
    dayjs(planning.begin).year(),
  );
  const years = Object.keys(groupByYear)
    .map((year) => parseInt(year, 10))
    .sort((a, b) => b - a);

  return (
    <>
      {years.map((year) => (
        <Box key={year} sx={{ paddingBottom: '0.5rem' }}>
          <Typography
            color="primary"
            variant="subtitle1"
            sx={{ fontWeight: 500, marginLeft: '-0.25rem' }}
          >
            ● {year}
          </Typography>
          <PlanningByMonth
            plannings={groupByYear[year]}
            cardPerLine={cardPerLine}
            setOpen={setOpen}
            setEditedPlanning={setEditedPlanning}
          />
        </Box>
      ))}
    </>
  );
};

const PlanningByMonth = ({
  plannings,
  cardPerLine,
  setOpen,
  setEditedPlanning,
}: {
  plannings: Planning[];
  cardPerLine: number;
  setOpen: (open: boolean) => void;
  setEditedPlanning: (planning: Planning) => void;
}) => {
  const groupByMonth = groupBy(plannings, (planning) =>
    dayjs(planning.begin).month(),
  );
  const months = Object.keys(groupByMonth)
    .map((month) => parseInt(month, 10))
    .sort((a, b) => b - a);

  return (
    <Box sx={{ borderLeft: `2px dashed ${COLORS.GREEN['300']}` }}>
      {months.map((month) => (
        <Box key={month}>
          <Typography variant="subtitle2" sx={{ ml: '0.5rem' }}>
            {dayjs().month(month).format('MMMM').toUpperCase()}
          </Typography>
          {chunk(sortBy(groupByMonth[month], 'job'), cardPerLine).map(
            (_plannings, idx) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                key={idx}
              >
                {_plannings.map((planning) => (
                  <PlanningCard
                    key={planning.id}
                    planning={planning}
                    onEdit={() => {
                      setOpen(true);
                      setEditedPlanning(planning);
                    }}
                  />
                ))}
              </Box>
            ),
          )}
        </Box>
      ))}
    </Box>
  );
};

export const PlanningList = () => {
  const notify = useNotify();
  const [status, setStatus] = useState<PlanningStatus | 'all'>('all');
  const [job, setJob] = useState<PlanningJob | 'all'>('all');
  const {
    data = [],
    isLoading,
    refetch,
  } = usePlannings(
    { status, job },
    {
      onError: () => {
        notify('Erreur lors du chargement des plannings', { type: 'error' });
      },
    },
  );
  const [open, setOpen] = useState(false);
  const [editedPlanning, setEditedPlanning] = useState<Planning | null>(null);
  const { width } = useWindowDimensions();
  const cardPerLine = Math.floor(width / CARD_MAX_WIDTH);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: '1rem',
        overflow: 'auto',
      }}
    >
      <Fab
        color="primary"
        aria-label="add"
        sx={(theme) => ({
          position: 'fixed',
          right: theme.spacing(2),
          bottom: theme.spacing(2),
        })}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
          Planning admin
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography>Statut :</Typography>
          <Select<PlanningStatus | 'all'>
            variant="outlined"
            value={status}
            onChange={(event) =>
              setStatus(event.target.value as PlanningStatus | 'all')
            }
            size="small"
          >
            <MenuItem value={'all'}>Tous</MenuItem>
            {Object.values(PlanningStatus).map((s, i) => (
              <MenuItem key={i} value={s}>
                {statusToString[s]}
              </MenuItem>
            ))}
          </Select>
          <Typography>Job :</Typography>
          <Select<PlanningJob | 'all'>
            value={job}
            variant="outlined"
            onChange={(event) =>
              setJob(event.target.value as PlanningJob | 'all')
            }
            size="small"
          >
            <MenuItem value={'all'}>Tous</MenuItem>
            {Object.values(PlanningJob).map((s) => (
              <MenuItem key={s} value={s}>
                {jobToString[s]}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Drawer
        PaperProps={{
          sx: {
            minWidth: 480,
          },
        }}
        open={open}
        ModalProps={{
          keepMounted: false,
        }}
        anchor="right"
        onClose={() => {
          setOpen(false);
          setEditedPlanning(null);
          refetch();
        }}
      >
        <PlanningForm
          onClose={() => {
            setOpen(false);
            setEditedPlanning(null);
            refetch();
          }}
          planning={editedPlanning ?? undefined}
        />
      </Drawer>
      {isLoading && <CircularProgress />}
      {!data?.length && (
        <Typography variant="h6">{'Aucun planning ne correspond'}</Typography>
      )}
      <List>
        <PlanningByYear
          plannings={data}
          cardPerLine={cardPerLine}
          setOpen={setOpen}
          setEditedPlanning={setEditedPlanning}
        />
      </List>
    </Box>
  );
};
