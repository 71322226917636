import { Create } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { PricingProCustomForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const PricingProCustomCreate = () => {
  const location = useLocation() as RedirectLocation;
  return (
    <Create
      resource="pricing-pro-custom"
      title="Ajouter un pricing"
      redirect={location?.state?.redirect || '/pricing-pro'}
    >
      <PricingProCustomForm />
    </Create>
  );
};
