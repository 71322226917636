import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DiscussionQuickFilter } from '../types/discussion';

const discussionFiltersSlice = createSlice({
  name: 'discussionFilters',
  initialState: {
    nurse: DiscussionQuickFilter.NONE,
    midwife: DiscussionQuickFilter.NONE,
  },
  reducers: {
    setQuickFilter(
      state,
      action: PayloadAction<{
        filter: DiscussionQuickFilter;
        filterType: 'nurse' | 'midwife';
      }>,
    ) {
      if (action.payload.filter === state[action.payload.filterType]) {
        return {
          ...state,
          [action.payload.filterType]: DiscussionQuickFilter.NONE,
        };
      }
      return { ...state, [action.payload.filterType]: action.payload.filter };
    },
    forceQuickFilter(
      state,
      action: PayloadAction<{
        filter: DiscussionQuickFilter;
        filterType: 'nurse' | 'midwife';
      }>,
    ) {
      return {
        ...state,
        [action.payload.filterType]: action.payload.filter,
      };
    },
  },
});

export const { setQuickFilter, forceQuickFilter } =
  discussionFiltersSlice.actions;

export default discussionFiltersSlice.reducer;
