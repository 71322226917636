import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';

import { LibraryFilterContainer } from '@components/libraryFilterContainer';
import { Chip } from '@mui/material';

import { setPregnancy, setChildren } from '../../store/libraryFilters';

export const PregnancyFilter = ({
  pregnancy,
  setSelectedAgeLevel,
}: {
  pregnancy?: boolean;
  setSelectedAgeLevel: (ageLevel?: number) => void;
}) => {
  // This is madness but actually, midwives are not using ageFilters with
  // pregnancy filter. They just want a toggle pregnancy/not pregnancy.
  const translate = useTranslate();
  const dispatch = useDispatch();

  return (
    <LibraryFilterContainer>
      <Chip
        variant="outlined"
        size="small"
        label={translate('common.pregnancy')}
        color={pregnancy === true ? 'primary' : 'default'}
        onClick={() => dispatch(setPregnancy())}
      />
      <Chip
        variant="outlined"
        size="small"
        label={translate('common.children')}
        color={pregnancy === false ? 'primary' : 'default'}
        onClick={() => {
          dispatch(setChildren());
          setSelectedAgeLevel(null);
        }}
      />
    </LibraryFilterContainer>
  );
};
