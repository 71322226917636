import { useEffect } from 'react';

import {
  EventHandlerTypes,
  Socket,
  SocketHandlerGenerator,
} from '../types/socket';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type EventAndHandlerPair = Entries<
  Partial<{
    [Event in keyof EventHandlerTypes]: EventHandlerTypes[Event];
  }>
>;

export const useSocketHandlers = <T extends Array<any>>(
  socket: Socket,
  socketMessageHandlerGenerator: SocketHandlerGenerator<T>,
  ...handlerArgs: T
) => {
  useEffect(() => {
    if (socket) {
      const socketMessageHandler = Object.entries(
        socketMessageHandlerGenerator,
      ).reduce<
        Partial<{
          [Event in keyof EventHandlerTypes]: EventHandlerTypes[Event];
        }>
      >((acc, [event, handler]) => {
        acc[event] = handler(...handlerArgs);
        return acc;
      }, {});

      (Object.entries(socketMessageHandler) as EventAndHandlerPair).forEach(
        ([event, handler]) => {
          socket.on(event, handler);
        },
      );
      return () => {
        if (socket) {
          (Object.entries(socketMessageHandler) as EventAndHandlerPair).forEach(
            ([event, handler]) => {
              socket.off(event, handler);
            },
          );
        }
      };
    }
    // react-hooks/exhaustive-deps does not accept spread array. We need to disable it because array changes on every render while its component are not
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...handlerArgs, socket, socketMessageHandlerGenerator]);
};
