import { useRecordContext } from 'react-admin';

import { OpenIdProfiles } from '@boTypes/openIdProfiles';
import { Box, Chip, ChipProps } from '@mui/material';

const AppleIcon = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.79805 24.0003H6.06566C6.05403 23.9902 6.04419 23.9737 6.03167 23.971C5.17856 23.8272 4.52128 23.3353 3.92839 22.729C3.15575 21.9386 2.54587 21.0218 2.00306 20.0601C0.604445 17.5854 -0.158354 14.9394 0.0276507 12.0552C0.095614 11.002 0.324543 9.98349 0.81996 9.04836C1.51748 7.73039 2.53425 6.74398 3.85685 6.10286C4.78419 5.65315 5.76608 5.47914 6.7748 5.70536C7.44191 5.85465 8.09383 6.08088 8.74484 6.29519C9.42448 6.51867 10.1104 6.73391 10.8267 6.59377C11.3319 6.49486 11.8273 6.32725 12.3129 6.14957C13.0265 5.88854 13.7464 5.6614 14.5074 5.6211C15.9955 5.54233 17.2707 6.09095 18.3903 7.05447C18.7292 7.34664 19.0252 7.69193 19.3516 8.02348C17.479 9.18849 16.5463 10.8444 16.7207 13.0352C16.8942 15.2141 18.037 16.7409 19.999 17.6156C19.9856 17.6614 19.9794 17.6916 19.9677 17.7191C19.3731 19.1488 18.6094 20.4805 17.6856 21.7105C16.8969 22.7592 16.0384 23.7328 14.6746 24.0003H14.0335C13.4924 23.7988 12.9434 23.6165 12.4104 23.393C11.1504 22.8655 9.88949 22.8554 8.61697 23.3289C8.01066 23.5542 7.40436 23.7768 6.79805 24.0003Z"
      fill="black"
    />
    <path
      d="M9.68734 5.7463C9.2572 3.49595 11.2407 0.357197 14.3607 0C14.8508 2.23935 12.7779 5.80034 9.68734 5.7463Z"
      fill="black"
    />
  </svg>
);

const GoogleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
    <path d="M1 1h22v22H1z" fill="none" />
  </svg>
);

export const IconWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ display: 'flex' }}>{children}</Box>
);

export const OpenIdProfileChipField = ({
  size,
  ...props
}: {
  size?: 'small' | 'medium';
} & ChipProps) => {
  const record = useRecordContext<OpenIdProfiles>();
  switch (record?.provider) {
    case 'google':
      return (
        <IconWrapper>
          <GoogleIcon />
        </IconWrapper>
      );
    case 'apple':
      return (
        <IconWrapper>
          <AppleIcon />
        </IconWrapper>
      );
    default:
      return <Chip {...props} size={size} label={record?.provider} />;
  }
};
