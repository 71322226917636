import { LinkedContent, RECOMMENDATION_TYPE } from '@boTypes/changelog';

import { DailyTipItem } from '../DailyTip/DailyTipItem';
import { FolderItem } from '../Folder/FolderItem';
import GuideItem from '../Guide/GuideItem';
import { MacroItem } from '../Macro/MacroItem';
import { MasterclassItem } from '../Masterclass/MasterclassItem';
import { PostItem } from '../Post/PostItem';

export const LinkedContentListItem = ({ item }: { item: LinkedContent }) => {
  if (
    !('guide' in item) &&
    !('program' in item) &&
    !('post' in item) &&
    !('masterclass' in item) &&
    !('daily_tip' in item) &&
    !('macro' in item)
  ) {
    return null;
  }

  switch (item.__component) {
    case RECOMMENDATION_TYPE.ARTICLE:
      return <PostItem item={item.post} />;
    case RECOMMENDATION_TYPE.MASTERCLASS:
      return <MasterclassItem item={item.masterclass} />;
    case RECOMMENDATION_TYPE.FOLDER:
      return <FolderItem item={item.program} />;
    case RECOMMENDATION_TYPE.GUIDE:
      return <GuideItem item={item.guide} />;
    case RECOMMENDATION_TYPE.DAILY_TIP:
      return <DailyTipItem item={item.daily_tip} />;
    case RECOMMENDATION_TYPE.MACRO:
      return <MacroItem item={item.macro} />;
    default:
      return null;
  }
};
