import { Identifier } from 'react-admin';

import { useQuery } from '@hooks/queryWrappers';

import {
  ChildHeight,
  ChildHeightRaw,
  ChildWeight,
  ChildWeightRaw,
} from '../types/childCharts';

const CHILD_WEIGHT_KEYS = {
  list: ['childWeights'] as (string | number)[],
};

const CHILD_HEIGHT_KEYS = {
  list: ['childHeights'] as (string | number)[],
};

export const useChildWeights = (id: Identifier) => {
  return useQuery<ChildWeightRaw[], any, ChildWeight[]>(
    CHILD_WEIGHT_KEYS.list.concat([id]),
    () => ({
      method: 'get',
      url: `/api/child-weights/${id}`,
    }),
    {
      select: (data) =>
        data.map(({ date, createdAt, weight, id: weightId }) => ({
          id: weightId,
          value: weight,
          date: new Date(date),
          createdAt: new Date(createdAt),
        })),
    },
  );
};

export const useChildHeights = (id: Identifier) => {
  return useQuery<ChildHeightRaw[], any, ChildHeight[]>(
    CHILD_HEIGHT_KEYS.list.concat([id]),
    () => ({
      method: 'get',
      url: `/api/child-heights/${id}`,
    }),
    {
      select: (data) =>
        data.map(({ date, createdAt, height, id: heightId }) => ({
          id: heightId,
          value: height * 100,
          date: new Date(date),
          createdAt: new Date(createdAt),
        })),
    },
  );
};
