import { useRecordContext } from 'react-admin';

import { Chip } from '@mui/material';

import { CompanyPlan } from '../../types';

interface CodesFieldProps {
  label?: string;
}

export const CodesField = ({}: CodesFieldProps) => {
  const record = useRecordContext<CompanyPlan>();
  const codes = record.codes || [];
  return (
    <>
      {codes
        .filter((code) => code.active)
        .map((code) => (
          <Chip label={`${code.name} - ${code.code}`} key={code.id} />
        ))}
    </>
  );
};
