import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import * as Sentry from '@sentry/browser';

import { baseURL, cmsURL, s3URL, searchURL } from '../common/url';

let apiClient: AxiosInstance = axios.create({
  baseURL,
});

let cmsClient: AxiosInstance = axios.create({
  baseURL: cmsURL,
});

let s3Client: AxiosInstance = axios.create({
  baseURL: s3URL,
});

let searchClient: AxiosInstance = axios.create({
  baseURL: searchURL,
});

let accessTokenRenewal: number = 0;

const awaitableDebounce = <T extends (...args: any[]) => Promise<any>>(
  fn: T,
  delay: number,
) => {
  let executionTS = 0;
  let resultPromise: Promise<void> | null = null;
  return (...args: Parameters<T>) => {
    if (resultPromise) {
      if (Date.now() > executionTS + delay) {
        resultPromise = null;
      }
    }

    if (!resultPromise) {
      executionTS = Date.now();
      resultPromise = fn(...args);
    }

    return resultPromise;
  };
};

const tokenFetchMaxDuration = 60000;
/* perform refresh twice to handle temporary loss of network */
const refreshToken_ = async () => {
  let fetched = false;
  let cancel = false;
  const promiseCount = 3;
  const abortController = new AbortController();

  const wait = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  // This complicated request is made to improve timeout handling: with axios timeout, we may have a request that is cancelled but still waiting for a response. This way to do is
  // more verbose but any request succeeding in the time limit will be used
  const refreshRequest = (index: number) =>
    !fetched && !cancel
      ? apiRequest({
          url: '/api/auth/refresh_token',
          method: 'POST',
          signal: abortController.signal,
        }).then(
          () => {
            // Request succeded. Cancel other request and set fetched to true
            fetched = true;
            abortController.abort();
          },
          // Request failed. reject if last request or if 401 else wait to let other request finish
          (reason) => {
            if (index === promiseCount - 1 || reason.response?.status === 401) {
              throw reason;
            } else {
              return wait(tokenFetchMaxDuration);
            }
          },
        )
      : Promise.resolve();

  try {
    await Promise.race([
      refreshRequest(0),
      wait(10000).then(() => refreshRequest(1)),
      wait(30000).then(() => refreshRequest(2)),
      wait(tokenFetchMaxDuration).then(() => {
        cancel = true;
        abortController.abort();
      }),
    ]);
  } catch (e: any) {
    if (e.response?.status === 401) {
      cancel = true;
    }
    throw e;
  }
};
const refreshToken = awaitableDebounce(refreshToken_, tokenFetchMaxDuration);

export const setApiClientLogout = (logout: () => void) => {
  const id = apiClient.interceptors.response.use(
    (result: AxiosResponse<any>) => {
      if (result.config.url?.includes('auth') && result?.data?.exp) {
        accessTokenRenewal = Date.now() + (result.data.exp - 60) * 1000; //1min margin
      }
      return result;
    },
    (error) => {
      if (
        error.response?.status === 401 &&
        (!error.config.url?.includes('auth') ||
          error.config.url?.includes('refresh_token'))
      ) {
        logout();
      } else if (
        (!error.response || error.response.status > 499) &&
        error.code !== AxiosError.ETIMEDOUT &&
        error.code !== AxiosError.ECONNABORTED
      ) {
        Sentry.captureException(error);
      }
      return Promise.reject(error);
    },
  );

  return () => apiClient.interceptors.response.eject(id);
};

export const fullApiRequest = async <T = any>(
  options: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  if (
    !options?.url?.includes('auth/') ||
    options?.url?.includes('socket_token') // socket_token is an exception : it requires a valid access token even if it is on /auth
  ) {
    if (!accessTokenRenewal || accessTokenRenewal < Date.now()) {
      await refreshToken();
    }
  }

  return apiClient.request({
    withCredentials: true,
    ...options,
    headers: {
      ...(options.headers ?? {}),
      Accept: 'application/json',
    },
  });
};

export async function apiRequest<T>(options: AxiosRequestConfig): Promise<T> {
  return (await fullApiRequest<T>(options)).data;
}

export async function apiRequestWithFullResponse<T>(
  options: AxiosRequestConfig,
): Promise<AxiosResponse<T, any>> {
  return fullApiRequest<T>(options);
}

export async function cmsRequest<T>(options: AxiosRequestConfig): Promise<T> {
  const onSuccess = (response: AxiosResponse<T>) => response.data;
  if (!options?.url?.includes('auth/')) {
    if (!accessTokenRenewal || accessTokenRenewal < Date.now()) {
      await refreshToken();
    }
  }
  return cmsClient
    .request({
      withCredentials: true,
      ...options,
      headers: {
        ...(options.headers ?? {}),
        Accept: 'application/json',
      },
    })
    .then(onSuccess);
}

export async function searchRequest<T>(
  options: AxiosRequestConfig,
): Promise<T> {
  const onSuccess = (response: AxiosResponse<{ hits: T }>) =>
    response.data.hits;
  return searchClient
    .request({
      ...options,
      headers: {
        ...(options.headers ?? {}),
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(onSuccess);
}

export async function s3Request<T>(options: AxiosRequestConfig): Promise<T> {
  const onSuccess = (response: AxiosResponse<T>) => response.data;
  if (!accessTokenRenewal || accessTokenRenewal < Date.now()) {
    await refreshToken();
  }
  return s3Client
    .request({
      withCredentials: true,
      ...options,
      headers: {
        ...(options.headers ?? {}),
        Accept: 'application/json',
      },
    })
    .then(onSuccess);
}
