import { Fragment, useState } from 'react';
import {
  DeleteButton,
  Edit,
  useCreate,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  Datagrid,
  List,
  TextField,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import Add from '@mui/icons-material/Add';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { UsersForm } from './form';
import useTransform from '../../hooks/transform';
import { Roles } from '../../types/user';

const CreateButton = () => {
  const { permissions } = usePermissions();
  const { id } = useParams<'id'>();
  const [create] = useCreate<{ id: number; userId: number; role: Roles }>(
    'roles',
  );
  const [currentRole, setCurrentRole] = useState<Roles>(Roles.HEALTH_PRO);
  const notify = useNotify();
  const refresh = useRefresh();

  const onCreate = () => {
    create(
      'roles',
      {
        data: {
          userId: Number(id),
          role: currentRole,
        },
      },
      {
        onSuccess: () => {
          notify('roles.created', {
            type: 'success',
            undoable: false,
          });
          refresh();
        },
        onError: (error: Record<string, any>) => {
          notify(error?.response?.data?.message || 'common.error.create', {
            type: 'warning',
          });
        },
      },
    );
  };
  if (
    !permissions?.includes(Roles.STAFF_MANAGER) &&
    !permissions?.includes(Roles.ADMIN)
  ) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Select<Roles>
        value={currentRole}
        onChange={(e) => setCurrentRole(e.target.value as Roles)}
        size="small"
      >
        {Object.values(Roles).map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={onCreate}>
        <Add />
      </IconButton>
    </Box>
  );
};

const Toolbar = () => (
  <TopToolbar
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    }}
  >
    <Typography variant="subtitle2">Rôles</Typography>
    <CreateButton />
  </TopToolbar>
);

const InlineRoleList = () => {
  const { id } = useParams<'id'>();
  const translate = useTranslate();
  return (
    <List
      resource="roles"
      exporter={false}
      pagination={null}
      perPage={50}
      filter={{ userId: Number(id) }}
      actions={<Toolbar />}
      empty={<Toolbar />}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label={translate('roles.role')} source="role" />
        <DeleteButton redirect={false} />
      </Datagrid>
    </List>
  );
};

export const UsersEdit = (props) => {
  const { transform } = useTransform('users', 'avatar');

  return (
    <Box
      sx={{
        paddingTop: 0,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingBottom: '1rem',
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Edit
            {...props}
            redirect="list"
            resource="users"
            transform={transform}
          >
            <UsersForm />
          </Edit>
        </Grid>
        <Grid item xs={12} md={3}>
          <InlineRoleList />
        </Grid>
      </Grid>
    </Box>
  );
};
