import { Create } from 'react-admin';

import { ScenariosForm } from './form';

export const ScenariosCreate = (props) => (
  <Create
    {...props}
    resource="scenarios"
    title="Ajouter un scenario"
    redirect="list"
  >
    <ScenariosForm />
  </Create>
);
