import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { SwitchInput } from '@components/forms/switchInput';
import { Button } from '@components/generic/Button';
import { DialogTitle } from '@components/generic/Dialog';
import { FormSettingsUpdate, useFormUpdate } from '@hooks/form-builder';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from '@mui/material';

import { SelectInput } from '../../../components/forms/selectInput';
import { TextInput } from '../../../components/forms/textInput';

export const FormSettingsForm = ({
  form,
  onSave,
  onCancel,
}: {
  form: FormSettingsUpdate;
  onSave: (form: FormSettingsUpdate) => Promise<any>;
  onCancel: () => any;
}) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: form,
  });

  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    if (Object.keys(data).some((key) => data[key] !== form[key])) {
      await onSave(data);
    } else {
      onCancel();
    }
  });

  const translate = useTranslate();

  return (
    <>
      <DialogContent sx={{ minWidth: 500 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <TextInput
            control={control}
            name="title"
            label={translate('forms.settings.titleField')}
          />
          <TextInput
            control={control}
            name="description"
            label={translate('forms.settings.description')}
          />
          <TextInput
            control={control}
            name="slug"
            label={translate('forms.settings.slug')}
          />
          <TextInput
            control={control}
            name="illustration"
            label={translate('forms.settings.illustration')}
          />
          <SwitchInput
            control={control}
            name="sentInChat"
            label={translate('forms.settings.sentInChat')}
          />
          <SelectInput
            control={control}
            name="status"
            label={translate('forms.settings.status')}
            variant={'outlined'}
          >
            {['draft', 'published', 'archived'].map((status) => (
              <MenuItem value={status} key={status}>
                {status}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            control={control}
            name="questionCount"
            label={translate('forms.settings.questionCount')}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '1rem',
        }}
      >
        <Button
          sx={{ mx: 2 }}
          loading={isSubmitting}
          value={translate('common.cancel')}
          onClick={onCancel}
        />
        <Button
          sx={{ mx: 2 }}
          variant="contained"
          color="primary"
          loading={isSubmitting}
          value={translate('common.save')}
          onClick={onSubmit}
        />
      </DialogActions>
    </>
  );
};

export const FormSettingsModal = ({
  form,
  onClose,
}: {
  form?: FormSettingsUpdate | null;
  onClose: () => void;
}) => {
  const { mutateAsync: update } = useFormUpdate();
  const translate = useTranslate();
  return (
    <Dialog open={!!form} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('forms.edit.title')}
      </DialogTitle>
      {form && (
        <FormSettingsForm
          form={form}
          onSave={async ({
            id,
            description,
            illustration,
            slug,
            status,
            title,
            version,
            sentInChat,
            questionCount,
          }: FormSettingsUpdate) => {
            await update({
              id,
              description,
              illustration,
              slug,
              status,
              title,
              version,
              sentInChat,
              questionCount: Number(questionCount),
            });
            onClose();
          }}
          onCancel={() => onClose()}
        />
      )}
    </Dialog>
  );
};
