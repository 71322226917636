import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import upperFirst from 'lodash/upperFirst';
import { useState } from 'react';
import { ViewStyle } from 'react-native';
import { useNavigate } from 'react-router';

import { FormSettingsUpdate } from '@hooks/form-builder';
import useWindowDimensions from '@hooks/useWindowDimensions';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';

import { CARD_MAX_WIDTH, FormCard } from './FormCard';
import { FormSettingsModal } from './FormSettingsForm';

const styles = {
  card: {
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#f0f0e0',
  } satisfies ViewStyle,
};
export const FormBuilderListRow = ({
  forms,
}: {
  forms: FormSettingsUpdate[];
}) => {
  const { width } = useWindowDimensions();
  const cardPerLine = Math.floor(width / (CARD_MAX_WIDTH + 16));
  const [editedForm, setEditedForm] = useState<FormSettingsUpdate | null>(null);
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {chunk(forms, cardPerLine).map((formRow, idx) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
          key={idx}
        >
          {formRow.map((form) => (
            <Box key={form.id} sx={{ position: 'relative' }}>
              <FormCard
                style={styles.card}
                key={form.id}
                {...form}
                onPress={() => navigate(`/forms/${form.id}`)}
              />
              <IconButton
                sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                aria-label="Edit"
                onClick={() => setEditedForm(form)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      ))}
      <FormSettingsModal
        form={editedForm}
        onClose={() => setEditedForm(null)}
      />
    </Box>
  );
};

export const FormBuilderList = ({ forms }: { forms: FormSettingsUpdate[] }) => {
  const rows = groupBy(forms, 'status');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 2 }}>
      {Object.keys(rows)
        .sort()
        .toReversed()
        .map((status) => (
          <Box key={status}>
            <Typography variant="h5">{upperFirst(status)}</Typography>
            <FormBuilderListRow key={status} forms={rows[status]} />
          </Box>
        ))}
    </Box>
  );
};
