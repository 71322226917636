import { JobTitle } from '../types/user';

export const sexChoices = [
  { id: 'm', name: 'Masculin' },
  { id: 'f', name: 'Féminin' },
];

export const subscriptionChoices = [
  { id: 'active', name: 'Souscription payante' },
  { id: 'trialing', name: 'Période essai' },
  { id: 'bonus', name: 'Compte gratuit' },
  { id: 'affiliated', name: 'Affilié' },
];

export const titleChoices = Object.values(JobTitle).map((job) => ({
  id: job,
  name: job,
}));
