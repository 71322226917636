import dayjs from 'dayjs';
import maxBy from 'lodash/maxBy';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useGetList, useNotify, useTranslate } from 'react-admin';
import { UseMutateAsyncFunction } from 'react-query';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { HandoverRevive, HandoverReviveCreate } from '@boTypes/handover';
import {
  ATTRIBUTION_OPTIONS_ENUM,
  CLASSICAL_OPTIONS_ENUM,
  MinimalSubject,
} from '@boTypes/subject';
import { Button as CustomButton } from '@components/generic/Button';
import styled from '@emotion/styled';
import {
  usePostAndCloseRevive,
  useTargetSubject,
} from '@hooks/discussionEvents';
import {
  useCloseHandoverRevive,
  useCreateHandoverRevive,
  useUpdateHandoverRevive,
} from '@hooks/handover';
import { useActiveTab } from '@hooks/subjects';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import { Videocam } from '@mui/icons-material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AddIcon from '@mui/icons-material/Add';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import EditIcon from '@mui/icons-material/Edit';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import {
  Button,
  List,
  Typography,
  SxProps,
  Slide,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { getHandoverDate } from '@utils/handovers';

import { CategoryChipSelect } from './CategoryChipSelect';
import { HandoverEditForm, HandoverCreateForm } from './handoverForm';
import { StaffUserSignature } from './staffUserSignature';
import { LastMaySender } from './subjectCard';
import {
  logAddedReviveToHandover,
  logClosedRevive,
} from '../../analytics/events';
import { DiscussionContext } from '../../common';
import { useDiscussionDetail } from '../../hooks/discussion';
import { HistoryItem, usePatientHistory } from '../../hooks/history';
import {
  DiscussionContextProvider,
  useDiscussionContext,
} from '../../hooks/useDiscussionContext';
import { usePatientDetail } from '../../hooks/usePatientDetail';
import { COLORS } from '../../themes';
import { Category, Handover, User } from '../../types';
import { getTimeAgo } from '../../utils/date';
import { DialogButton } from '../DialogButton/DialogButton';
import { ReviveDialog } from '../discussions/reviveDialog';
import { ShyText } from '../fields';
import { CategoryChip } from '../fields/CategoriesChipField';

const getReviveColor = (revive?: HandoverRevive) => {
  if (!revive || revive.performedAt || !revive.active) {
    return undefined;
  }
  const reviveAt = new Date(revive.reviveAt).getTime();
  if (reviveAt < Date.now()) {
    return COLORS.PINK[500];
  }
  if (reviveAt < Date.now() + 2 * 3600 * 1000) {
    return COLORS.ORANGE;
  }
  return undefined;
};

const border = `1px solid ${COLORS.GREY_LAYOUT}`;

const AccordionSummaryWrapper = styled(AccordionSummary)({
  alignItems: 'center',
  flex: 1,
  padding: 0,
  minHeight: 0,
  height: '1.5rem',
  '&:focus::after': {
    backgroundColor: 'transparent',
  },
  '&:active:hover::after': {
    backgroundColor: 'transparent',
  },
  '& .Mui-expanded': {
    padding: 0,
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    minHeight: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
});

export const HandoverCard = ({
  handover,
  canEdit = false,
  onEditClick = () => {},
  onCardClick = () => {},
}: {
  handover: Handover;
  canEdit?: boolean;
  onEditClick?: (handover: Handover) => void;
  onCardClick?: () => void;
}) => {
  // Here we only retrieve data fetched in handoverList component hence the "enabled: false"
  const { data } = useGetVaryingMany('users', [handover.userId], {
    enabled: false,
  });

  const canCreateRevive =
    handover.createdAt && !handover.revive
      ? new Date(handover.createdAt).getTime() > Date.now() - 24 * 3600 * 1000 // can revive only in the day where the handover was created
      : false;

  const { mutateAsync: createRevive } = useCreateHandoverRevive();
  const { mutateAsync: updateRevive } = useUpdateHandoverRevive(
    handover?.revive?.id,
  );
  const { mutateAsync: closeRevive } = useCloseHandoverRevive();
  const [openRevive, setOpenReviveDialog] = useState(false);
  const notify = useNotify();
  const postAndCloseRevive = usePostAndCloseRevive();

  const handleSubmitRevive = useCallback(
    async (args: Omit<HandoverReviveCreate, 'handoverId'>) => {
      const mutation: UseMutateAsyncFunction<
        HandoverRevive,
        any,
        HandoverReviveCreate
      > = handover.revive ? updateRevive : createRevive;
      try {
        await mutation({ handoverId: Number(handover.id), ...args });
        logAddedReviveToHandover(handover.subject?.id);
        setOpenReviveDialog(false);
        notify('revives.successCreate', { type: 'success' });
      } catch {
        notify('revives.errorCreate', { type: 'error' });
      }
    },
    [
      createRevive,
      handover.id,
      handover.revive,
      handover.subject?.id,
      notify,
      updateRevive,
    ],
  );

  const translate = useTranslate();

  return (
    <Box
      sx={{
        marginBottom: '0.25rem',
        padding: '0.25rem 0.5rem 0 0.5rem',
        border,
        borderRadius: 2,
        position: 'relative',
      }}
      onClick={onCardClick}
    >
      <ShyText record={handover} oneline source="problem" label="" />
      <ShyText
        component="pre"
        record={handover}
        oneline
        source="observations"
        label={translate('handover.observations')}
      />
      <ShyText
        component="pre"
        record={handover}
        oneline
        source="advices"
        label={translate('handover.advice')}
      />
      {handover.revive && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="caption" component="span" color="primary">
              {handover.revive.performedAt
                ? translate('revives.performedAt')
                : translate('revives.performAt')}
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: '0.75rem', marginLeft: '0.25rem' }}
              color={getReviveColor(handover.revive)}
            >
              {dayjs(
                handover.revive.performedAt || handover.revive.reviveAt,
              ).format('DD/MM/YYYY HH:mm')}
            </Typography>
            {handover.revive.attributedStaffUserId && (
              <>
                <Typography
                  variant="caption"
                  component="span"
                  color="primary"
                  sx={{ paddingLeft: 0.5, paddingRight: 0.5 }}
                >
                  {translate('revives.by')}
                </Typography>
                <LastMaySender userId={handover.revive.attributedStaffUserId} />
              </>
            )}
          </Box>
          <ShyText
            component="pre"
            record={handover.revive}
            oneline
            source="plannedText"
            label={translate('revives.plannedText')}
          />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: canEdit ? 'space-between' : 'flex-end',
          alignItems: 'center',
          marginBottom: '0.25rem',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          {canEdit && (
            <Tooltip title={translate('handover.editTooltip')} placement="top">
              <IconButton
                size="small"
                onClick={(ev) => {
                  onEditClick(handover);
                  ev.stopPropagation();
                }}
              >
                <EditIcon
                  color="primary"
                  fontSize="small"
                  sx={{ fontSize: '1rem' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {canCreateRevive && (
            <Tooltip title={translate('revives.createOne')} placement="top">
              <IconButton
                onClick={(ev) => {
                  setOpenReviveDialog(true);
                  ev.stopPropagation();
                }}
                size="small"
              >
                <AccessAlarmIcon
                  color="primary"
                  fontSize="small"
                  sx={{ fontSize: '1rem' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {canEdit &&
            !canCreateRevive &&
            Boolean(handover.revive?.id && !handover.revive.performedAt) && (
              <Tooltip title={translate('revives.close')} placement="top">
                <div>
                  <DialogButton
                    onClick={() =>
                      closeRevive(handover.revive?.id, {
                        onSuccess: (_, reviveId) => {
                          logClosedRevive(reviveId);
                        },
                      })
                    }
                    size="small"
                    title={translate('revives.close')}
                    text={translate('revives.closeQuestion')}
                    component={IconButton}
                  >
                    <AlarmOffIcon
                      color="primary"
                      fontSize="small"
                      sx={{ fontSize: '1rem' }}
                    />
                  </DialogButton>
                </div>
              </Tooltip>
            )}
          {canEdit &&
            !canCreateRevive &&
            Boolean(handover.revive?.id && !handover.revive.performedAt) && (
              <Tooltip title={translate('revives.edit')} placement="top">
                <IconButton
                  onClick={(ev) => {
                    setOpenReviveDialog(true);
                    ev.stopPropagation();
                  }}
                  size="small"
                >
                  <EditNotificationsIcon
                    color="primary"
                    fontSize="small"
                    sx={{ fontSize: '1rem' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          {canEdit &&
            Boolean(handover?.revive?.plannedText) &&
            !handover.revive.performedAt && (
              <Tooltip title={translate('revives.sendText')} placement="top">
                <div>
                  <DialogButton
                    onClick={() =>
                      postAndCloseRevive(
                        {
                          content: handover.revive.plannedText,
                          type: DiscussionEventType.TEXT,
                          discussionId: handover.revive.discussionId,
                        },
                        handover?.revive,
                      )
                    }
                    size="small"
                    title={translate('revives.title')}
                    text={translate('revives.sendRevive')}
                    component={IconButton}
                  >
                    <ScheduleSendIcon
                      color="primary"
                      fontSize="small"
                      sx={{ fontSize: '1rem' }}
                    />
                  </DialogButton>
                </div>
              </Tooltip>
            )}

          <ReviveDialog
            open={openRevive}
            handoverRevive={handover.revive}
            onClose={() => setOpenReviveDialog(false)}
            onSubmit={handleSubmitRevive}
          />
        </Box>
        <StaffUserSignature
          staffUser={data?.[0]}
          createdAt={handover.createdAt}
        />
      </Box>
    </Box>
  );
};

const ReportCard = ({
  id,
  report,
  staffUser,
  start,
}: {
  id: number;
  report: string;
  staffUser: User;
  start: Date;
}) => {
  const t = useTranslate();
  return (
    <Box
      sx={{
        marginBottom: '0.25rem',
        padding: '0.25rem 0.5rem',
        border,
        borderRadius: 2,
        position: 'relative',
      }}
    >
      <Typography variant="caption" component="span" color="primary">
        {t('consultations.report')}
      </Typography>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          fontSize: '0.875rem',
        }}
      >
        {report}
      </Typography>
      <CustomButton
        to={`/consultations/${id}`}
        variant="text"
        size="small"
        sx={{ padding: 0 }}
      >
        {t('common.seeMore')}
      </CustomButton>
      <StaffUserSignature staffUser={staffUser} createdAt={start} />
    </Box>
  );
};

export const SubjectContent = ({
  subject,
  canEdit,
  onEditClick,
  referenceDate,
  categoryList,
}: {
  subject: HistoryItem;
  canEdit: boolean;
  onEditClick: (handover: Handover) => void;
  referenceDate?: dayjs.Dayjs;
  categoryList: Category[];
}) => {
  const handovers =
    subject.type !== 'Consultation' &&
    subject.handovers
      ?.filter((handover) => handover.active)
      .sort((a, b) => {
        const aTime = new Date(a.createdAt).getTime();
        const bTime = new Date(b.createdAt).getTime();
        return bTime - aTime;
      });

  let expanded = Boolean(canEdit);
  if (
    !canEdit &&
    (subject.type === 'Consultation' ? subject.report : handovers?.length)
  ) {
    // The latest handovers should NOT be collapsable (business rule)
    expanded = undefined;
  }

  const discussion = useDiscussionDetail();
  const discussionContext = useDiscussionContext();
  const subjectDate =
    (subject.type !== 'Consultation' &&
      (subject.firstMessageAt ?? subject.end)) ||
    subject.start;

  const date = useMemo(
    () => getHandoverDate(subject, discussionContext, referenceDate),
    [discussionContext, referenceDate, subject],
  );

  const earliestRevive = useMemo(() => {
    if (!handovers) {
      return undefined;
    }
    return maxBy(
      handovers.flatMap((handover) => handover.revive ?? []),
      (revive) => new Date(revive.reviveAt).getTime(),
    );
  }, [handovers]);

  const reviveColor = getReviveColor(earliestRevive);
  const category =
    //@ts-ignore
    subject.category ?? categoryList.find((c) => c.id === subject.categoryId);

  const focusOnSubject = useTargetSubject(discussion.id);

  const focusSubjectOnClick = useCallback(
    (_subject: HistoryItem) => {
      // midwife subjects do not have a type
      // For nurses, only "questions" can be scrolled up to
      return !_subject.type || _subject.type !== 'Consultation'
        ? () => focusOnSubject(_subject as MinimalSubject)
        : undefined;
    },
    [focusOnSubject],
  );

  return (
    <Box
      sx={{
        marginLeft: '0.125rem',
        paddingLeft: '0.125rem',
        borderLeft: `1px solid ${COLORS.GREEN['300']}`,
        display: 'flex',
        flexDirection: 'row',
        '&::before': {
          left: '-0.575rem',
          top: '0.375rem',
          position: 'relative',
          content: '""',
          display: 'inline-block',
          width: '0.875rem',
          height: '0.875rem',
          borderRadius: '50%',
          backgroundColor: COLORS.GREEN['300'],
        },
      }}
    >
      <Accordion
        disableGutters
        defaultExpanded
        expanded={expanded}
        sx={{
          flex: 1,
          border: `1px solid transparent`,
        }}
        elevation={0}
      >
        <AccordionSummaryWrapper
          expandIcon={
            Boolean(
              subject.type === 'Consultation'
                ? subject?.report
                : handovers?.length,
            ) && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.875rem' }} />
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onClick={focusSubjectOnClick(subject)}
            >
              {handovers &&
                handovers?.some((h) => h.revive && !h.revive.performedAt) && (
                  <>
                    <AccessAlarmIcon
                      sx={{
                        fontSize: '0.625rem',
                        marginRight: '0.25rem',
                        color: reviveColor,
                      }}
                    />
                  </>
                )}
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 500,
                }}
              >
                {getTimeAgo(subjectDate)}
              </Typography>
              {Boolean(date) && (
                <Typography
                  sx={{
                    fontSize: '0.625rem',
                    color: reviveColor,
                  }}
                >
                  &nbsp;{`${date}`}
                </Typography>
              )}
              {subject.type === 'Consultation' && (
                <Videocam
                  fontSize="small"
                  sx={{ color: COLORS.GREY_TEXT_LIGHT }}
                />
              )}
            </Box>
            {Boolean(category && !canEdit) && (
              <CategoryChip
                size="small"
                category={category}
                sx={{ fontSize: '0.75rem' }}
              />
            )}
            {Boolean(canEdit) && (
              <CategoryChipSelect
                subject={discussion.lastSubject}
                sx={{ fontSize: '0.75rem' }}
              />
            )}
          </Box>
        </AccordionSummaryWrapper>
        {Boolean(handovers.length) && (
          <AccordionDetails sx={{ margin: '0.125rem 0 0 0', padding: 0 }}>
            {handovers.map((handover) => (
              <HandoverCard
                key={handover.id}
                handover={handover}
                canEdit={canEdit}
                onEditClick={onEditClick}
                onCardClick={focusSubjectOnClick(subject)}
              />
            ))}
          </AccordionDetails>
        )}
        {subject.type === 'Consultation' && Boolean(subject?.report) && (
          <AccordionDetails sx={{ margin: '0.125rem 0 0 0', padding: 0 }}>
            <ReportCard
              id={subject.id}
              report={subject.report}
              staffUser={subject.staffUser}
              start={subject.start}
            />
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export const HandoverList = ({ sx = {} }: { sx?: SxProps }) => {
  const t = useTranslate();
  const contentRef = useRef<HTMLElement>(null);
  const discussion = useDiscussionDetail();
  const { history, fetchNextPage, hasNextPage, revives } =
    usePatientHistory(discussion);
  const [editedHandover, setEditedHandover] = useState<Handover | null>(null);
  const [create, setCreate] = useState<boolean>(false);

  const discussionContext = discussion?.kidId
    ? DiscussionContext.NURSE
    : DiscussionContext.MIDWIFE;

  const onClose = useCallback(() => {
    setEditedHandover(null);
    setCreate(false);
  }, []);

  const patientDetail = usePatientDetail(discussion);

  const referenceDate = useMemo(() => {
    if (patientDetail.type === 'adult') {
      if (!patientDetail.data?.pregnancy?.expectedEnd) {
        return;
      }
      const endDate = dayjs(patientDetail.data?.pregnancy?.expectedEnd);
      return endDate.subtract(41, 'weeks');
    }

    return dayjs(patientDetail.data?.birthDay);
  }, [patientDetail.data, patientDetail.type]);

  const { data: categoryList } = useGetList<Category>(
    'categories',
    {
      pagination: { page: 1, perPage: 1000 },
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );

  // fetch all staff users at once
  const handoverStaffUsers = useMemo(
    () =>
      history?.flatMap((subject) =>
        subject.type !== 'Consultation'
          ? subject?.handovers
              ?.map((h) => h.userId)
              .concat(
                subject?.handovers?.map(
                  (h) => h?.revive?.attributedStaffUserId,
                ),
              )
              .filter(Boolean) ?? []
          : [],
      ),
    [history],
  );
  useGetVaryingMany('users', handoverStaffUsers);
  const activeTab = useActiveTab();

  const defaultReviveExpand =
    activeTab === ATTRIBUTION_OPTIONS_ENUM.REVIVE ||
    activeTab === CLASSICAL_OPTIONS_ENUM.REVIVE;
  const [expandRevive, setExpandRevive] = useState(defaultReviveExpand);

  return (
    <DiscussionContextProvider discussionContext={discussionContext}>
      <Box
        ref={contentRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: `0 -3px 5px -4px ${COLORS.GREY_LAYOUT}, 0 -5px 5px -5px ${COLORS.GREY_LAYOUT}`,
          ...sx,
        }}
      >
        <Box
          sx={{
            padding: '0.5rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            boxShadow: `0 3px 5px -4px ${COLORS.GREY_LAYOUT}, 0 -8px 8px -8px ${COLORS.GREY_LAYOUT}`,
            zIndex: '2',
          }}
        >
          <Typography
            sx={{ flex: 0, fontSize: '1rem', fontWeight: 500 }}
            variant="body1"
          >
            {t('history.title')}
          </Typography>
          <IconButton
            size="small"
            onClick={() => setCreate(true)}
            disabled={!discussion?.lastSubject?.id}
            sx={{
              fontSize: '0.75rem',
              marginLeft: '0.5rem',
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <List
          className="scrollbar"
          sx={{
            flex: 1,
            overflowY: 'auto',
            padding: '0 0.25rem 0 0.5rem',
          }}
        >
          {Boolean(revives?.length) && (
            <Accordion
              disableGutters
              sx={{
                flex: 1,
                border: `1px solid transparent`,
              }}
              elevation={0}
              defaultExpanded={defaultReviveExpand}
              expanded={expandRevive}
              onChange={(_, expanded) => setExpandRevive(expanded)}
            >
              <AccordionSummaryWrapper
                expandIcon={
                  <ArrowForwardIosSharpIcon sx={{ fontSize: '0.875rem' }} />
                }
              >
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                >
                  {t('history.revives', { smart_count: revives?.length })}
                </Typography>
              </AccordionSummaryWrapper>
              <AccordionDetails sx={{ padding: 0 }}>
                {revives?.map((handoverWithRevive) => (
                  <HandoverCard
                    key={'revive-' + handoverWithRevive.id}
                    handover={handoverWithRevive}
                    canEdit={true}
                    onEditClick={setEditedHandover}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
          {history?.map((subject) => (
            <SubjectContent
              key={`${subject.id}-${subject.type}`}
              subject={subject}
              canEdit={subject.id === discussion?.lastSubject?.id}
              onEditClick={setEditedHandover}
              referenceDate={referenceDate}
              categoryList={categoryList}
            />
          ))}
          {hasNextPage && (
            <Button size="small" onClick={() => fetchNextPage()}>
              {t('common.loadMore')}
            </Button>
          )}
        </List>
        <Slide
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 3,
          }}
          direction="left"
          in={Boolean(editedHandover || create)}
          mountOnEnter
          unmountOnExit
          container={contentRef.current}
        >
          <Paper sx={{ height: '100%', width: '100%' }} elevation={4}>
            {create && (
              <HandoverCreateForm
                subjectId={discussion.lastSubject?.id}
                onClose={onClose}
                activeRevives={revives?.length ?? 0}
              />
            )}
            {editedHandover && (
              <HandoverEditForm handover={editedHandover} onClose={onClose} />
            )}
          </Paper>
        </Slide>
      </Box>
    </DiscussionContextProvider>
  );
};
