import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Identifier, useTranslate } from 'react-admin';
import { Calendar as RBCalendar } from 'react-big-calendar';
import { views as Views } from 'react-big-calendar/lib/utils/constants';

// Those css files are inspired from the sass files of react-big-calendar
import './css/styles.css';
import './css/agenda.css';
import './css/event.css';
import './css/month.css';
import './css/time-grid.css';
// import 'react-big-calendar/lib/css/react-big-calendar.css';

import { PlanningJob } from '@boTypes/planning';
import { EntitySlotType } from '@boTypes/slot';
import { StaffUser } from '@boTypes/staffUser';

import { useCalendarConfig } from './configHook';
import { DisplaySelector } from './DisplaySelector';
import { CalendarDrawer } from './drawer';
import { CalendarPopover } from './popover';
import { useSlotActions } from './slotHook';
import { TimezoneSelector } from './TimezoneSelector';
import { useSelector } from '../../store';
import { dayjsTz } from '../../utils/date';
import { Drawer } from '../generic/Drawer';

const style = {
  height: '100%',
  minHeight: '100%',
};

export type CalendarEvent = {
  id: Identifier;
  staffUserId?: number;
  job?: string;
  start: Date;
  end: Date;
  isPriority?: boolean;
  onCall?: boolean;
  onCallActivated?: boolean;
  isTriage?: boolean;
  title: string;
  color?: string;
  entityType?: EntitySlotType;
  planningId?: string;
  _id?: number;
  otherAvailable?: string[];
};

export interface Slot {
  start: Date;
  end: Date;
  id?: number;
  staffUserId?: number;
}

const Calendar = ({
  events,
  view = Views.WEEK,
  staffUsers,
  options: { timeslots = 1, step = 60, styles } = { timeslots: 1, step: 60 },
  drawerCallback,
  displayActions,
  selectable = true,
  displayExtraActions = false,
  callbackExtraActions,
  displayOtherAvailable = false,
  displaySelector = false,
  defaultDate,
  participantIds,
  isAdmin = false,
  job,
  dayLayoutAlgorithm = 'overlap',
  ...calendarProps
}: {
  events: CalendarEvent[];
  view?: Views[keyof Views];
  staffUsers: Record<StaffUser['id'], StaffUser>;
  options?: {
    timeslots?: number;
    step?: number;
    styles?: Record<string, any>;
  };
  drawerCallback?: {
    create: (slot: CalendarEvent) => Promise<any>;
    update: (slot: CalendarEvent) => Promise<any>;
    remove: (slot: CalendarEvent) => Promise<any>;
  };
  displayActions?: {
    update: (slot: CalendarEvent) => boolean;
    delete: (slot: CalendarEvent) => boolean;
  };
  selectable?: boolean;
  onView?: (v: Views[keyof Views]) => void;
  setCurrentRange?: (date: [string, string]) => void;
  displayExtraActions?: boolean;
  callbackExtraActions?: {
    addAttribution?: (slotId: CalendarEvent['id']) => void;
    removeAttribution: (slotId: CalendarEvent['id']) => void;
  };
  // to display availabilities in slots
  displayOtherAvailable?: boolean;
  // to display user selector in toolbar
  displaySelector?: boolean;
  defaultDate?: Date;
  isAdmin?: boolean;
  participantIds?: number[];
  job?: PlanningJob;
  dayLayoutAlgorithm?: 'overlap' | 'no-overlap';
}) => {
  const tz = useSelector((state) => state.timezone);
  const [_view, onView] = useState(view);
  const [timezoneDrawer, setTimezoneDrawer] = useState(false);
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const translate = useTranslate();
  const closeTimeZoneDrawer = useCallback(() => {
    setTimezoneDrawer(false);
  }, [setTimezoneDrawer]);
  const [displayUnattributed, setDisplayUnattributed] = useState(true);

  const staffUserList = useMemo(
    () =>
      sortBy(Object.values(staffUsers), (s: StaffUser) =>
        s?.fullName?.toLowerCase(),
      ),
    [staffUsers],
  );

  const {
    anchorEl,
    drawerRecord,
    handleDoubleClickEvent,
    handleSelectSlot,
    onSelectEvent,
    onSlotCallback,
    selectedEvent,
    setAnchorEl,
    setDrawerRecord,
    setSelectedEvent,
    setShowDrawer,
    setShowPopover,
    showDrawer,
    showPopover,
  } = useSlotActions(drawerCallback, displayActions, isAdmin, job);

  const onClosePopover = useCallback(() => {
    setShowPopover(false);
    setSelectedEvent(null);
    setAnchorEl(null);
  }, [setAnchorEl, setSelectedEvent, setShowPopover]);

  const [displayedUsers, _setDisplayedUsers] = useState<
    Record<StaffUser['id'], boolean>
  >({});

  const setDisplayedUsers = useCallback(
    (userId: StaffUser['id'], value: boolean, ifNotSet = false) => {
      _setDisplayedUsers((prev) =>
        ifNotSet && userId in prev
          ? prev
          : {
              ...prev,
              [userId]: value,
            },
      );
    },
    [],
  );

  useEffect(() => {
    Object.keys(staffUsers).forEach((id) => {
      participantIds?.includes(Number(id)) &&
        setDisplayedUsers(Number(id), true, true);
    });
  }, [staffUsers, setDisplayedUsers, participantIds]);

  const {
    components,
    now,
    views,
    formats,
    eventPropGetter,
    messages,
    localizer,
  } = useCalendarConfig(
    _view,
    displaySelector,
    staffUsers,
    displayedUsers,
    setDisplayDrawer,
    setTimezoneDrawer,
    true,
  );

  const onRangeChange = useCallback(
    (range: { start: Date; end: Date } | Date[]) => {
      if (calendarProps?.setCurrentRange) {
        if (Array.isArray(range)) {
          if (range.length === 1) {
            const day = range[0];
            const startOfDay = dayjsTz(day, tz)
              .startOf('day')
              .toDate()
              .toISOString();

            const endOfDay = dayjsTz(day, tz)
              .endOf('day')
              .toDate()
              .toISOString();
            calendarProps.setCurrentRange([startOfDay, endOfDay]);
          } else {
            calendarProps.setCurrentRange([
              dayjsTz(range[0], tz).startOf('day').toDate().toISOString(),
              dayjsTz(range[6], tz).endOf('day').toDate().toISOString(),
            ]);
          }
        } else {
          calendarProps.setCurrentRange([
            dayjsTz(range.start, tz).startOf('day').toDate().toISOString(),
            dayjsTz(range.end, tz).endOf('day').toDate().toISOString(),
          ]);
        }
      }
    },
    [calendarProps, tz],
  );

  const onViewCallback = useCallback(
    (v: Views[typeof Views]) => {
      onView(v);
      calendarProps?.onView?.(v);
    },
    [calendarProps, onView],
  );

  const filteredEvents = useMemo(
    () =>
      events.filter(
        (e) =>
          (displayUnattributed && !e.staffUserId) ||
          displayedUsers[e.staffUserId],
      ),
    [events, displayedUsers, displayUnattributed],
  );

  return (
    <>
      <RBCalendar
        views={views}
        view={_view}
        components={components}
        formats={formats}
        localizer={localizer}
        events={filteredEvents}
        defaultDate={defaultDate || now.toDate()}
        min={
          tz === 'Europe/Paris'
            ? now
                .tz('Europe/Paris')
                .hour(8)
                .minute(0)
                .second(0)
                .millisecond(0)
                .toDate()
            : now.tz(tz).startOf('day').toDate()
        }
        max={
          tz === 'Europe/Paris'
            ? now
                .tz('Europe/Paris')
                .hour(23)
                .minute(0)
                .second(0)
                .millisecond(0)
                .toDate()
            : now.tz(tz).endOf('day').toDate()
        }
        startAccessor="start"
        endAccessor="end"
        style={_view === Views.WEEK ? styles || style : style}
        culture="fr"
        allDayAccessor={true}
        eventPropGetter={_view !== Views.AGENDA ? eventPropGetter : undefined}
        step={step}
        timeslots={timeslots}
        messages={messages}
        showMultiDayTimes={true}
        onSelectEvent={onSelectEvent}
        onSelectSlot={handleSelectSlot}
        onDoubleClickEvent={handleDoubleClickEvent}
        selectable={selectable}
        onView={onViewCallback}
        onRangeChange={onRangeChange}
        dayLayoutAlgorithm={dayLayoutAlgorithm}
      />
      <CalendarPopover
        staffUsers={staffUsers}
        onClosePopover={onClosePopover}
        anchorEl={anchorEl}
        showPopover={showPopover}
        selectedEvent={selectedEvent}
        tz={tz}
        callbackActions={{
          update: (s) => {
            // @ts-ignore
            setDrawerRecord(s);
            setShowDrawer(true);
          },
          remove: drawerCallback?.remove,
        }}
        displayActions={displayActions}
        displayExtraActions={displayExtraActions}
        callbackExtraActions={callbackExtraActions}
        displayOtherAvailable={displayOtherAvailable}
        isAdmin={isAdmin}
      />
      <CalendarDrawer
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        record={drawerRecord}
        // @ts-ignore
        callback={onSlotCallback}
        // @ts-ignore
        removeRecord={drawerCallback?.remove}
        staffUsers={staffUserList}
        isLoadingStaffUsers={false}
        tz={tz}
        isAdmin={isAdmin}
      />
      <Drawer
        anchor={'right'}
        open={timezoneDrawer}
        onClose={closeTimeZoneDrawer}
        SlideProps={{ unmountOnExit: true }}
        title={translate('timezoneSelector.title')}
      >
        <TimezoneSelector />
      </Drawer>
      {displayDrawer && (
        <Drawer
          anchor={'right'}
          SlideProps={{ unmountOnExit: true }}
          open={displayDrawer}
          onClose={() => setDisplayDrawer(false)}
          title={translate('planning.displayedUsers')}
        >
          <DisplaySelector
            staffUsers={staffUsers}
            displayedUsers={displayedUsers}
            setDisplayedUsers={setDisplayedUsers}
            displayUnattributed={displayUnattributed}
            setDisplayUnattributed={setDisplayUnattributed}
          />
        </Drawer>
      )}
    </>
  );
};

export default React.memo(Calendar);
