import dayjs from 'dayjs';

import { useMutation, useQueryClient } from '@hooks/queryWrappers';

export const useValidateInvoices = () => {
  const query = useQueryClient();
  return useMutation<any, any, { invoiceIds: string[] }>(
    ['validateInvoices'],
    { method: 'put', url: '/api/users-invoices/validate' },
    {
      onSuccess() {
        query.invalidateQueries('invoices-admin-list');
      },
    },
  );
};

export const useGetInvoicesSepa = () => {
  return useMutation<any, any, { invoiceIds: string[] }>(
    ['sepaInvoices'],
    { method: 'post', url: '/api/billing/invoices/fromIds' },
    {
      onSuccess(res) {
        // Download file
        const blob = new Blob([res], { type: 'application/xml' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = `sepa-${dayjs().format('YYYY-MM-DD')}.xml`;
        link.click();
      },
    },
  );
};
