import { Create, CreateProps } from 'react-admin';

import { CategoriesForm } from './form';

export const CategoriesCreate = (props: CreateProps) => (
  <Create
    {...props}
    resource="categories"
    title="Ajouter une thématique"
    redirect="list"
  >
    <CategoriesForm />
  </Create>
);
