import { ChatDiscussionEvent } from '@boTypes/discussionEvent';
import { Box, Typography } from '@mui/material';

export const TypingMessage = ({
  message,
}: {
  message: ChatDiscussionEvent;
}) => (
  <Box sx={{ display: 'flex', px: 2, py: 1, flexDirection: 'column' }}>
    <div className="dots-jumping">
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
    <Box>
      <Typography>{message.content}</Typography>
    </Box>
  </Box>
);
