import { MouseEventHandler, ReactElement } from 'react';

import { Title } from '@components/title';
import { Drawer as MuiDrawer } from '@mui/material';
import type { DrawerProps } from '@mui/material/Drawer';

export const Drawer = ({
  title,
  onClose,
  children,
  ...drawerProps
}: {
  title: string;
  onClose: MouseEventHandler<HTMLButtonElement>;
  children: ReactElement;
} & DrawerProps) => {
  return (
    <MuiDrawer onClose={onClose} {...drawerProps}>
      <Title title={title} onClose={onClose} />
      {children}
    </MuiDrawer>
  );
};
