import { Controller, Path } from 'react-hook-form';

import {
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';

import { ExposedControllerProps } from './type';

type ToggleButtonsProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<ToggleButtonGroupProps, 'onChange' | 'value' | 'variant'> & {
    options: { label: string; value: any }[];
    label?: string;
    transformValue?: (value: string) => any;
  };

export function ToggleButtonsInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  options,
  ...other
}: ToggleButtonsProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => (
        <>
          <InputLabel>{label}</InputLabel>
          <ToggleButtonGroup
            size="small"
            disabled={formState.isSubmitting}
            exclusive
            {...field}
            onChange={(e, value) => {
              if (value === null) {
                return field.onChange(undefined);
              }
              value === 'all'
                ? field.onChange(undefined)
                : field.onChange(value);
            }}
            value={field.value ?? 'all'}
            {...other}
          >
            {options.map((option) => (
              <ToggleButton key={option.value} value={option.value}>
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      )}
    />
  );
}
