import { ComponentProps, ComponentType, useState } from 'react';
import { useTranslate } from 'react-admin';

import { Button } from '@mui/material';

import {
  ConfirmDialog,
  ConfirmDialogProps,
} from '../ConfirmDialog/ConfirmDialog';

type DialogIconButtonProps<T extends ComponentType = typeof Button> =
  ComponentProps<T> &
    Omit<ConfirmDialogProps, 'onClick'> & {
      component?: T;
    };

export const DialogButton = ({
  title,
  text,
  cancelText,
  confirmText,
  onClick,
  component = Button,
  ...props
}: DialogIconButtonProps) => {
  const translate = useTranslate();
  cancelText = cancelText || translate('common.cancel');
  confirmText = confirmText || translate('common.confirm');
  const [open, setOpen] = useState(false);

  const ButtonComponent = component;

  return (
    <>
      <ConfirmDialog
        title={title}
        text={text}
        cancelText={cancelText}
        confirmText={confirmText}
        onClose={() => setOpen(false)}
        onClick={onClick}
        open={open}
      />
      <ButtonComponent
        {...props}
        onClick={(ev) => {
          setOpen((prev) => !prev);
          ev.stopPropagation();
        }}
      />
    </>
  );
};
