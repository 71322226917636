import { useNotify, useRedirect } from 'ra-core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation } from '@hooks/queryWrappers';

import { ResetPasswordForm } from './ResetPasswordForm';
import { AuthCard } from '../components/authCard';
import { TwoFA } from '../TwoFA';

export const ResetPassword = () => {
  const [smsSent, setSmsSent] = useState<boolean>(false);
  const [pwd, setPwd] = useState<string>('');
  const notify = useNotify();
  const redirect = useRedirect();
  const { id, token } = useParams();
  const { mutate: finalizeReset } = useMutation<any, any, string>(
    ['reset_password', id, token],
    (pwdResetCode: string) => ({
      method: 'POST',
      url: `/api/auth/reset_password`,
      data: {
        pwdResetCode,
        id,
        token,
        newPassword: pwd,
      },
    }),
  );
  const handleSubmit = async (pwdResetCode: string) => {
    try {
      await finalizeReset(pwdResetCode);
      notify('auth.forms.success.password_reset');
      redirect('/login');
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  };

  const successCallback = (data) => {
    setPwd(data.new_password);
    setSmsSent(true);
  };

  return smsSent ? (
    <AuthCard hint="Veuillez entrer le code reçu par SMS">
      <TwoFA onSubmit={handleSubmit} />
    </AuthCard>
  ) : (
    <AuthCard hint="Réinitialisez votre mot de passe">
      <ResetPasswordForm successCallback={successCallback} />
    </AuthCard>
  );
};
