import { Identifier } from 'react-admin';

import { useQuery } from '@hooks/queryWrappers';

import { AppUserWeight, AppUserWeightRaw } from '../types/appUserWeights';

const APP_USER_WEIGHT_KEYS = {
  list: ['appUserWeights'] as (string | number)[],
};

export const useAppUserWeights = (id: Identifier) => {
  return useQuery<AppUserWeightRaw[], any, AppUserWeight[]>(
    APP_USER_WEIGHT_KEYS.list.concat([id]),
    () => ({
      method: 'get',
      url: `/api/user-weights/${id}`,
    }),
    {
      select: (data) =>
        data.map(({ date, createdAt, weight, id: weightId }) => ({
          id: weightId,
          value: weight,
          date: new Date(date),
          createdAt: new Date(createdAt),
        })),
    },
  );
};
