export const messages = {
  date: 'Date',
  time: 'Heure',
  event: 'Créneau',
  allDay: 'Toute la journée',
  week: 'Hebdo',
  day: 'Quotidien',
  month: 'Mensuel',
  previous: '<',
  next: '>',
  yesterday: 'Hier',
  tomorrow: 'Demain',
  today: "Aujourd'hui",
  agenda: 'Liste',

  noEventsInRange: 'Aucun créneau',

  showMore: (total: number) => `+${total} autre${total > 1 ? 's' : ''}`,
};
