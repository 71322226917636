import get from 'lodash/get';
import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';

import { Typography, TypographyProps } from '@mui/material';

import { getTimeAgo } from '../../utils/date';

interface DateTimeAgoProps extends TypographyProps {
  date: Date | string;
}

export const DateTimeAgo = ({ date, ...props }: DateTimeAgoProps) => {
  return (
    <Typography variant="body2" {...sanitizeFieldRestProps(props)}>
      {getTimeAgo(date) ?? ''}
    </Typography>
  );
};

export const DateTimeAgoField = ({
  source,
  ...props
}: {
  source: string;
  label: string;
}) => {
  const record = useRecordContext<Record<string, any>>();
  return <DateTimeAgo date={get(record, source)} {...props} />;
};
