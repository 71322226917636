import { CSSProperties, useCallback, useState } from 'react';
import { View } from 'react-native';
import { useParams } from 'react-router';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { SubjectExplorerPopulated } from '@boTypes/subject';
import { Loader } from '@components/Loader';

import {
  SubjectExplorerCard,
  useNavigateForExplorer,
} from './subjectExplorerCard';

const keyExtractor = (index: number, data: SubjectExplorerPopulated[]) => {
  return data[index]?.subject?.id ?? 'loader';
};

export const SubjectListWrapper = ({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage,

  // height and width for fine UI
  height,
  width,
}: {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: SubjectExplorerPopulated[];
  loadNextPage: () => void;
  height: number;
  width: number;
}) => {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const [itemHeight, setItemHeight] = useState(80);

  const { subjectId } = useParams();
  const navigate = useNavigateForExplorer();

  const SubjectCardRender = useCallback(
    ({
      data,
      index,
      style,
    }: {
      data: SubjectExplorerPopulated[];
      index: number;
      style: CSSProperties;
    }) => {
      if (!data[index]) {
        return (
          <div key={'loader'} style={style}>
            <Loader />
          </div>
        );
      }

      const { subject, family } = data[index];
      const selected = subjectId === subject?.id.toString();

      return (
        <div key={subject.id} style={style}>
          <View
            onLayout={(event) => {
              setItemHeight(event.nativeEvent.layout.height);
            }}
          >
            <SubjectExplorerCard
              subject={subject}
              family={family}
              selected={selected}
              onCardClick={navigate}
            />
          </View>
        </div>
      );
    },
    // [setItemHeight],
    [setItemHeight, subjectId, navigate],
  );

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          itemData={items}
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          className="scrollbar"
          height={height}
          width={width}
          ref={ref}
          itemSize={itemHeight}
          itemKey={keyExtractor}
        >
          {SubjectCardRender}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};
