import { useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import { AccountTree, Add } from '@mui/icons-material';
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { useSelector } from '../../store';
import { COLORS } from '../../themes';

export const FormCard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);

  if (!roles.includes(Roles.FORM_EDITOR)) {
    return null;
  }

  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ padding: 2 }}>
        <Typography
          variant="subtitle1"
          color="primary"
          sx={{ paddingBottom: 1 }}
        >
          {translate('admin.forms.title')}
        </Typography>
        <List dense>
          <ListItem disablePadding>
            <ListItemButton disableGutters component="a" href="/#/forms">
              <ListItemIcon sx={{ minWidth: '2rem' }}>
                <AccountTree
                  fontSize="small"
                  sx={{ color: COLORS.GREY_TEXT }}
                />
              </ListItemIcon>
              <ListItemText primary={translate('admin.forms.list')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disableGutters component="a" href="/#/forms/new">
              <ListItemIcon sx={{ minWidth: '2rem' }}>
                <Add fontSize="small" sx={{ color: COLORS.GREY_TEXT }} />
              </ListItemIcon>
              <ListItemText primary={translate('admin.forms.create')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Card>
    </Grid>
  );
};
