import { CalendarEvent } from '..';
import { textColorWithBackground } from '../../../utils/color';

export const inverseWrapper = ({ children }) => {
  if (Array.isArray(children.props.children)) {
    children.props.children.reverse();
  }
  return children;
};

export const eventPropGetterByColor = (
  event: CalendarEvent,
  isSelected: boolean,
): { className?: string; style?: Object } => {
  if (!event?.color) {
    return {};
  }

  return {
    style: {
      backgroundColor: event.color,
      border: isSelected ? `2px solid white` : `1px solid white`,
      color: textColorWithBackground(event.color),
      fontSize: '0.825em',
    },
  };
};
