import { SyntheticEvent } from 'react';
import { useTranslate } from 'react-admin';

import { styled, Tab, TabProps, Tabs, TabsProps } from '@mui/material';

export const MayTabs = styled((props: TabsProps) => (
  <Tabs {...props} variant="fullWidth" />
))({
  height: '47px',
  minHeight: '0',
});

export const MayTab = styled((props: TabProps) => (
  <Tab disableRipple iconPosition="start" {...props} />
))({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    fontWeight: 600,
    '&:focus::after': {
      backgroundColor: 'transparent',
    },
  },
  '&:focus::after': {
    backgroundColor: 'transparent',
  },
  height: '48px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 400,
  padding: '0 0.5rem',
  minWidth: '0px',
  width: 'auto',
  minHeight: '1.5rem',
  backgroundColor: 'transparent',
});

export const TabSelector = function <ItemType extends string>({
  value,
  onChange,
  options,
  tabProps = {} as Record<ItemType, TabProps>,
  ...props
}: {
  value: ItemType;
  options: Record<
    ItemType,
    string | (({ selected }: { selected: boolean }) => React.ReactElement)
  >;
  onChange: (e: SyntheticEvent<Element, Event>, newVal: ItemType) => void;
  tabProps?: Record<ItemType, TabProps>;
} & TabsProps) {
  const translate = useTranslate();
  return (
    <MayTabs
      value={value}
      {...props}
      onChange={onChange}
      aria-label="subject type filter"
    >
      {Object.keys(options).map((key) => {
        let Label = options[key];
        if (typeof Label === 'string') {
          Label = translate(Label);
        } else {
          Label = <Label selected={value === key} />;
        }
        return (
          <MayTab
            label={Label}
            key={key}
            value={key}
            {...(tabProps[key] ?? {})}
          />
        );
      })}
    </MayTabs>
  );
};
