import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { CMSGuide, Guide } from '@boTypes/guide';
import { CMSMacro, Macro } from '@boTypes/macroType';
import { CMSMasterclass, Masterclass } from '@boTypes/masterclass';
import { CMSPost, Post } from '@boTypes/post';
import { useCMSQuery, useQuery } from '@hooks/queryWrappers';

import {
  logContentSuggestionMacroSuggestions,
  logContentSuggestionResults,
  logContentSuggestionSend,
} from '../../../../analytics/events';
import { useSentContent } from '../../../../entities/library/hook';
import { setMacroSuggestion } from '../../../../store/macroSuggestion';

export const useContentSuggestions = (subjectId: number, open: boolean) => {
  const { data: suggestionIds, isLoading } = useQuery<
    {
      guides: { id: number }[];
      masterclasses: { id: number }[];
      posts: { id: number }[];
      macro: { id: number }[];
    },
    any,
    {
      guides: number[];
      masterclasses: number[];
      posts: number[];
      macro: number[];
    }
  >(
    ['suggestions', subjectId],
    () => ({
      method: 'POST',
      url: '/api/content-suggestion/search',
      data: { subjectId },
    }),
    {
      enabled: !!subjectId && open,
      select: (data) => ({
        guides: data.guides?.map((guide) => guide.id),
        masterclasses: data.masterclasses?.map((masterclass) => masterclass.id),
        posts: data.posts?.map((post) => post.id),
        macro: data.macro?.map((macro) => macro.id),
      }),
    },
  );

  const { data: suggestions, isLoading: isLoadingCMS } = useCMSQuery<
    {
      posts: CMSPost[];
      guides: CMSGuide[];
      masterclasses: CMSMasterclass[];
      macro: CMSMacro[];
    },
    any,
    {
      posts: Post[];
      guides: Guide[];
      masterclasses: Masterclass[];
      macro: Macro[];
    }
  >(
    ['suggestionsCMS', suggestionIds],
    {
      method: 'POST',
      url: '/get-content',
      data: suggestionIds,
      // data: {
      //   guides: [1, 2, 3],
      //   masterclasses: [1, 2, 3],
      //   posts: [1, 2, 3],
      //   macro: [1, 2, 3],
      // },
    },
    {
      enabled: !!suggestionIds && !isLoading && open,
      // enabled: !isLoading && open,
      select: (data) => ({
        posts: data.posts.map((post) => ({
          ...post,
          coverThumbnailUrl: post.cover?.formats?.thumbnail?.path,
          authorNames: post.authors.map((author) => author.name),
        })),
        guides: data.guides,
        masterclasses: data.masterclasses,
        macro: data.macro,
      }),
      onSuccess: (data) => {
        logContentSuggestionResults(
          subjectId,
          (data.posts.length ?? 0) +
            (data.guides.length ?? 0) +
            (data.masterclasses.length ?? 0) +
            (data.macro.length ?? 0),
          data.macro.length ?? 0,
          data.posts.length ?? 0,
          data.guides.length ?? 0,
          data.masterclasses.length ?? 0,
        );
      },
    },
  );

  return { suggestions, isLoading: isLoading || isLoadingCMS };
};

export const useSuggestions = ({
  subjectId,
  open,
  discussionId,
  onContentSend,
  onCopyContent,
  onClose,
}: {
  subjectId: number;
  open?: boolean;
  discussionId: number;
  onClose: () => void;
  onContentSend: (input: {
    type: DiscussionEventType;
    content: string;
  }) => void;
  onCopyContent: (text: string) => void;
}) => {
  const dispatch = useDispatch();

  const [openSimilarQuestionDialog, setOpenSimilarQuestionDialog] =
    useState(false);

  const handleCopyContent = (
    text: string,
    macroId: number,
    macroSuggestionId: number,
  ) => {
    onCopyContent(text);
    dispatch(setMacroSuggestion({ macroId, macroSuggestionId }));
    logContentSuggestionMacroSuggestions(subjectId, macroSuggestionId, macroId);
    onClose();
  };

  const handleContentSend = (input: {
    type: DiscussionEventType;
    content: string;
    contentId: number;
    contentType: string;
  }) => {
    onContentSend(input);
    logContentSuggestionSend(subjectId, input.contentType, input.contentId);
    onClose();
  };

  const { suggestions, isLoading } = useContentSuggestions(subjectId, open);
  const sentContent = useSentContent(discussionId, open);

  const guides = useMemo<Guide[]>(() => {
    const lookupToSent = sentContent.reduce(
      (acc, { type, content: _content }) => {
        if (type === DiscussionEventType.GUIDE) {
          acc[_content] = true;
        }
        return acc;
      },
      {},
    );
    if (!suggestions?.guides?.length) {
      return [];
    }
    return suggestions?.guides?.map((guide) => ({
      ...guide,
      sent: !!lookupToSent[guide.slug],
    }));
  }, [suggestions, sentContent]);

  const posts = useMemo<Post[]>(() => {
    const lookupToSent = sentContent.reduce(
      (acc, { type, content: _content }) => {
        if (type === DiscussionEventType.POST) {
          acc[_content] = true;
        }
        return acc;
      },
      {},
    );
    if (!suggestions?.posts?.length) {
      return [];
    }
    return suggestions?.posts?.map((post) => ({
      ...post,
      sent: !!lookupToSent[post.slug],
    }));
  }, [suggestions, sentContent]);

  const masterclasses = useMemo<Masterclass[]>(() => {
    const lookupToSent = sentContent.reduce(
      (acc, { type, content: _content }) => {
        if (type === DiscussionEventType.MASTERCLASS) {
          acc[_content] = true;
        }
        return acc;
      },
      {},
    );
    if (!suggestions?.masterclasses?.length) {
      return [];
    }
    return suggestions?.masterclasses?.map((mc) => ({
      ...mc,
      sent: !!lookupToSent[mc.id],
    }));
  }, [suggestions, sentContent]);

  return {
    posts,
    masterclasses,
    guides,
    isLoading,
    macro: suggestions?.macro ?? [],
    handleContentSend,
    handleCopyContent,
    openSimilarQuestionDialog,
    setOpenSimilarQuestionDialog,
  };
};
