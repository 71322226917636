import { useNotify } from 'ra-core';
import { useEffect, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { useNavigate } from 'react-router';

import { Auth } from './Auth';
import { AuthCard } from './components/authCard';
import { useLogin } from './hooks/login';
import { TwoFA } from './TwoFA';
import { useSelector } from '../store';

interface Credentials {
  email?: string;
  password?: string;
}

interface AuthParams extends Credentials {
  two_factor_code: string;
}

export const Login = () => {
  const [smsSent, setSmsSent] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<Credentials>({});
  const notify = useNotify();
  const login = useLogin();

  const successCallback = (auth: AuthParams) => {
    setCredentials(auth);
    setSmsSent(true);
  };

  const navigate = useNavigate();
  const { email } = useSelector((state) => state.user);
  const identity = useGetIdentity();

  useEffect(() => {
    if (email && identity?.data?.email === email) {
      navigate('/');
    }
  }, [email, identity?.data?.email, navigate]);

  // NOTE: we choose this error msg because in theory, if the person received
  // one sms, it means he entered the right username/password.
  // But the user can change his email/password after having received the sms, we don't know.
  const handleLoginFA = async (
    two_factor_code: string,
    trustedDevice = false,
  ) => {
    const auth = { ...credentials, two_factor_code, trustedDevice };
    try {
      await login(auth);
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  };

  if (smsSent) {
    return (
      <AuthCard hint="Veuillez entrer le code reçu par sms">
        <TwoFA onSubmit={handleLoginFA} withTrusted={true} />
      </AuthCard>
    );
  }

  return <Auth successCallback={successCallback} />;
};
