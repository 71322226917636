import { DailyTip } from './dailyTip';
import Folder from './folder';
import { Guide } from './guide';
import { Macro } from './macroType';
import { Masterclass } from './masterclass';
import { Post } from './post';
import { JobTitle } from './user';

export enum ChangelogType {
  ALL = 'all',
  NURSES_ONLY = 'nurseOnly',
  MIDWIVES_ONLY = 'midwivesOnly',
  DOCTORS_ONLY = 'doctorsOnly',
  PRO = 'pro',
  ADMIN = 'admin',
}

export enum RECOMMENDATION_TYPE {
  ARTICLE = 'recommend-content.article-wrapper',
  MASTERCLASS = 'recommend-content.masterclass-wrapper',
  GUIDE = 'recommend-content.guide-wrapper',
  FOLDER = 'recommend-content.program-wrapper',
  MACRO = 'recommend-content.macro',
  DAILY_TIP = 'recommend-content.daily-tip',
}

export type ContentArticleItem = {
  __component: RECOMMENDATION_TYPE.ARTICLE;
  id: number;
  post: Post;
};

export type ContentMasterclassItem = {
  __component: RECOMMENDATION_TYPE.MASTERCLASS;
  id: number;
  masterclass: Masterclass;
};

export type ContentGuideItem = {
  __component: RECOMMENDATION_TYPE.GUIDE;
  id: number;
  guide: Guide;
};

export type ContentProgramItem = {
  __component: RECOMMENDATION_TYPE.FOLDER;
  id: number;
  program: Folder;
};

export type ContentDailyTipItem = {
  __component: RECOMMENDATION_TYPE.DAILY_TIP;
  id: number;
  daily_tip: DailyTip;
};

export type ContentMacroItem = {
  __component: RECOMMENDATION_TYPE.MACRO;
  id: number;
  macro: Macro;
};

export type LinkedContent =
  | ContentGuideItem
  | ContentMasterclassItem
  | ContentArticleItem
  | ContentProgramItem
  | ContentDailyTipItem
  | ContentMacroItem;

export interface Changelog {
  id: number;
  publishedAt: string;
  read: boolean;
  type: ChangelogType;
  content: string;
  title: string;
  linkedContent: LinkedContent[];
  author: string;
}

export interface ChangelogTracker {
  changelog: Changelog;
  boUser: number;
}

export const jobTitleToChangelogAccess = {
  [JobTitle.ADMIN]: [
    ChangelogType.ALL,
    ChangelogType.ADMIN,
    ChangelogType.PRO,
    ChangelogType.NURSES_ONLY,
    ChangelogType.MIDWIVES_ONLY,
    ChangelogType.DOCTORS_ONLY,
  ],
  [JobTitle.PSYCHOLOGIST]: [ChangelogType.ALL, ChangelogType.PRO],
  [JobTitle.PEDIATRICIAN]: [
    ChangelogType.ALL,
    ChangelogType.PRO,
    ChangelogType.DOCTORS_ONLY,
  ],
  [JobTitle.CHILD_PSYCHIATRIST]: [ChangelogType.ALL, ChangelogType.PRO],
  [JobTitle.NUTRITIONIST_DOCTOR]: [
    ChangelogType.ALL,
    ChangelogType.PRO,
    ChangelogType.DOCTORS_ONLY,
  ],
  [JobTitle.MIDWIFE]: [
    ChangelogType.ALL,
    ChangelogType.PRO,
    ChangelogType.MIDWIVES_ONLY,
  ],
  [JobTitle.NURSERY_NURSE]: [
    ChangelogType.NURSES_ONLY,
    ChangelogType.ALL,
    ChangelogType.PRO,
  ],
  [JobTitle.GENERAL_PRACTITIONER]: [
    ChangelogType.ALL,
    ChangelogType.DOCTORS_ONLY,
  ],
};
