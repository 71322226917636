import { useState, useCallback } from 'react';
import { ImageField, SimpleShowLayout, useTranslate } from 'react-admin';

import { AccessTime, Phone } from '@mui/icons-material';
import { Paper, Stack, Typography } from '@mui/material';

import { RelatedDiscussionURLField } from '../../../components/fields';
import { TokboxHandler } from '../../../components/tokbox/tokboxHandler';
import { COLORS } from '../../../themes';
import { ConsultationWithRelationIds, Discussion } from '../../../types';

export const ShowConsultation = ({
  relatedDiscussion,
  consultation,
}: {
  relatedDiscussion: Discussion;
  consultation: ConsultationWithRelationIds;
}) => {
  const [openVisio, setOpenVisio] = useState(false);
  const translate = useTranslate();

  const startVisio = useCallback(() => {
    setOpenVisio(true);
  }, [setOpenVisio]);

  return (
    <Paper sx={{ padding: '1rem' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={1}
        >
          <AccessTime sx={{ color: COLORS.GREY_TEXT }} />
          <Typography variant="subtitle2">
            {translate('common.date')}:{' '}
          </Typography>
          <Typography>
            {consultation?.start && consultation?.end
              ? `${new Date(consultation?.start).toLocaleDateString('fr-FR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })} - ${new Date(consultation?.end).toLocaleTimeString(
                  'fr-FR',
                  {
                    // timeStyle: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                  },
                )}`
              : 'Pas de date'}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={1}
        >
          <Phone sx={{ color: COLORS.GREY_TEXT }} />
          <Typography variant="subtitle2">
            {translate('common.phone')}:{' '}
          </Typography>
          <Typography>{consultation?.appUser?.phone}</Typography>
        </Stack>
        <RelatedDiscussionURLField
          discussion={relatedDiscussion}
          label={translate('common.goToDiscussion')}
          midwife={false}
        />
      </Stack>
      <Typography variant="subtitle2" sx={{ marginTop: '1rem' }}>
        Motif
      </Typography>
      <Typography>{consultation?.reason}</Typography>
      {consultation && (
        <TokboxHandler
          startVisio={startVisio}
          setOpenVisio={setOpenVisio}
          openVisio={openVisio}
          record={consultation}
        />
      )}
      <SimpleShowLayout record={consultation}>
        {consultation?.files?.map((url, index) => (
          // We use an image field in a wacky way. We could use a <img> instead
          // but because of laziness and lack of time, I'm using react-admin wrap elements to
          // ensure rendering is somewhat consistent.
          <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
            <ImageField
              source="url"
              label={`photo_${index}`}
              record={{ url, id: 'hello ts' }}
            />
          </a>
        ))}
      </SimpleShowLayout>
    </Paper>
  );
};
