import React from 'react';

import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CoffeeMachine } from './coffeeMachine';
import LockAvatar from './LockAvatar';
import { COLORS } from '../../themes';

const Image = styled('img')({
  width: '250px',
  padding: '0 1rem 1rem 1rem',
  zIndex: 1000,
});

const Root = styled('main')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
  minHeight: '100vh',
  background: `linear-gradient(270deg, #f2a5a3, #F6E3AC)`,
  backgroundSize: '400% 400%',
  animation: 'backgroundAnimation 20s ease infinite',
});

const AuthUIWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledCard = styled(Card)({
  minWidth: 300,
  borderRadius: '1rem',
  backdropFilter: 'blur(21px) saturate(180%)',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  border: '1px solid rgba(209, 213, 219, 0.3)',
});

const Hint = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  color: COLORS.GREEN['800'],
});

export const AuthCard = ({
  children,
  hint,
}: {
  children?: React.ReactNode;
  hint: string;
}) => {
  const hasLargeScreen = useMediaQuery('(min-width:900px)');

  return (
    <Root>
      <AuthUIWrapper>
        <Image src={'/may_logo_green.svg'} alt="MAY logo" />
        <StyledCard>
          <LockAvatar />
          <Hint>{hint}</Hint>
          {children}
        </StyledCard>
      </AuthUIWrapper>
      {hasLargeScreen && <CoffeeMachine />}
    </Root>
  );
};
