import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';

import InsightsIcon from '@mui/icons-material/Insights';

import { ScoringRuleNodeType } from '../../types';
import { isScoringRulesValid } from '../../utils/validityCheck';
import { MenuWrapper } from '../MenuWrapper';
import { ScoringRulesEdit } from '../scoringRules/ScoringRulesEdit';
import { WarningTile } from '../WarningTile';

export function ScoringRuleNode({
  id,
  data: { question },
}: NodeProps<ScoringRuleNodeType['data']>) {
  const { setNodes, setEdges } = useReactFlow();

  const invalidFields = isScoringRulesValid(question);

  return (
    <>
      <Handle type="target" position={Position.Right} />
      <MenuWrapper
        content={question}
        onChange={(result) => {
          Object.assign(question, result);
        }}
        onDelete={() => {
          question.scoringRules = [];
          setNodes((nodes) => nodes.filter((node) => node.id !== id));
          setEdges((edges) => edges.filter((edge) => edge.target !== id));
        }}
        FormComponent={ScoringRulesEdit}
      >
        <WarningTile invalidFields={invalidFields} />
        <InsightsIcon />
      </MenuWrapper>
    </>
  );
}
