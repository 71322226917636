import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { DiscussionUserMapping } from '../types';

const discussionUsersSlice = createSlice({
  name: 'discussionUsers',
  initialState: {} as DiscussionUserMapping,
  reducers: {
    setUserDiscussion(state, action: PayloadAction<DiscussionUserMapping>) {
      return { ...state, ...action.payload };
    },
    addUserDiscussion(
      state,
      action: PayloadAction<{ discussionId: string; userId: string }>,
    ) {
      !state[action.payload.discussionId].includes(action.payload.userId) &&
        state[action.payload.discussionId].push(action.payload.userId);
      return state;
    },
    removeUserDiscussion(
      state,
      action: PayloadAction<{ discussionId: string; userId: string }>,
    ) {
      if (state[action.payload.discussionId].includes(action.payload.userId)) {
        state[action.payload.discussionId] = state[
          action.payload.discussionId
        ].filter((el) => el !== action.payload.userId);
      }

      return state;
    },
  },
});

export const { setUserDiscussion, addUserDiscussion, removeUserDiscussion } =
  discussionUsersSlice.actions;

export default discussionUsersSlice.reducer;
