import { useTranslate } from 'react-admin';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { Typography } from '@mui/material';

import { WeeklyTipItem, HEIGHT, WIDTH } from './WeeklyTipItem';
import { WeeklyTip } from '../../types';
import { FullWidthList, useWrappedItemWithSend } from '../fullWidthList';

export const WeeklyTipList = ({
  list = [],
  onSend,
}: {
  list: WeeklyTip[];
  onSend?: (
    { type, content }: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
  ) => void;
}) => {
  const translate = useTranslate();

  const Item = useWrappedItemWithSend(WeeklyTipItem, list, onSend);

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle2" color="primary">
        {translate('library.weeklyTips')}
      </Typography>
      <FullWidthList
        itemCount={list.length}
        itemSize={WIDTH + 16}
        height={HEIGHT + 20 + (onSend ? 18 : 0)}
        layout="horizontal"
      >
        {Item}
      </FullWidthList>
    </>
  );
};
