import { Create } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { PricingProGenericForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const PricingProGenericCreate = () => {
  const location = useLocation() as RedirectLocation;
  return (
    <Create
      resource="pricing-pro-generic"
      title="Ajouter un pricing"
      redirect={location?.state?.redirect || '/pricing-pro'}
    >
      <PricingProGenericForm />
    </Create>
  );
};
