import { CSSProperties, useCallback, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { DailyTip } from '@boTypes/dailyTip';
import { DiscussionEventType } from '@boTypes/discussionEvent';
import Folder from '@boTypes/folder';
import { Guide } from '@boTypes/guide';
import ItemSuggestion from '@boTypes/ItemSuggestion';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import { WeeklyTip } from '@boTypes/weeklyTip';
import { DailyTipItemDialog } from '@components/DailyTip/DailyTipItem';
import { FolderItemDialog } from '@components/Folder/FolderItem';
import { GuideCarousel } from '@components/Guide/GuideItem';
import { ItemSuggestionDialog } from '@components/ItemSuggestion/ItemSuggestionDialog';
import { Loader } from '@components/Loader';
import { MacrosItemDialog } from '@components/Macro/MacroItem';
import { MasterclassItemDialog } from '@components/Masterclass/MasterclassItem';
import { PostItemDialog } from '@components/Post/PostItem';
import { PregnancyQuestionDialog } from '@components/PregnancyQuestion/PregnancyQuestionDialog';
import { WeeklyTipItemDialog } from '@components/WeeklyTip/WeeklyTipItem';
import { Send } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { stringToPastelColor } from '@utils/color';

import { logContentView } from '../../analytics/events';
import { COLORS } from '../../themes';

const ItemSize = 36;

type CMSContent<T> = T extends ContentType.GUIDES
  ? Guide
  : T extends ContentType.POSTS
    ? Post
    : T extends ContentType.MASTERCLASSES
      ? Masterclass
      : T extends ContentType.MACRO
        ? Macro
        : T extends ContentType.FOLDER
          ? Folder
          : T extends ContentType.DAILY_TIPS
            ? DailyTip
            : T extends ContentType.PREGNANCY_QUESTIONS
              ? PregnancyQuestion
              : T extends ContentType.ITEM_SUGGESTIONS
                ? ItemSuggestion
                : T extends ContentType.WEEKLY_TIPS
                  ? WeeklyTip
                  : never;

export enum ContentType {
  GUIDES = 'guides',
  POSTS = 'posts',
  MACRO = 'macro',
  MASTERCLASSES = 'masterclasses',
  FOLDER = 'folder',
  DAILY_TIPS = 'dailyTips',
  PREGNANCY_QUESTIONS = 'pregnancyQuestion',
  ITEM_SUGGESTIONS = 'itemSuggestions',
  WEEKLY_TIPS = 'weeklyTips',
}

const mappingToDiscussionEventType = {
  [ContentType.GUIDES]: DiscussionEventType.GUIDE,
  [ContentType.POSTS]: DiscussionEventType.POST,
  [ContentType.MASTERCLASSES]: DiscussionEventType.MASTERCLASS,
  [ContentType.DAILY_TIPS]: DiscussionEventType.DAILY_TIP,
  [ContentType.FOLDER]: DiscussionEventType.FOLDER,
  [ContentType.WEEKLY_TIPS]: DiscussionEventType.WEEKLY_TIP,
} as Record<ContentType, DiscussionEventType>;

export function ContentDetail<T extends ContentType>({
  type,
  open,
  setOpen,
  item,
  onCopy,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: T;
  item?: CMSContent<T>;
  onCopy?: (text: string, macroId: number, macroSuggestionId: number) => void;
}) {
  const close = useCallback(() => setOpen(false), [setOpen]);
  if (!open) {
    return null;
  }
  if (type === ContentType.GUIDES) {
    return (
      <GuideCarousel
        slug={(item as Guide)?.slug}
        open={open}
        setOpen={setOpen}
      />
    );
  } else if (type === ContentType.POSTS) {
    return <PostItemDialog open={open} setOpen={setOpen} item={item as Post} />;
  } else if (type === ContentType.MASTERCLASSES) {
    return (
      <MasterclassItemDialog
        item={item as Masterclass}
        opened={open}
        close={close}
      />
    );
  } else if (type === ContentType.MACRO) {
    return (
      <MacrosItemDialog
        open={open}
        close={close}
        item={item as Macro}
        onCopy={(text, suggestionId) => onCopy(text, item.id, suggestionId)}
      />
    );
  } else if (type === ContentType.DAILY_TIPS) {
    return (
      <DailyTipItemDialog item={item as DailyTip} open={open} close={close} />
    );
  } else if (type === ContentType.FOLDER) {
    return <FolderItemDialog item={item as Folder} open={open} close={close} />;
  } else if (type === ContentType.PREGNANCY_QUESTIONS) {
    return (
      <PregnancyQuestionDialog
        item={item as PregnancyQuestion}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.ITEM_SUGGESTIONS) {
    return (
      <ItemSuggestionDialog
        item={item as ItemSuggestion}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.WEEKLY_TIPS) {
    return (
      <WeeklyTipItemDialog item={item as WeeklyTip} open={open} close={close} />
    );
  }
}

const keyExtractor = (index: number, data: { id: number }[]) =>
  data[index]?.id ?? 'loader';

export function ContentList<T extends ContentType>({
  type,
  onSend,
  onCopy,
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage,
}: {
  type: T;
  items?: CMSContent<T>[];
  onSend?: (
    input: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
  onCopy?: (text: string, macroId: number, macroSuggestionId: number) => void;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  loadNextPage: () => void;
}) {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<CMSContent<T>>();

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const ListItemRender = useCallback(
    ({
      data,
      index,
      style,
    }: {
      data: typeof items;
      index: number;
      style: CSSProperties;
    }) => {
      const itm = data[index];
      return (
        <div key={itm?.id ?? 'loader'} style={style}>
          {!itm ? (
            <ListItem
              disableGutters
              disablePadding
              sx={{ px: 1 }}
              key={'loader'}
            >
              <Loader />
            </ListItem>
          ) : (
            <ListItem
              disableGutters
              disablePadding
              key={`${type}-${itm.id}`}
              sx={{ px: 1 }}
            >
              <ListItemButton
                color="primary"
                disableGutters
                sx={{
                  '&:hover': {
                    borderRadius: 2,
                    backgroundColor: COLORS.GREEN['100'],

                    '.MuiTouchRipple-child': {
                      backgroundColor: COLORS.GREEN['800'],
                    },
                  },
                  justifyContent: 'flex-start',
                  paddingLeft: !onCopy && onSend ? '2.5rem' : '',
                  height: '2.25rem',
                }}
                onClick={() => {
                  setItem(itm);
                  logContentView(type, itm.id);
                  setOpen(true);
                }}
              >
                {!onCopy && onSend ? (
                  <ListItemSecondaryAction style={{ left: '0', right: 'auto' }}>
                    <IconButton
                      aria-label="send"
                      color="primary"
                      onClick={() =>
                        onSend(
                          {
                            type: mappingToDiscussionEventType[type],
                            content: [
                              ContentType.GUIDES,
                              ContentType.POSTS,
                            ].includes(type)
                              ? // @ts-ignore
                                itm.slug
                              : String(itm.id),
                          },
                          type,
                          itm.id,
                        )
                      }
                    >
                      <Send />
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : null}
                {/* @ts-ignore */}
                {(itm.cover ||
                  // @ts-ignore
                  itm.coverThumbnailUrl ||
                  // @ts-ignore
                  itm.expertImageThumbnailUrl ||
                  // @ts-ignore
                  itm.weeklyTipIllustrationThumbnailUrl) && (
                  <ListItemAvatar sx={{ minWidth: 32, marginLeft: 1 }}>
                    <Avatar
                      src={
                        // @ts-ignore
                        itm.cover ||
                        // @ts-ignore
                        itm.coverThumbnailUrl ||
                        // @ts-ignore
                        itm.expertImageThumbnailUrl ||
                        // @ts-ignore
                        itm.weeklyTipIllustrationThumbnailUrl
                      }
                      sx={{
                        width: 28,
                        height: 28,
                        borderColor: COLORS.GREY_TEXT_LIGHT,
                        borderWidth: 1,
                        borderStyle: 'solid',
                      }}
                    />
                  </ListItemAvatar>
                )}
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      color:
                        // @ts-ignore
                        itm?.sent !== undefined && itm?.sent
                          ? COLORS.GREY_TEXT_LIGHT
                          : 'inherit',
                    },
                  }}
                  sx={{
                    pl: 1,
                    flexGrow: 0,
                  }}
                  /* @ts-ignore */
                  primary={itm?.title || itm?.name}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '0.5rem',
                    marginLeft: '0.5rem',
                  }}
                >
                  {/* @ts-ignore */}
                  {itm.categories?.map((category: string) => (
                    <Chip
                      key={category}
                      label={category}
                      size="small"
                      sx={{ backgroundColor: stringToPastelColor(category) }}
                    />
                  ))}
                  {type === ContentType.PREGNANCY_QUESTIONS && (
                    <Chip
                      // @ts-ignore
                      key={itm.output}
                      // @ts-ignore
                      label={itm.output}
                      size="small"
                      sx={{
                        // @ts-ignore
                        backgroundColor: stringToPastelColor(itm.output),
                      }}
                    />
                  )}
                  {/* @ts-ignore */}
                  {type === ContentType.WEEKLY_TIPS && itm?.type && (
                    <Chip
                      // @ts-ignore
                      key={itm.type}
                      // @ts-ignore
                      label={itm.type}
                      size="small"
                      sx={{
                        // @ts-ignore
                        backgroundColor: stringToPastelColor(
                          // @ts-ignore
                          itm?.type,
                        ),
                      }}
                    />
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          )}
        </div>
      );
    },
    [onSend, onCopy, type],
  );

  if (!items?.length) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 52px)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <List disablePadding dense sx={{ height: '100%' }}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemData={items}
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    className="scrollbar"
                    height={height}
                    width={width}
                    ref={ref}
                    itemSize={ItemSize}
                    overscanCount={5}
                    itemKey={keyExtractor}
                  >
                    {ListItemRender}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </List>
        <ContentDetail
          type={type}
          open={open}
          setOpen={setOpen}
          item={item}
          onCopy={onCopy}
        />
      </Box>
    </Box>
  );
}
