import { useTranslate } from 'ra-core';
import { useState } from 'react';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';

import { StyledIconButton } from '@components/discussions/styledIconButton';
import { ShyText } from '@components/fields';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  BooleanOperator,
  Condition,
  FormStep,
  QuestionType,
  StepLink,
} from '@teammay/form-core';

import { StepNodeType } from '../../types';
import { ConditionEdit, ConditionForm } from '../conditions/ConditionEdit';
import { EditWrapper } from '../EditWrapper';
import { MenuWrapper } from '../MenuWrapper';
import { StepEdit } from '../steps/StepEdit';
export function StepNode({
  id,
  data: { step, onChange, link },
}: NodeProps<StepNodeType['data']>) {
  const translate = useTranslate();

  const { setNodes } = useReactFlow();
  const handleChanged = (result: Partial<FormStep>) => {
    onChange({ updatedStep: result as FormStep });
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, question: result };
        }
        return node;
      }),
    );
  };
  const [condition, setCondition] = useState<ConditionForm | null>(null);
  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={false} />
      <MenuWrapper
        content={step}
        onChange={handleChanged}
        FormComponent={StepEdit}
        onDelete={
          step.links?.length
            ? undefined
            : () => onChange({ deleteStep: step.id })
        }
      >
        <Box
          sx={{
            p: 1,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {Boolean(link) && (
            <IconButton onClick={() => setCondition(link.condition)}>
              <AccountTreeIcon />
            </IconButton>
          )}
          <Box sx={{ flex: 1 }}>
            <ShyText source="title" record={step} oneline />
            <ShyText source="description" record={step} oneline />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <Tooltip title={translate('forms.step.createQuestion')}>
              <StyledIconButton
                onClick={() =>
                  onChange({
                    addQuestion: {
                      id: self.crypto.randomUUID(),
                      title: 'New Question',
                      description: '',
                      type: QuestionType.RADIO,
                      hideCondition: null,
                      options: [],
                      stepId: step.id,
                      order: 0,
                    },
                  })
                }
              >
                <QuestionMarkIcon />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title={translate('forms.step.createTemplate')}>
              <StyledIconButton
                onClick={() =>
                  onChange({
                    addTemplate: {
                      id: self.crypto.randomUUID(),
                      title: 'New Template',
                      description: '',
                      stepId: step.id,
                      order: 0,
                      image: '',
                    },
                  })
                }
              >
                <InsertPhotoIcon />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Box>
      </MenuWrapper>
      <EditWrapper
        content={condition as ConditionForm}
        onSave={(content) => {
          Object.assign(link, { condition: { id: condition.id, ...content } });
          setCondition(null);
        }}
        open={!!condition}
        onCancel={() => setCondition(null)}
        FormComponent={ConditionEdit}
        defaultValues={{ booleanOperator: BooleanOperator.AND }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        onClick={() => {
          const to = {
            id: self.crypto.randomUUID(),
            title: 'New Step',
            links: [],
          };
          const newCondition: Condition = {
            id: self.crypto.randomUUID(),
            booleanOperator: BooleanOperator.AND,
            children: [],
            questionRules: [],
            dataRules: [],
          };
          const newLink: StepLink = {
            id: self.crypto.randomUUID(),
            stepId: step.id,
            nextStepId: to.id,
            order: step.links.length,
            conditionId: newCondition.id,
            condition: newCondition,
          };
          onChange({
            addStep: {
              from: step as FormStep,
              to,
              link: newLink,
            },
          });
        }}
      />
    </>
  );
}
