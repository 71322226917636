import { Title, useTranslate } from 'react-admin';

import { Paper, Typography, Link, List, ListItem } from '@mui/material';
import { Box } from '@mui/system';

export const Help = () => {
  const translate = useTranslate();

  return (
    <Paper sx={{ padding: '1rem', minHeight: '100%' }} elevation={0}>
      <Title title={translate('title.help')} />
      <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
        {translate('title.help')}
      </Typography>

      <List>
        <ListItem>
          <Link
            href="https://drive.google.com/drive/u/0/folders/1IcuHpTpj5sqNsC5OKcXVVuxrR0zvmnbb"
            rel="noopener noreferrer"
            target="_blank"
            underline="hover"
          >
            Aide May
          </Link>
        </ListItem>
        <ListItem>
          <Typography variant="subtitle1" color="primary">
            Rafraichir le BO
          </Typography>
        </ListItem>
        <Box sx={{ ml: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">
              Chrome/Firefox et Windows/Linux
            </Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>
                Appuyez simultanément sur : <kbd>Ctrl</kbd> +<kbd>shift</kbd> +{' '}
                <kbd>R</kbd> OU <kbd>Ctrl</kbd> + <kbd>F5</kbd>
              </Typography>
              <Typography variant="body2">
                (shift étant la touche près de Ctrl avec une flèche vers le
                haut, la touche pour faire des majuscules !)
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">Chrome et Mac</Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>
                Appuyez simultanément sur <kbd>command</kbd> (ou <kbd>cmd</kbd>)
                + <kbd>shift</kbd> + <kbd>R</kbd>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">Safari et Mac</Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>
                Appuyez simultanément sur <kbd>command</kbd> (ou <kbd>cmd</kbd>)
                + <kbd>option</kbd> + <kbd>E</kbd>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">Internet Explorer</Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>
                Changez de navigateur. Dans l’incapacité de le faire…{' '}
              </Typography>
              <Typography>
                Appuyez sur <kbd>Ctrl</kbd> + <kbd>F5</kbd>
              </Typography>
            </Box>
          </Box>
        </Box>
        <ListItem sx={{ mt: '1.5rem' }}>
          <Typography variant="body2">
            Pour toute question technique ou remontée de bug, vous pouvez nous
            contacter directement sur le channel slack #help-technique.
            <br />
          </Typography>
        </ListItem>
      </List>
    </Paper>
  );
};
