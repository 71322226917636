import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const timezoneSlice = createSlice({
  name: 'timezone',
  initialState: 'Europe/Paris' as string,
  reducers: {
    setTimezone(
      state,
      { payload: { timezone } }: PayloadAction<{ timezone: string }>,
    ) {
      return timezone;
    },
  },
});

export const { setTimezone } = timezoneSlice.actions;

export default timezoneSlice.reducer;
