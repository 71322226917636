import { useRecordContext, useResourceContext } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { Edit as EditIcon } from '@mui/icons-material';

import { Button } from './Button';

export const EditButton = ({ redirect }: { redirect?: string }) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const { pathname } = useLocation();
  return (
    <Button
      to={{
        pathname: `/${resource}/${record.id}`,
        state: { redirect: redirect || pathname },
      }}
      startIcon={<EditIcon />}
      color="primary"
      size="small"
    >
      Modifier
    </Button>
  );
};
