import { groupBy } from 'lodash';
import React from 'react';
import { Path } from 'react-hook-form';

import { User } from '@boTypes/user';
import { ListSubheader, MenuItem } from '@mui/material';
import { userFullName } from '@utils/staffUser';

import {
  AutocompleteSelectInput,
  AutocompleteSelectInputProps,
  SelectInput,
} from './selectInput';
import { useSelector } from '../../store';
import { stringToColor } from '../../utils/color';

export function StaffSelectInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  isLoadingStaff,
  staffUsers,
  withColor = false,
  groupByJobTitle = false,
  ...inputProps
}: Omit<
  React.ComponentProps<typeof SelectInput<TFieldValues, TName>>,
  'children' | 'disabled'
> & {
  isLoadingStaff: boolean;
  withColor?: boolean;
  groupByJobTitle?: boolean;
  staffUsers: Pick<User, 'id' | 'firstName' | 'lastName' | 'jobTitle'>[];
}) {
  const userId = useSelector((state) => state.user.userId);
  const usersByJobTitle = groupByJobTitle
    ? groupBy(staffUsers, 'jobTitle')
    : [];

  return (
    <SelectInput {...inputProps} disabled={isLoadingStaff}>
      {isLoadingStaff && <MenuItem disabled>Chargement...</MenuItem>}
      {groupByJobTitle && Object.keys(usersByJobTitle).length > 1
        ? Object.entries(usersByJobTitle).map(([title, usr]) => {
            return [
              <ListSubheader key={title}>{title}</ListSubheader>,

              ...usr.map((u) => {
                const fullName = userFullName(u, Number(userId));
                return (
                  <MenuItem value={u.id} key={u.id}>
                    {withColor && (
                      <span
                        style={{
                          width: '1rem',
                          height: '1rem',
                          backgroundColor: stringToColor(fullName),
                          borderRadius: '50%',
                          marginRight: '1rem',
                        }}
                      />
                    )}
                    {userFullName(u, Number(userId))}
                  </MenuItem>
                );
              }),
            ];
          })
        : staffUsers.map((u) => {
            const fullName = userFullName(u, Number(userId));
            return (
              <MenuItem value={u.id} key={u.id}>
                {withColor && (
                  <span
                    style={{
                      width: '1rem',
                      height: '1rem',
                      backgroundColor: stringToColor(fullName),
                      borderRadius: '50%',
                      marginRight: '1rem',
                    }}
                  />
                )}
                {fullName}
              </MenuItem>
            );
          })}
    </SelectInput>
  );
}

export function AutocompleteStaffSelectInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  isLoadingStaff,
  staffUsers,
  ...inputProps
}: Omit<
  AutocompleteSelectInputProps<TFieldValues, TName>,
  'children' | 'disabled' | 'options' | 'defaultValue' | 'loading'
> & {
  isLoadingStaff: boolean;
  staffUsers: Pick<User, 'id' | 'firstName' | 'lastName'>[];
}) {
  const userId = useSelector((state) => state.user.userId);
  return (
    <AutocompleteSelectInput
      {...inputProps}
      disabled={isLoadingStaff}
      loading={isLoadingStaff}
      defaultValue={{ id: userId, label: 'Moi' }}
      options={staffUsers.map((u) => ({
        id: u.id,
        label: userFullName(u, Number(userId)),
      }))}
    />
  );
}
