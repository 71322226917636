// import './wdyr'; // uncomment to get why did you render running
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import * as Sentry from '@sentry/react';

import App from './App';

import './index.css';

if (process.env.NODE_ENV === 'production') {
  const dsn = window.env.SENTRY_URL;
  const isStaging = window.env.BACKEND_URL.includes('staging');
  Sentry.init({
    dsn,
    environment: isStaging ? 'staging' : 'production',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [
      'localhost',
      window.env.S3_ENDPOINT_URL,
      window.env.CMS_URL,
      window.env.SEARCH_URL,
      window.env.BACKEND_URL,
      window.env.SEARCH_URL,
      'https://pro.may-care.fr',
      'https://pro.staging.may-test-env.fr',
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01,
    // Session Replay
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 0.01,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications',
    ],
  });
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
