import { Roles } from '../types/user';

const resourceRoles = {
  ['consultations']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['library']: ['any'],
  ['seminaire']: ['any'],
  ['children']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['pregnancies']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['patients']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['midwife-subjects']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['nurse-subjects']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['midwifesubjects']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['calendars']: [
    Roles.HEALTH_PRO,
    Roles.AVAILABILITIES,
    Roles.ADMIN,
    Roles.STAFF_MANAGER,
  ],
  ['forms']: [Roles.ADMIN, Roles.FORM_EDITOR],
  ['nursesubjects']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['discussions']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['discussions-midwife']: [Roles.HEALTH_PRO, Roles.ADMIN],
  ['categories']: [Roles.ADMIN],
  ['scenarios']: [Roles.ADMIN],
  ['companies']: [Roles.ADMIN],
  ['plans']: [Roles.ADMIN],
  ['consultation-categories']: [Roles.ADMIN],
  ['users']: [Roles.ADMIN, Roles.STAFF_MANAGER],
  ['subjects-explorer']: [Roles.ADMIN, Roles.SUPERUSER, Roles.STAFF_MANAGER],
  ['pricing-pro']: [Roles.ADMIN],
  ['admin']: [Roles.ADMIN, Roles.STAFF_MANAGER, Roles.AFFILIATIONS_MANAGER],
  ['code-affiliations']: [Roles.ADMIN],
};

export const menuFilterFunction =
  (userRoles: Roles[], _email?: string) =>
  (resource: { name: string; icon?: any }) => {
    if (!resource.icon || !resourceRoles[resource.name]) {
      return false;
    }
    if (resourceRoles[resource.name].includes('any')) {
      return true;
    }
    return userRoles?.some((userRole) =>
      resourceRoles[resource.name].includes(userRole),
    );
  };
