import { useTranslate } from 'react-admin';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { Guide } from '@boTypes/guide';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import { Loader } from '@components/Loader';
import { PressableOpacity } from '@components/PressableOpacity';
import { Close, DifferenceOutlined, Info, MenuBook } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';

import { ContentSuggestionFeedback } from './Feedback';
import { SuggestionContentType, SuggestionList } from './SuggestionList';
import { logSimilarQuestionOpen } from '../../../../analytics/events';
import { SimilarQuestionDialog } from '../../../../entities/similarQuestion/dialog';
import { COLORS } from '../../../../themes';

export const ContentSuggestion = ({
  onClose,
  guides,
  posts,
  masterclasses,
  macro,
  isLoading,
  openLibrary,
  handleContentSend,
  handleCopyContent,
  setSimilarQuestionDialog,
  openSimilarQuestionDialog,
  subjectId,
  withinLibrary = false,
}: {
  onClose?: () => void;
  isLoading: boolean;
  openLibrary?: () => void;
  guides: Guide[];
  posts: Post[];
  masterclasses: Masterclass[];
  macro: Macro[];
  subjectId: number;
  setSimilarQuestionDialog?: (value: boolean) => void;
  openSimilarQuestionDialog?: boolean;
  handleContentSend: ({
    type,
    content,
    contentId,
    contentType,
  }: {
    type: DiscussionEventType;
    content: string;
    contentId: number;
    contentType: string;
  }) => void;
  handleCopyContent: (
    text: string,
    macroId: number,
    macroSuggestionId: number,
  ) => void;
  withinLibrary?: boolean;
}) => {
  const translate = useTranslate();
  const hasSuggestions =
    !isLoading &&
    (macro.length > 0 ||
      guides.length > 0 ||
      posts.length > 0 ||
      masterclasses.length > 0);
  return (
    <Paper elevation={0} sx={{ m: 0, p: 0 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mx: 2,
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {translate('contentSuggestion.title')}
        </Typography>
        {!withinLibrary && (
          <PressableOpacity onPress={onClose}>
            <Close />
          </PressableOpacity>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mx: 2 }}>
        <Info sx={{ mr: 1, color: COLORS.GREY_TEXT, fontSize: '1rem' }} />
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            verticalAlign: 'center',
            fontSize: '0.75rem',
          }}
        >
          {translate('contentSuggestion.subtitle')}
        </Typography>
      </Box>
      {setSimilarQuestionDialog && (
        <Button
          size="small"
          onClick={() => {
            logSimilarQuestionOpen(subjectId);
            setSimilarQuestionDialog(true);
          }}
          color="primary"
          sx={{ mx: 2 }}
          startIcon={<DifferenceOutlined />}
        >
          {translate('similarQuestion.button')}
        </Button>
      )}
      {openSimilarQuestionDialog && (
        <SimilarQuestionDialog
          opened={openSimilarQuestionDialog}
          close={() => {
            onClose?.();
            setSimilarQuestionDialog(false);
          }}
          subjectId={subjectId}
        />
      )}
      {isLoading && <Loader />}
      {!hasSuggestions && (
        <Typography sx={{ m: 2 }}>
          {translate('contentSuggestion.empty')}
        </Typography>
      )}
      {hasSuggestions && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: 2,
            marginRight: 2,
            gap: 2,
          }}
        >
          <Typography variant="body2">
            {translate('contentSuggestion.assessment')}
          </Typography>
          <ContentSuggestionFeedback subjectId={subjectId} />
        </Box>
      )}
      <SuggestionList
        title={translate('contentSuggestion.macro')}
        items={macro}
        type={SuggestionContentType.MACRO}
        onContentCopy={handleCopyContent}
        subjectId={subjectId}
      />
      <SuggestionList
        title={translate('contentSuggestion.guide')}
        items={guides}
        type={SuggestionContentType.GUIDES}
        subjectId={subjectId}
        onContentSend={handleContentSend}
      />
      <SuggestionList
        title={translate('contentSuggestion.post')}
        items={posts}
        onContentSend={handleContentSend}
        subjectId={subjectId}
        type={SuggestionContentType.POSTS}
      />
      <SuggestionList
        title={translate('contentSuggestion.masterclass')}
        items={masterclasses}
        onContentSend={handleContentSend}
        subjectId={subjectId}
        type={SuggestionContentType.MASTERCLASSES}
      />
      <Box
        sx={{
          mx: 2,
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!withinLibrary && (
          <Button
            size="small"
            onClick={openLibrary}
            startIcon={<MenuBook />}
            color="primary"
          >
            {translate('contentSuggestion.openLibrary')}
          </Button>
        )}
        {hasSuggestions && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {withinLibrary && (
              <Typography variant="body2">
                {translate('contentSuggestion.assessment')}
              </Typography>
            )}
            <ContentSuggestionFeedback subjectId={subjectId} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
