import type { DebounceSettings, DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import { useRef, useEffect } from 'react';

export const useDebouncedCallback = <T extends (...arg: any) => any>(
  callback: T,
  delay: number,
  options?: DebounceSettings,
): DebouncedFunc<T> => {
  const debounceCallbackRef = useRef(callback);
  const debounceRef = useRef(debounce(callback, delay, options));
  useEffect(() => {
    if (callback !== debounceCallbackRef.current) {
      debounceRef.current = debounce(callback, delay, options);
    }
    const debouncer = debounceRef.current;
    return () => debouncer.cancel();
    // We do not want options to be part of the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay]);
  return debounceRef.current;
};
