import { useState } from 'react';
import { useTranslate } from 'react-admin';

import { useDiscussionContext } from '@hooks/useDiscussionContext';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { IconButton, SxProps, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { FollowupList } from './followupList';
import { FormList } from './formList';
import { DiscussionContext } from '../../common';
import { COLORS } from '../../themes';

export const PatientFile = ({
  sx,
  onChartPress,
}: {
  sx?: SxProps;
  onChartPress?: () => void;
}) => {
  const t = useTranslate();
  const [displayed, setDisplayed] = useState<'followUp' | 'forms'>('followUp');
  const discussionContext = useDiscussionContext();
  // follow up list
  const [create, setCreate] = useState<boolean>(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          boxShadow: `0 3px 5px -4px ${COLORS.GREY_LAYOUT}`,
        }}
      >
        <Typography
          variant="body1"
          style={{ fontSize: '1rem', fontWeight: 500 }}
        >
          {t('patientFile.title')}
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <IconButton onClick={() => setCreate(true)}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={() => setDisplayed('followUp')}>
            <FamilyRestroomIcon
              color={displayed === 'followUp' ? 'primary' : undefined}
            />
          </IconButton>
          {discussionContext === DiscussionContext.MIDWIFE && ( // TODO remove this condition when the feature is ready
            <IconButton onClick={() => setDisplayed('forms')}>
              <AccountTreeIcon
                color={displayed === 'forms' ? 'primary' : undefined}
              />
            </IconButton>
          )}
          {onChartPress && (
            <IconButton onClick={onChartPress}>
              <ShowChartIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      {displayed === 'followUp' && (
        <FollowupList create={create} setCreate={setCreate} />
      )}
      {displayed === 'forms' && <FormList />}
    </Box>
  );
};
