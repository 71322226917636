import React, { useCallback, useEffect } from 'react';
import {
  Datagrid,
  ListContextProvider,
  ThemeProvider,
  useListContext,
  useListFilterContext,
} from 'react-admin';

import { Divider, Tabs, Tab } from '@mui/material';

import { mayDatagridTheme } from '../themes';
import type { Discussion, TabObject } from '../types';

interface TabsDatagridProps {
  tabs: Array<{ id: string; name: string }>;
  setTab: React.Dispatch<React.SetStateAction<TabObject>>;
  tab: TabObject;
  children?: React.ReactNode;
  rowClick?: string;
}

export const TabsDatagrid = ({
  tabs,
  tab,
  setTab,
  children,
  ...rest
}: TabsDatagridProps) => {
  const listContext = useListContext<Discussion>();
  const { filterValues, setFilters } = useListFilterContext();
  useEffect(() => {
    if (filterValues.status === tab.id) {
      return;
    }
    setFilters({ ...filterValues, status: tab.id }, {});
  }, [tab.id, filterValues, setFilters]);

  const data = listContext.data ?? [];

  const handleChange = useCallback(
    (event, status) => {
      tab.id !== status && setTab(tabs.find((t) => t.id === status));
    },
    [tabs, setTab, tab.id],
  );

  return (
    <>
      <Tabs
        variant="fullWidth"
        centered
        value={tab.id}
        onChange={handleChange}
        indicatorColor="primary"
      >
        {tabs.map(({ id, name }) => (
          <Tab key={id} label={name} value={id} />
        ))}
      </Tabs>
      <Divider />
      <ThemeProvider theme={mayDatagridTheme}>
        <ListContextProvider
          value={{
            ...listContext,
            data: Object.values(data),
          }}
        >
          <Datagrid {...rest} bulkActionButtons={false}>
            {children}
          </Datagrid>
        </ListContextProvider>
      </ThemeProvider>
    </>
  );
};
