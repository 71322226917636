import React, { useState, useCallback, useEffect } from 'react';

import { Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const computeAppert = (weight: number) =>
  // Appert method: milk quantity = 250 + "weight in g" / 10
  250 + weight / 10;

export const AppertDialog = React.memo<Props>(({ open, setOpen }: Props) => {
  const [weight, setWeight] = useState(3000);
  const [nbBottles, setNbBottles] = useState(4);
  const [milk, setMilk] = useState(550);
  const [bottleQuantity, setBottleQuantity] = useState(137);

  const close = useCallback(() => setOpen(false), [setOpen]);

  const updateWeight = useCallback(
    (event) => {
      const newWeight = event.target.value;
      setWeight(newWeight);
      setMilk(computeAppert(newWeight));
    },
    [setMilk, setWeight],
  );

  const updateNbBottles = useCallback(
    (event) => {
      setNbBottles(event.target.value);
    },
    [setNbBottles],
  );

  useEffect(() => {
    setBottleQuantity(Math.round(milk / nbBottles));
  }, [setBottleQuantity, milk, nbBottles]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="Méthode Appert"
      aria-describedby="Calculateur méthode Appert"
      maxWidth="md"
    >
      <DialogTitle onClose={close}>Calculateur méthode Appert</DialogTitle>
      <DialogContent>
        <div>
          <TextField
            label="Poids de l'enfant (en g)"
            inputProps={{ type: 'number', step: 10 }}
            value={weight}
            onChange={updateWeight}
          />
        </div>
        <div>
          <TextField
            label="Nombre biberons"
            inputProps={{
              type: 'number',
              step: 1,
              min: 4,
              max: 8,
            }}
            value={nbBottles}
            onChange={updateNbBottles}
          />
        </div>
        <Typography style={{ marginTop: '1rem' }}>
          Quantité de lait recommandé :{milk} mL / 24h
        </Typography>
        <Typography>
          Soit :{bottleQuantity}
          mL par biberon
        </Typography>

        <Typography variant="caption">
          Formule utilisée (Appert): 250mL + (poids en gramme) / 10
        </Typography>
      </DialogContent>
    </Dialog>
  );
});
