import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@hooks/queryWrappers';
import {
  Button,
  DialogActions,
  Dialog,
  styled,
  CircularProgress,
} from '@mui/material';
import '@vonage/video-publisher/video-publisher.js';
import '@vonage/video-subscribers/video-subscribers.js';

import { ConsultationWithRelationIds } from '../../types';

const Root = styled('div')({
  width: '100%',
  height: '100vh',
  backgroundColor: 'black',
  position: 'relative',
});

// NOTE: It was a pain to adjust Tokbox component CSS. Documentation is lacking or confusing, for tokbox-react.
// In the end the idea that worked came from https://www.nexmo.com/blog/2019/07/16/opentok-react-components-dr
// = override tokbox css classes in App.js and use absolute positioning.
// When using absolute positioning, parent div gets height 0 so to still have root background color,
// we must set dimensions with vw and vh instead of %

export const Tokbox = ({
  record,
  openVisio,
}: {
  record: ConsultationWithRelationIds;
  openVisio: boolean;
  setOpenVisio: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [publisher, setPublisher] = useState(null);
  const [subscribers, setSubscribers] = useState(null);
  const { data } = useQuery<{ user_tok_token: string; tok_api_key: string }>(
    ['tokboxToken', record.visioCode],
    { method: 'GET', url: `/api/toktoken/${record.visioCode}` },
    {
      enabled: record.visioCode && openVisio,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    if (
      !record.visioCode ||
      !data?.user_tok_token ||
      !data?.tok_api_key ||
      !publisher ||
      !subscribers ||
      session
    ) {
      return () => null;
    }
    //@ts-ignore
    const OT = window.OT;

    // Initialize an OpenTok Session object
    const newSession = OT.initSession(data.tok_api_key, record.visioCode);
    setSession(newSession);

    publisher.session = newSession;
    publisher.token = data.user_tok_token;
    publisher.properties = {
      height: 150,
      width: 200,
    };
    subscribers.session = newSession;
    subscribers.token = data.user_tok_token;
    subscribers.properties = {
      height: '100%',
      width: '100%',
    };
  }, [
    record.visioCode,
    data?.user_tok_token,
    data?.tok_api_key,
    session,
    publisher,
    subscribers,
    openVisio,
  ]);

  const closeVisio = useCallback(() => {
    location.reload();
  }, []);

  return (
    <Dialog
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={openVisio}
      PaperProps={{
        sx: {
          minHeight: '90vh',
          maxHeight: '90vh',
          maxWidth: '90%',
        },
      }}
    >
      <Root>
        {/* @ts-ignore */}
        <video-subscribers
          ref={setSubscribers}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
        {/* @ts-ignore */}
        <video-publisher
          ref={(ref) => {
            setPublisher(ref);
          }}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: 150,
            width: 200,
          }}
        />
        {Boolean(!session) && (
          <CircularProgress
            sx={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        )}
      </Root>
      <DialogActions
        sx={{
          backgroundColor: 'black',
        }}
      >
        <Button onClick={closeVisio} color="primary">
          Raccrocher
        </Button>
      </DialogActions>
    </Dialog>
  );
};
