import React from 'react';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
  Box,
  CardActions,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  styled,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { logContentView } from '../../analytics/events';
import { COLORS } from '../../themes';
import { Masterclass } from '../../types';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
const IMAGE_HEIGHT = 96;
const TITLE_LINE_NB = 2;
const TITLE_HEIGHT = TITLE_LINE_NB * 40;
export const HEIGHT = IMAGE_HEIGHT + TITLE_HEIGHT;

const StyledImage = styled('img')({
  width: '20%',
  height: '100%',
  objectFit: 'cover',
});

export const MasterclassItemDialog = ({
  item,
  opened,
  close,
}: {
  item: Masterclass;
  opened: boolean;
  close: () => void;
}) => {
  const { formats, url } = item?.masterclass_expert.photo || {
    url: item?.expertImageThumbnailUrl ?? '',
  };
  const translate = useTranslate();

  return (
    <Dialog
      open={opened}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth="md"
    >
      <DialogTitle onClose={close}>{item.title}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <StyledImage src={formats?.medium?.path ?? url} alt={item.title} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="subtitle2"
              component="h2"
              color="primary"
              sx={{ marginLeft: '1rem' }}
            >
              {translate('library.summary')}
            </Typography>
            <ReactMarkdown>{item.summary}</ReactMarkdown>
          </Box>
        </Box>
        <Divider sx={{ py: 1 }} />
        {item.trackTitles && (
          <>
            <Typography
              variant="subtitle2"
              component="h2"
              color="primary"
              sx={{ py: '1rem' }}
            >
              {translate('library.tracks')}
            </Typography>
            <List disablePadding dense>
              {item.trackTitles.map((trackTitle, index) => (
                <ListItem disableGutters key={trackTitle}>
                  {`${index + 1} - ${trackTitle}`}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const MasterclassItem = ({
  item,
  onSend,
}: {
  item: Masterclass;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
}) => {
  const { title, masterclass_expert: expert } = item;
  const { formats, url } = expert.photo || {
    url: item?.expertImageThumbnailUrl ?? '',
  };
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    logContentView('masterclass', item.id);
    setOpen(true);
  };
  const close = () => setOpen(false);
  const masterclassInfo = {
    type: DiscussionEventType.MASTERCLASS,
    content: String(item.entryId || item.id),
  };

  return (
    <CMSCard
      width={WIDTH}
      height={HEIGHT}
      style={{
        background: `linear-gradient(to bottom, ${COLORS.GREEN['500']} 0%, ${COLORS.GREEN['800']} 100%)`,
      }}
    >
      {open ? (
        <MasterclassItemDialog item={item} opened={open} close={close} />
      ) : null}
      <CMSCardActionArea
        height={HEIGHT}
        onClick={onClick}
        sx={{ alignItems: 'center' }}
      >
        {item.sent && <CMSCardSentOverlay />}
        <CMSCardContent>
          <CMSCardTitle variant="subtitle2" component="h2" clamp={2}>
            {title}
          </CMSCardTitle>
          <Typography
            variant="body2"
            component="h3"
            sx={{
              color: COLORS.WHITE_TEXT,
              fontWeight: '100',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {expert.name}
          </Typography>
        </CMSCardContent>
        <CardMedia
          sx={{ objectFit: 'contain' }}
          component="img"
          alt={title}
          height={String(IMAGE_HEIGHT)}
          image={formats?.thumbnail?.path ?? url}
          title={title}
        />
        {item.sent && (
          <DownloadDoneIcon
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              bottom: theme.spacing(0),
            })}
          />
        )}
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0 }}>
          <SendIcon
            onClick={() =>
              onSend(masterclassInfo, DiscussionEventType.MASTERCLASS, item.id)
            }
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};
