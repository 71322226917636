import { produce } from 'immer';
import _difference from 'lodash/difference';
import _memoize from 'lodash/memoize';

import * as Sentry from '@sentry/browser';

export const formatSubjectAttributions = _memoize(
  (data: { staffUserIds: number[]; subjectIds: number[][] }) => {
    return {
      staffUserFromSubjectId: data?.staffUserIds.reduce(
        (acc, staffId, index) => {
          data.subjectIds[index].forEach((subjectId) => {
            acc[subjectId] = staffId;
          });
          return acc;
        },
        {} as Record<number, number>,
      ),
      subjectsFromStaffUSerId: data?.staffUserIds.reduce(
        (acc, staffId, index) => {
          acc[staffId] = data.subjectIds[index];
          return acc;
        },
        {} as Record<number, number[]>,
      ),
    };
  },
);
export const addSubjectInAttributions = produce(
  (
    previousData:
      | {
          staffUserIds: number[];
          subjectIds: number[][];
        }
      | undefined,
    newData: {
      subjectIds: number[];
      staffUserIds: number[];
    },
  ) => {
    if (!previousData || !previousData.staffUserIds.length) {
      return {
        staffUserIds: newData.staffUserIds,
        subjectIds: [newData.subjectIds],
      };
    }

    if (newData.staffUserIds.length !== newData.subjectIds.length) {
      Sentry.captureException(
        new Error(
          `Invalid attribution data : received ${newData.staffUserIds.length} staffUserIds and ${newData.subjectIds.length} subjectIds`,
        ),
      );
    }

    // this may seem overkill but as this function is memoized, it does not cost much to use it and helps to optimize the code
    const formattedData = formatSubjectAttributions(previousData);

    for (let index = 0; index < newData.staffUserIds.length; index++) {
      const previousAttributedStaff =
        formattedData.staffUserFromSubjectId[newData.subjectIds[index]];

      if (previousAttributedStaff) {
        if (previousAttributedStaff === newData.staffUserIds[index]) {
          // already attributed to this staff skip
          continue;
        }

        // attributed to another staff, remove it
        const previousStaffIndex = previousData.staffUserIds.indexOf(
          previousAttributedStaff,
        );
        if (previousStaffIndex >= 0) {
          const previousSubjectIndex = previousData.subjectIds[
            previousStaffIndex
          ].indexOf(newData.subjectIds[index]);
          if (previousSubjectIndex >= 0) {
            previousData.subjectIds[previousStaffIndex].splice(
              previousSubjectIndex,
              1,
            );
          }
        }
      }

      const staffUserId = newData.staffUserIds[index];
      const subjectId = newData.subjectIds[index];
      const staffIndex = previousData.staffUserIds.indexOf(staffUserId);
      if (staffIndex < 0) {
        previousData.staffUserIds.push(staffUserId);
        previousData.subjectIds.push([subjectId]);
      } else {
        previousData.subjectIds[staffIndex].push(subjectId);
      }
    }
  },
);

export const removeSubjectFromAttributions = produce(
  (
    previousData:
      | {
          staffUserIds: number[];
          subjectIds: number[][];
        }
      | undefined,
    removedData: {
      subjectIds: number[];
      staffUserIds: number[];
    },
  ) => {
    if (!previousData) {
      return previousData;
    }

    if (removedData.staffUserIds.length !== removedData.subjectIds.length) {
      Sentry.captureException(
        new Error(
          `Invalid remove attribution data : received ${removedData.staffUserIds.length} staffUserIds and ${removedData.subjectIds.length} subjectIds`,
        ),
      );
    }

    for (let index = 0; index < removedData.staffUserIds.length; index++) {
      const staffUserId = removedData.staffUserIds[index];
      const subjectId = removedData.subjectIds[index];
      const staffIndex = previousData.staffUserIds.indexOf(staffUserId);
      if (staffIndex < 0) {
        continue;
      }
      const previousSubjectIndex =
        previousData.subjectIds[staffIndex].indexOf(subjectId);
      if (previousSubjectIndex >= 0) {
        previousData.subjectIds[staffIndex].splice(previousSubjectIndex, 1);
      }
    }
  },
);

export const ensureStaffListInAttributions = produce(
  (
    previousData:
      | {
          staffUserIds: number[];
          subjectIds: number[][];
        }
      | undefined,
    userIds: number[],
  ) => {
    if (!previousData) {
      return previousData;
    }
    const notConnectedStaff = _difference(previousData?.staffUserIds, userIds);
    const indexToRemove = previousData.staffUserIds
      .map((id, index) => [id, index])
      .filter(([id]) => notConnectedStaff.includes(id))
      .map(([, index]) => index)
      .reverse();

    indexToRemove.forEach((index) => {
      previousData.staffUserIds.splice(index, 1);
      previousData.subjectIds.splice(index, 1);
    });
  },
);

export const addSubjectToWaitingList = produce(
  (previousData: number[] | undefined, newData: number) => {
    if (!previousData) {
      return [newData];
    }
    if (!previousData.includes(newData)) {
      previousData.push(newData);
    }
  },
);

export const removeSubjectFromWaitingList = produce(
  (previousData: number[] | undefined, newData: number) => {
    if (!previousData) {
      return previousData;
    }
    const index = previousData.indexOf(newData);
    if (index >= 0) {
      previousData.splice(index, 1);
    }
  },
);
