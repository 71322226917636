import { required, SelectInput, SimpleForm, TextInput } from 'react-admin';

import { FormProps } from '../../types';
import { JobTitle } from '../../types/user';

const choices = Object.values(JobTitle).map((job) => ({
  id: job,
  label: job,
}));
export const PricingProGenericForm = (props: FormProps) => {
  return (
    <SimpleForm {...props}>
      <TextInput
        label="Nom"
        source="name"
        validate={required()}
        variant="standard"
      />
      <SelectInput
        label="Profession"
        source="jobTitle"
        choices={choices}
        optionText="label"
      />
      <TextInput
        label="Base"
        source="pricePerUnitBase"
        variant="standard"
        validate={required()}
      />
      <TextInput
        label="Nuit"
        source="pricePerUnitNight"
        variant="standard"
        validate={required()}
      />
      <TextInput
        label="Férié"
        source="pricePerUnitSundayAndHoliday"
        variant="standard"
        validate={required()}
      />
      <TextInput
        label="Coeff astreinte"
        source="onCallNonActivatedRate"
        variant="standard"
        validate={required()}
      />
    </SimpleForm>
  );
};
